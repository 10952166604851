<template>
    <v-app class="operating-hours">
        <div   v-if="isLoaded">
            <p class="operating-hours__name charge-text__info text-secondary">Operating hours</p>
            <v-row class="operating-hours__heading">
                <v-col lg="2"></v-col>
                <v-col lg="5">
                    We start as early as:
                </v-col>
                <v-col lg="5">
                    We start as early as:
                </v-col>
            </v-row>
            <v-row class="operating-hours__row py-1" align="center" v-for="workingHours in activeLocationWorkingHours" :key="hours.id">
                <v-col class="operating-hours__column" lg="2">
                    <v-checkbox
                        :label="dayNames[workingHours.day]"
                        v-model="workingHours.is_active"
                        hide-details
                        class="mt-0 pt-0"
                    />
                </v-col>
                <v-col lg="5" class="py-1">
                    <v-select
                        :items="hours"
                        v-model="workingHours.from_hour"
                        dense outlined hide-details
                        append-icon="fa-chevron-down"
                        class="operating-hours__select custom--v-select"
                        item-text="display"
                        item-value="value"
                        :disabled="!workingHours.is_active"
                    />
                </v-col>
                <v-col lg="5" class="py-1">
                    <v-select
                        :items="hours"
                        v-model="workingHours.to_hour"
                        dense outlined hide-details
                        append-icon="fa-chevron-down"
                        class="operating-hours__select custom--v-select"
                        item-text="display"
                        item-value="value"
                        :disabled="!workingHours.is_active"
                    />
                </v-col>
            </v-row>
        </div>

        <app-loading-indicator v-if="!isLoaded"/>

        <div class="operating-hours__action d-flex justify-end flex-column">
            <p class="ml-auto">
                Note: After setting your standard hours, you must block days individually if you are not available for new
                requests.
            </p>
            <v-btn
                color="primary"
                elevation="0"
                max-width="135"
                min-width="135"
                class="operating-hours__btn ml-auto charge-action__btn"
                @click="save"
            >
                Save
            </v-btn>
        </div>
    </v-app>
</template>
<script>
import { mapActions } from "vuex";
import { WORKING_DAY_NAMES, working_days_to_selection } from "@/plugins/data.js";
import AppLocationSelector from "../../../components/AppLocationSelector.vue";
import loading from "../../../mixins/loading.js";
import AppLoadingIndicator from "../../../components/AppLoadingIndicator.vue";

export default {
    props: ['activeLocation'],

    mixins: [loading],

    components: {
        AppLocationSelector,
        AppLoadingIndicator
    },

    data () {
        return {
            /** @type {WorkingHours[]} */
            activeLocationWorkingHours: [],

            hours: working_days_to_selection(),
            dayNames: WORKING_DAY_NAMES,
        }
    },

    methods: {
        ...mapActions('working_days', ['loadBranchWorkingHours', 'updateWorkingHours']),

        async save () {
            await this.updateWorkingHours([this.activeLocation, this.activeLocationWorkingHours]);
            this.$emit('close');
        },
    },

    watch: {
        activeLocation: {
            immediate: true,

            handler (newLocation) {
                this.startLoading(async () => {
                    this.activeLocationWorkingHours = await this.loadBranchWorkingHours(newLocation);
                });
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.v-simple-table-box {
    min-height: 450px;
}

.custom--v-simple-table::v-deep {

    tbody {

        tr {
            background-color: transparent !important;

            &:first-child {

                td {
                    padding-top: 15px !important;
                }
            }

            &:last-child {

                td {
                    padding-bottom: 15px !important;
                }
            }
        }

        td {
            border-bottom: 0 !important;
            padding-top: 7.5px !important;
            padding-bottom: 7.5px !important;
        }
    }
}
.custom--v-select {
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.1768115907907486px;
    color: #0D3659 !important;
}
</style>
