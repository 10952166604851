<template>
    <div class="view-image">
        <div class="tw-flex tw-items-center tw-justify-between tw-p-[10px] tw-pl-[20px]">
            <h1 class="tw-text-[24px] tw-font-semibold tw-text-[#0c3659]">View photo</h1>
            <v-btn icon class="custom--v-btn" @click.once="close">
                <img src="/assets/icons/delete.svg" alt="delete icon"/>
            </v-btn>
        </div>

        <img :src="image" alt="" class="tw-w-full tw-aspect-[5/3] tw-object-contain">

        <div v-if="actions_exist" class="view-image__actions">
            <v-btn
                color="primary"
                class="custom--v-btn v-size--medium tw-mr-[10px]"
                @click="pinImage"
            >
               Pin
            </v-btn>

            <v-btn
                color="secondary"
                outlined
                class="custom--v-btn v-size--medium"
                @click="deleteImage"
            >
                Delete
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "ViewImage",

    props: {
        image: {
            type: String,
            required: true
        },
        id: Number,
        actions_exist: Boolean
    },

    methods: {
        ...mapActions('photos', ['pin', 'delete']),

        close () {
            this.$emit('close')
        },
        pinImage () {
            this.pin({image_id: this.id});
            this.close();
        },

        deleteImage () {
            this.delete({image_id: this.id});
            this.close();
        },
    }
}
</script>

<style scoped lang="scss">
.view-image {
    &__img {
        display: inline-block;
        max-width: 100%;
        height: auto;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        h1 {
            font-size: 24px;
            font-weight: 600;
            line-height: 27px;
            color: #0c3659;

        }
    }

    &__actions {
        display: flex;
        justify-content: end;
        padding: 20px 20px 30px;

        ::v-deep {
            .custom--v-btn:first-child {
                background-color: #8F3985;
            }
        }
    }
}
</style>