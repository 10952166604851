<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" depressed
          class="!tw-h-[28px] !tw-px-[10px] !tw-bg-[#FFF] tw-border tw-border-solid tw-border-[#DDD] tw-rounded-[8px] [&:before]:tw-hidden !tw-duration-300 hover:!tw-bg-[#E4E8FF] hover:tw-border-[#4D81FF] [&:[aria-expanded=true]]:!tw-bg-[red]"
        >
          <div class="tw-flex tw-items-center tw-gap-[6px]">
            <i class="icony icony-calendar-circle-o tw-text-[#0D3659]"></i>
            <span class="tw-text-[14px] tw-text-[#0D3659] tw-font-semibold">{{ selectedDateRangeText || 'Select date range' }}</span>
          </div>
        </v-btn>
      </template>

      <v-list class="tw-grid tw-gap-[4px] !tw-p-[8px]">
        <div
          v-for="(item, index) in menuItems" :key="index"
          @click="selectDateRange(item.value)"
          class="tw-grid tw-gap-[6px] tw-p-[8px] tw-border tw-border-solid tw-border-[#E7EFF3] tw-rounded-[4px] tw-leading-none tw-cursor-pointer tw-duration-300 hover:tw-bg-[#F6F8FF]"
        >
          <strong class="tw-text-[16px] tw-font-semibold tw-text-[#0D3659]">{{ item.title }}</strong>
          <span class="tw-text-[14px] tw-font-medium tw-text-[#AAAAAA]">{{ getDateRangesDisplay(item.value) }}</span>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    ordersDeliveries: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      menuItems: [
        { title: 'Last week', value: 'lastWeek' },
        { title: 'Current week', value: 'currentWeek' },
        { title: 'Last month', value: 'lastMonth' },
        { title: 'Current month', value: 'currentMonth' },
        { title: 'Last 30 days', value: 'last30Days' }
      ],
      dateRanges: {},
      selectedDateRangeText: ''
    };
  },

  computed: {
    ...mapGetters('auth', ['activeBranch']),

    getActiveBranchId () {
      return this.activeBranch.id;
    }
  },

  methods: {
    ...mapMutations('orders', ['SET_LOADING', 'SET_DELIVERIES_L']),
    ...mapActions('orders', ['load', 'loadDeliveries']),

    formatDate(date, format = 'long') {
      const optionsLong = { year: 'numeric', month: 'long', day: 'numeric' };
      const optionsShort = { year: 'numeric', month: '2-digit', day: '2-digit' };

      if (format === 'long') {
        return new Date(date).toLocaleDateString('en-US', optionsLong);
      } else if (format === 'short') {
        const [month, day, year] = new Date(date).toLocaleDateString('en-US', optionsShort).split('/');
        return `${year}-${month}-${day}`;
      }

      return date;
    },

    getDateRanges() {
      const now = new Date();

      // Current week
      const currentWeekStart = new Date(now);
      currentWeekStart.setDate(now.getDate() - now.getDay() + 1); // Adjust to Monday
      const currentWeekEnd = new Date(now);
      currentWeekEnd.setDate(now.getDate() - now.getDay() + 7); // Adjust to Sunday

      // Last week
      const lastWeekStart = new Date(currentWeekStart);
      lastWeekStart.setDate(currentWeekStart.getDate() - 7);
      const lastWeekEnd = new Date(currentWeekEnd);
      lastWeekEnd.setDate(currentWeekEnd.getDate() - 7);

      // Current month
      const currentMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);
      const currentMonthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      // Last month
      const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);

      // Last 30 days
      const last30DaysStart = new Date(now);
      last30DaysStart.setDate(now.getDate() - 30);
      const last30DaysEnd = new Date(now);

      return {
        currentWeek: {
          display: `${this.formatDate(currentWeekStart)} - ${this.formatDate(currentWeekEnd)}`,
          log: `${this.formatDate(currentWeekStart, 'short')},${this.formatDate(currentWeekEnd, 'short')}`
        },
        lastWeek: {
          display: `${this.formatDate(lastWeekStart)} - ${this.formatDate(lastWeekEnd)}`,
          log: `${this.formatDate(lastWeekStart, 'short')},${this.formatDate(lastWeekEnd, 'short')}`
        },
        currentMonth: {
          display: `${this.formatDate(currentMonthStart)} - ${this.formatDate(currentMonthEnd)}`,
          log: `${this.formatDate(currentMonthStart, 'short')},${this.formatDate(currentMonthEnd, 'short')}`
        },
        lastMonth: {
          display: `${this.formatDate(lastMonthStart)} - ${this.formatDate(lastMonthEnd)}`,
          log: `${this.formatDate(lastMonthStart, 'short')},${this.formatDate(lastMonthEnd, 'short')}`
        },
        last30Days: {
          display: `${this.formatDate(last30DaysStart)} - ${this.formatDate(last30DaysEnd)}`,
          log: `${this.formatDate(last30DaysStart, 'short')},${this.formatDate(last30DaysEnd, 'short')}`
        }
      };
    },

    getDateRangesDisplay(range) {
      const dateRanges = this.getDateRanges();
      const dateRange = dateRanges[range];
      return dateRange.display;
    },

    async selectDateRange(rangeKey) {
      this.selectedDateRangeText = this.dateRanges[rangeKey].display;
      this.SET_LOADING(true);

      if (this.ordersDeliveries) {
        this.loadDeliveries({
          branch_id: this.getActiveBranchId,
          arrival_date_in: this.dateRanges[rangeKey].log
        }).then(() => {
          this.SET_LOADING(false);
        });
      } else {
        this.load({
          branch_id: this.getActiveBranchId,
          arrival_date_in: this.dateRanges[rangeKey].log
        }).then(() => {
          this.SET_LOADING(false);
        });
      }
    }
  },

  created() {
    this.dateRanges = this.getDateRanges();
  },
};
</script>
