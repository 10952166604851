<template>
    <section class="price__helper-truck">
        <div class="price__desc">
            <p>{{tabDescription}}</p>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        tabDescription: String
    }
}
</script>