<template>
    <div class="mobile-profile-menu">
        <v-navigation-drawer
            app
            disable-resize-watcher
            v-model="$store.state.drawer.isOpen"
        >
            <div class="mobile-profile-menu__header">
                <v-avatar
                    color="primary"
                    size="80"
                    class="mr-5"
                >
                    <img
                        :src="logo"
                        :alt="name"
                    >
                </v-avatar>
                <strong>{{ name }}</strong>
            </div>
            <v-list dense rounded>
                <v-list-item
                    link
                    v-for="{title, name, icon} in items" :key="title"
                    @click="$emit('onClickItem', name)"
                >
                    <v-list-item-icon class="mr-2">
                        <v-icon size="16" :color="title === 'Log out' ? 'primary' : ''">
                            {{ icon }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="{'text-primary' : title === 'Log out'}">
                            <strong style="font-weight: 600;">{{ title }}</strong>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        items: {
            type: Array,
            default: null
        }
    },

    computed: {
        ...mapGetters('auth', ['name', 'logo']),
    },
}
</script>