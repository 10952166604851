<template>
    <div class="travel-area">
        <h3 class="text-lg pricing-header--extra font-semibold text-secondary mb-1">
            Travel Area
        </h3>

        <p class="mb-4 service-area__desc text-secondary">
            This is the max distance you are willing to travel between location on the job.
        </p>

        <v-row>
            <v-col lg="8">
                <div class="slider-box mb-4">
                    <v-slider
                        v-model="local" hide-details
                        :min="0" :max="300"
                        class="custom--v-slider"
                    />

                    <div class="slider-box__div text-secondary">
                        {{ local }} miles
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ValueEmitter from "../../mixins/ValueEmitter";

export default {
    mixins: [ValueEmitter],

    methods: {
        saveBranchForm () {
            this.startLoading(async () => {
                await this.$store.dispatch('locations/updateBranch', this.activeBranch)
            });
        },


    },

    watch: {
        local () {
            if (this.local < 1) {
                this.local = 1;
            }
        },
    },
}
</script>