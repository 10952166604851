export default {
    namespaced: true,

    actions: {
        /**
         * Get all crews related to branch
         *
         * @param {Object} _
         * @param {Number} branch_id
         */
        async index (_, branch_id) {
            return await axios.get(`branches/${ branch_id }/crews`);
        },

        /**
         * Get a single crew related to branch
         *
         * @param {Object} _
         * @param {Number} branch_id
         * @param {Number} crew_id
         */
        async show (_, { branch_id, crew_id }) {
            return await axios.get(`branches/${ branch_id }/crews/${ crew_id }`);
        },

        /**
         * Update a crew data
         *
         * @param {Object} _
         * @param {Number} branch_id
         * @param {Number} crew_id
         * @param {Promise<Object>} data
         */
        async update (_, { branch_id, crew_id, data }) {
            return await axios.patch(`branches/${ branch_id }/crews/${ crew_id }`, data);
        },

        /**
         * Create a new crew
         *
         * @param {Object} _
         * @param {Number} branch_id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        async store (_, { branch_id, data }) {
            return await axios.post(`branches/${ branch_id }/crews`, data);
        },

        /**
         * Delete a crew
         *
         * @param {Object} _
         * @param {Number} branch_id
         * @param {Number} crew_id
         * @param {Promise<Object>} data
         */
        async destroy (_, { branch_id, crew_id }) {
            return await axios.delete(`branches/${ branch_id }/crews/${ crew_id }`);
        }
    }

}