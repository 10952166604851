export default {
    namespaced: true,

    actions: {
        /**
         * Make a request to reschedule given request
         *
         * @param {Object} _
         * @param {Number} order_id
         * @param {String} requested_date
         * @param {String} requested_time
         * @param {String} note
         * @param {Object} price
         * @param {Boolean} instantly
         * @return {Promise<void>}
         */
        async store (_, { order_id, requested_date, requested_time, note, price }) {
            return await axios.post(`customer/orders/${ order_id }/reschedule-requests/`, {
                requested_time: requested_time.split('Arrival between ')[1],
                requested_date, note, price
            });
        },

        /**
         * Accept rescheduling
         *
         * @param {Object} _
         * @param {Number} order_id
         * @param {Number} reschedule_request_id
         * @return {Promise<void>}
         */
        async approve (_, { order_id, reschedule_request_id }) {
            return await axios.patch(`customer/orders/${ order_id }/reschedule-requests/${ reschedule_request_id }`, {
                status: 'approved',
            });
        },

        /**
         * Reject rescheduling
         *
         * @param {Object} _
         * @param {Number} order_id
         * @param {Number} reschedule_request_id
         * @return {Promise<void>}
         */
        async reject (_, { order_id, reschedule_request_id }) {
            return await axios.patch(`customer/orders/${ order_id }/reschedule-requests/${ reschedule_request_id }`, {
                status: 'rejected',
            });
        },
    }
}
