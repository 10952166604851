<template>
    <div class="rating d-flex">
        <div v-for="(url, index) in stars" :key="index" @click="rating(index+1)">
            <img :src="url" alt="star icon" :class="[size === 'small' ? 'size--small' : '', isFull ? 'animation-star' : '']" />
        </div>
    </div>
</template>

<script>
export default {
    name: "AppRating",

    props: {
        starsCount: Number,
        size: String,
        readable: Boolean
    },

    data () {
        return {
           stars: [],
            isFull: false
        }
    },

    methods: {
        rating (index) {
            if(this.readable) {
                return false;
            }

            this.stars = [];

            for (var i=1; i<=5; i++) {
                if(i <= index) {
                    this.stars.push('/assets/icons/star-full.svg')
                }else {
                    this.stars.push('/assets/icons/star-o.svg')
                }
            }

        //    set animation
            const isFullStars = (star) => star === '/assets/icons/star-full.svg';
            this.isFull = this.stars.every(isFullStars)

        }
    },

    mounted () {
        for (var i=1; i<=5; i++) {
            if(this.starsCount && i <= this.starsCount) {
                this.stars.push('/assets/icons/star-full.svg')
            }else {
                this.stars.push('/assets/icons/star-o.svg')
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .rating > div:not(:last-child) {
        margin-right: 4px;
    }
    .size--small {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }

    @keyframes blurStars {
        0% {
            filter: brightness(0%);
            filter: contrast(0%);
            filter: drop-shadow(0px 0px 0px #fff);
        }
        100% {
            filter: brightness(125%);
            filter: contrast(125%);
            filter: drop-shadow(3px 3px 3px #FBAB1E);
        }
    }

    .animation-star {
        animation: blurStars 1s ease-in-out backwards;
    }
</style>