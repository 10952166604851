<template>
    <section class="price-helper">
        <div class="price__desc">
            <p>{{tabDescription}}</p>
        </div>
    </section>
</template>
<script>
 export default {
    props: {
         tabDescription: String,
    }
 }
</script>
<style scoped>

</style>