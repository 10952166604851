import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    customClass: {
        title: 'sweet__titleImportant'
    },
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

/**
 * Alert success message
 *
 * @param {String} title
 * @param {String} description
 */
export const sweetAlertSuccess = (title, description = '') => {
    Toast.fire({
        title: title,
        text: description,
        icon: 'success',
    });
}

/**
 * Alert error message
 *
 * @param {string} title
 * @param {string} description
 */
export const sweetAlertError = (title, description = '') => {
    Toast.fire({
        title: title,
        text: description,
        icon: 'error',
    });
}

/**
 * Show alert for confirmation particular action
 *
 * @param {string}   text - Confirmation text
 * @param {string}   confirmButtonText - Confirmation button text
 * @param {Function} onConfirm - Function to handle on confirm
 * @return {void}
 */
export const successConfirmAction = (text, confirmButtonText, onConfirm) => {
    Swal.fire({
        title: "Confirmation",
        text, confirmButtonText,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#48e384",
    }).then(async result => {
        if (result.isConfirmed) {
            await onConfirm(result);
        }
    });
};

export const warningConfirmAction = (text, confirmButtonText, onConfirm, input = null, placeholder = '') => {
    Swal.fire({
        title: "Confirmation",
        text, confirmButtonText,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: 'Back',
        confirmButtonColor: "#8f3984",
        input: input,
        inputPlaceholder: placeholder,
        inputValidator: (value) => {
            if (!value) {
                return 'This field is required.';
            } else if (value.length < 20) {
                return 'Minimum 20 characters required.';
            }
        }
    }).then(async result => {
        if (result.isConfirmed) {
            await onConfirm(result);
        }
    });
};

export { Toast };
