<script>
export default {
  data() {
    return {
      movingCompanyNotifications: false,
      orderRemindersNotifications: false,
      promotionsNotifications: false,
      supportNotifications: false,
    }
  },

  methods: {
    saveNotifications() {
      const notifications = {
        movingCompanyNotifications: this.movingCompanyNotifications,
        orderRemindersNotifications: this.orderRemindersNotifications,
        promotionsNotifications: this.promotionsNotifications,
        supportNotifications: this.supportNotifications
      }
    }
  }
}
</script>

<template>
  <v-card class="tw-mx-[30px] tw-my-[18px] tw-p-8 tw-flex tw-flex-col tw-justify-between">
    <h2 class="tw-text-[16px] tw-font-bold tw-text-secondary tw-mb-[16px]">Notifications</h2>

    <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap tw-mb-8 tw-w-full md:tw-w-[70%]">
      <!-- Notifications card -->
      <div class="lg:tw-w-11/12 tw-w-full tw-border-2 tw-rounded-[8px] tw-border-[#ddd] tw-mb-4">
        <div class="tw-p-1 tw-pl-6 tw-bg-[#F6F8FF] tw-rounded-tl-[8px] tw-rounded-tr-[8px] tw-font-semibold tw-text-secondary text-[16px]">
          Message
        </div>
        <div class="tw-p-1 tw-pl-6 tw-py-0 tw-flex tw-items-center tw-justify-between">
          <span class="tw-text-primary">Moving companies send you messages</span>
          <v-switch
            v-model="movingCompanyNotifications"
            color="#12924C"
            class="tw-mt-2"
            inset
          ></v-switch>
        </div>
      </div>
      <div class="lg:tw-w-11/12 tw-w-full tw-border-2 tw-rounded-[8px] tw-border-[#ddd] tw-mb-4">
        <div class="tw-p-1 tw-pl-6 tw-bg-[#F6F8FF] tw-rounded-tl-[8px] tw-rounded-tr-[8px] tw-font-semibold tw-text-secondary text-[16px]">
          Order reminders renibder’s updates
        </div>
        <div class="tw-p-1 tw-pl-6 tw-py-0 tw-flex tw-items-center tw-justify-between">
          <span class="tw-text-primary">You’ve got upcoming order or there are other updates about your order.</span>
          <v-switch
            v-model="orderRemindersNotifications"
            color="#12924C"
            class="tw-mt-2"
            inset
          ></v-switch>
        </div>
      </div>
      <div class="lg:tw-w-11/12 tw-w-full tw-border-2 tw-rounded-[8px] tw-border-[#ddd] tw-mb-4">
        <div class="tw-p-1 tw-pl-6 tw-bg-[#F6F8FF] tw-rounded-tl-[8px] tw-rounded-tr-[8px] tw-font-semibold tw-text-secondary text-[16px]">
          Promotions and tips
        </div>
        <div class="tw-p-1 tw-pl-6 tw-py-0 tw-flex tw-items-center tw-justify-between">
          <span class="tw-text-primary">There are coupons, promotions, surveys, and project ideas you might like.</span>
          <v-switch
            v-model="promotionsNotifications"
            color="#12924C"
            class="tw-mt-2"
            inset
          ></v-switch>
        </div>
      </div>
      <div class="lg:tw-w-11/12 tw-w-full tw-border-2 tw-rounded-[8px] tw-border-[#ddd] tw-mb-4">
        <div class="tw-p-1 tw-pl-6 tw-bg-[#F6F8FF] tw-rounded-tl-[8px] tw-rounded-tr-[8px] tw-font-semibold tw-text-secondary text-[16px]">
          Account support
        </div>
        <div class="tw-p-1 tw-pl-6 tw-py-0 tw-flex tw-items-center tw-justify-between">
          <span class="tw-text-primary">We have updates about your account, projects and security/privacy matters.</span>
          <v-switch
            v-model="supportNotifications"
            color="#12924C"
            class="tw-mt-1"
            inset
          ></v-switch>
        </div>
      </div>
    </div>

    <div class="tw-flex tw-justify-end tw-mb-0">
      <v-btn 
        color="#8F3985"
        class="!tw-text-[#fff] tw-w-[177px]"
        @click="saveNotifications"
      >
        Save
      </v-btn>
    </div>
  </v-card>
</template>

<style>
.v-input__control {
  height: auto !important;
}
</style>