<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="getDate"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                placeholder="Write a date"
                class="custom--v-text-field pt-0"
                :error-messages="dateErrors"
                @input="$v.new_date.$touch()"
                @blur="$v.new_date.$touch()"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            @input="menu = false"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
    name: "DatePicker",

    mixins: [validationMixin],

    props: {
        expiration_date: {
            type: String,
            required: true
        },
        isClickedSubmit: Boolean
    },

    validations: {
        new_date: { required }
    },

    data () {
        return {
            menu: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
            new_date: ''
        }
    },

    computed: {
        dateErrors () {
            const errors = [];
            if (! this.$v.new_date.$dirty) return errors
            ! this.$v.new_date.required && errors.push('Expiration date is required')
            return errors
        },

        getDate () {
            if(this.expiration_date) {
                this.new_date = this.expiration_date;
                return this.new_date;
            }

            return this.new_date;
        }
    },

    methods: {
        /**
         *
         * @param date
         * @returns {string|null}
         */
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },

        /**
         *
         * @param val
         */
        validateInput (val) {
            if(val) {
                this.$v.$touch();
            }
        }
    },

    watch: {
        /**
         *
         * @param value
         */
        date (value) {
            this.new_date = this.formatDate(this.date);
            this.$emit('onChange', this.new_date);
        },

        /**
         *
         * @param value
         */
        isClickedSubmit (value) {
            this.validateInput(value);
        }

    },

    created () {
        this.new_date = this.expiration_date;
    }
}
</script>