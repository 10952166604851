<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      disabled: true,
      showOld: false,
      showNew: false,
      showConfirm: false,
    }
  },

  mixins: [validationMixin],

  validations: {
    oldPassword: {
        required,
        minLength: minLength(6)
    },
    newPassword: {
        required,
        minLength: minLength(6)
    },
    confirmPassword: {
        required,
        minLength: minLength(6)
    },
  },

  methods: {
    ...mapActions('auth', ['updatePassword']),

    savePassword() {
      if(!this.oldPassword.length < 6 && this.newPassword !== this.confirmPassword) {
        this.disabled = true;
        return;
      }

      this.disabled = false;
      const password = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
      }

      this.updatePassword(password);
    }
  }
}
</script>

<template>
  <v-card class="tw-mx-[30px] tw-my-[18px] tw-p-6 tw-flex tw-flex-col tw-justify-between">
    <div class="tw-h-[400px] lg:tw-h-[500px]">
      <h2 class="tw-text-[16px] tw-font-bold tw-text-secondary tw-mt-4 tw-mb-[24px]">Reset password</h2>
  
      <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap tw-mb-8">
        <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
          <div class="tw-mx-2 tw-mb-4">
            <div class="tw-mb-1">
              Old password
            </div>
            <v-text-field
                label="Your old password"
                single-line
                hide-details
                outlined
                class="custom--v-text-field"
                v-model="oldPassword"
                :append-icon="showOld ? 'fa-eye' : 'fa-eye-slash'"
                :type="showOld ? 'text' : 'password'"
                :error="$v.oldPassword.$error"
                @click:append="showOld = !showOld"
            />
          </div>
        </div>
        <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
          <div class="tw-mx-2 tw-mb-4">
            <div class="tw-mb-1">
              New password
            </div>
            <v-text-field
                label="Your new password"
                single-line
                hide-details
                outlined
                class="custom--v-text-field"
                v-model="newPassword"
                :append-icon="showNew ? 'fa-eye' : 'fa-eye-slash'"
                :type="showNew ? 'text' : 'password'"
                :error="$v.newPassword.$error"
                @click:append="showNew = !showNew"
            />
          </div>
        </div>
        <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
          <div class="tw-mx-2 tw-mb-4">
            <div class="tw-mb-1">
              Confirm password
            </div>
            <v-text-field
                label="Confirm password"
                single-line
                hide-details
                outlined
                class="custom--v-text-field"
                v-model="confirmPassword"
                :append-icon="showConfirm ? 'fa-eye' : 'fa-eye-slash'"
                :type="showConfirm ? 'text' : 'password'"
                :error="$v.confirmPassword.$error"
                @click:append="showConfirm = !showConfirm"
            />
          </div>
        </div>
        <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
          <div class="tw-mx-2 tw-mb-4 tw-hidden">
          </div>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-justify-end tw-mb-8">
      <v-btn 
        color="#8F3985"
        class="!tw-text-[#fff] tw-w-[177px]"
        @click="savePassword"
      >
        Save
      </v-btn>
    </div>
  </v-card>
</template>
