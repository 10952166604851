<template>
    <section class="price__service-fee">
        <div class="price__desc">{{ tabDescription }}</div>
        <div class="service-fee">
            <h4 class="double-driving__header font-semibold text-secondary mb-2">Service Fee</h4>
            <table class="service-fee__table pricing-service-fee-table">
                <tr class="service-fee__row">
                    <td class="service-fee__column">
                        <h5 class="service-fee__heading">For Loading & Unloading help</h5>
                        <div class="d-flex align-center justify-space-between">
                            <div class="mr-4 text-secondary font-normal">
                                First Bedroom Price
                            </div>
                            <span class="ml-auto mr-4">$</span>
                            <v-text-field
                                single-line hide-details outlined
                                type="text"
                                class="service-fee__input custom--v-text-field --dark"
                                v-model.number="branch.fee_first_bedroom"/>
                        </div>
                    </td>
                    <td class="service-fee__column">
                        <h5 class="service-fee__heading">Extra charge</h5>
                        <div class="d-flex align-center justify-space-between">
                            <div class="mr-4 text-secondary font-normal">
                                Additional Bedroom Price
                            </div>
                            <span class="ml-auto mr-4">$</span>
                            <v-text-field
                                single-line hide-details outlined
                                type="text"
                                class="service-fee__input custom--v-text-field --dark"
                                v-model.number="branch.fee_extra_bedroom"/>
                        </div>
                    </td>
                    <td class="service-fee__column">
                        <h5 class="service-fee__heading">Only for Loading or Unloading Help</h5>
                        <div class="d-flex align-center justify-space-between">
                            <div class="mr-4 text-secondary font-normal">
                                One time fixed fee
                            </div>
                            <span class="ml-auto mr-4">$</span>
                            <v-text-field single-line hide-details outlined type="text"
                                          class="service-fee__input custom--v-text-field --dark"/>
                        </div>
                    </td>
                    <td class="d-flex align-end justify-end">
                        <div>
                            <v-btn elevation="0" class="align-self-end" color="primary" @click="save" min-width="133">
                                Save
                            </v-btn>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </section>
</template>

<script>
import {pick} from "lodash";
import {mapActions, mapMutations, mapState} from "vuex";
import PricingDoubleDriving from './PricingDoubleDriving.vue';

export default {
    components: {PricingDoubleDriving},

    props: {
        tabDescription: String,
    },

    computed: {
        ...mapState('auth', ['activeBranch']),

        /**
         * @return {PartialObject<Branch>}
         */
        branch() {
            return pick(this.activeBranch, ['fee_first_bedroom', 'fee_extra_bedroom', 'id']);
        },
    },

    methods: {
        ...mapActions('locations', ['updateBranch', 'load']),

        async save() {
            await this.updateBranch(this.branch);

            await this.load();
        },
    }
}
</script>

<style scoped lang="scss">

.pricing-service-fee-table {
    width: 100%;

    td {
        width: 50%;
    }
}

.custom--v-text-field::v-deep {
    background: #F5F5F9;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    overflow: hidden;
    font-weight: 500;

    .v-input__slot {
        min-height: 27px !important;
        height: 27px !important;
    }

    input {
        color: #0D3659;
    }
}
</style>