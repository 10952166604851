<template>
    <div>
        <v-autocomplete
            single-line hide-details
            outlined class="custom--v-text-field custom-v-autocomplete"
            :items="states" item-text="name" item-value="id"
            v-model="local"
            id="state"
        />
    </div>
</template>

<script>
import ValueEmitter from "../mixins/ValueEmitter";
import { mapActions, mapState } from "vuex";

export default {

    mixins: [ValueEmitter],

    methods: {
        ...mapActions('locations', ['loadStates']),
    },

    computed: {
        ...mapState('locations', ['states'])
    },

    created () {
        this.loadStates();
    }
};
</script>

<style scoped lang="scss">
.custom-app-state > .custom-v-autocomplete::v-deep {
    background: #F5F5F9 !important;
    border:none !important;
    border-radius: 8px !important;

    input {
      color: #0D3659;
    }

    fieldset {
      border:none !important;
    }

    &:focus, &:focus-within, &:focus-visible {
      border: 1px solid #8F3985 !important;
    }
}
</style>
