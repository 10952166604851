export const ORDER_STATUSES = {
    WAITING_FOR_PAYMENT: 0,
    STATUS_NEW: 1,
    STATUS_ACCEPTED: 2,
    STATUS_BEING_RESCHEDULED: 6,
    STATUS_REJECTED: 3,
    STATUS_REJECTED_AFTER_ACCEPTING: 4,
    STATUS_COMPLETED: 5,
    STATUS_BEING_COMPLETED: 7,
};

export const ORDER_COLORS = [
    "#EB4445",
    "#6C5CCA",
    "#00BE9C",
    "#FD7623",
    "#276BEB",
    "#FBAB1E",
    "#273B69"
];

export const WORKING_DAY_NAMES = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

export const WEEK_DAY_SHORT_NAMES = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun'
];

export const WORKING_DAY_HOURS = [
    '12am', '1am', '2am', '3am',
    '4am', '5am', '6am', '7am',
    '8am', '9am', '10am', '11am',
    '12pm', '1pm', '2pm', '3pm',
    '4pm', '5pm', '6pm', '7pm',
    '8pm', '9pm', '10pm', '11pm',
];

export const PROPERTY_TYPES = [
    '', 'Home', 'Apartment', 'Townhouse',
    'Lots/Land', 'Multi-Family', 'Condo',
    'Manufactured', 'Mansion', 'Residential Storage',
    'Office', 'Storage', 'Warehouse', 'Single Item',
    'Multiple Items', 'Room of Items', 'Full Cleanout',
];

export const working_days_to_selection = () => WORKING_DAY_HOURS.map((display, value) => ({ value, display }));

/**
 * Generate simple selections data
 *
 * @param {Number} till
 * @param {String} label
 *
 * @returns {{value: Number, display: String}[]}
 */
export const generateSelection = (till, label) => {
    const result = [];

    for (let i = 1; i <= till; i++) {
        result.push({
            value: i,
            display: i > 1 ? (i + ' ' + label + 's') : (i + ' ' + label)
        });
    }

    return result;
}