<template>
    <div>
        <v-tabs
            v-model="tab"
            color="#0D3659"
            height="61"
            class="custom--dashboard-header--v-tabs helper--dashboard !tw-mb-[18px]"
        >
            <v-tab v-for="item in tabs" :key="item.title" class="custom--v-tab">
                <span
                    inline
                    class="tw-capitalize tw-font-semibold tw-text-[18px]"
                >
                    {{ item.title }} - {{ item.count }}
                </span>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="custom--v-tabs-items">
            <v-tab-item>
                <div class="tw-grid tw-gap-[18px]" v-if="isNewRequestsExist">
                    <dashboard-card
                        v-for="order in new_requests"
                        :key="order.id"
                        :order="order"
                    />
                </div>

              <v-alert v-if="!isNewRequestsExist" color="primary" text class="tw-text-center">
                No moving orders yet. Check back for new requests.
              </v-alert>
            </v-tab-item>
            <v-tab-item>
                <div class="tw-grid tw-gap-[18px]" v-if="isUpcomingExist">
                    <dashboard-card
                        v-for="order in upcoming"
                        :key="order.id"
                        :order="order"
                    />
                </div>

              <v-alert v-if="!isUpcomingExist" color="primary" text class="tw-text-center">
                No upcoming moving orders yet.
              </v-alert>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DashboardCard from './DashboardCard.vue';

export default {
  name: 'MovingTab',
  components: {
    DashboardCard
  },

  data() {
    return {
      tab: null
    }
  },
  computed: {
    ...mapState('dashboard', ['upcoming', 'new_requests']),

    tabs () {
      const item = (title, count) => ({ title, count });

      return [
        item('New Requests', this.new_requests.length),
        item('Upcoming', this.upcoming.length),
      ];
    },

    isNewRequestsExist() { return this.new_requests.length },
    isUpcomingExist() { return this.upcoming.length },
  },
}
</script>
