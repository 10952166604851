export default {
    namespaced: true,

    state: {
        faqs: {}
    },

    getters: {
        faqs: state => state.faqs
    },

    mutations: {
        SET_FAQ (state, payload) {
            state.faqs = payload;
        }
    },

    actions: {
        async load ({ rootState, commit }) {
            const { id } = rootState.auth.activeBranch;
            const { faqs } = await axios.get(`/branches/${ id }?section=introduction`);

            commit('SET_FAQ', faqs);
        },

        async save ({ dispatch, rootState }, { faq_id, answer }) {
            const { id } = rootState.auth.activeBranch;

            await axios.post('/faqs', {
                branch_id: id,
                faq_id,
                answer
            });
            dispatch('load');
        },

        async update ({ dispatch, rootState }, { faq_id, answer }) {
            const { id } = rootState.auth.activeBranch;

            await axios.put(`/faqs/${faq_id}`, {
                branch_id: id,
                answer
            });
            dispatch('load');
        },
    }
}
