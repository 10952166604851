export default {
  install(Vue) {
    Vue.prototype.$scrollLock = {
      enable() {
        document.body.classList.add('tw-h-[100vh]', 'tw-overflow-hidden');
      },
      disable() {
        document.body.classList.remove('tw-h-[100vh]', 'tw-overflow-hidden');
      }
    };
  }
};