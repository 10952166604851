<template>
    <div class="invite-email-table">
        <v-data-table
            :headers="headers"
            :items="emails"
            class="custom--v-data-table"
        >
            <template v-slot:item.actions="{ item }">
                <v-btn v-if="!item.is_approved" @click="del(item.id)">Delete</v-btn>
            </template>

            <template v-slot:item.is_approved="{ item }">
                <div v-if="item.is_approved" class="d-flex align-center">
                    <img src="/assets/icons/checkbox.svg" alt="checkbox-checked" class="tw-me-2">
                    <span>Responded</span>
                </div>
                <div v-else class="d-flex align-center">
                    <img src="/assets/icons/checkbox-false.svg" alt="checkbox-unchecked" class="tw-me-2">
                    <span>Not Responded</span>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import loading from "../../../mixins/loading.js";

export default {
    name: "InviteFriendsEmailsTable",

    mixins: [ loading ],

    data() {
        return {
            headers: [
                {
                    text: 'Email',
                    value: 'referred_email',
                    sortable: false
                },
                {
                    text: 'Status',
                    value: 'is_approved',
                    sortable: false

                },
                {
                    text: 'Updated',
                    value: 'updated_at',
                    sortable: false
                },
                {
                    text: '',
                    value: 'actions',
                    sortable: false
                },
            ]
        }
    },

    methods: {
        ...mapActions('referrals', [ 'delete' ]),

        del(id) {
            this.delete(id);
        }
    },
    computed: {
        ...mapGetters('referrals',['emails'])
    }
}
</script>

<style lang="scss" scoped>
.custom--v-data-table::v-deep {

    max-height: 273px;
    overflow: auto;

    .v-data-table-header {
        tr {
            height: 69px;
            padding: 25px 0;
        }
    }

    td, th {
        border: none !important;
        padding: 0 24px !important;
    }

    tbody tr {
        &:nth-child(odd) {
            background: #F5F5F9 !important;
        }
    }

    td {
        padding: 0 24px;
        font-family: 'Gilroy', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #6B6D81;

        &:last-child {
            display: flex;
            align-items: center;
            justify-content: end;
        }

        button {
            width: 66px !important;
            height: 30px !important;
            background: #FFFFFF !important;
            border: 1px solid #DDDDDD !important;
            border-radius: 4px !important;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            color: #0D3659;
        }
    }

    th > span {
        font-family: 'Gilroy', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        color: #0D3659;
    }

    .v-data-footer {
        display: none !important;
    }

    .v-data-table__empty-wrapper td {
        display: revert;
    }
}
</style>
