<template>
    <div class="horizontal-calendar-grid">
        <div class="horizontal-calendar-grid__header">
            <v-btn icon @click="handlePrev">
                <v-icon size="16" color="secondary">fa-solid fa-chevron-left</v-icon>
            </v-btn>
            <v-btn icon @click="handleNext">
                <v-icon size="16" color="secondary">fa-solid fa-chevron-right</v-icon>
            </v-btn>
        </div>
        <v-carousel hide-delimiters touchless :show-arrows="false" class="horizontal-calendar-grid__carousel daily-calendar"
            ref="carousel" v-model="currentDayIndex" :reverse="reverseTransition">
            <v-carousel-item v-for="(day, i) in days" :key="i">
                <div class="calendar-title">{{ calendarTitle }}</div>
                <v-tabs>
                    <v-tab v-for="(time, j) in times" :key="j * j">
                        <div class="daily-time">{{ time.split("-")[0] }}</div>
                        <div class="daily-time-divider"></div>
                        <div class="daily-time">{{ time.split("-")[1] }}</div>
                        <div v-if="orderedTimes.includes(time)" class="ordered-status" :style="`background-color: ${orderedTimeBgColor}`"></div>
                    </v-tab>
                </v-tabs>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
import Calendar from 'json-calendar'
import { ORDER_COLORS, WEEK_DAY_SHORT_NAMES } from "@/plugins/data.js";
export default {
    props: ['pickerDate', 'selectedDate', 'orders'],
    data() {
        return {
            currentYear: new Date().getFullYear(),
            currentMonthIndex: 0,
            currentDayIndex: 0,
            daysMaxLength: 33,
            days: [],
            times: [ "7 AM - 8 AM", "8 AM - 9 AM", "9 AM - 10 AM", "10 AM - 11 AM", "12 PM - 3 PM", "1 PM - 4 PM", "2 PM - 5 PM", "3 PM - 6 PM" ],
            reverseTransition: false,
        }
    },
    computed: {
        calendarTitle() {
            const calendar = new Calendar();
            const currentMonthName = calendar.monthNames[this.currentMonthIndex];
            const currentDay = this.days[this.currentDayIndex].day;
            return currentDay + " " + currentMonthName + ", " + this.currentYear;
        },
        orderedTimes() {
            let result = [];
            if (this.orders.length === 1) {
                const { orders }= this.orders[0];
                orders.forEach(o => {
                    if (!result.includes(o.arrival_time)) {
                        result.push(o.arrival_time);
                    }
                })
            }
            return result;
        },
        orderedTimeBgColor() {
            if(this.orders.length === 1) {
                const weekdayNameIndex = WEEK_DAY_SHORT_NAMES.indexOf(this.orders[0].dayName);
                return ORDER_COLORS[weekdayNameIndex];
            };
        }
    },
    methods: {
        handlePrev() { 
            this.reverseTransition = false;

            if (this.days[this.currentDayIndex].day === 1) {
                this.currentMonthIndex = this.days[this.currentDayIndex].monthIndex;

                this.reverseTransition = true;

                // Identify prev month index and year:
                if (this.currentMonthIndex === 0) {
                    this.currentMonthIndex = 11;
                    this.currentYear--;
                } else {
                    this.currentMonthIndex--;
                }

                this.setDays();
                this.currentDayIndex = this.days.length - 1;
            }
            else {
                this.currentDayIndex--;
                this.$refs.carousel.prev();
            }

            this.$emit('setCurrentDay', [this.days[this.currentDayIndex]]);
        },

        handleNext() {
            this.reverseTransition = false;

            if (this.currentDayIndex === this.days.length - 1) {
                this.currentMonthIndex = this.days[this.currentDayIndex].monthIndex;

                this.reverseTransition = true;

                // Identify next month index and year:
                if (this.currentMonthIndex === 11) {
                    this.currentYear++;
                    this.currentMonthIndex = 0;
                } else {
                    this.currentMonthIndex++
                }

                this.setDays();
                this.currentDayIndex = this.daysMaxLength - this.days[this.days.length - 1].day;
            }
            else {
                this.currentDayIndex++;
                this.$refs.carousel.next();
            }

            this.$emit('setCurrentDay', [this.days[this.currentDayIndex]]);
        },

        setDays() {
            const calendar = new Calendar({ today: new Date(this.currentYear, this.currentMonthIndex) });
            this.days = [];
            calendar.weeks.map(week => {
                week.map(day => {
                    if (day.monthIndex === this.currentMonthIndex) {
                        this.days.push(day);
                    };
                });
            });

            const daysLength = this.days.length;

            for (let i = 0; i < this.daysMaxLength - daysLength; i++) {
                this.days.unshift({});
            }
        },

        setCurrentDayIndex(todayDate) {
            this.days.forEach((day, index) => {
                if (day.day === todayDate) {
                    this.currentDayIndex = index;
                }
            })
        },

        willChangeCurrentYear(firstWeekDay, lastWeekday) {
            return firstWeekDay.monthIndex === 11 && lastWeekday.monthIndex === 0 || firstWeekDay.monthIndex === 0 && firstWeekDay.day === 1
        }
    },
    created() {
        let todayDate = new Date().getDate();
        this.currentMonthIndex = new Date().getMonth();

        if (this.selectedDate) {
            this.currentYear = this.selectedDate.year;
            this.currentMonthIndex = this.selectedDate.monthIndex;
            todayDate = this.selectedDate.day;
        }

        this.setDays();
        this.setCurrentDayIndex(todayDate);

        this.$emit('setCurrentDay', [this.days[this.currentDayIndex]]);
    },
}
</script>