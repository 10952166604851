<template>
    <div class="tw-flex tw-flex-col sm:tw-flex-row tw-items-end sm:tw-items-center tw-justify-center sm:tw-justify-end tw-gap-[10px] tw-text-right sm:tw-text-center">
        <div v-if="order.can_accept">
            <v-btn
                elevation="0"
                color="primary"
                @click="acceptDeliveryAction()"
                v-if="activeBranchId !== 'company'"
            >
                Accept
            </v-btn>

            <v-btn
                color="primary"
                @click="acceptWith"
            >
                Accept
            </v-btn>
        </div>

        <div v-if="order.can_reject">
            <v-btn
                v-if="activeBranchId !== 'company'"
                elevation="0"
                color="light"
                @click="rejectDeliveryAction()"
            >
                Reject
            </v-btn>
            <v-btn
                color="light"
                @click="rejectWith"
            >
                Reject
            </v-btn>
        </div>

        <div v-if="order.can_cancel">
            <v-btn
                class="tw-mr-2 !sm:tw-mr-0 reshedule-btn"
                color="primary"
            >
                Reschedule
            </v-btn>
            <v-btn
                elevation="0"
                color="light"
                @click="cancelDeliveryAction"
            >
                Cancel
            </v-btn>
        </div>
    </div>
</template>

<script>
import { successConfirmAction, warningConfirmAction } from "@/plugins/swal";
import { mapGetters, mapState } from "vuex";
import Swal from 'sweetalert2';
import confirmImage from '../../../public/assets/images/confirm-modal.png'
export default {
    props: {
        order: {
            type: Object,
            required: true,
        },
        shouldAccept: {
            type: Boolean,
            default: false
        },
        shouldCancel: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        async 'shouldAccept' (newVal, oldVal) {
            if (newVal !== oldVal) {
                await this.acceptWith()
            }
        },
        async 'shouldCancel' (newVal, oldVal) {
            if (newVal !== oldVal) {
                await this.cancelDeliveryAction()
            }
        },
    },

    computed: {
        ...mapGetters('auth', ['branches']),
        ...mapState('dashboard', ['activeBranchId', 'nearestBranch'])
    },

    methods: {

        acceptDeliveryAction() {
            successConfirmAction('Do you really want to accept this bidding?', 'Accept', async () => {
                await this.$store.dispatch('dashboard/acceptDelivery', { branch_id: this.activeBranchId, order_id: this.order.id });
            });
        },

        rejectDeliveryAction() {
            warningConfirmAction('Do you really want to reject this delivery?', 'Reject', async () => {
                await this.$store.dispatch('dashboard/rejectDelivery', { branch_id: this.activeBranchId, order_id: this.order.id });
            });
        },

        async cancelDeliveryAction() {
            warningConfirmAction(
                'Do you really want to cancel this delivery?',
                'Confirm',
                async () => {
                    await this.$store.dispatch('labor/cancelDelivery', this.order.id);
                }
            );
            
            await this.$store.dispatch('labor/load');
        },

        async acceptWith() {
            await this.$store.dispatch('dashboard/getNearestBranch', { delivery: this.order.id });

            Swal.fire({
                title: `<strong class="tw-text-[28px] tw-font-semibold tw-pt-0">You are accepting this <br/> delivery for ${this.nearestBranch.name}</strong>`,
                imageUrl: confirmImage,
                imageHeight: 200,
                imageWidth: 230,
                showCloseButton: true,
                focusConfirm: false,
                confirmButtonText: "Confirm",
                confirmButtonColor: '#8F3985',
                confirmButtonAriaLabel: "Thumbs up, great!",
                customClass: 'rounded-xl'
            }).then(({ isConfirmed }) => {
                if (!isConfirmed) return;
                this.$store.dispatch('dashboard/acceptDelivery', {
                    branch_id: this.$store.state.dashboard.nearestBranch.id,
                    delivery_id: this.order.id
                });
            });
            await this.$store.dispatch('dashboard/load')
        },

        async rejectWith() {
            warningConfirmAction(
                'Do you really want to reject this delivery?',
                'Reject',
                async () => {
                    await this.$store.dispatch('dashboard/rejectDelivery', {
                        company_id: this.$store.state.auth.company.id,
                        order_id: this.order.id
                    });
                }
            );
            
            await this.$store.dispatch('dashboard/load')
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 535px) {
    .reshedule-btn {
        margin-bottom: 10px;
        margin-right: 0 !important;
    }
    
}
</style>
