import { BACKEND_URL } from "@/plugins/consts.js";

export default {
    namespaced: true,

    state: {
        /** @type {branches: Array}
         * @type {states: Array}
         * @type {isOpenLocationMenu: Boolean} */

        branches: [],
        counties: [],
        states: [],
        cities: [],
        isOpenLocationMenu: false
    },

    mutations: {
        SET_BRANCHES (state, payload) {
            state.branches = payload;
        },

        SET_COUNTIES (state, payload) {
            state.counties = payload;
        },

        SET_STATES (state, payload) {
            state.states = payload;
        },

        SET_CITIES (state, payload) {
            state.cities = payload;
        },

        SET_LOCATION_MENU (state) {
            state.isOpenLocationMenu = ! state.isOpenLocationMenu;
        }
    },

    actions: {
        async load ({ commit }) {
            const branches = await axios.get('branches');

            commit('SET_BRANCHES', branches);
            commit('auth/SET_BRANCHES', branches, { root: true });

            return branches;
        },

        async loadCounties ({ commit, state }, query) {
            query ??= '';
            const counties = await axios.get(`counties?query=${query}`);
            commit('SET_COUNTIES', counties);
        },

        async loadStates ({ commit, state }) {
            if (state.states.length !== 0) return true;

            const states = await axios.get('cities');
            commit('SET_STATES', states);
        },

        async loadCities({ commit }, state_id) {
            const cities = await axios.get(`cities?state_id=${state_id}`);
            commit('SET_CITIES', cities);
            return cities;
        },

        async createBranch (_, branch) {
            return await axios.post('branches', branch);
        },

        async updateBranch (_, branch) {
            return await axios.patch(`branches/${ branch.id }`, branch);
        },

        async destroyBranch (_, branch) {
            return await axios.delete(`branches/${ branch.id }`);
        },

        async latLngByZipCode (_, zip_code) {
            return await axios.get('latLng/' + zip_code);
        },

        async validZipCode (_, zip_code) {
            return await axios.get('zip_code/' + zip_code, {
                baseURL: BACKEND_URL + '/validations/'
            })
        },
    }
}
