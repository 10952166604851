<template>
    <v-menu ref="menu"  offset-y :close-on-content-click="false"  :min-width="350" :max-width="350" :max-height="600" :nudge-bottom="15">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs" v-on="on"
                icon class="notification-btn ml-1"
            >
                <div v-if="hasUnreadNotifications">
                    <v-badge class="desktop--notifications custom--dashboard-header--v-badge notification--icon"  :content="notifications.length" overlap color="primary">
                        <v-icon size="22" color="rgba(67, 89, 113, .7)">
                            far fa-bell
                        </v-icon>
                    </v-badge>
                    <div class="mobile--notifications">
                        <span>{{ notifications.length }}</span>
                        <v-icon size="14" color="rgba(67, 89, 113, .7)">
                            far fa-bell
                        </v-icon>
                    </div>
                </div>

                <v-icon size="24" color="#6B6D81" v-else>
                    far fa-bell
                </v-icon>
            </v-btn>
        </template>

        <v-card v-if="!isClose" class="notification-card">
            <v-card-actions class="d-flex justify-end py-0">
                <v-btn icon @click="$refs.menu.save()">
                    <img src="/assets/icons/delete.svg" alt="delete icon" />
                </v-btn>
            </v-card-actions>
            <v-card-title class="notification-card__title pt-0 mb-2 text-secondary">
                Notifications
            </v-card-title>

            <v-card-subtitle class="notification-card__subtitle">
                {{ unreadNotificationsCount }} unread notifications
            </v-card-subtitle>

            <v-card-text class="notification-card__text pl-5">
                <v-list class="notification-card__list">
                    <v-list-item
                        class="notification-card__item"
                        v-for="({id, data: {title, link}, date, unread}) in notifications" :key="id"
                        two-line dense link
                        color="white darken-3"
                    >
                        <v-list-item-content @click="readNotification(link)"  class="notification-card__content">
                            <v-list-item-title>
                                <p class="mb-0 text-secondary" v-if="unread">
                                    {{ getNotificationName(title) }}
                                </p>
                            </v-list-item-title>
                            <v-list-item-subtitle v-text="date"/>
                        </v-list-item-content>

                        <v-list-item-action class="notification-card__check" v-if="unread">
                            <v-btn icon v-cloak @click="markAsRead({ id })">
                                <v-icon  size="15">
                                    fa-check
                                </v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    data () {
        return {
            notificationsMenu: [
                { title: 'Lorem ipsum dolor' },
                { title: 'Lorem ipsum dolor sit amet' },
            ],
            isClose: false
        };
    },

    computed: {
        ...mapState('notifications', ['notifications']),
        ...mapGetters('notifications', ['unreadNotificationsCount', 'hasUnreadNotifications']),
    },

    methods: {
        ...mapActions('notifications', ['markAsRead']),

        readNotification(link) {
            window.open(link);
        },

        getNotificationName(title) {
            return title.length > 28 ? title.split('').splice(0, 28).join('') + ' ...' : title
        }

    }
};
</script>
<style lang="scss" scoped>
$bg-color:#F5F5F9;
$primary: #8F3985;

.notification-card {
    &__title,  &__subtitle {
        padding-left: 16px;
        word-break: break-all;
    }
    &__title {
        font-size: 25px;
        font-weight: 600;
        line-height: 17px;
    }
    &__subtitle {
        color: #0D3659 !important;
    }
    &__item {
        background: #F5F5F9;
        border-radius: 4px;
        &:not(:last-child) {
          margin-bottom: 10px;  
        }
        &:active, &:hover {
            background: #FEECFC;
            p, & > div, .v-list-item__subtitle , i{
                color: #8F3985;
            }
        }
    }
    &__content {
        p {
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
        }
        .v-list-item__subtitle {
            font-size: 14px;
            font-weight: 500;
            line-height: 25px;
        }
    }
}
 .notification-btn {
   border-radius: 5px !important;
 }
 .display-none {
    display: none !important;
 }
// mobile notifications
.mobile--notifications {
    display: flex;
    align-items: center;
    padding: 8px  3px;
    background: #E7EFF3;
    border-radius: 10px;

    @media (min-width: 990px) {
        display: none;
    }
    span {
        color: $primary;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
    }
}
.desktop--notifications {
    @media (max-width: 990px) {
        display: none;
    }
}
</style>