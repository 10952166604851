import router from "../router";
import { useRedirectUrl } from "@/router/routes.js";
import tokenize from "@/plugins/tokenize.js";
import { find } from "lodash";

export default {
    namespaced: true,

    state: {
        isAuthenticated: false,
        user: {},
        company: {},
        /** @type Branch[] */
        branches: [],
        /** @type Branch */
        activeBranch: {},
    },

    getters: {
        isAuthenticated: state => state.isAuthenticated,
        isCustomer: state => state.user?.isCustomer,
        isLabor: state => state.user?.isLabor,
        name: (state, { isCustomer }) =>
            isCustomer ? state.user.first_name : state.company?.name,
        logo: (state, { isCustomer }) =>
            isCustomer ? state.user.image : state.company?.image,
        branches: state => state.branches,
        activeBranch: state => state.activeBranch,
        user: state => state.user
    },

    mutations: {
        SET_AUTH(state, { user, company, isAuthenticated }) {
            state.user = user;
            state.company = company;
            state.isAuthenticated = isAuthenticated;
        },

        SET_USER(state, payload) {
            state.user = payload;
        },

        SET_BRANCH(state, branch_id) {
            state.activeBranch = find(state.branches, { id: branch_id });
            localStorage.setItem('activeBranchId', branch_id);
        },

        SET_BRANCHES(state, branches) {
            state.branches = branches;

            if (!state.user.isCustomer) {
                const activeBranch = state.activeBranch.branch_id ? state.activeBranch : branches[0];

                if(localStorage.getItem('activeBranchId')) {
                    //branchId must be Number type
                    const branchId = Number(localStorage.getItem('activeBranchId'));
                    state.activeBranch = find(state.branches, { id: branchId });
                    return true;
                }

                state.activeBranch = activeBranch;
            }
        },
    },

    actions: {
        /**
         * Handle check authentication
         *
         * @param {Function} commit
         *
         * @param dispatch
         * @param {String} token
         * @returns {Promise<boolean>}
         */
        async check({ commit, dispatch }, token) {
            if (token) {
                tokenize.save(token);
            }
            try {

                if (!tokenize.get()) return false;
                axios.defaults.headers.common["Authorization"] = "Bearer " + tokenize.get();
                const { user, company, branches } = await axios.get("auth/check");
                commit("SET_AUTH", { user, company, isAuthenticated: true });
                commit("SET_BRANCHES", branches);

                dispatch("notifications/createNotificationFetcher", {}, { root: true });
                return true;
            } catch ( e ) {
                axios.defaults.headers.common["Authorization"] = null;
                return false;
            }
        },

        /**
         * Handle login
         *
         * @param {Function} commit
         * @param {Function} dispatch
         * @param {String} email
         * @param {String} password
         *
         * @returns {Promise<boolean>}
         */
        async login({ commit, dispatch }, { email, password }) {
            try {
                const { user, company, token, branches } = await axios.post("auth/login", {
                    email,
                    password,
                });

                tokenize.save(token);

                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + tokenize.get();

                commit("SET_AUTH", { user, company, isAuthenticated: true });

                commit("SET_BRANCHES", branches);

                dispatch("notifications/createNotificationFetcher", {}, { root: true });

                if (!useRedirectUrl()) {
                    await router.push({ name: "dashboard" });
                }

                return true;
            } catch ( e ) {
                return false;
            }
        },

        /**
         * Handle logout
         *
         * @param {Function} commit
         * @param {Function} dispatch
         * @returns {Promise<void>}
         */
        async logout({ commit, dispatch }) {
            await axios.post("auth/logout");
            axios.defaults.headers.common["Authorization"] = null;
            tokenize.delete();
            commit("SET_AUTH", { isAuthenticated: false });
            dispatch("notifications/destroyNotificationFetcher", {}, { root: true });
            await router.push({ name: "login" });
        },

        /**
         * Handle updating password
         * @param {Object} _
         * @param {String} old_password
         * @param {String} new_password
         * @return {Promise<void>}
         */
        async updatePassword(_, { old_password, new_password }) {
            await axios.put("auth/update-password", { old_password, new_password });
        },

        /**
         * Handle resetting password
         * @param {Object} _
         * @param {String} email
         * @return {Promise<void>}
         */
        async resetPassword(_, { email }) {
            await axios.get(`auth/reset-password/${ email }`);
        },

        /**
         * Handle updating customer
         * @param rootState
         * @param state
         * @param commit
         * @param data
         * @returns {Promise<void>}
         */
        async updateCustomer({ rootState, state, commit }, data) {
            const { id } = rootState.auth.user;

            await axios.put(`users/${ id }`, data);

            const { user } = await axios.get("auth/check");
            commit("SET_USER", user);
        },

        async register(_, data) {
            await axios.post("auth/register", { ...data });
        },
    },
};
