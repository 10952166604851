import { sweetAlertError, sweetAlertSuccess } from "@/plugins/swal.js";

export const getParam = key => new URLSearchParams(window.location.search).get(key);

export const convertSecondToTime = (second) => {
    const days = Math.floor(second / (1000 * 60 * 60 * 24));
    const hour = Math.floor((second % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minute = Math.floor((second % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((second % (1000 * 60)) / 1000);

    if (days > 0) {
        return `${ days }d ${ hour }h ${ minute }m ${ seconds }s`;
    }

    if (hour > 0) {
        return `${ hour }h ${ minute }m ${ seconds }s`;
    }

    if (minute > 0) {
        return `${ minute }m ${ seconds }s`;
    }

    return `${ seconds }s`;
};

export const copyClipboard = async (link, successTitle, errorTitle) => {
    try {
        await navigator.clipboard.writeText(link);
        sweetAlertSuccess(successTitle);
    } catch (err) {
        sweetAlertError(errorTitle);
    }
}

export const valueMapper = (value, alternateText = 'No data', additionalText) => {
    let result = value || alternateText;
    if (value && additionalText) result += ' ' + additionalText;
    return result;
};

export const copyText = (text) => {
    return copyClipboard(text, 'Copied successfully', 'Error while is copying');
}
