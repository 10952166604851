<template>
    <div class="review-card__star">
        <span v-if="isMobileHeader" class="horizontal-line hidden-sm-and-down"></span>

        <v-rating
            half-icon="fa-star-half-alt"
            readonly
            color="#FBAB1E"
            background-color="#FBAB1E"
            class="custom--v-rating review-card__star--rating"
            :value="Number(reviewHeaderDetails.star)"
        ></v-rating>

        <span class="horizontal-line"></span>

        <a :href="reviewHeaderDetails.order_link" target="_blank" class="d-flex">
            <span class="review-card__star--id text-secondary font-semibold" v-text="getOrderId"/>
        </a>

        <span v-if="!(this.isCustomer && this.reviewHeaderDetails.activeTab === 1)" class="horizontal-line"></span>

        <span class="review-card__star--name text-secondary font-semibold">{{ getName }}</span>

        <v-tooltip v-if="!reviewHeaderDetails.publish_date" top content-class="charge-text__tooltip">
            <template v-slot:activator="{ on, attrs }">
                <v-img v-bind="attrs" v-on="on" src="assets/icons/info.svg" alt="info icon" width="12" class="ml-1" />
            </template>
            <span class="custom--v-tooltip">
                Before your review can be published, it must first undergo <b> a 10-day </b>
                review period. During this time, you will have the opportunity to make any necessary changes before it can become visible to the public.
            </span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ReviewCardHeader",

    props: {
        reviewHeaderDetails: {
            star: { type: String, required: true },
            publish_date: { type: String, required: true },
            order_link: { type: String, required: true },
            activeTab: { type: Number, require: true },
            customerName: { type: String, required: true },
            branchName: { type: String, required: true }
        },
        isMobileHeader: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters('auth', ['user', 'isCustomer']),

        getPublishDate () {
            return !this.reviewHeaderDetails.publish_date ? 'Not Published' : this.reviewHeaderDetails?.publish_date;
        },

        getName () {
            if(this.isCustomer && this.reviewHeaderDetails.activeTab === 0 ) return this.reviewHeaderDetails?.branchName;

            if(this.isCustomer && this.reviewHeaderDetails.activeTab === 1) return '';

            if(!this.isCustomer && this.reviewHeaderDetails.activeTab === 0) return this.reviewHeaderDetails?.customerName;

            if(!this.isCustomer && this.reviewHeaderDetails.activeTab === 1) return this.reviewHeaderDetails?.branchName;
        },

        getOrderId() {
            if (this.reviewHeaderDetails.order_link) {
                return 'Order - #' + this.reviewHeaderDetails?.order_link.split('=')[1];   
            }

            return "Former client"
        }
    }
}
</script>
<style scoped lang="scss">
.horizontal-line {
    width: 1px;
    height: 15px;
    margin: 0 10px;
    background: #D9D9D9
}

.custom--v-rating::v-deep {
    .v-icon {
        font-size: 14px;
    }
}

@media (max-width: 540px) {
    .review-card__star--rating .v-icon {
        margin: 10px;
    }

    .custom--v-rating::v-deep {
        line-height: 8px;

        &.v-rating {
            .v-icon {
                font-size: 12px;
                padding: 0;
            }
        }
    }

    span {
        font-size: 12px !important;
        font-weight: 500;
    }
}

</style>
