<template>
    <v-app class="p-3">
        <div class="d-flex align-center justify-space-between w-full px-3 mb-3">
            <small>
                Invite a reviewer
            </small>
            <v-btn text fab small class="mr-n3" @click="$emit('close')">
                <v-img src="/assets/icons/delete.svg" alt="close icon"/>
            </v-btn>
        </div>
        <div class="invite-reviewer__main d-flex align-center justify-center bordered-wrapper mx-3" v-if="!isLoading">
            <div class="invite-helper__left">
                <h1 class="invite-reviewer__header">Ask Review - {{ name }}</h1>
                <div class="invite-reviewer__data">
                    <!--                    toggle -->
                    <v-radio-group v-model="clients" class="custom--v-radio-group">
                        <v-radio value="loyal_clients" class="custom--v-radio">
                            <template v-slot:label>
                                <div class="invite-reviewer__radio">Former Clients</div>
                            </template>
                        </v-radio>
                        <v-radio value="new_clients" class="custom--v-radio">
                            <template v-slot:label>
                                <div class="invite-reviewer__radio">TopHelpers Clients</div>
                            </template>
                        </v-radio>
                    </v-radio-group>

                    <div class="invite-reviewer__text text-center">
                        Movers can always send an "Ask Review" request to their old customers or new TopHelpers.com
                        clients who have not yet written a review. Having more reviews will help build trust and attract
                        more leads.
                    </div>

                    <v-divider class="mt-3 mb-7"></v-divider>
                    <div class="mb-6">
                        <div class="mb-1.5 text-secondary font-medium">
                            Locations
                        </div>

                        <v-select
                            v-model="activeBranch"
                            :items="locations"
                            item-text="name"
                            item-value="id"
                            dense
                            outlined
                            hide-details
                            :placeholder="locations.length === 0 ? 'No data available' : 'Select location'"
                            append-icon="fa-chevron-down"
                            class="custom--v-select mb-2"
                        >
                            <template #selection="{item: {name}}">
                                {{ name }}
                            </template>

                            <template #item="{item: {name}}">
                                {{ name }}
                            </template>
                        </v-select>

                        <div class="mb-1.5 text-secondary font-medium d-flex align-center">
                            Customer you served
                            <div v-if="clients === 'new_clients' && orders.length > 0"
                                 class="invite-reviewer--select ml-auto">
                                <v-checkbox
                                    v-model="allSelections"
                                    class="custom--v-checkbox"
                                    color="primary"
                                    hide-details
                                    label="Select for all orders"
                                >
                                </v-checkbox>
                            </div>
                        </div>


                        <div v-if="clients === 'new_clients'">
                            <v-select
                                v-model="activeOrder"
                                :items="orders"
                                item-text="name"
                                item-value="id"
                                dense
                                outlined
                                hide-details
                                append-icon="fa-chevron-down"
                                :placeholder="orders.length === 0 ? 'No data available' : 'Select order'"
                                class="custom--v-select mb-2"
                            >
                            </v-select>

                            <v-btn v-if="orders.length> 0" @click="showOrder(activeOrder)">Show</v-btn>
                        </div>

                        <v-combobox
                            v-else
                            v-model="emails"
                            :search-input.sync="search"
                            hide-selected
                            multiple
                            outlined
                            persistent-hint
                            chips deletable-chips
                            append-icon=""
                            placeholder="Add Email"
                            class="v-input--is-focused"
                        >
                            <template v-slot:no-data>
                                <v-list-item v-if="canShowReviewer">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Press <kbd>enter</kbd> to add "<strong>{{ search }}</strong>";
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-combobox>

                    </div>

                    <v-btn :disabled="disableSend" depressed color="primary" class="custom--v-btn w-full mb-2"
                           @click="addEmails">
                        Send Request
                    </v-btn>

                    <span style="color: red" v-if="emails.length > 100">You reached the limit of 100 invites</span>
                </div>

            </div>
        </div>

        <app-loading-indicator v-else/>
    </v-app>
</template>

<script>
import InviteHelperList from './DashboardInviteHelperList';
import loading from "@/mixins/loading.js";
import AppLoadingIndicator from "@/components/AppLoadingIndicator.vue";
import { validate } from 'email-validator';
import { mapActions, mapGetters } from "vuex";
import AppLocationSelector from "@/components/AppLocationSelector";
import { validEmail } from "@/plugins/custom_validations";
import { sweetAlertError } from "@/plugins/swal";
import AppOrder from "@/components/Modals/AppOrder.vue";
import { filter } from "lodash";

export default {
    mixins: [loading],

    components: {
        InviteHelperList,
        AppLocationSelector,
        AppLoadingIndicator,
    },

    data () {
        return {
            activeBranch: null,
            activeOrder: null,
            emails: [],
            search: '',
            locations: [],
            orders: [],
            ordersId: [],
            invited_reviews_count: null,
            disableSend: true,
            clients: 'loyal_clients',
            allSelections: false
        }
    },

    methods: {
        ...mapActions('invites', ['store', 'index', 'askReview']),

        addEmails () {
            this.startLoading(async () => {
                if (this.clients === 'loyal_clients') {
                    await this.store([this.activeBranch, this.emails]);
                    this.emails = [];
                    return true;
                }

                if (this.allSelections) {
                    await this.askReview([true, this.activeBranch, [], true]);
                    return true;
                }

                this.ordersId = this.orders.map(order => order.id);
                await this.askReview([true, this.activeBranch, [this.activeOrder], false]);
                return true;
            });

            this.$emit('close');
        },

        showOrder (orderId) {
            this.$modal.show(AppOrder, {
                orderId: orderId,
            }, {
                width: '800px',
                height: '660px',
            });
        }
    },

    computed: {
        ...mapGetters('auth', ['name']),

        canShowReviewer () {
            return validate(this.search);
        },
    },

    created () {
        this.startLoading(async () => {
            this.locations = await this.index();

            this.activeBranch = this.locations[0].id;
            this.orders = this.locations[0]?.orders ?? [];
            this.activeOrder = this.orders[0]?.id ?? null;

            this.invited_reviews_count = this.locations
                .find(location => location.id === this.activeBranch)
                .invited_reviews_count;
        });
    },

    watch: {
        emails () {
            this.startLoading(async () => {
                const response = await validEmail(this.emails.at(-1));

                if (response !== 'new') {
                    this.emails.pop();

                    sweetAlertError('This email is already invited');
                }
            })

            if (this.emails.length > 0 && ! validate(this.emails.at(-1))) {
                this.emails.pop();
            }

            this.disableSend = this.invited_reviews_count + this.emails.length > 100;
        },

        activeBranch (id) {
            this.orders = filter(this.locations, { id: id })[0].orders;
            this.activeOrder = this.orders[0]?.id ?? null;

            if (this.clients === 'new_clients') {
                this.disableSend = this.orders.length === 0;
            }
        },

        clients (value) {
            if (value === 'loyal_clients') {
                this.disableSend = this.emails.length === 0;
            } else {
                this.disableSend = this.orders.length === 0;
            }
        }
    },
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify-components/v-combobox";

::v-deep {
    .custom--v-select.v-select .v-input__slot .v-select__selections {
        padding: 0 !important;
    }

    .v-input__append-inner {
        margin-top: 3px !important;
    }
}

.bordered-wrapper {
    border: 1px solid #E0E4E9;
}

.invite-helper {

    &__left {
        width: 100%;

        h3 {
            font-size: 30px;
            font-weight: 700;
            color: #0c3659;
            line-height: 1.3;
        }
    }

    &__right {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 40px;
        border-radius: 8px;
        background-color: #F9F9F9;

        span {
            color: #AAAAAA;
            font-size: 18px;
            margin-bottom: 15px;
        }

        h3 {
            font-size: 25px;
            font-weight: 700;
            color: #0c3659;
            text-align: center;
            line-height: 1.5;
        }
    }

    &__credit-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95px;
        height: 95px;
        margin-bottom: 30px;
        border-radius: 5px;
        background-color: #FFF;
        box-shadow: 0 0 40px rgba(#000, 0.2);

        .fa {
            font-size: 40px;
        }
    }
}

.invite-reviewer {
    width: 100%;
    padding: 34px;

    &__header {
        padding: 10px 0 14px;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
        background: #0D3659;
        color: #fff;
    }

    &__data {
        padding: 10px 34px 34px;
    }

    &__radio, &__text {
        color: #6B6D81;
        font-size: 16px;
        font-weight: 500;
    }

    &__text {
        color: #0c3659 !important;
    }
}

::v-deep {
    .custom--v-radio-group {
        border: none !important;
        margin: 0 auto;

        .v-input__slot {
            padding: 0 !important;
        }

        .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
            margin-bottom: 0 !important;
            margin-right: 6px !important;
        }

        .v-item--active .invite-reviewer__radio {
            color: #8F3985 !important;
        }
    }

    .custom--v-select {
        .v-input__control {
            background: #F5F5F9 !important;
        }
    }

    .v-text-field__details {
        display: none !important;
    }
}
</style>