<template>
    <div>
        <v-row>
            <v-col cols="12" v-if="tabDescription">
                <div class="mt-4 mb-10">
                    <p>{{ tabDescription }}</p>
                </div>
            </v-col>
            <v-col lg="9">
                <ul class="equipments">
                    <li class="equipment" v-for="equipment in equipments" :key="equipment.uuid">
                        <div class="equipment__left">
                            <strong class="text-secondary">
                                {{ equipment.name }}
                            </strong>
                        </div>

                        <div class="equipment__right">
                            <span class="text-secondary">
                                $
                            </span>

                            <v-text-field
                                v-model="equipment.value"
                                single-line
                                hide-details
                                outlined
                                type="text"
                                :background-color="equipment.is_active ? '#FFFFFF' : '#F8F8F8'"
                                placeholder="No fee"
                                class="custom--v-text-field mr-5"
                                :disabled="!equipment.is_active"
                            />

                            <v-switch hide-details v-model="equipment.is_active"/>
                        </div>
                    </li>
                </ul>
            </v-col>
        </v-row>
        <v-btn
            class="px-10 mt-6"
            elevation="0"
            color="primary"
            large
            @click="save"
            :loading="isLoading"
        >
            Save changes
        </v-btn>
    </div>
</template>

<script>
import { mapActions } from "vuex/dist/vuex.esm.browser";
import loading from "@/mixins/loading.js";

export default {
    props: {
        branch: Object,
        tabDescription: String,
    },

    mixins: [loading],

    data () {
        return {
            /** @type {BranchEquipment[]} */
            equipments: [...this.branch.equipments],
        };
    },

    methods: {
        ...mapActions('locations', ['updateBranch']),

        save () {
            this.startLoading(async () => {
                await this.updateBranch({
                    id: this.branch.id,
                    equipments: this.equipments
                });
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.equipments {
    margin: 0;
    padding: 0;
    border: 1px solid #DDDDDD;
}

.equipment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px 10px 15px;

    &:nth-child(odd) {
        background-color: #F8F8F8;
    }

    &:nth-child(even) {
        background-color: #FFFFFF;
    }

    &__left {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        strong {
            font-size: 16px;
            margin-right: 15px;
        }

        span {
            color: #AAAAAA;
            font-size: 14px;
        }
    }

    &__right {
        display: flex;
        align-items: center;

        span {
            font-weight: bold;
            font-size: 16px;
            margin-right: 10px;
        }

        ::v-deep .v-text-field {

            .v-input__control {

                .v-input__slot {
                    width: 100px;
                    height: 32px;
                    min-height: 32px;
                    padding: 0 10px !important;
                }
            }
        }

        .v-input--switch {
            margin: 0;
            padding: 0;
        }
    }
}
</style>