<template>
    <div class="">
        <div class="rounded dashboard-card">
            <div :class="[timeDiff === 0 ? 'bg--change': '', 'card-header tw-w-full rounded tw-h-auto']">
                <div class='card-name tw-flex tw-w-full'>
                    <div class="card-name__id text-base normal-case">
                        <span>{{ order.start.split(' ')[0] }}</span> - #{{ order.id }}
                    </div>
                    <div class="card-name--zipcode ml-lg-auto" :class="{'card-name__zipcode--mobile': !showUpOrderTime}" @click="openOrderReviewModal">
                        <span v-text="getOrderName" />
                        <v-icon color="primary" size="10" class="ml-2">fa-chevron-right</v-icon>
                    </div>
                    <div v-if="showUpOrderTime" class="ml-auto ml-sm-0 card-time d-flex align-center">
                        <v-icon size="14" color="secondary" class="mr-2 ml-3">fa-clock</v-icon>
                        <span>{{ timeDiff }}</span>
                    </div>
                </div>
            </div>

            <v-card outlined tile class="rounded-b dashboard-card__main">
                <div class="card-toggle px-md-2 px-md-5 px-sm-4">
                    <div class="w-100 d-flex align-center flex-wrap">
                    <span class="card-name card-name__status text-sm" v-text="order.status" />
                        <v-btn
                            @click="viewDetails"
                            class="card-toggle__btn ml-auto px-4 v-btn v-btn--has-bg theme--light elevation-0  secondary">
                            {{ isCardOpened ? "Close details" : "View details" }}
                        </v-btn>
                    </div>

                    <app-view-details
                        v-if="isCardOpened"
                        :orderDetails="orderDetails"
                    />
                </div>

                <v-col class="card-info d-flex align-center flex-wrap px-md-5">
                    <div class="card-info__content w-50 d-flex align-center justify-space-between">
                        <div class="d-flex align-center">
                            <div class="card-info__image mr-2.5">
                                <v-img src="/assets/icons/trucks.svg" alt="trucks icon" />
                            </div>

                            <strong v-text="order.truck_number" />
                        </div>

                        <div class="d-flex align-center">
                            <div class="card-info__image mr-2.5">
                                <img src="/assets/icons/helpers.svg" alt="helpers icon">
                            </div>

                            <strong>
                                {{ $pluralize(order.helpers_count, 'helper') }}
                            </strong>
                        </div>

                        <div class="d-flex align-center">
                            <div class="card-info__image mr-2.5">
                                <img src="/assets/icons/hours.svg" alt="hours icon">
                            </div>

                            <strong>
                                {{ $pluralize(order.hours_count, 'hour') }}
                            </strong>
                        </div>
                    </div>

                    <div class="ml-md-auto card-info__phone">
                        <strong v-text="getOrderPhoneNumber" />
                    </div>
                </v-col>

                <v-col class="dashboard-card--facts d-flex flex-wrap flex-col md:flex-row">
                    <div class="dashboard-card__left">
                        <h5 v-if="isBeingRescheduled" class="text-secondary text-base font-medium mb-2 text-center text-sm-left">Current</h5>
                        <v-row align="end" justify="space-between">
                            <v-col cols="12" sm="3" md="auto" lg="4" class="col__info-item px-sm-3">
                                <div class="d-flex align-center align-sm-start">
                                    <v-icon size="16" color="primary" class="mr-2 mt-0.5">
                                        fa-calendar-alt
                                    </v-icon>
                                    <div class="d-flex w-full flex-sm-column">
                                        <span class="text-sm" v-text="'Moving date:'"/>
                                        <del class="ml-auto ml-sm-0" v-if="isBeingRescheduled" v-text="order.arrival_date" />
                                        <strong v-if="!isBeingRescheduled" class="ml-auto ml-sm-0 text-base text-medium info-item" v-text="order.arrival_date"/>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12" sm="4" md="auto" lg="4" class="col__info-item px-sm-3">
                                <div class="d-flex align-center align-sm-start">
                                    <v-icon size="16" color="primary" class="mr-2 mt-0.5">fa-clock</v-icon>
                                    <div class="d-flex w-full flex-sm-column">
                                        <span class="text-sm" v-text="'Arriving between:'"/>
                                        <del class="ml-auto ml-sm-0" v-if="isBeingRescheduled" v-text="order.arrival_time" />
                                        <strong v-if="!isBeingRescheduled" class="ml-auto ml-sm-0 text-base text-medium  info-item" v-text="order.arrival_time"/>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12" sm="4" md="auto" lg="4" class="col__info-item px-sm-3">
                                <div class="d-flex align-center align-sm-start">
                                    <div class="mr-1.5 mt-0.5">
                                        <svg width="16" height="16" viewBox="0 0 14 13" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7 0C3.41707 0 0.5 2.91707 0.5 6.5C0.5 10.0829 3.41707 13 7 13C10.5829 13 13.5 10.0829 13.5 6.5C13.5 2.91707 10.5829 0 7 0ZM7 6.18293C7.09511 6.18293 7.19021 6.19874 7.30125 6.19874C8.37924 6.3414 9.21948 7.27685 9.21948 8.4024C9.21948 9.51213 8.39507 10.4475 7.31707 10.5902V11.4147C7.31707 11.589 7.17442 11.7317 7.00003 11.7317C6.82564 11.7317 6.68298 11.589 6.68298 11.4147V10.5902C5.60499 10.4317 4.78057 9.51224 4.78057 8.4024C4.78057 8.228 4.92323 8.08535 5.09762 8.08535C5.17691 8.08535 5.2562 8.11709 5.31956 8.18046C5.38293 8.24394 5.41467 8.32312 5.41467 8.4024C5.41467 9.27438 6.12805 9.98777 7.00003 9.98777C7.87201 9.98777 8.5854 9.27438 8.5854 8.4024C8.5854 7.53042 7.87201 6.81704 7.00003 6.81704C6.90493 6.81704 6.80982 6.80123 6.69879 6.80123C5.62079 6.65857 4.78055 5.72312 4.78055 4.59757C4.78055 3.48784 5.60497 2.5525 6.68296 2.40973V1.58531C6.68296 1.41092 6.82562 1.26827 7.00001 1.26827C7.17439 1.26827 7.31705 1.41093 7.31705 1.58531V2.40973C8.39504 2.56832 9.21946 3.48773 9.21946 4.59757C9.21946 4.77196 9.0768 4.91461 8.90242 4.91461C8.82313 4.91461 8.74384 4.88287 8.68047 4.81951C8.6171 4.75603 8.58537 4.67685 8.58537 4.59756C8.58537 3.72558 7.87198 3.0122 7 3.0122C6.12802 3.0122 5.41463 3.72558 5.41463 4.59756C5.41463 5.46954 6.12802 6.18293 7 6.18293Z"
                                                fill="#8F3985"/>
                                        </svg>
                                    </div>
                                    <div class="d-flex w-full flex-sm-column">
                                        <span class="text-sm" v-text="'Order price:'"/>
                                        <del class="ml-auto ml-sm-0" v-if="isBeingRescheduled" v-text="getOrderPrice()" />
                                        <strong v-if="!isBeingRescheduled" class="ml-auto ml-sm-0 text-base text-medium info-item" v-text="getOrderPrice()" />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="dashboard-card__right" v-if="isBeingRescheduled">
                        <h5 class="text-secondary text-sm font-medium text-center text-sm-left mb-2 mb-lg-0">New</h5>
                        <v-row align="end" justify="space-between" class="flex-wrap">
                            <v-col cols="12" sm="3" md="auto" lg="4" class="col__info-item px-sm-3">
                                <div class="d-flex align-center align-sm-start">
                                    <v-icon size="16" color="primary" class="mr-2 mt-0.5">
                                        fa-calendar-alt
                                    </v-icon>
                                    <div class="w-full d-flex flex-sm-column">
                                        <span class="text-sm" v-text="'Moving date:'"/>
                                        <strong class="ml-auto ml-sm-0 text-base text-medium info-item" v-text="getDate(order.new_start)"/>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12" sm="4" md="auto" lg="4" class="col__info-item px-sm-3">
                                <div class="d-flex align-center align-sm-start">
                                    <v-icon size="16" color="primary" class="mr-2 mt-0.5">fa-clock</v-icon>
                                    <div class="w-full d-flex flex-sm-column">
                                    <span class="text-sm" v-text="'Arriving between: '"/>
                                        <strong class="ml-auto ml-sm-0 text-base text-medium info-item" v-text="order.new_arrival_time.toUpperCase()"/>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12" sm="4" md="auto" lg="4" class="col__info-item px-sm-3">
                                <div class="d-flex align-center align-sm-start">
                                    <div class="mr-1.5 mt-0.5">
                                        <svg width="16" height="16" viewBox="0 0 14 13" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7 0C3.41707 0 0.5 2.91707 0.5 6.5C0.5 10.0829 3.41707 13 7 13C10.5829 13 13.5 10.0829 13.5 6.5C13.5 2.91707 10.5829 0 7 0ZM7 6.18293C7.09511 6.18293 7.19021 6.19874 7.30125 6.19874C8.37924 6.3414 9.21948 7.27685 9.21948 8.4024C9.21948 9.51213 8.39507 10.4475 7.31707 10.5902V11.4147C7.31707 11.589 7.17442 11.7317 7.00003 11.7317C6.82564 11.7317 6.68298 11.589 6.68298 11.4147V10.5902C5.60499 10.4317 4.78057 9.51224 4.78057 8.4024C4.78057 8.228 4.92323 8.08535 5.09762 8.08535C5.17691 8.08535 5.2562 8.11709 5.31956 8.18046C5.38293 8.24394 5.41467 8.32312 5.41467 8.4024C5.41467 9.27438 6.12805 9.98777 7.00003 9.98777C7.87201 9.98777 8.5854 9.27438 8.5854 8.4024C8.5854 7.53042 7.87201 6.81704 7.00003 6.81704C6.90493 6.81704 6.80982 6.80123 6.69879 6.80123C5.62079 6.65857 4.78055 5.72312 4.78055 4.59757C4.78055 3.48784 5.60497 2.5525 6.68296 2.40973V1.58531C6.68296 1.41092 6.82562 1.26827 7.00001 1.26827C7.17439 1.26827 7.31705 1.41093 7.31705 1.58531V2.40973C8.39504 2.56832 9.21946 3.48773 9.21946 4.59757C9.21946 4.77196 9.0768 4.91461 8.90242 4.91461C8.82313 4.91461 8.74384 4.88287 8.68047 4.81951C8.6171 4.75603 8.58537 4.67685 8.58537 4.59756C8.58537 3.72558 7.87198 3.0122 7 3.0122C6.12802 3.0122 5.41463 3.72558 5.41463 4.59756C5.41463 5.46954 6.12802 6.18293 7 6.18293Z"
                                                fill="#8F3985"/>
                                        </svg>
                                    </div>
                                    <div class="w-full d-flex flex-sm-column">
                                        <span class="text-sm" v-text="'Order price:'"/>
                                        <strong class="ml-auto ml-sm-0 text-base text-medium info-item" v-text="'$' + order.order_price"/>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row
                        v-if="isButtonsViewed"
                        align="end" justify="end"
                        :class="{'border-top': isBeingRescheduled}"
                    >
                        <v-col sm="12" cols="12" md="auto" class="px-0">
                            <user-dashboard-card-actions v-if="isCustomer" :order="order" class="px-sm-3"/>

                            <dashboard-card-actions v-else :order="order" class="px-sm-3"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-card>
        </div>
    </div>
</template>

<script>
import { ORDER_STATUSES } from "@/plugins/data";
import AppReschedule from "../../components/Modals/AppReschedule.vue"
import { mapActions, mapGetters } from "vuex";
import AppViewDetails from "@/components/AppViewDetails";
import UserDashboardCardActions from "@/views/UserDashboard/UserDashboardCardActions.vue";
import DashboardCardActions from "@/views/Dashboard/DashboardCardActions.vue";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { convertSecondToTime } from "@/plugins/helpers.js";
import OrderReviews from "@/components/Modals/OrderReviews.vue";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
    components: { DashboardCardActions, UserDashboardCardActions, AppViewDetails },
    props: {
        order: {
            type: Object,
            required: true,
        }
    },

    data () {
        return {
            orderDetails: {},
            isCardOpened: false,
            timeDiff: 0,
            interval: null,
        }
    },

    methods: {
        ...mapActions('orders', { showHelperOrder: 'show' }),
        ...mapActions('user_orders', { showCustomerOrder: 'show' }),
        ...mapActions('review', ['get_helper_order_reviews', 'get_customer_order_reviews']),

        async viewDetails () {
            if (this.isCardOpened) {
                this.isCardOpened = false;
                return;
            }

            this.isCardOpened = true;

            this.orderDetails = this.isCustomer
                ? await this.showCustomerOrder(this.order.id)
                : await this.showHelperOrder(this.order.id);
        },

        openRescheduleModal () {
            this.$modal.show(AppReschedule, {
                order: this.order
            }, {
                width: 1000,
                height: 700,
            });
        },

        async openOrderReviewModal () {
            const reviews = await this.getUserReviews()

            this.$modal.show(OrderReviews, {
                reviews: reviews
            }, {
                width: 800,
                height: 600,
            })
        },

        async getUserReviews () {
            if (this.isCustomer) {
                return await this.get_customer_order_reviews(this.order.id);
            } else {
                return await this.get_helper_order_reviews(this.order.id);
            }
        },

        getName (name) {
            return this.order.reschedule_request && ( this.order.isHelperBeingRescheduled || this.order.isCustomerBeingRescheduled) ? `Old ${ name }` : name;
        },

        getDate (date) {
            return date ? date.split(' ')[0] : ''
        },

        getOrderPrice () {
            if(this.order.reschedule_request && this.order.reschedule_request.status === "pending" && (this.order.status !== "Canceled" && this.order.status !== "Rejected" )) {
                return '$' + this.order.old_order_price;
            }

            return '$' + this.order.order_price;
        }
    },

    computed: {
        ...mapGetters('auth', ['isCustomer']),

        isNewOrder () {
            return this.order.status_id === ORDER_STATUSES.STATUS_NEW && !this.isCustomer;
        },

        isAcceptedOrder () {
            return this.order.status_id === ORDER_STATUSES.STATUS_ACCEPTED && !this.isCustomer;
        },

        isBeingCompletedOrder () {
            return this.order.status_id === ORDER_STATUSES.STATUS_BEING_COMPLETED;
        },

        isBeingRescheduled () {
            return this.order.status_id === ORDER_STATUSES.STATUS_BEING_RESCHEDULED  && this.order.reschedule_request;
        },

        isButtonsViewed () {
             if (this.isBeingRescheduled) {
                 if(!this.order.isHelperBeingRescheduled && !this.order.isCustomerBeingRescheduled) {
                     return  false;
                 }
             }
             return true;
        },

        showUpOrderTime () {
            return !this.isCustomer && this.timeDiff && this.order.status !== 'Rejected' && this.order.status !== 'Canceled' && this.order.status !== 'Accepted'
        },

        getOrderPhoneNumber () {
            return this.isCustomer ? `Company phone: ${ this.order.company_phone_number }` : `Customer phone: ${ this.order.customer_phone }`;
        },

        getOrderName () {
            let name = this.isCustomer ? this.order.branch_name : this.order.name;
            if(this.$vuetify.breakpoint.smAndDown) {
                return name.three_dots(5) + ` - ${this.order.zip_code}`
            }

            return name + ` - ${this.order.zip_code}`
        }
    },

    mounted () {
        this.interval = setInterval(() => {
            const a = new Date(this.order.till_auto_reject);
            const b = new Date(dayjs().tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss'));

            const difference = a - b;

            this.timeDiff = difference > 0
                ? convertSecondToTime(difference)
                : null;
        }, 1000);
    },

    destroyed () {
        clearInterval(this.interval);
    }
}
</script>

<style lang="scss">
@import "src/assets/scss/style";

//request icon
.custom--v-icon::v-deep {
    position: absolute;
}
.border-top {
    margin-top: 10px !important;
    border-top: 1px solid #ddd;
}

del {
    color: #0c3659;
    text-decoration: line-through;
}

.card-name__zipcode--mobile {
    margin-top: 0 !important;
    background: #fff !important;
    margin-left: auto !important;
    padding: 3px 11px !important;
}

.dashboard-card__left, .dashboard-card__right {
    width: 100%;

    //desktop version
    @media (min-width: 1300px) {
        width: 50%;
    }
}
.dashboard-card__left {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    padding-right: 10px;

    @media (min-width: 1300px) {
        border-bottom: none;
        padding-bottom: 0;
        padding-right: 0;
    }
}

.dashboard-card__right {
    padding: 10px 0  0;
}

.dashboard-block {
    max-width: 90%;
    margin-bottom: 20px;
    margin-left: 18px;
    background: #fff;
    border-radius: 8px;

    &:first-child {
        margin-top: 18px;
    }

    &:last-child {
        margin-bottom: 70px !important;
    }

    //    mobile responsive
    @media (max-width: 990px) {
        background: none !important;
    }

    @media (max-width: 990px) {
        width: 100%;
        max-width: 100%;
        margin: 18px auto 20px !important;
    }

    @media (min-width: 990px) {
        padding: 0;
    }
}

.dashboard-card {
    position: relative;
    border: 1px solid #ddd;
    background: #fff;

    @media (max-width: 991px) {
        background-color: #FFF !important;
        &:last-child {
            margin-bottom: 20px !important;
        }
    }

    &__main {
        border: none !important;
    }

    //    mobile responsive
    @media (max-width: 760px) {
        padding: 15px 10px !important;
        background: #fff;
        border: none;
    }
}

.dashboard-card--facts {
    border-top: 1px solid #ddd;

    @media (max-width: 760px) {

    }
}

.card-header {
    height: 39px;
    margin-bottom: 9px;
    padding: 11px 20px 9px;
    background-color: $bgCard !important;
    display: flex;
    align-items: center;
    position: relative;
    top: 0;
    z-index: 1;

    //    mobile responsive
    @media (max-width: 760px) {
        height: auto;
        padding: 10px;
    }
}

.card-name {
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-weight: 600;

    &__id {
        color: $card-text;
    }

    &__status {
        font-family: $body-font-family;
        font-size: 16px !important;
        line-height: 21px !important;
        color: $secondary;
    }

    &--zipcode {
        margin-left: auto;
        margin-top: -2px;
        padding: 3px 11px;
        background: #FFFFFF;
        border-radius: 8px;
        cursor: pointer;

        span {
            color: $card-text;
        }

        i {
            margin-top: -2px;
        }
    }

    //mobile responsive
    @media (max-width: 530px) {
        flex-wrap: wrap;

        &--zipcode {
            order: 2;
            margin-left: 0;
            background: none;
            padding: 0;
            margin-top: 10px;
        }
    }
}

.card-toggle {
    background: #F5F5F9;
    padding: 6px 0 7px;

    &__btn {
        height: 30px !important;
        font-weight: 600;
    }

    //    mobile responsive
    @media (max-width: 760px) {
        border-radius: 8px !important;
        padding: 10px !important;
    }
}

.card-info {
    padding: 10.5px 0;

    @media (max-width: 400px) {
        display: flex;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        color: $secondary;

        @media (min-width: 401px) {
            &--phone:nth-child(4) {
                display: none !important;
            }
        }
        @media (min-width: 1200px) {
            width: 44%;
        }

        @media (max-width: 1199px) {
            width: 60%;
        }

        @media (max-width: 900px) {
            width: 100%;
        }

        @media (max-width: 900px) {
            padding: 12px 0;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;

            & > div {
                flex-direction: column;
                strong {
                    margin-top: 10px;
                }
            }

            & > div {
                width: 40%;
            }
            & > div:nth-child(4) {
                width: 60%;
            }
            & > div:nth-child(2) {
                width: 60%;
            }
        }

        & > div:nth-child(3) {
            margin-right: 0;
        }
    }

    &__image {
        @media (max-width: 767px) {
            margin-right: 4px;
        }
    }

    &__phone {
        color: $secondary;
        @media (min-width: 768px) {
            &__icon {
                display: none;
            }
            span {
                display: inline-block;
            }
        }
        @media (max-width: 900px) {
            margin-left: 10px;
            margin-top: 8px;
            &__icon {
                display: block;
            }
            span {
                display: none;
            }
        }
    }

    strong {
        line-height: 19px;
        font-weight: 600 !important;
    }
}

.dashboard-card-action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    @media (max-width: 500px) {
        margin-bottom: -12px;
    }

    @media (min-width: 1300px) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.col__info-item {
    padding: 15px 21.33px;

    @media(max-width: 760px) {
        padding: 7px 6px;
    }

    strong {
        @media(max-width: 500px) {
            font-size: 16px;
        }
    }

    .info-item {
        color: $secondary;
    }
}

.order-card {

    &__info {

        h3 {
            color: #0D3659;
            font-size: 18px;
            font-weight: 800;
            margin-bottom: 10px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            li {
                color: #0D3659;
                font-size: 16px;

                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                strong {
                    font-weight: 700;
                }
            }
        }
    }
}

.card-time {
    color: $secondary;
}
</style>
