<template>
    <div class="service-area">
        <h3 class="service-area__header pricing-header--extra text-lg font-semibold text-secondary mb-1">
            Service Area
        </h3>

        <p class="service-area__desc mb-4 text-secondary">
            Check out the Service Area page to edit your settings for more specific areas you service.
        </p>

        <v-row class="service-area__row">
            <v-col class="service-area__column" lg="8">
                <div class="slider-box mb-4">
                    <v-slider
                        hide-details
                        max="100"
                        min="0"
                        v-model="local.service_area"
                        class="custom--v-slider"
                    />

                    <div class="slider-box__div text-secondary">
                        {{ local.service_area }} miles
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ValueEmitter from "../../mixins/ValueEmitter";

export default {
    mixins: [ValueEmitter],

    watch: {
        'local.service_area' () {
            if (this.local.service_area < 1) {
                this.local.service_area = 1;
            }

            if (this.local.service_area > this.local.surge_distance) {
                this.local.surge_distance = this.local.service_area;
            }
        },
    }
}
</script>