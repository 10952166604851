<template>
    <div class="tw-p-[15px] tw-rounded-[8px] tw-bg-white">
        <div class="tw-rounded-[8px] tw-overflow-hidden tw-border tw-border-solid tw-border-[#dddddd]">
            <div :class="[timeDiff === 0 ? 'bg--change': '', 'tw-bg-[#E4E8FF] tw-px-[20px] tw-py-[10px] tw-mb-[10px]']">
                <div class='tw-flex tw-justify-between'>
                    <div class="tw-font-semibold tw-text-[#0D3659]">
                        <span>{{ order.items_name }}</span>
                    </div>
                    <div class="tw-block tw-px-[15px] tw-py-[3px] tw-bg-white tw-text-[#0D3659] tw-font-semibold tw-text-[14px] tw-rounded-[8px] tw-cursor-pointer">
                        <span>{{ order.customer.name }}</span>
                        <v-icon color="secondary" size="10" class="tw-ml-2">fa-chevron-right</v-icon>
                    </div>
                    <div v-if="showUpOrderTime" class="ml-auto ml-sm-0 card-time d-flex align-center">
                        <v-icon size="14" color="secondary" class="mr-2 ml-3">fa-clock</v-icon>
                        <span>{{ timeDiff }}</span>
                    </div>
                </div>
            </div>

            <v-card outlined tile class="rounded-b dashboard-card__main">
                <div class="tw-bg-[#F4F6FF] tw-px-4">
                    <div class="tw-w-full tw-flex tw-items-center tw-justify-between tw-flex-wrap tw-py-2">
                        <div class="tw-font-bold tw-text-[#0D3659]">
                            <span v-text="order.status" />
                        </div>
                        <button 
                            type="button"
                            class="tw-bg-[white] tw-border tw-border-solid tw-border-[#E3E8FF] tw-text-[14px] tw-rounded-[8px] tw-text-[#0D3659] tw-font-semibold hover:tw-bg-[#0D3659] hover:tw-border-[#0D3659] tw-duration-300 hover:tw-text-[#FFF] tw-px-4"
                            @click="openDeliveryDetailsModal(order.id)"
                        >
                            {{ isCardOpened ? "Close details" : "View details" }}
                        </button>
                    </div>
                </div>

                <div class="tw-pt-[15px] tw-px-[20px]">
                    <div class="tw-flex tw-justify-between tw-gap-[35px] tw-pb-[15px] tw-border-0 tw-border-b tw-border-solid tw-border-[#DDDDDD]">
                        <div class="tw-flex tw-gap-[35px]">
                            <div class="tw-flex tw-items-center tw-text-[#0D3659] tw-font-semibold">
                                <i class="icony icony-mapmarker-clock tw-mr-[8px] tw-text-[#8F3985]"></i>
                                <span>{{ order.pickup }}</span>
                            </div>
                            <div class="tw-flex tw-items-center tw-text-[#0D3659] tw-font-semibold">
                                <i class="icony icony-mapmarker-check tw-mr-[8px] tw-text-[#8F3985]"></i>
                                <span>{{ order.destination }}</span>
                            </div>
                        </div>
                        <div class="tw-text-[#0D3659] tw-font-semibold">
                            {{ getPhone(order.customer.phone) }}
                        </div>
                    </div>
                </div>

                <div class="tw-grid tw-grid-cols-2 tw-py-[15px] tw-px-[20px]">
                    <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-gap-[30px]">
                        <div class="tw-flex tw-items-start">
                            <v-icon size="16" color="primary" class="tw-mr-2 tw-mt-1">
                                fa-calendar-alt
                            </v-icon>
                            <div class="d-flex w-full flex-sm-column">
                                <span class="text-sm" v-text="'Moving date:'"/>
                                <strong class="ml-auto ml-sm-0 text-base text-medium info-item" v-text="order.arrival_date"/>
                            </div>
                        </div>

                        <div class="tw-flex tw-items-start">
                            <v-icon size="16" color="primary" class="tw-mr-2 tw-mt-1">fa-clock</v-icon>
                            <div class="d-flex w-full flex-sm-column">
                                <span class="text-sm" v-text="'Show up time:'"/>
                                <strong  class="ml-auto ml-sm-0 text-base text-medium  info-item" v-text="order.arrival_time"/>
                            </div>
                        </div>

                        <div class="tw-flex tw-items-start">
                            <div class="tw-mr-2 tw-mt-1">
                                <svg 
                                    width="16" 
                                    height="16" 
                                    viewBox="0 0 14 13" 
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7 0C3.41707 0 0.5 2.91707 0.5 6.5C0.5 10.0829 3.41707 13 7 13C10.5829 13 13.5 10.0829 13.5 6.5C13.5 2.91707 10.5829 0 7 0ZM7 6.18293C7.09511 6.18293 7.19021 6.19874 7.30125 6.19874C8.37924 6.3414 9.21948 7.27685 9.21948 8.4024C9.21948 9.51213 8.39507 10.4475 7.31707 10.5902V11.4147C7.31707 11.589 7.17442 11.7317 7.00003 11.7317C6.82564 11.7317 6.68298 11.589 6.68298 11.4147V10.5902C5.60499 10.4317 4.78057 9.51224 4.78057 8.4024C4.78057 8.228 4.92323 8.08535 5.09762 8.08535C5.17691 8.08535 5.2562 8.11709 5.31956 8.18046C5.38293 8.24394 5.41467 8.32312 5.41467 8.4024C5.41467 9.27438 6.12805 9.98777 7.00003 9.98777C7.87201 9.98777 8.5854 9.27438 8.5854 8.4024C8.5854 7.53042 7.87201 6.81704 7.00003 6.81704C6.90493 6.81704 6.80982 6.80123 6.69879 6.80123C5.62079 6.65857 4.78055 5.72312 4.78055 4.59757C4.78055 3.48784 5.60497 2.5525 6.68296 2.40973V1.58531C6.68296 1.41092 6.82562 1.26827 7.00001 1.26827C7.17439 1.26827 7.31705 1.41093 7.31705 1.58531V2.40973C8.39504 2.56832 9.21946 3.48773 9.21946 4.59757C9.21946 4.77196 9.0768 4.91461 8.90242 4.91461C8.82313 4.91461 8.74384 4.88287 8.68047 4.81951C8.6171 4.75603 8.58537 4.67685 8.58537 4.59756C8.58537 3.72558 7.87198 3.0122 7 3.0122C6.12802 3.0122 5.41463 3.72558 5.41463 4.59756C5.41463 5.46954 6.12802 6.18293 7 6.18293Z"
                                        fill="#8F3985"
                                    />
                                </svg>
                            </div>
                            <div class="d-flex w-full flex-sm-column">
                                <span 
                                    class="text-sm" 
                                    v-text="'Order price:'"
                                />
                                <strong 
                                    v-if="isCustomer" 
                                    class="ml-auto ml-sm-0 text-base text-medium info-item" 
                                    v-text="'$' + order.invoice.total_price" 
                                />
                                <strong 
                                    v-else 
                                    class="ml-auto ml-sm-0 text-base text-medium info-item" 
                                    v-text="'$' + order.price" 
                                />
                            </div>
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-end tw-ml-auto">
                        <delivery-actions
                            :order="order"
                            :shouldAccept="shouldAccept"
                            :shouldCancel="shouldCancel"
                        />
                    </div>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import { ORDER_STATUSES } from "@/plugins/data";
import AppReschedule from "../../components/Modals/AppReschedule.vue"
import { mapActions, mapGetters, mapState } from "vuex";
import DashboardDeliveryCardDetails from "@/views/Dashboard/DashboardDeliveryCardDetails.vue";
import DeliveryActions from "./DeliveryActions.vue";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { convertSecondToTime } from "@/plugins/helpers.js";
import OrderReviews from "@/components/Modals/OrderReviews.vue";
import DeliveryCardDetails from "@/components/Modals/DeliveryCardAction.vue";
import { EventBus } from "../../main";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
    components: {
        DashboardDeliveryCardDetails,
        DeliveryActions
    },

    props: {
        order: {
            type: Object,
            required: true,
        }
    },

    data () {
        return {
            orderDetails: {},
            isCardOpened: false,
            timeDiff: 0,
            interval: null,
            shouldAccept: false,
            shouldCancel: false,
        }
    },

    methods: {
        ...mapActions('orders', { showHelperOrder: 'show' }),
        ...mapActions('user_orders', { showCustomerOrder: 'show' }),
        ...mapActions('review', ['get_helper_order_reviews', 'get_customer_order_reviews']),
        ...mapActions('dashboard', ['getDetailedDelivery']),

        async viewDetails () {
            if (this.isCardOpened) {
                this.isCardOpened = false;
                return;
            }

            this.isCardOpened = true;
        },

        openRescheduleModal () {
            this.$modal.show(AppReschedule, {
                order: this.order
            }, {
                width: 1000,
                height: 700,
            });
        },

        async openOrderReviewModal () {
            const reviews = await this.getUserReviews()

            this.$modal.show(OrderReviews, {
                reviews: reviews
            }, {
                width: 800,
                height: 600,
            })
        },

        async openDeliveryDetailsModal(id) {
            await this.getDetailedDelivery(id);

            this.$modal.show(DeliveryCardDetails, {
                deliveryDetails: this.detailedDelivery
            }, {
                width: 1200,
                height: 'auto',
            }, {
              'before-open': this.$scrollLock.enable,
              'before-close': this.$scrollLock.disable,
            });
            EventBus.$on('acceptDelivery', () => {
                this.shouldAccept = true;
            });
            EventBus.$on('cancelDelivery', () => {
                this.shouldCancel = true;
            });
        },

        async getUserReviews () {
            if (this.isCustomer) {
                return await this.get_customer_order_reviews(this.order.id);
            } else {
                return await this.get_helper_order_reviews(this.order.id);
            }
        },

        getName (name) {
            return this.order.reschedule_request && ( this.order.isHelperBeingRescheduled || this.order.isCustomerBeingRescheduled) ? `Old ${ name }` : name;
        },

        getDate (date) {
            return date ? date.split(' ')[0] : ''
        },

        getOrderPrice () {
            if(this.order.reschedule_request && this.order.reschedule_request.status === "pending" && (this.order.status !== "Canceled" && this.order.status !== "Rejected" )) {
                return '$' + this.order.old_order_price;
            }

            return '$' + this.order.order_price;
        },

        getAddresses(location) {
            if (!location.city && !location.state && !location.zip) return 'No destination';
            return `${location.city} ${location.state}, ${location.zip}`;
        },

        getPhone(phone) {
            const countryAreaCode = phone.substring(0, 9);
            if (this.order.status === 'Accepted') return `Customer phone: ${phone}`;
            else return `Customer phone: ${countryAreaCode} - ****`;
        }
    },

    computed: {
        ...mapGetters('auth', ['isCustomer']),
        ...mapState('dashboard', ['detailedDelivery']),

        isNewOrder () {
            return this.order.status_id === ORDER_STATUSES.STATUS_NEW && !this.isCustomer;
        },

        isAcceptedOrder () {
            return this.order.status_id === ORDER_STATUSES.STATUS_ACCEPTED && !this.isCustomer;
        },

        isBeingCompletedOrder () {
            return this.order.status_id === ORDER_STATUSES.STATUS_BEING_COMPLETED;
        },

        isBeingRescheduled () {
            return this.order.status_id === ORDER_STATUSES.STATUS_BEING_RESCHEDULED  && this.order.reschedule_request;
        },

        isButtonsViewed () {
            if (this.isBeingRescheduled) {
                if(!this.order.isHelperBeingRescheduled && !this.order.isCustomerBeingRescheduled) {
                    return  false;
                }
            }
            return true;
        },

        showUpOrderTime () {
            return !this.isCustomer && this.timeDiff && this.order.status !== 'Rejected' && this.order.status !== 'Canceled' && this.order.status !== 'Accepted'
        },

        getOrderPhoneNumber () {
            return this.isCustomer ? `Company phone: ${ this.order.company_phone_number }` : `Customer phone: ${ this.order.customer_phone }`;
        },

        getOrderName () {
            let name = this.isCustomer ? this.order.branch_name : this.order.name;
            if(this.$vuetify.breakpoint.smAndDown) {
                return name.three_dots(5) + ` - ${this.order.zip_code}`
            }

            return name + ` - ${this.order.zip_code}`
        },

        getVehicleImage() {
            const vehicleTypes = {
                1: 'sedan',
                2: 'hatchback',
                3: 'pickup',
                4: 'truck',
            };

            const vehicle = vehicleTypes[this.order.vehicleType.id] || 'unknown';
            return `assets/images/vehicles/${vehicle}.svg`;
        },

        getItemsName() {
            const itemsName = this.order.items.map(item => item.name);
            if (itemsName.length > 1) {
                const lastItem = itemsName.pop();
                return `${itemsName.join(', ')} and ${lastItem}`;
            } else {
                return itemsName[0];
            }
        }
    },

    mounted () {
        this.interval = setInterval(() => {
            const a = new Date(this.order.till_auto_reject);
            const b = new Date(dayjs().tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss'));

            const difference = a - b;

            this.timeDiff = difference > 0
                ? convertSecondToTime(difference)
                : null;
        }, 1000);
    },

    destroyed () {
        clearInterval(this.interval);
    }
}
</script>
