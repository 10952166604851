/**
 * Get branch_id by block day
 *
 * @param {BranchBlockDay|Object|undefined} blockDay
 */
export const getBranchId = blockDay => {
    if (blockDay?.company_id) {
        return 'company';
    }

    if (blockDay?.branch_id) {
        return blockDay.branch_id;
    }

    return null;
};

/**
 * Get interval by block day
 *
 * @param {BranchBlockDay|Object|undefined} blockDay
 */
export const getInterval = blockDay => {
    if (blockDay?.is_on_morning && ! blockDay?.is_on_afternoon) {
        return 'is_on_morning';
    }

    if (blockDay?.is_on_afternoon && ! blockDay?.is_on_morning) {
        return 'is_on_afternoon';
    }

    return 'is_on_full_day';
}