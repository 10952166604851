<template>
    <div class="tw-bg-white tw-p-[10px]">
        <div class="tw-grid tw-grid-cols-3 tw-gap-[16px]">
            <div class="tw-col-span-2">
                <div class="tw-grid tw-grid-cols-2 tw-gap-[16px]">
                    <div class="tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[12px]">
                        <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Customer info</strong>
                        <ul class="tw-list-none !tw-p-0 tw-m-0">
                            <li v-for="({ label, value }, index) in moverInfo" :key="index" class="tw-flex tw-justify-between tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]">
                                <span class="tw-text-[#aaaaaa]" v-text="label"/>
                                <span class="tw-text-[#6B6D81]" v-text="value"/>
                            </li>
                        </ul>
                    </div>
                    <div class="tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[12px]">
                        <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Pickup address</strong>
                        <ul class="tw-list-none !tw-p-0 tw-m-0">
                            <li v-for="({ label, value }, index) in pickup" :key="index" class="tw-flex tw-justify-between tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]">
                                <span class="tw-text-[#aaaaaa]" v-text="label"/>
                                <span class="tw-text-[#6B6D81]" v-text="value"/>
                            </li>
                        </ul>
                    </div>
                    <div class="tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[12px]">
                        <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Note</strong>
                        <div class="tw-text-[#6B6D81] tw-font-medium tw-mb-[5px] tw-text-[14px]">{{ deliveryDetails.notes || 'There is no notes!' }}</div>
                    </div>
                    <div class="tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[12px]">
                        <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Destination address</strong>
                        <ul class="tw-list-none !tw-p-0 tw-m-0">
                            <li v-for="({ label, value }, index) in destination" :key="index" class="tw-flex tw-justify-between tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]">
                                <span class="tw-text-[#aaaaaa]" v-text="label"/>
                                <span class="tw-text-[#6B6D81]" v-text="value"/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <div class="tw-h-full tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[12px]">
                    <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Item info</strong>
<!--                        <ul class="tw-list-none !tw-p-[0_0_15px_0] last:!tw-pb-0 tw-mb-[15px] last:tw-mb-0 tw-boder-0 tw-border-solid tw-border-[#e4e9ff] tw-border-b-[1px] last:tw-border-b-0" v-for="items in itemInfo">-->
<!--                            <li v-for="(item, index) in items" :key="index" class="tw-flex tw-justify-between tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]">-->
<!--                                <span class="tw-text-[#aaaaaa]" v-text="item.label"/>-->
<!--                                <span class="tw-text-[#6B6D81]" v-text="item.value"/>-->
<!--                            </li>-->
<!--                        </ul>-->

                    <ul class="tw-list-none !tw-p-[0_0_15px_0] last:!tw-pb-0 tw-mb-[15px] last:tw-mb-0 tw-border-b-[1px] tw-border-solid tw-border-[#e4e9ff] last:tw-border-b-0">
                        <li class="tw-flex tw-justify-between tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]">
                            <span class="tw-text-[#aaaaaa]" v-text="'Items:'"/>
                            <span class="tw-text-[#6B6D81]" v-text="deliveryDetails.items_name"/>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Order from "@/plugins/types/order";

export default {
    props: {
        deliveryDetails: {
            type: Order,
            default: () => ({})
        }
    },

    data() {
        return {
            moverInfo: [
                { 'label': 'Full name:', 'value': this.deliveryDetails.customer.name },
                { 'label': 'Phone number:', 'value': this.deliveryDetails.customer.phone },
                // { 'label': 'Secondary phone:', 'value': this.deliveryDetails.user.contact_secondary_phone_number },
                // { 'label': 'Email:', 'value': this.deliveryDetails.user.email },
            ],
            pickup: [
                { 'label': 'St address:', 'value': this.deliveryDetails.pickup.address },
                { 'label': 'City or town:', 'value': this.deliveryDetails.pickup.city },
                { 'label': 'State:', 'value': this.deliveryDetails.pickup.state },
                { 'label': 'Zip code:', 'value': this.deliveryDetails.pickup.zip },
            ],
            destination: [
                { 'label': 'St address:', 'value': this.deliveryDetails.destination.address },
                { 'label': 'City or town:', 'value': this.deliveryDetails.destination.city },
                { 'label': 'State:', 'value': this.deliveryDetails.destination.state },
                { 'label': 'Zip code:', 'value': this.deliveryDetails.destination.zip },
            ],
            // itemInfo: this.getItemInfo(),
        }
    },

    methods: {
        // getItemInfo() {
        //     return this.deliveryDetails.items.map(item => [
        //         { 'label': 'Item name/number:', 'value': item.name },
        //         { 'label': 'Weight:', 'value': item.weight },
        //         { 'label': 'Hight:', 'value': item.height },
        //         { 'label': 'Width:', 'value': item.width },
        //     ]);
        // }
    },
}
</script>
