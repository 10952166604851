var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{ref:"menu",attrs:{"offset-y":"","close-on-content-click":false,"min-width":350,"max-width":350,"max-height":600,"nudge-bottom":15},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"notification-btn ml-1",attrs:{"icon":""}},'v-btn',attrs,false),on),[(_vm.hasUnreadNotifications)?_c('div',[_c('v-badge',{staticClass:"desktop--notifications custom--dashboard-header--v-badge notification--icon",attrs:{"content":_vm.notifications.length,"overlap":"","color":"primary"}},[_c('v-icon',{attrs:{"size":"22","color":"rgba(67, 89, 113, .7)"}},[_vm._v(" far fa-bell ")])],1),_c('div',{staticClass:"mobile--notifications"},[_c('span',[_vm._v(_vm._s(_vm.notifications.length))]),_c('v-icon',{attrs:{"size":"14","color":"rgba(67, 89, 113, .7)"}},[_vm._v(" far fa-bell ")])],1)],1):_c('v-icon',{attrs:{"size":"24","color":"#6B6D81"}},[_vm._v(" far fa-bell ")])],1)]}}])},[(!_vm.isClose)?_c('v-card',{staticClass:"notification-card"},[_c('v-card-actions',{staticClass:"d-flex justify-end py-0"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.menu.save()}}},[_c('img',{attrs:{"src":"/assets/icons/delete.svg","alt":"delete icon"}})])],1),_c('v-card-title',{staticClass:"notification-card__title pt-0 mb-2 text-secondary"},[_vm._v(" Notifications ")]),_c('v-card-subtitle',{staticClass:"notification-card__subtitle"},[_vm._v(" "+_vm._s(_vm.unreadNotificationsCount)+" unread notifications ")]),_c('v-card-text',{staticClass:"notification-card__text pl-5"},[_c('v-list',{staticClass:"notification-card__list"},_vm._l((_vm.notifications),function(ref){
var id = ref.id;
var ref_data = ref.data;
var title = ref_data.title;
var link = ref_data.link;
var date = ref.date;
var unread = ref.unread;
return _c('v-list-item',{key:id,staticClass:"notification-card__item",attrs:{"two-line":"","dense":"","link":"","color":"white darken-3"}},[_c('v-list-item-content',{staticClass:"notification-card__content",on:{"click":function($event){return _vm.readNotification(link)}}},[_c('v-list-item-title',[(unread)?_c('p',{staticClass:"mb-0 text-secondary"},[_vm._v(" "+_vm._s(_vm.getNotificationName(title))+" ")]):_vm._e()]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(date)}})],1),(unread)?_c('v-list-item-action',{staticClass:"notification-card__check"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.markAsRead({ id: id })}}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v(" fa-check ")])],1)],1):_vm._e()],1)}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }