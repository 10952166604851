import { BACKEND_URL } from '@/plugins/consts';

export default {

  actions: {

    async validateZipCode (_, zip_code) {
      return await axios.get('zip_code/' + zip_code, {
        baseURL: BACKEND_URL + '/validations/'
      })
    },
  }
}
