export default {
    state: {
        isOpen: false,
    },

    mutations: {
        switch (state) {
            state.isOpen = !state.isOpen;
        }
    }
}