<template>
    <div class="dashboard-card-action-buttons d-flex flex-wrap">
        <div v-if="order.isHelperBeingRescheduled">
            <v-btn
                class="card-complete card-complete__active"
                elevation="0"
                color="primary"
                @click="approveReschedulingAction"
            >
                Approve
            </v-btn>
            <v-btn
                elevation="0" color="light"
                @click="rejectReschedulingAction"
            >
                Reject
            </v-btn>

            <v-btn
                elevation="0" color="light"
                @click="rejectAfterAcceptingAction(order.id)"
            >
                Cancel
            </v-btn>
        </div>

        <div v-if="isNew">
            <v-btn
                elevation="0" color="light"
                @click="openRescheduleModal"
            >
                Reschedule
            </v-btn>

            <v-btn
                elevation="0"
                color="light"
                @click="rejectAction(order.id)"
            >
                Reject
            </v-btn>

            <v-btn
                class="card-complete"
                elevation="0"
                color="primary"
                @click="acceptAction(order.id)"
            >
                Accept
            </v-btn>
        </div>

        <div v-if="isAccepted && !order.helperCanComplete">
            <v-btn
                elevation="0" color="light"
                @click="openRescheduleModal"
            >
                Reschedule
            </v-btn>

            <v-btn
                :disabled="!order.helperCanComplete"
                elevation="0"
                color="light"
                @click="completeAction(order.id)"
            >
                Complete
            </v-btn>

            <v-btn
                class="card-complete"
                elevation="0"
                color="primary"
                @click="rejectAfterAcceptingAction(order.id)"
            >
                Cancel
            </v-btn>
        </div>

        <div v-if="order.helperCanComplete">
            <v-btn
                elevation="0" color="primary"
                @click="completeAction(order.id)"
            >
                Complete
            </v-btn>
        </div>

        <div>
            <v-btn
                v-if="order.helperCanArchive"
                elevation="0" color="primary"
                @click="removeAction(order.id)"
            >
                Remove
            </v-btn>

            <v-btn
                v-if="order.helperCanReview"
                elevation="0" color="primary"
                @click.prevent="openReviewModal"
            >
                Review
            </v-btn>
        </div>
    </div>
</template>

<script>
import { successConfirmAction, warningConfirmAction } from "@/plugins/swal";
import AppReschedule from "@/components/Modals/AppReschedule";
import { isAcceptedOrder, isNewOrder } from "@/plugins/order_methods";
import { mapActions } from "vuex";
import AppReview from "@/components/Modals/AppReview.vue";

export default {
    props: {
        order: {
            type: Object,
            required: true,
        },
    },

    methods: {
        ...mapActions('reschedule', {
            'approveRescheduling': 'approve',
            'rejectRescheduling': 'reject',
        }),

        completeAction (order_id) {
            successConfirmAction("Do you really want to complete this order?", 'Complete it', async () => {
                await this.$store.dispatch('dashboard/complete', order_id)
            });
        },

        rejectAfterAcceptingAction (order_id) {
            warningConfirmAction(
              'Do you really want to reject accepted order?',
              'Confirm',
              async (result) => {
                const inputValue = result.value;
                await this.$store.dispatch('dashboard/reject_after_accepting', {
                  order_id: order_id,
                  reason: inputValue
                })
              },
              'text',
              'Enter reason for cancellation...'
            );
        },

        rejectAction (order_id) {
            warningConfirmAction('Do you really want to reject this order?', 'Confirm', async () => {
                await this.$store.dispatch('dashboard/reject', order_id);
            });
        },

        acceptAction (order_id) {
            successConfirmAction('Do you really want to accept this order?', 'Accept it', async () => {
                await this.$store.dispatch('dashboard/accept', order_id);
            });
        },

        removeAction (order_id) {
            warningConfirmAction("Do you really want to remove this order?", 'Remove it', async () => {
                await this.$store.dispatch('dashboard/archive', order_id);
            })
        },

        approveReschedulingAction () {
            successConfirmAction("Do you really want to approve this rescheduling?", 'Approve it', async () => {
                await this.approveRescheduling({
                    order_id: this.order.id,
                    reschedule_request_id: this.order.reschedule_request.id,
                });

                await this.$store.dispatch('dashboard/load');
            });
        },

        rejectReschedulingAction () {
            warningConfirmAction("Do you really want to reject this rescheduling?", 'Reject it', async () => {
                await this.rejectRescheduling({
                    order_id: this.order.id,
                    reschedule_request_id: this.order.reschedule_request.id,
                });

                await this.$store.dispatch('dashboard/load');
            });
        },

        openRescheduleModal () {
            this.$modal.show(AppReschedule, {
                order: this.order
            }, {
                width: 1000,
                height: 700,
            });
        },

        openReviewModal () {
            this.$modal.show(AppReview, {
                order: this.order
            }, {
                width: 764,
                height: 700,
            });
        },
    },

    computed: {
        isNew () {
            return isNewOrder(this.order);
        },

        isAccepted () {
            return isAcceptedOrder(this.order);
        }
    }
}
</script>
<style lang="scss" scoped>
.dashboard-card-action-buttons div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
}

::v-deep {
    .v-btn {
        font-size: 16px;
        font-weight: 600;

        //extra small devices
        @media (max-width: 375px) {
            width: 100%;
            &:nth-child(1), &:nth-child(2) {
                margin-bottom: 10px;
            }
        }

        //small devices
        @media (max-width: 420px) and (min-width: 376px) {
            &:nth-child(1), &:nth-child(2) {
                min-width: 140px;
                max-width: 170px;
                width: 48%;
            }

            &:nth-child(2) {
                margin-left: auto;
            }

            &:nth-child(3) {
                width: 100%;
                margin-top: 10px;
            }
        }
        //other devices

        @media (min-width: 421px) {
            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
}
</style>

