<template>
    <div class="mb-5">
        <div class="__heading d-flex">
            <h2>Cargo Insurance</h2>
            <v-tooltip top content-class="charge-text__tooltip">
                <template v-slot:activator="{ on, attrs }">
                    <img v-bind="attrs" v-on="on" src="assets/icons/info.svg" alt="info icon" width="12"
                         class="ml-1">
                </template>
                <span class="custom--v-tooltip">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aut delectus, dignissimos ex id quod?
                </span>
            </v-tooltip>
        </div>
        <v-row>
            <v-col xl="3" lg="3" md="6" sm="12" cols="12">
                <h4>Policy number</h4>

                <v-text-field
                    v-model="cargo_insurance_license_data.policy_number"
                    dense
                    outlined
                    placeholder="Write a number"
                    class="custom--v-text-field"
                    :error-messages="policyNumberErrors"
                    @input="$v.cargo_insurance_license_data.policy_number.$touch()"
                    @blur="$v.cargo_insurance_license_data.policy_number.$touch()"
                >
                </v-text-field>
            </v-col>
            <v-col xl="3" lg="3" md="6" sm="12" cols="12">
                <h4>Policy issuer</h4>

                <v-text-field
                    v-model="cargo_insurance_license_data.policy_issuer"
                    dense
                    outlined
                    placeholder="Write a issue"
                    class="custom--v-text-field"
                    :error-messages="policyIssueErrors"
                    @input="$v.cargo_insurance_license_data.policy_issuer.$touch()"
                    @blur="$v.cargo_insurance_license_data.policy_issuer.$touch()"
                >
                </v-text-field>
            </v-col>
            <v-col xl="3" lg="3" md="6" sm="12" cols="12">
                <h4>Expiration date</h4>

                <date-picker
                 :is-clicked-submit="isSubmit"
                 :expiration_date="cargo_insurance_license_data.expiration_date"
                 @onChange="(value) => {this.cargo_insurance_license_data.expiration_date = value}"
                />
            </v-col>
            <v-col xl="3" lg="3" md="6" sm="12" cols="12" class="d-flex align-center">
                <v-btn
                    depressed
                    color="secondary"
                    class="custom--v-btn" min-width="80"
                    @click="submit(cargo_insurance_license_data)"
                >
                    Save
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import DatePicker from "../../components/DatePicker.vue";
export default {
    name: "CredentialCargoInsurance",

    components: { DatePicker },

    mixins: [validationMixin],

    props: {
      branchId: {
          type:Number,
          required: true
      }
    },

    validations: {
        cargo_insurance_license_data: {
            policy_number: {required},
            policy_issuer: {required},
            expiration_date: {required}
        },
    },

    data () {
        return {
            cargo_insurance_license_data: {
                policy_number: '',
                policy_issuer: '',
                expiration_date: ''
            },
            isSubmit: false
        }
    },

    computed: {
        ...mapGetters('credentials', ['cargo_insurance_license']),

        policyNumberErrors () {
            const errors = [];
            if (! this.$v.cargo_insurance_license_data.policy_number.$dirty) return errors;
            ! this.$v.cargo_insurance_license_data.policy_number.required && errors.push('Field is required');
            return errors;
        },

        policyIssueErrors () {
            const errors = [];
            if (! this.$v.cargo_insurance_license_data.policy_issuer.$dirty) return errors;
            ! this.$v.cargo_insurance_license_data.policy_issuer.required && errors.push('Field is required');
            return errors;
        }
    },

    methods: {
        ...mapActions('credentials', ['update']),

        /**
         *
         * @param data
         * @returns {boolean}
         */

        submit (data) {
            this.$v.$touch();
            this.isSubmit = true;

            //if invalid return false
            if (this.$v.$invalid) {
                return false;
            }

           this.update({
               data_name: 'cargo_insurance_license',
               data
           })
        }
    },

    created() {
        if (this.cargo_insurance_license) {
            this.cargo_insurance_license_data = this.cargo_insurance_license
        }
    },
}
</script>