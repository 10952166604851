<template>
    <div class="invite-email">
        <div class="invite-email__main d-flex">
            <v-combobox
                v-model="emails"
                :search-input.sync="search"
                hide-selected
                multiple
                outlined
                persistent-hint
                chips deletable-chips
                append-icon=""
                placeholder="Add Email"
                class="custom--v-combobox v-input--is-focused mr-sm-6"
            >
                <template v-slot:no-data>
                    <v-list-item v-if="canShowHelper">
                        <v-list-item-content>
                            <v-list-item-title>
                                Press <kbd>enter</kbd> to add "<strong>{{ search }}</strong>";
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-combobox>
            <span class="invite-email__txt hidden-sm-and-up mb-3">Separate emails with commas</span>

            <v-btn depressed min-height="50" class="custom--v-btn v-btn v-size--medium" color="primary" @click="addEmails">Send</v-btn>
        </div>
        <span class="invite-email__txt invite-email__txt--desktop">Separate emails with commas</span>
    </div>
</template>

<script>
import { validate } from "email-validator";
import { mapActions } from "vuex";
import loading from "../../../mixins/loading.js";

export default {
    name: "InviteFriendsEmailsForm",

    mixins: [ loading ],

    data() {
        return {
            emails: [],
            search: '',
        }
    },

    computed: {
        canShowHelper() {
            return validate(this.search);
        },
    },

    methods: {
        ...mapActions('referrals', [ 'index', 'store' ]),

        addEmails() {
            this.startLoading(async () => {
                await this.store(this.emails);
                this.emails = [];
            });
        },
    },

    watch: {
        emails() {
            // if last added email is not valid email, delete it
            if (this.emails.length > 0 && !validate(this.emails.at(-1))) {
                this.emails.pop();
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/vuetify-components/v-combobox";

@media (max-width: 599px) {
    .invite-email {
        position: relative;
    }
    .invite-email__main {
        flex-direction: column;

        .v-input--is-focused {
            width: 100%;
        }
        .custom--v-btn::v-deep {
            width: 100% !important;
        }
    }
    .invite-email__txt {
        margin-top: 0;
    }
    .invite-email__txt--desktop {
        display: none;
    }
    .custom--v-combobox::v-deep {
        .v-text-field__details {
            display: none;
        }
    }
}
</style>
