<script>
import DeleteAccount from '@/components/Modals/DeleteAccount.vue';
import { Toast } from '../../../plugins/swal';
import { EventBus } from '../../../main';
export default {
  data() {
    return {
      movingCompanyNotifications: false,
      showDeleteModal: false,
    }
  },

  methods: {
    openDeleteModal() {
      this.$modal.show(DeleteAccount, {}, {
            width: 450,
            height: 'auto',
        }, {
          'before-open': this.$scrollLock.enable,
          'before-close': this.$scrollLock.disable,
        });
        EventBus.$on('deleteAccount', () => {
          Toast.fire({
            icon: "success",
            title: "Account deleted successfully",
          });
        });
    }
  }
}
</script>

<template>
  <v-card class="tw-mx-[30px] tw-my-[18px] tw-p-8 tw-flex tw-flex-col tw-justify-between">
    <div class="tw-h-[400px] lg:tw-h-[500px]">
      <h2 class="tw-text-[16px] tw-font-bold tw-text-secondary tw-mb-[16px]">Danger zone</h2>
  
      <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap tw-mb-8 tw-w-full md:tw-w-[70%]">
        <!-- Notifications card -->
        <div class="lg:tw-w-11/12 tw-w-full tw-border-2 tw-rounded-[8px] tw-border-[#ddd] tw-mb-4">
          <div class="tw-p-1 tw-pl-6 tw-bg-[#F6F8FF] tw-rounded-tl-[8px] tw-rounded-tr-[8px] tw-font-semibold tw-text-secondary text-[16px]">
            Delete account
          </div>
          <div class="tw-p-1 tw-pl-6 tw-py-2 tw-flex tw-items-center tw-justify-between">
            <span class="tw-text-primary">This permanently deletes your account, and everything related to it.</span>
            <span 
              @click="openDeleteModal"
              class="fas fa-trash-alt tw-text-primary tw-mr-3 tw-cursor-pointer hover:tw-text-secondary tw-duration-100"
            >
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="tw-flex tw-justify-end tw-mb-0">
      <v-btn 
        color="#8F3985"
        class="!tw-text-[#fff] tw-w-[177px]"
      >
        Save
      </v-btn>
    </div>
  </v-card>
</template>
