<template>
    <v-main class="custom--v-main">
        <app-header>
            <h3 class="text-xl font-semibold text-secondary mb-4">
                Locations ({{ branches.length }})
            </h3>
        </app-header>
        <section class="location-modal--mobile">
            <v-divider class="mb-7"/>
            <div class="d-flex align-center justify-center flex-column flex-wrap">
                <div class="location-modal--mobile__search w-100 d-flex align-center">
                    <input v-model="searchingInput" type="text" name="locationSearch"
                           placeholder="Search locations">
                    <v-icon size="20" color="#aaa" class="d-inline-block ms-auto">
                        fa-search
                    </v-icon>
                </div>
                <div class="location-modal--mobile__locations d-flex align-center flex-column mr-sm-auto mx-auto">
                    <div class="w-100 d-flex align-center mr-auto"
                         v-for="{id, state_name, zip_code, address, city_name,  name, slug} in getBranch(searchingInput)"
                         :key="id">
                        <div class="location-modal--mobile__block d-flex align-center"
                             :class="{'location--active': isActive(id)}">
                            <div class="modal-locations__column" @click="changeBranch(id)">
                                <h5 :class="{'d-none': isActive(id)}">{{ name }}</h5>
                                <span v-if="isActive(id)">{{ address }}</span>
                                <span class="d-block">{{ state_name + ' ' + city_name + ', ' + zip_code }}</span>
                            </div>
                            <a :href="`${frontend_url()}/companies/${slug}`" target="_blank"
                               class="modal-locations__column ml-auto"
                               v-if="!isActive(id)">
                                <v-icon size="15" color="#6B6D81">
                                    icon-logout
                                </v-icon>
                            </a>
                        </div>
                    </div>
                    <div v-if="searchingInput.length > 0 && getBranch(searchingInput).length === 0"
                         class="branch-not__found">
                        <p>Branch not found</p>
                    </div>
                </div>
            </div>
            <v-divider  class="mb-5"/>
            <div class="d-flex justify-center">
                <v-btn depressed color="primary" class="v-btn v-size--medium mx-auto"
                       @click.prevent="passLocationAndCloseMenu">
                    <span>Add a location</span>
                </v-btn>
            </div>
        </section>
    </v-main>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import loading from "@/mixins/loading.js";
import { FRONTEND_URL } from "@/plugins/consts.js";

export default {
    name: "ChooseBranch",

    mixins: [loading],

    data () {
        return {
            searchingInput: ''
        }
    },

    computed: {
        ...mapState('locations', ['branches']),
        ...mapState('auth', ['branches'])
    },

    methods: {
        ...mapMutations('locations', ['SET_LOCATION_MENU']),
        ...mapMutations('auth', ['SET_BRANCH']),
        ...mapActions('locations', ['load']),

        passLocationAndCloseMenu () {
            this.SET_LOCATION_MENU();

            this.$router.push({
                name: 'locations'
            })
        },

        isActive (id) {
            return this.$store.state.auth.activeBranch.id === id;
        },

        changeBranch (branch_id) {
            this.startLoading(async () => await this.SET_BRANCH(branch_id));
            this.SET_LOCATION_MENU();
        },

        frontend_url () {
            return FRONTEND_URL;
        },

        getBranch (branchName) {
            if (this.searchingInput.length > 0) {
                return this.branches.filter(branch => branch.name.toLocaleLowerCase().includes(branchName.toLocaleLowerCase()));
            } else {
                return this.branches;
            }
        },
    },

    created () {
        this.load();
    }
}
</script>

<style lang="scss">
@import "src/assets/scss/config/variables";


.location-modal--mobile {
    max-width: 96%;
    margin: 0 auto;

    &__search {
        min-width: 320px;
        padding: 16px 26px 17px 26px;
        margin: 0 0 15px auto;
        background: #fff;
        border-radius: 25px;
        height: 49px;

        @media (max-width: 400px) {
            margin: 0 auto 15px;
        }

        input[type="text"] {
            outline: none;
            background: none;
            border: none;
            width: 77%;
            height: 100%;
            font-family: 'Mulish', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.10000000149011612px;
            color: $secondary;
        }
    }

    &__locations {
        min-width: 330px;
        max-height: 350px;
        overflow: auto;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;

        &--active {
            background: #FEECFC;
        }

        & > div {
            border-radius: 5px;
        }
    }

    &__block {
        min-width: 320px;
        width: 100%;
        margin: 0 auto 10px 0;
        border-radius: 8px;
        background: #fff;
        padding: 8px 25px 5px 25px;

        &:hover {
            cursor: pointer;
        }
    }
}

.location--active {
    min-height: 57px;
    background: #FEECFC;

    span {
        font-weight: 600 !important;
        color: $primary !important;
        word-break: break-word;
    }
}
</style>