<template>
  <v-main class="custom--v-main">
    <app-header>
      <v-tabs v-model="tab">
        <v-tab
          v-for="item in items"
          :key="item"
          class="tw-m-[0_32px_0_0] !tw-p-0 !tw-min-w-[auto] !tw-max-w-[auto] tw-normal-case tw-font-semibold !tw-text-[16px] !tw-text-[#6B6D81] [&.v-tab--active]:!tw-text-[#0D3659]"
        >
          {{ item }}
        </v-tab>
        <v-tabs-slider color="#8F3985"></v-tabs-slider>
      </v-tabs>

      <template #right>
        <v-btn depressed color="primary" class="v-btn v-size--medium mr-1" @click="openBranchModal">
          <span class="mr-auto">{{ branchName.three_dots(12) }}</span>
          <v-icon size="8" class="ml-8">
            {{ !isClickedBranch ? 'fa-chevron-right' : 'fa-chevron-down' }}
          </v-icon>
        </v-btn>

        <app-location-selector
          v-if="isClickedBranch"
          @closeBranch="(isClose) => {isClickedBranch = isClose}"
          @getBranchId="(id, name) => {branch = id; branchName = name}"
          class="v-size--medium mr-5"
        />
      </template>
    </app-header>

    <div class="tw-p-[25px_30px]">
      <v-tabs-items v-model="tab" class="!tw-bg-[transparent]">
        <v-tab-item>
          <orders-movings-overview :branchId="branch"/>
          <orders-movings-list :branchId="branch"/>
        </v-tab-item>
        <v-tab-item>
          <orders-deliveries-overview :branchId="branch"/>
          <orders-deliveries-list :branchId="branch"/>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-main>
</template>

<script>
import AppLocationSelector from '@/components/AppLocationSelector';
import OrdersDeliveriesOverview from '@/views/Orders/OrdersDeliveriesOverview.vue';
import OrdersMovingsList from '@/views/Orders/OrdersMovingsList.vue';
import { DASHBOARD_URL } from '@/plugins/consts';
import OrdersMovingsOverview from '@/views/Orders/OrdersMovingsOverview.vue';
import OrdersDeliveriesList from '@/views/Orders/OrdersDeliveriesList.vue';

export default {
  head () {
    return {
      link: [
        {
          rel: 'canonical',
          href: DASHBOARD_URL + '/orders',
        },
      ],
    };
  },

  metaInfo: { title: 'Top Helpers | Orders | Moving Services' },

  components: {
    OrdersDeliveriesList,
    OrdersMovingsOverview,
    OrdersDeliveriesOverview,
    OrdersMovingsList,
    AppLocationSelector
  },

  data () {
    return {
      isClickedBranch: false,
      branch: 0,
      branchName: 'All Branches',
      isLoadingOrdersSection: false,

      tab: null,
      items: [
        'Moving', 'Delivery',
      ],
    };
  },

  methods: {
    openBranchModal () {
      this.isClickedBranch = !this.isClickedBranch;
    },
  }
};
</script>
