<template>
    <section class="introduction-faq mb-7">
        <h1 class="introduction__header text-secondary">Frequently Asked Questions</h1>

        <v-expansion-panels accordion flat class="custom--v-expansions-panel">
            <v-expansion-panel
                class="custom--v-expansion-panel"
                active-class="expansion--active"
                v-for="{id, question, answer } in faqs" :key="id"
            >
                <v-expansion-panel-header>
                    <span>{{ question }}</span>
                    <template v-slot:actions>
                        <img src="assets/images/profile/faq-plus.svg" alt="plus icon"/>
                        <img src="assets/images/profile/faq-minus.svg" alt="minus icon"/>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div>
                        <v-textarea
                            v-model="answer"
                            dense
                            outlined
                            background-color="#F5F5F9"
                            class="custom--v-textarea"
                            auto-grow
                            autofocus
                            rows="1"
                        ></v-textarea>
                        <div class="d-flex justify-end mt-4">
                            <v-btn
                                small
                                depressed
                                color="primary"
                                min-width="78" max-height="26"
                                class="mr-2.5 custom--v-btn"
                                @click="submit(id, answer)"
                            >
                                Save
                            </v-btn>
                            <v-btn small depressed
                                color="secondary" text
                                min-width="78" max-height="26"
                                class="custom--v-btn"
                                @click="edit"
                            >
                                Edit
                            </v-btn>
                        </div>
                    </div>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { filter } from "lodash";

export default {
    name: "DashboardFaq",

    mixins: [validationMixin],

    validations: {
        answer: {
            required,
            minLength: minLength(10)
        }
    },

    data () {
        return {
            isDisable: true,
            is_faq_disable: true
        }
    },

    computed: {
        ...mapGetters('faq', ['faqs']),

        answerErrors () {
            const errors = [];
            if (! this.$v.answer.$dirty) return errors;
            ! this.$v.answer.required && errors.push('About field is required');
            ! this.$v.answer && errors.push('About text should contain at least 10 characters');

            return errors;
        }
    },

    methods: {
        ...mapActions('faq', ['load', 'save', 'update']),

        /**
         *
         * @param faq_id
         * @param answer
         * @returns {boolean}
         */
        submit (faq_id, answer) {
            const active_faq = filter(this.faqs, { id: faq_id })[0];

            if (active_faq.answer) {
                this.update({ faq_id, answer });
                return true;
            }

            this.save({ faq_id, answer });
        },

        edit () {
            this.is_faq_disable = false;
        }
    },

    created () {
        this.load();
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/pages/profile/introduction";

.custom--v-expansions-panel::v-deep {
    .v-expansion-panel-header {
        span {
            max-width: calc(100% - 30px);
            white-space: wrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 15px;
            font-weight: 600;
        }
    }
}
</style>