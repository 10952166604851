<template>
  <div class="invite-link__social">
    <h3 class="tw-text-[20px] tw-font-semibold tw-text-[#0D3659] tw-mb-[14px]">Share your personal referral code</h3>

    <div class="tw-flex tw-flex-wrap max-sm:tw-flex-col tw-gap-[12px]">
        <InviteFriendsShareSocialCard
            v-for="(sharingLink, index) in sharingLinks"
            :sharing-link="sharingLink"
            :key="index"
        />
    </div>
  </div>
</template>

<script>
import { FRONTEND_URL } from "../../../plugins/consts.js";
import InviteFriendsShareSocialCard from "./InviteFriendsShareSocialCard.vue";

export default {
    name: "InviteFriendsShareSocialLinks",
    components: { InviteFriendsShareSocialCard },

    props: {
        referral: {
            code: {
                type: String,
                required: true
            },
            moverLink: {
                type: String,
                required: true
            },
            clientLink: {
                type: String,
                required: true
            }
        }
    },

    data () {
        return {
            link: FRONTEND_URL + `/referral?referral_code=`,
            sharingLinks: [
                {
                    network: 'facebook',
                    url: this.link + this.referral.code,
                    className: 'media--facebook !tw-m-0',
                    title: this.getLink(this.referral.code)
                },
                {
                    network: 'twitter',
                    url: this.link + this.referral.code,
                    className: 'media--twitter !tw-m-0',
                    title: this.getLink(this.referral.code)
                },
                {
                    network: 'linkedin',
                    url: this.link + this.referral.code,
                    className: 'media--linkedin !tw-m-0',
                    title: this.getLink(this.referral.code)
                },
            ]
        }
    },
    methods: {
        getLink(referralCode) {
            return ` If you are mover , you can use ${this.referral.moverLink + referralCode} \n If you are client, you can use ${this.referral.clientLink + referralCode}`
        }
    }
}
</script>
