<template>
    <div>
        <div class="desktop-profile-menu">
            <div v-if="isCustomer" class="customer__profile-bar">
                <v-avatar
                    color="primary"
                    size="40"
                    class="mr-4"
                >
                    <img
                        :src="logo"
                        :alt="name"
                    >
                </v-avatar>

                <span class="normal-case font-semibold w-1/2 whitespace-normal text-left">
                    {{ branchName }}
                </span>
            </div>
            <div v-else-if="isLabor" class="customer__profile-bar">
                <v-avatar
                    color="primary"
                    size="40"
                    class="mr-4"
                >
                    <img
                        :src="user.image"
                        :alt="user.first_name"
                    >
                </v-avatar>

                <span class="normal-case font-semibold w-1/2 whitespace-normal text-left">
                    {{ user.first_name }}
                </span>
            </div>

            <a v-else :href="frontend_url" class="d-flex align-center mx-auto">
                <img
                    src="/assets/images/logos/logo-purple.svg"
                    alt="Tophelpers Logo"
                    title="Tophelpers logo"
                >
            </a>
        </div>
        <div v-if="!isCustomer && !isLabor" class="desktop-profile-info position-relative">
            <div class="profile-info__column">
                <v-tooltip v-if="branchFullHeaderName.length > 10" bottom content-class="charge-text__tooltip">
                    <template v-slot:activator="{ on, attrs }" >
                        <h3 v-bind="attrs"
                            v-on="on" class="profile-info__header  mb-2.5  font-bold text-secondary">
                            {{ branchHeaderName }}
                        </h3>
                    </template>
                    <span>{{ branchFullHeaderName }}</span>
                </v-tooltip>
                <h3 v-else class="profile-info__header  mb-2.5  font-bold text-secondary">{{ branchFullHeaderName }}</h3>
                <p class="profile-info__state">
                    {{ branchFullAddress }}
                </p>
                <div @click="SET_LOCATION_MENU" class="profile-info__location d-flex align-center">
                    See all locations ({{ branches.length }})
                    <v-icon color="primary" size="8" class="ml-2">
                        fa-chevron-right
                    </v-icon>
                </div>
                <app-location :isOpen="isOpenLocationMenu"/>
            </div>
            <div class="profile-info__column align-self-start">
                <router-link 
                    :to="{
                        name: 'view-company',
                        params: {
                            slug: this.activeSlug.name
                        }
                    }" 
                    class="d-inline-block"
                >
                    <v-icon color="primary" size="15">
                        icon-logout
                    </v-icon>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import AppLocation from "@/components/Modals/AppLocation.vue";
import loading from "../../mixins/loading";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { FRONTEND_URL } from "@/plugins/consts.js";

export default {
    components: { AppLocation },

    props: {
        items: {
            type: Array,
            default: null,
        }
    },

    mixins: [loading],

    data () {
        return {
            locations: this.$store.state.locations.branches,
            address: this.$store.state.auth.activeBranch.full_address,
        }
    },

    computed: {
        ...mapState('locations', ['isOpenLocationMenu']),
        ...mapGetters('auth', ['name', 'logo', 'isCustomer', 'isLabor', 'activeBranch', 'branches', 'user']),

        branchName () {
            return this.name.three_dots(10);
        },

        branchHeaderName () {
            return this.activeBranch.name.three_dots(8);
        },

        branchFullHeaderName () {
            return this.activeBranch.name;
        },

        branchFullAddress () {
            const fullAddress = this.activeBranch.full_address ?? this.activeBranch.address;
            return fullAddress.three_dots(59);
        },

        frontend_url () {
            return FRONTEND_URL;
        },

        activeSlug () {
            return this.$store.state.auth.activeBranch;
        }
    },

    methods: {
        ...mapActions('auth', ['logout']),
        ...mapMutations('locations', ['SET_LOCATION_MENU']),
    },
}
</script>

<style scoped lang="scss">

.charge-text__tooltip {
    max-width: 250px;
    word-break: break-all;
}

.agent__profile-bar {

    & > span {
        width: 104px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.customer__profile-bar {
    display: flex;
    align-items: center;
    padding-left: 30px;
    background-color: #FFF;

    img {
        width: 40px;
        height: 40px;
    }

    & > span {
        font-family: $body-font-family;
        font-size: 22px;
        font-weight: 700;
        line-height: 23px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #0D3659;
    }
}

</style>