<template>
    <v-menu offset-y>
        <template #activator="{on, attrs}">
            <v-btn depressed color="light" class="custom&#45;&#45;v-btn" v-bind="attrs" v-on="on">
                <span class="text-secondary">
                    Sort by
                </span>

                <v-icon size="13" class="mt-0.5 ml-3">
                    fa-chevron-down
                </v-icon>
            </v-btn>
        </template>

        <v-list dense>
            <v-list-item v-for="filter of filters" :key="filter" link>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ filter }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    data () {
        return {
            filters: [
                'Name', 'Zip Code',
                'Area radius',
            ],
        };
    },
};
</script>