export default {
    namespaced: true,

    state: {
        /** @type Order[] */
        active: [],

        /** @type Order[] */
        archive: [],

        delivery: [],

        archiveDelivery: [],

        orderDetails: null,
    },

    getters: {
        activeDeliveriesCount: (state) => state.active.length + state.delivery.length,
        archivedDeliveriesCount: (state) => state.archive.length + state.archiveDelivery.length,

        getArchiveDelivery: (state) => {
            return state.archiveDelivery.map(item => ({
                ...item,
                isArchived: true,
            }));
        },

        detailOrderFormat(state) {
            const { customer, pickup, destination, items, images, vehicle, notes } = state.orderDetails || {};
            return {
                moverInfo: [
                    { 'label': 'Full name:', 'value': customer?.full_name || '' },
                    { 'label': 'Phone number:', 'value': customer?.phone || '' },
                    { 'label': 'Secondary phone:', 'value': customer?.secondary_phone || '' },
                    { 'label': 'Email:', 'value': customer?.email || '' },
                ],
                pickup: pickup ? [
                    { 'label': 'St address:', 'value': pickup.address },
                    { 'label': 'City or town:', 'value': pickup.city },
                    { 'label': 'State:', 'value': pickup.state },
                    { 'label': 'Zip code:', 'value': pickup.zip },
                    { 'label': 'Stairs:', 'value': pickup.stairs?.display || '' },
                ] : [],
                destination: destination ? [
                    { 'label': 'St address:', 'value': destination.address },
                    { 'label': 'City or town:', 'value': destination.city },
                    { 'label': 'State:', 'value': destination.state },
                    { 'label': 'Zip code:', 'value': destination.zip },
                    { 'label': 'Stairs:', 'value': destination.stairs?.display || '' },
                ] : [],
                items: items || [],
                images: images || [],
                otherInfo: vehicle ? [{ 'label': 'Vehicle type:', 'value': vehicle }] : [],
                notes: notes || ''
            };
        }
    },

    mutations: {
        SET_ORDER_DETAILS(state, payload) {
            state.orderDetails = payload
        }
    },

    actions: {
        /**
         * Index customer active and archive orders for a week
         *
         * @param {Function} commit
         * @returns {Promise<void>}
         */
        async index({ state, dispatch }) {
            const { delivery, orders } = await axios.get('customer/orders');

            state.active = orders.active ?? [];
            state.archive = orders.archive ?? [];
            state.delivery = delivery ?? [];

            await dispatch('indexDelivery');
        },

        async indexDelivery({ state }) {
            const archivedDeliveries = await axios.get('customer/deliveries/archive');

            state.archiveDelivery = archivedDeliveries ?? [];
        },

        /**
         * Show single customer order
         *
         * @param {Object} _
         * @param {Number} orderId
         */
        async show(_, orderId) {
            return await axios.get(`customer/orders/${ orderId }`);
        },

        /**
         * Complete an order
         *
         * @param {Function} dispatch
         * @param {Number} order_id
         * @returns {Promise<void>}
         */
        async complete({ dispatch }, order_id) {
            await axios.post(`customer/orders/${ order_id }/complete`);

            await dispatch('index');
        },

        /**
         * Cancel an order
         *
         * @param {Function} dispatch
         * @param {Number} order_id
         * @returns {Promise<void>}
         */
        async cancel({ dispatch }, payload) {
            const { order_id, reason } = payload;
            await axios.get(`customer/orders/${ order_id }?status=cancel`, {
                params: {
                    cancel_reason: reason
                }
            });

            await dispatch('index');
        },

        // Cancel delivery as customer
        async cancelDelivery({ dispatch }, payload) {
            const { order_id, reason } = payload;
            await axios.post(`customer/deliveries/${ order_id }/cancel`, {
                reason: reason
            });
            await dispatch('index');
        },

        async completeDelivery({ dispatch }, order_id) {
            await axios.post(`customer/deliveries/${ order_id }/complete`);
            await dispatch('index');
        },

        async getOrderDetails({ commit }, order_id) {
            const data = await axios.get(`customer/deliveries/${ order_id }`);
            commit('SET_ORDER_DETAILS', data)
        },

        /**
         * Archive an order
         *
         * @param dispatch
         * @param order_id
         * @returns {Promise<void>}
         */
        async archive({ dispatch }, order_id) {
            await axios.get(`customer/orders/${ order_id }?status=archive`);

            await dispatch('index');
        },

        async archiveDelivery({ dispatch }, order_id) {
            await axios.post(`customer/deliveries/${ order_id }/archive`);

            await dispatch('index');
        },

        async set_review_customer({ dispatch }, { order_id, review }) {
            await axios.post(`customer/orders/${ order_id }/reviews`, review);

            await dispatch('index');
        },

        async set_review_mover({ dispatch }, { order_id, review }) {
            await axios.post(`/orders/${ order_id }/reviews`, review);

            await dispatch('index');
        }

    },
}
