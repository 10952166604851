<template>
    <v-app class="tw-p-[20px_40px_40px_40px]">
        <div class="tw-flex tw-items-center tw-justify-between tw-mb-[20px]">
            <span class="tw-text-[18px] tw-text-[#0D3659] tw-font-semibold" v-text="isCreating ? 'Add New Branch' : 'Edit Branch'" />
            <v-btn icon @click="$emit('close');">
                <img src="assets/icons/delete.svg" alt="x-icon"/>
            </v-btn>
        </div>

        <div class="tw-grid tw-grid-cols-5 tw-gap-[40px]">
            <div class="tw-col-span-5 md:tw-col-span-3">
                <div class="tw-grid tw-grid-cols-6 tw-gap-[25px]">
                    <div class="tw-col-span-6">
                        <div class="tw-text-[14px] tw-text-[#0D3659] tw-mb-[5px] tw-font-medium">
                            Name of the location
                        </div>
                        <v-text-field
                            single-line
                            hide-details
                            outlined
                            clearable
                            clear-icon="fa-times"
                            class="custom--v-text-field"
                            v-model="branch.name"
                        />
                        <span style="color: red;" v-if="branch.name && $v.branch.name.$invalid"
                              v-text="'Max length is 60 character'"/>
                    </div>
                    <div class="tw-col-span-6">
                        <div class="tw-text-[14px] tw-text-[#0D3659] tw-mb-[5px] tw-font-medium">
                            Address
                        </div>
                        <v-text-field
                            single-line
                            hide-details
                            outlined
                            clearable
                            clear-icon="fa-times"
                            class="custom--v-text-field"
                            v-model="branch.address"
                        />
                        <span style="color: red;" v-if="branch.address && $v.branch.address.$invalid"
                              v-text="'Max length is 60 character'"/>
                    </div>
                    <div class="tw-col-span-6 sm:tw-col-span-2">
                        <div class="tw-text-[14px] tw-text-[#0D3659] tw-mb-[5px] tw-font-medium">
                            State
                        </div>
                        <app-state-select
                            v-model="branch.state_id"
                        />
                    </div>
                    <div class="tw-col-span-6 sm:tw-col-span-2">
                        <div class="tw-text-[14px] tw-text-[#0D3659] tw-mb-[5px] tw-font-medium">
                            Zip code
                        </div>

                        <v-text-field
                            type="number"
                            single-line
                            hide-details
                            outlined
                            class="custom--v-text-field"
                            v-model="$v.branch.zip_code.$model"
                            v-maska="zip_mask"
                        />
                    </div>
                    <div class="tw-col-span-6 sm:tw-col-span-2">
                        <div class="tw-text-[14px] tw-text-[#0D3659] tw-mb-[5px] tw-font-medium">
                            City or Town
                        </div>
                        <app-city-select
                            :state-id="branch.state_id"
                            v-model="branch.city_id"
                        />
                    </div>
                    <div class="tw-col-span-6 sm:tw-col-span-3">
                        <div class="tw-text-[14px] tw-text-[#0D3659] tw-mb-[5px] tw-font-medium">
                            <div class="tw-flex tw-gap-[10px]">
                                <span>County (Serving area)</span>
                                <v-tooltip top content-class="charge-text__tooltip">
                                    <template v-slot:activator="{ on, attrs }">
                                        <img v-bind="attrs" v-on="on" src="assets/icons/info.svg" alt="info icon" width="12" class="ml-1">
                                    </template>
                                    <span class="custom--v-tooltip">
                                        Choosing your serving area allows you receive item-delivery biddings that's closer to you.
                                    </span>
                                </v-tooltip>
                            </div>
                        </div>
                        <app-county-select
                            v-model="branch.counties"
                        />
                    </div>
                    <div class="tw-col-span-6 sm:tw-col-span-3">
                        <div class="tw-text-[14px] tw-text-[#0D3659] tw-mb-[5px] tw-font-medium">
                            Phone number
                        </div>
                        <v-text-field
                            v-model="branch.primary_phone"
                            single-line
                            hide-details
                            outlined
                            type="tel"
                            background-color="white"
                            class="custom--v-text-field"
                            v-maska="'+1 (###) - ### - ####'"
                        />
                    </div>
                    <div class="tw-col-span-6">
                        <div class="tw-grid tw-grid-cols-3 tw-gap-[25px] tw-items-end">
                            <div class="tw-col-span-2 -tw-mb-[5px]">
                                <div class="tw-text-[14px] tw-text-[#0D3659] tw-mb-[5px] tw-font-medium">
                                    Service area radius
                                </div>
                                <v-slider
                                    v-model="branch.service_area"
                                    thumb-label
                                    max="100"
                                    hide-details
                                    class="custom--v-slider"
                                ></v-slider>
                            </div>
                            <div>
                                <v-btn
                                    depressed color="primary" block  class="custom--v-btn"
                                    @click="isCreating ? createBranch() : updateBranch()"
                                    :loading="isLoading"
                                >
                                    {{ isCreating ? 'Create' : 'Save' }}
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-col-span-5 md:tw-col-span-2">
                <locations-item-map
                      :latitude="branch.latitude"
                      :longitude="branch.longitude"
                      :radius="branch.service_area"
                      :editable="true"
                      :update-lat-lng="updateLatLng"
                      class="max-sm:!tw-h-[250px]"
                />
            </div>
        </div>
    </v-app>
</template>

<script>
import LocationsItemMap from "./LocationsItemMap";
import AppCountySelect from "../../components/AppCountySelect";
import AppStateSelect from "../../components/AppStateSelect";
import AppCitySelect from "../../components/AppCitySelect";
import { mapActions } from "vuex";
import { zip_mask } from "@/plugins/custom_masks";
import { maska } from "maska";
import { mapKeys, pick } from "lodash";
import loading from "@/mixins/loading.js";
import { validationMixin } from 'vuelidate';
import { maxLength, minLength, required } from "vuelidate/lib/validators";

export default {
    props: {
        load: Function,
        isCreating: {
            type: Boolean,
            default: false,
        }
    },

    mixins: [loading, validationMixin],

    components: {
        LocationsItemMap,
        AppCountySelect,
        AppStateSelect,
        AppCitySelect
    },

    data () {
        return {
            zip_mask,
            /**
             * @type {Branch|String}
             */
            branch: { ...this.$attrs.branch },

            cities: [],

            canSubmit: true
        }
    },

    validations: {
        branch: {
            zip_code: { required, minLength: minLength(5) },
            name: {required, maxLength: maxLength(60)},
            address: {required, maxLength: maxLength(60)},
        }
    },

    directives: {
        maska
    },

    methods: {
        ...mapActions('locations', ['latLngByZipCode', 'validZipCode']),
        ...mapActions('auth', ['check']),

        createBranch () {
            if (this.canSubmit && !this.$v.$invalid) {
                this.startLoading(async () => {
                    let modifiedBranch = mapKeys(this.branch, (value, key) => {
                      if (key === 'counties') return 'county_ids';
                      return key;
                    });

                    let countyIds = modifiedBranch.county_ids;
                    if (!Array.isArray(countyIds)) countyIds = [countyIds];
                    modifiedBranch.county_ids = countyIds;

                    await this.$store.dispatch('locations/createBranch', modifiedBranch);
                    await this.load();
                    await this.check();
                    this.$modal.hideAll();
                })
            }
        },

        updateBranch () {
            if (this.canSubmit && !this.$v.$invalid) {
                this.startLoading(async () => {
                    let changes = mapKeys(pick(this.branch, [
                        'id',
                        'name',
                        'address',
                        'state_id',
                        'city_id',
                        'longitude',
                        'latitude',
                        'zip_code',
                        'service_area',
                        'primary_phone',
                        'counties'
                    ]), (value, key) => {
                        if (key === 'counties') return 'county_ids';
                        return key;
                    });

                    // Based on the backend requirements county_ids should be an array
                    let countyIds = changes.county_ids;
                    if (!Array.isArray(countyIds)) countyIds = [countyIds];
                    if (typeof countyIds[0] === 'object') countyIds[0] = countyIds[0].id;
                    changes.county_ids = countyIds;

                    await this.$store.dispatch('locations/updateBranch', changes);
                    this.load();
                    this.$modal.hideAll()
                });
            }
        },

        updateLatLng ({ lat, lng }) {
            this.branch.latitude = lat;
            this.branch.longitude = lng;
        },
    },

    watch: {
        'branch.zip_code': {
            async handler (zip_code) {
                if (zip_code.length !== 5) return true;

                const {valid} = await this.validZipCode(zip_code);

                this.canSubmit = valid;

                zip_code = await this.latLngByZipCode(zip_code);
                this.branch.latitude = zip_code.latitude;
                this.branch.longitude = zip_code.longitude;
            }
        }
    },
};
</script>
