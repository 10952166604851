<template>
    <section class="price-calendar">
        <div class="price__desc">
            <p>{{ tabDescription }}</p>
        </div>
        <v-row>
            <v-col lg="5">
                <v-card class="price-calendar__table" outlined>
                    <p class="charge-text__info text-secondary">Calendar</p>
                    <v-date-picker
                     v-model="clickedDate"
                     :min="today" 
                     class="custom--v-date-picker" 
                     full-width :events="events" 
                     header-color="white"
                     :event-color="eventColor" />
                </v-card>
            </v-col>
            <v-col lg="7">
                <v-card class="price-calendar__charge" outlined :loading="isLoading">
                    <v-card-text class="charge-text">
                        <p class="charge-text__info text-secondary">
                            {{ isCreating ? `Create ${ type }` : `Update ${ type }` }}
                        </p>
                        <h2 class="charge-text__time text-secondary">{{ getDay() }}</h2>
                        <v-row class="charge-block">
                            <v-col class="charge-block__column" lg="4">
                                <div class="charge-block__header  mb-1.5 text-secondary font-medium">Change type</div>
                                <v-select class="charge-block__select custom--v-select"
                                  v-model="form.is_discount"
                                  :items="is_discount" item-text="display" item-value="value"
                                  dense outlined hide-details
                                  placeholder="Change type" />
                            </v-col>
        
                            <v-col class="charge-block__column" lg="4">
                                <div class="charge-block__header mb-1.5 text-secondary font-medium">US dollars/Percentage</div>
                                <v-select
                                  class="charge-block__select custom--v-select"
                                  v-model="form.is_value_percentage" 
                                  :items="is_value_percentage" item-text="display"
                                  item-value="value" dense outlined hide-details
                                  placeholder="US dollars/Percentage" />
                            </v-col>
                            <v-col class="charge-block__column" lg="4">
                                <div class="charge-block__header mb-1.5 text-secondary font-medium">Amount</div>
                                <v-text-field v-model="form.value" single-line hide-details outlined type="text"
                                    class="charge-block__input custom--v-text-field" placeholder="Amount"
                                    v-maska="only_integers_allowed" />
                            </v-col>
                        </v-row>
                        <div class="charge-action d-flex align-center">
                            <p class="charge-action__text text-secondary">20$ extra charge to the base rate. <br>
                                10% discount on the base rate</p>
                            <v-row class="charge-action__row align-end ml-auto" align="center" dense>
                                <v-col class="charge-action__column" lg="auto">
                                    <v-btn elevation="0" color="primary" class="charge-action__btn px-14"
                                        @click="isCreating ? createDiscount() : updateDiscount()">
                                        {{ isCreating ? 'Add' : 'Update' }}
                                    </v-btn>
                                </v-col>
        
                                <v-col class="charge-action__column" lg="auto" v-if="!isCreating">
                                    <v-btn elevation="0" color="secondary" class="charge-action__btn px-14" @click="destroyDiscount">
                                        Delete
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
                <v-card class="price-calendar__block-day price-calendar__charge" outlined>
                     <v-card-text class="p-0">
                        <div class="d-flex">
                                <p class="charge-text__info text-secondary">Blocking day</p>
                                <v-tooltip top content-class="charge-text__tooltip">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" icon style="margin-top: -10px">
                                            <svg width="11" height="11" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.39105 0C1.96544 0 0 2.0132 0 4.5C0 6.9848 1.96544 9 4.39105 9C6.81569 9 8.78211 6.9848 8.78211 4.5C8.78211 2.0132 6.81569 0 4.39105 0ZM3.86705 1.5089C3.99849 1.3741 4.15589 1.3068 4.34119 1.3068C4.52347 1.3068 4.67881 1.3742 4.80928 1.5089C4.93984 1.6396 5.00561 1.7987 5.00561 1.9887C5.00561 2.1785 4.93984 2.3399 4.80928 2.4746C4.67881 2.6053 4.52337 2.6706 4.34119 2.6706C4.15589 2.6706 3.99849 2.6032 3.86705 2.4705C3.73659 2.3357 3.6718 2.1765 3.6718 1.9887C3.6718 1.7987 3.73659 1.6396 3.86705 1.5089ZM5.58542 7.481H3.29231V7.091H3.54534C3.68175 7.091 3.7764 7.0584 3.83124 6.9951C3.88803 6.9318 3.91692 6.8195 3.91692 6.6602V4.3938C3.91692 4.2508 3.87613 4.1406 3.79436 4.063C3.71571 3.9854 3.60408 3.9466 3.46269 3.9466H3.29231V3.5668L4.9897 3.4872V6.8111C4.9897 6.9009 5.01663 6.9704 5.07147 7.0194C5.12826 7.0664 5.21394 7.0908 5.32849 7.0908H5.58552V7.481H5.58542Z"
                                                    fill="#8F3985" />
                                            </svg>
                                        </v-btn>
                                    </template>
                                    <span class="custom--v-tooltip">If you are unavailable to work, you can block a time period by selecting Morning, Afternoon block or you can block a
                                    full day. Then customers will not be able to give you new orders. <br/><br />
                                    
                                    You can constantly adjust or remove blocked time periods at any time.</span>
                                </v-tooltip>
                                
                        </div>
                        <h2 class="charge-text__time text-secondary">{{getDay()}}</h2>
                        <v-btn-toggle class="custom--v-btn-toggle" v-model="day">
                                <v-btn class="custom--v-btn">
                                    Morning
                                </v-btn>
                                <v-btn class="custom--v-btn">
                                    Afternoon
                                </v-btn>
                                <v-btn class="custom--v-btn">
                                    All day
                                </v-btn>
                        </v-btn-toggle>
                        <div class="price-calendar__block charge-action d-flex">
                        <v-btn 
                          elevation="0" 
                          color="primary"
                          :disabled="day === null"
                          class="charge-action__btn justify-end ml-auto px-14"
                          @click="blockedDay">
                                Save
                        </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col lg="12">
                <operating-hours :activeLocation="branchId"></operating-hours>
                <operating-window />
            </v-col>
        </v-row>
    </section>
    
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import loading from "@/mixins/loading.js";
import dayjs from "dayjs";
import { find, map } from "lodash";
import { warningConfirmAction } from "@/plugins/swal.js";
import { maska } from "maska";
import { only_integers_allowed } from "@/plugins/custom_masks";
import Options from '../Options/Options.vue';
import OperatingHours from '../Options/OperatingHours/OperatingHours.vue';
import OperatingWindow from '../Options/OperatingWindow/OperatingWindow.vue';

const defaultForm = {
    is_discount: false,
    is_value_percentage: false,
    value: 0,
};

export default {
    components: { OperatingHours , Options, OperatingWindow},
    props: {
        branchId: Number,
        tabDescription: String,
        activeLocation: null,
    },

    mixins: [loading],

    data () {
        return {
            discounts: [],
            openedDiscountId: null,
            clickedDate: dayjs(new Date()).format('YYYY-MM-DD'),
            form: { ...defaultForm },
            day: null,
            today: dayjs(new Date()).format('YYYY-MM-DD'),    
            branchId: this.activeBranch,

            // options
            is_discount: [
                { value: false, display: 'Extra charge' },
                { value: true, display: 'Discount' },
            ],

            is_value_percentage: [
                { value: false, display: 'US dollars' },
                { value: true, display: 'Percentage' },
            ],
            only_integers_allowed,
        }
    },

    methods: {
        ...mapActions('discounts', ['index', 'store', 'update', 'destroy']),
        ...mapActions('calendar', ['storeBlockedDay', 'updateBlockedDay']),

        loadDiscounts () {
            this.startLoading(async () => {
                this.discounts = await this.index({
                    branch_id: this.branchId
                });

                this.openDiscount(this.clickedDate);
            });
        },

        openDiscount (date) {
            const discount = find(this.discounts, { date });

            if (discount) {
                this.openedDiscountId = discount.id;
                this.form = discount;
            } else {
                this.openedDiscountId = null;
                this.form = { ...defaultForm }
            }
        },

        async createDiscount () {
            this.loadingStart();

            await this.store({
                branch_id: this.branchId,
                data: {
                    date: this.clickedDate,
                    ...this.form
                },
            });

            this.loadDiscounts();
        },

        async updateDiscount () {
            this.loadingStart();

            await this.update({
                branch_id: this.branchId,
                discount_id: this.openedDiscountId,
                data: {
                    date: this.clickedDate,
                    ...this.form
                },
            });

            this.loadDiscounts();
        },

        async destroyDiscount () {
            warningConfirmAction('Do you want to really delete this offer?', 'Delete', async () => {
                this.loadingStart();

                await this.destroy({
                    branch_id: this.branchId,
                    discount_id: this.openedDiscountId,
                });

                this.loadDiscounts();
            });
        },

        async blockedDay () {
           
            let isMorning = false;
            let isAfternoon = false;

            if(this.day === 0) {
                isMorning = true;
            }

            if (this.day === 1) {
                isAfternoon = true;
            }

            if (this.day === 2) {
                isAfternoon = true;
                isMorning = true;
            }

            this.storeBlockedDay({
                branch_id: this.branchId,
                date: this.clickedDate,
                is_on_morning: isMorning,
                is_on_afternoon: isAfternoon,
            });
        },

        eventColor (date) {
            const discount = find(this.discounts, { date });

            return discount.is_discount ? '#1b6644' : '#f65631';
        },

        loadCompanyWorkingHours() {
            this.startLoading(async () => {
                await this.$store.dispatch('working_days/loadCompanyWorkingHours');
            });
        },

        getDay() { 
            return dayjs(this.clickedDate).format('MMMM D')
        }
    },

    watch: {
        clickedDate: {
            immediate: true,

            handler (newClickedDate) {
                this.openDiscount(newClickedDate);
            }
        }
    },

    computed: {
        ...mapGetters('auth', ['activeBranch']),
        ...mapGetters('working_days', ['companyWorkingDays']),

        isCreating () {
            return this.openedDiscountId === null;
        },

        type () {
            return this.form.is_discount ? 'discount' : 'extra charge';
        },

        events () {
            return map(this.discounts, 'date');
        }
    },

    created () {
        this.loadDiscounts();
    },

    directives: {
        maska
    },
}
</script>

<style scoped lang="scss">
::v-deep {
    .v-picker {
        border: 0;

        &__title {
            display: none;
        }
        .v-date-picker-header {
            position: relative;
            margin: 15px -9px 30px;

            & > button i {
                font-size: 18px !important;
                color:#0D3659 !important;
            }

            & > button:first-child {
                position: absolute;
                right: 47px;
            }
            & > button:last-child {
                position: absolute;
                right: 12px;
                z-index: 100;
            }
            .v-date-picker-header__value {
                position: absolute;
                left: 0;

                button {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #0D3659 !important;
                }
            }
        }
        .v-date-picker-table {
            padding: 0 !important;
        }
        .v-date-picker-table--date thead tr th {
            height: 50px;
            font-family: Gilroy;
            font-size: 16px !important;
            font-weight: 600;
            line-height: 20px !important;
            color:#0D3659 !important;
        }
        .v-date-picker-table--date td {
            width:56px;
            height: 62px;
            border: 0.28185px solid #DFDFDF;
        }     
        .v-picker__title__btn {
            font-size: 20px;
        }

        .v-date-picker-table {
            height: auto;
        }
    }
    .custom--v-select, .custom--v-text-field {
        fieldset {
            border-radius: 4px !important;
        }
        input, .v-select__selection--comma {
            color: #0D3659;
            font-weight: 500;
        }
    }
    .custom--v-btn-toggle {
        .custom--v-btn {
            border-radius: 4px !important;
            width: 165px !important;
            font-family: Gilroy;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #0D3659;
            background: #F5F5F9;
            border:none !important;

            &:not(:last-child) {
                margin-right: 15px;
            }

        }
        .v-btn--active {
            background: #8F3985;
            color: white !important;
        }
    }
    
}

.close-card {
    position: absolute;
    top: 5px;
    right: 5px;
}
</style>
