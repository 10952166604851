var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"desktop-profile-menu"},[(_vm.isCustomer)?_c('div',{staticClass:"customer__profile-bar"},[_c('v-avatar',{staticClass:"mr-4",attrs:{"color":"primary","size":"40"}},[_c('img',{attrs:{"src":_vm.logo,"alt":_vm.name}})]),_c('span',{staticClass:"normal-case font-semibold w-1/2 whitespace-normal text-left"},[_vm._v(" "+_vm._s(_vm.branchName)+" ")])],1):(_vm.isLabor)?_c('div',{staticClass:"customer__profile-bar"},[_c('v-avatar',{staticClass:"mr-4",attrs:{"color":"primary","size":"40"}},[_c('img',{attrs:{"src":_vm.user.image,"alt":_vm.user.first_name}})]),_c('span',{staticClass:"normal-case font-semibold w-1/2 whitespace-normal text-left"},[_vm._v(" "+_vm._s(_vm.user.first_name)+" ")])],1):_c('a',{staticClass:"d-flex align-center mx-auto",attrs:{"href":_vm.frontend_url}},[_c('img',{attrs:{"src":"/assets/images/logos/logo-purple.svg","alt":"Tophelpers Logo","title":"Tophelpers logo"}})])]),(!_vm.isCustomer && !_vm.isLabor)?_c('div',{staticClass:"desktop-profile-info position-relative"},[_c('div',{staticClass:"profile-info__column"},[(_vm.branchFullHeaderName.length > 10)?_c('v-tooltip',{attrs:{"bottom":"","content-class":"charge-text__tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h3',_vm._g(_vm._b({staticClass:"profile-info__header  mb-2.5  font-bold text-secondary"},'h3',attrs,false),on),[_vm._v(" "+_vm._s(_vm.branchHeaderName)+" ")])]}}],null,false,708262975)},[_c('span',[_vm._v(_vm._s(_vm.branchFullHeaderName))])]):_c('h3',{staticClass:"profile-info__header  mb-2.5  font-bold text-secondary"},[_vm._v(_vm._s(_vm.branchFullHeaderName))]),_c('p',{staticClass:"profile-info__state"},[_vm._v(" "+_vm._s(_vm.branchFullAddress)+" ")]),_c('div',{staticClass:"profile-info__location d-flex align-center",on:{"click":_vm.SET_LOCATION_MENU}},[_vm._v(" See all locations ("+_vm._s(_vm.branches.length)+") "),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary","size":"8"}},[_vm._v(" fa-chevron-right ")])],1),_c('app-location',{attrs:{"isOpen":_vm.isOpenLocationMenu}})],1),_c('div',{staticClass:"profile-info__column align-self-start"},[_c('router-link',{staticClass:"d-inline-block",attrs:{"to":{
                    name: 'view-company',
                    params: {
                        slug: this.activeSlug.name
                    }
                }}},[_c('v-icon',{attrs:{"color":"primary","size":"15"}},[_vm._v(" icon-logout ")])],1)],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }