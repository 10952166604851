<template>
    <section :class="[!isOpen ? 'd-none' : '', 'location-modal']">
        <div class="location-modal__exit d-flex align-center justify-end">
            <v-btn icon @click="SET_LOCATION_MENU()">
                <v-img src="/assets/icons/delete.svg"/>
            </v-btn>
        </div>

        <h1 class="location-modal__header">Locations ({{ branches.length }})</h1>
        <div class="location-modal__info d-flex align-center justify-center flex-column flex-wrap">
            <div class="location-modal__search w-100 d-flex align-center me-auto">
                <input v-model="searchingInput" type="text" name="locationSearch" placeholder="Search locations">
                <v-icon size="20" color="#aaa" class="d-inline-block ms-auto">
                    fa-search
                </v-icon>
            </div>
            <div class="location-modal__locations d-flex align-center flex-column">
                <div class="w-100 d-flex align-center"
                     v-for="{id, address, name} in getBranch(searchingInput)"
                     :key="id">
                    <div class="location-modal__block d-flex align-center" :class="{'location--active': isActive(id)}">
                        <div class="modal-locations__column" @click="changeBranch(id)">
                            <h5 :class="{'d-none': isActive(id)}">{{ name }}</h5>
                            <span v-if="isActive(id)">{{ name }}</span>
                            <span class="d-block">{{ address }}</span>
                        </div>
                        <span class="modal-locations__column"
                           v-if="!isActive(id)"
                           @click="viewAsCustomer"
                        >
                            <v-icon size="15" color="#6B6D81"
                            >
                                icon-logout
                            </v-icon>
                        </span>
                    </div>
                </div>
                <div v-if="searchingInput.length > 0 && getBranch(searchingInput).length === 0"
                     class="branch-not__found">
                    <p>Branch not found</p>
                </div>
            </div>
        </div>
        <v-btn depressed color="primary" class="v-btn v-size--medium me-auto" @click.prevent="passLocationAndCloseMenu">
            <span>Add a location</span>
        </v-btn>
    </section>
</template>

<script>
import loading from "@/mixins/loading.js";
import { mapActions, mapMutations, mapState } from "vuex";
import { FRONTEND_URL } from "@/plugins/consts.js";

export default {
    name: "AppLocation",

    props: {
        isOpen: Boolean
    },

    data () {
        return {
            searchingBranches: [],
            searchingInput: ''
        }
    },

    mixins: [loading],

    computed: {
        ...mapState('locations', ['branches']),
        ...mapState('auth', ['branches']),
        activeSlug () {
            return this.$store.state.auth?.activeBranch;
        }
    },

    methods: {
        ...mapMutations('locations', ['SET_LOCATION_MENU']),
        ...mapMutations('auth', ['SET_BRANCH']),
        ...mapActions('locations', ['load']),

        passLocationAndCloseMenu () {
            this.SET_LOCATION_MENU();

            this.$router.push({
                name: 'locations'
            })
        },

        isActive (id) {
            return this.$store.state.auth?.activeBranch?.id === id;
        },

        changeBranch (branch_id) {
            this.startLoading(async () => await this.SET_BRANCH(branch_id));
            this.SET_LOCATION_MENU();
        },

        frontend_url () {
            return FRONTEND_URL;
        },

        getBranch (branchName) {
            if (this.searchingInput.length > 0) {
                return this.branches.filter(branch => branch.name.toLocaleLowerCase().includes(branchName.toLocaleLowerCase()));
            } else {
                return this.branches;
            }
        },

        viewAsCustomer() {
            this.$router.push({
                name: 'view-company',
                params: {
                    slug: this.activeSlug?.name
                }
            })
        },

        created () {
            this.load();
        }

    },

}
</script>

<style lang="scss">
@import "src/assets/scss/style";

.location-modal {
    width: 390px;
    max-height: 594px;
    padding: 0 16px 35px 35px;
    border: 0.5px solid #DDDDDD;
    box-shadow: 0 9.18458px 20.4102px rgba(112, 144, 176, 0.12);
    border-radius: 8px;
    position: absolute;
    z-index: 100000;
    background: #fff;
    top: 15%;
    left: 250px;

    &__header {
        padding-bottom: 30px;
        margin-right: 16px;
        margin-bottom: 15px;
        font-size: 25px;
        color: #0D3659;
        font-weight: 600;
        line-height: 17px;
        border-bottom: 1px solid #DDDDDD
    }

    &__info {
        border-bottom: 1px solid #bbb;
        padding: 0 16px 5px 0;
        margin: 0 16px 15px 0;
    }

    &__search {
        width: 320px;
        padding: 16px 26px 17px 26px;
        margin: 0 auto 15px 0;
        background: #F5F5F9;
        border-radius: 25px;
        height: 49px;

        input[type="text"] {
            outline: none;
            background: none;
            border: none;
            width: 77%;
            height: 100%;
            font-family: 'Mulish', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.10000000149011612px;
            color: $secondary;
        }
    }

    &__locations {
        max-width: 330px;
        max-height: 350px;
        overflow: auto;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;

        &--active {
            background: #FEECFC;
        }

        & > div {
            border-radius: 5px;
        }
    }

    &__block {
        width: 320px;
        margin: 0 auto 10px 0;
        border-radius: 8px;
        background: #F6F6F6;
        padding: 8px 25px 5px 25px;

        &:hover {
            cursor: pointer;
        }
    }

    .modal-locations__column {
        h5, span {
            width: 255px !important;
            margin-bottom: 0;
            word-break: break-all;
        }

        h5 {
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            color: #0D3659;
        }

        span {
            font-size: 14px;
            line-height: 25px;
            color: #aaa;
            font-weight: 400;
        }
    }

    &__exit {
        img:hover {
            cursor: pointer;
        }
    }
}

.location--active {
    min-height: 57px;
    background: #FEECFC;

    span {
        color: $primary !important;
        font-weight: 600 !important;
    }

    span:last-child {
        font-weight: 500 !important;
    }
}

.branch-not__found {
    p {
        color: red !important;
        line-height: 18px !important;
    }
}

</style>
