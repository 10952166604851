<template>
    <l-map :center="location">
        <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>

        <l-circle
            :lat-lng="location"
            :radius="radius * 1000"
            @ready="onCircleReady"
        />

        <l-marker
            :lat-lng="location"
            :draggable="editable"
            @update:latLng="updateLatLng"
        />
    </l-map>
</template>

<script>
import { Icon, latLng } from "leaflet";
import { LCircle, LMap, LMarker, LTileLayer } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    props: {
        latitude: Number,
        longitude: Number,
        radius: Number, // km
        editable: {
            type: Boolean,
            default: false,
        },
        updateLatLng: {
            type: Function,
            default: () => {
            },
        }
    },

    components: {
        LMap,
        LTileLayer,
        LCircle,
        LMarker,
    },

    data () {
        return {
            circle: null,
        };
    },

    computed: {
        location () {
            return latLng(this.latitude, this.longitude);
        },
    },

    methods: {
        onCircleReady (circle) {
            this.circle = circle;
            this.fitCircleIntoMap();
        },

        fitCircleIntoMap () {
            // https://stackoverflow.com/a/16845714/6699031
            this.circle._map.fitBounds(this.circle.getBounds());
        },
    },

    watch: {
        radius: 'fitCircleIntoMap',
    },
};
</script>
