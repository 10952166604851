<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="local"
            hide-default-footer
            :items-per-page="15"
            class="custom--v-table"
        >
            <template #item.edit="{ item }">
                <v-btn
                    class="px-5"
                    elevation="0"
                    color="primary"
                    @click="openModal(item)"
                    small
                >
                    Edit
                </v-btn>
                <v-btn
                    class="px-5"
                    elevation="0"
                    color="secondary ml-2"
                    small
                    @click="deleteService(item)"
                >
                    Delete
                </v-btn>
            </template>
        </v-data-table>

        <div class="d-flex justify-end">
            <v-btn
                class="px-10 mt-6"
                elevation="0"
                color="primary"
                @click="openModal()"
                large
            >
                Add New Service
            </v-btn>
        </div>
    </div>
</template>

<script>
import HoursModal from "./PricingModal";
import ValueEmitter from "../../mixins/ValueEmitter";
import { warningConfirmAction } from "@/plugins/swal.js";

export default {
    props: {
        loadPricing: Function,
        activeBranch: Number,
        movers_only: {
            type: Boolean,
            default: false,
        }
    },

    mixins: [ValueEmitter],

    data () {
        return {
            headers: [
                {
                    text: 'Service',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'Trucks number',
                    sortable: false,
                    value: 'truck_number'
                },
                {
                    text: 'Base Hourly Rate',
                    align: 'end',
                    sortable: false,
                    value: 'base_hourly_rate',
                },
                {
                    text: '',
                    align: 'end',
                    sortable: false,
                    value: 'edit',
                },
            ],
        }
    },

    methods: {

        openModal (servicePrice = null) {
            this.$modal.show(HoursModal, {
                branch_id: this.activeBranch,
                service_price: servicePrice,
                is_creating: ! servicePrice,
                movers_only: this.movers_only,
            }, {
                width: 1000,
                height: 700,
            }, {
                closed: this.loadPricing,
            });
        },

        deleteService (service) {
            warningConfirmAction('Do you really want to delete this service?', 'Delete it', async () => {
                await this.$store.dispatch('service_pricing/destroy', [this.activeBranch, service.id]);
                await this.loadPricing()
            });
        },
    },
}
</script>
