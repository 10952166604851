var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading || _vm.loadingSection)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(!_vm.loadingSection)?_c('div',[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.deliveries.length > 0),expression:"deliveries.length > 0"}],class:_vm.tableClasses,attrs:{"headers":_vm.headers,"items":_vm.deliveries,"hide-default-footer":"","items-per-page":15},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('button',{staticClass:"tw-p-[3px_8px] -tw-mx-[8px] tw-text-[14px] tw-font-semibold tw-text-[#8F3985] tw-rounded-[5px] hover:tw-bg-[#8F3985]/20 tw-duration-300",on:{"click":function($event){$event.preventDefault();return _vm.openDeliveryModal(item)}}},[_c('span',{domProps:{"textContent":_vm._s(item.id)}})])]}},{key:"item.arrival_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.arrival_date))+" ")]}},{key:"item.prices",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s('$' + item.prices)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tw-p-[4.5px_8px] tw-border tw-rounded-[8px] tw-text-[#0D3659] tw-text-[14px] tw-font-medium",class:[_vm.statusClass(item.status_id)],domProps:{"textContent":_vm._s(_vm.statusLabel(item.status_id))}})]}}],null,false,1628876020)}),_c('v-pagination',{staticClass:"custom--v-pagination",attrs:{"length":_vm.deliveries_last_page},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_c('v-alert',{staticClass:"text-center",attrs:{"color":"primary","text":""}},[_vm._v("Invite your client to the platform")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }