<template>
    <v-main v-if="isLoaded" class="view-helper custom--v-main">
        <header class="view-helper-header">
            <h1>{{ name + ' - ' + branch.branch_name }}</h1>

            <section class="view-helper-header__info">
                <v-rating
                    :valuel="Number(branch.rating)"
                    readonly
                    color="#fff"
                    background-color="#fff"
                    half-icon="fa-star-half-alt"
                    half-increments
                    class="custom--v-rating"
                ></v-rating>
                <p class="mr-5 ml-1">{{ branch.rating }}</p>
                <v-img src="assets/images/profile/view-profile/correct.svg" alt="correct icon" class="mr-2"/>
                <p class="mr-6">{{ branch.acceptance_rate + '%' }} </p>
                <v-img src="assets/images/profile/view-profile/cancel.svg" alt="cancel icon" class="mr-2"/>
                <p>{{ branch.cancellation_rate + '%' }}</p>
            </section>
        </header>
        <article class="view-helper-main">
            <main>
                <section class="view-helper__about d-flex align-center justify-center">
                    <v-row class="view-helper--container">
                        <v-col sm="9" cols="12">
                            <div v-if="branch.about_us" class="view-helper__about-block">
                                <p :style="[toggledItemID === 1 ? '' : 'max-height: 8em;display: -webkit-box;overflow: hidden']"
                                   style="white-space: pre-line">
                                    <span>About: </span>
                                    {{ branch.about_us }}
                                </p>
                                <div v-if="branch.about_us.length > 500">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-decoration-none fw-bold"
                                        @click="toggle(1)"
                                    >{{ toggledItemID === 1 ? 'Show Less' : 'Read more  ' }}</a>
                                </div>
                            </div>
                            <p v-else class="font-weight-medium text-xl">No about text yet...</p>
                        </v-col>
                        <v-col sm="3" cols="12">
                            <img :src="branch.avatar" title="avatar" alt="avatar"/>
                        </v-col>
                    </v-row>
                </section>
                <section class="view-helper__results">
                    <div class="view-helper--container d-flex align-center justify-space-between flex-wrap">
                        <div class="view-helper__results-block">
                            <img src="/assets/images/profile/view-profile/location.svg" alt="location icon"
                                 title="location icon"/>
                            <span v-text="branch.address.three_dots(40)"/>

                        </div>
                        <div class="view-helper__results-block">
                            <img src="/assets/images/profile/view-profile/anniversary.svg" alt="icon" title="icons"/>
                            <span v-text="branch.found_date"/>
                        </div>
                        <div class="view-helper__results-block">
                            <img src="/assets/images/profile/view-profile/employee.svg" alt="icon" title="icons"/>
                            <span>{{
                                    branch.employees_count > 1 ? branch.employees_count + ' Employees' : branch.employees_count + ' Employee'
                                }} </span>
                        </div>
                        <div class="view-helper__results-block">
                            <img src="/assets/images/profile/view-profile/hired.svg" alt="icon" title="icons"/>
                            <span>
                                Hired
                                {{
                                    branch.orders_count > 1 ? branch.orders_count + ' times' : branch.orders_count + ' time'
                                }}</span>
                        </div>
                        <div class="view-helper__results-block">
                            <img
                                :src="branch.is_checked ? '/assets/images/profile/view-profile/checked.svg' : '/assets/images/profile/view-profile/rejected.svg'"
                                alt="icon" title="icons"/>
                            <span v-text="branch.is_checked ? 'Background checked' : 'Background unchecked'"/>
                        </div>
                    </div>
                </section>
                <section class="view-helper__reviews">
                    <h1 class="text-secondary mb-8">Recent customer reviews</h1>
                    <div v-if="branch.reviews.length > 0">
                        <Splide :options="sliderOptions">
                            <SplideSlide v-for="{ id, name, avatar, comment, rating } in branch.reviews" :key="id">
                                <div class="review-slider-item">
                                    <div>
                                        <div>
                                            <img :src="avatar" :alt="name" :title="name">
                                        </div>
                                        <div>
                                            <div class="d-flex flex-column">
                                                <strong> {{ name.length > 20 ? name.three_dots(20) : name }} </strong>
                                                <span>Customer</span>
                                                <v-rating
                                                    :value="Number(rating)"
                                                    half-icon="fa-star-half-alt"
                                                    half-increments
                                                    size="12"
                                                    color="#FBAB1E"
                                                    background-color="#FBAB1E"
                                                    class="custom--v-rating"
                                                    readonly/>
                                            </div>
                                        </div>
                                    </div>
                                    <p :style="id === toggledItemID ? '' : 'height: 165px; display: -webkit-box;'"
                                       v-text="comment"/>

                                    <div class="mt-1" v-show="comment.length > 250">
                                        <a
                                            href="javascript:void(0);"
                                            class="text-decoration-none fw-bold"
                                            @click="toggle(id)"
                                        >{{ id === toggledItemID ? 'Show Less' : 'Read more' }}</a>
                                    </div>
                                </div>
                            </SplideSlide>
                            <template v-slot:controls>
                                <div class="splide__arrows">
                                    <button class="splide__arrow splide__arrow--prev">
                                        <v-icon color="secondary">fa-chevron-left</v-icon>
                                    </button>
                                    <button class="splide__arrow splide__arrow--next">
                                        <v-icon>fa-chevron-right</v-icon>
                                    </button>
                                </div>
                            </template>
                        </Splide>
                    </div>
                    <div v-else class="view-helper--container">
                        <p class="text-secondary font-weight-medium text-xl text-center pb-10 mb-0">No reviews
                            yet...</p>
                    </div>
                </section>
                <section class="view-helper__photos">
                    <div class="view-helper--container d-flex flex-column">
                        <h1 class="text-secondary mb-8">Photos</h1>
                        <div v-if="branch.photos.length !== 0">
                            <img v-for="{ id, image } in branch.photos" :key="id" :src="image" alt="image"
                                 @click="openImage(image)">
                        </div>
                        <p v-else class="text-secondary font-weight-medium text-xl text-center">No photos yet...</p>
                    </div>
                </section>
                <section class="view-helper__services">
                    <div class="view-helper--container d-flex flex-column">
                        <h1 class="text-secondary mb-8">Services</h1>
                        <div v-if="branch.selected_service.length > 0">
                            <p v-for="{id, service, is_active } in branch.selected_service"
                               :class="is_active ? 'service--active' : '' " :key="id">{{ service }}</p>
                        </div>
                        <p v-else class="text-secondary-darker font-weight-medium text-xl text-center">No services
                            yet...</p>
                    </div>
                </section>
                <section class="view-helper__credentials">
                    <div class="view-helper--container d-flex flex-column">
                        <h1 class="text-secondary mb-8">Credentials</h1>
                        <section class="d-flex flex-wrap">
                            <div v-for="({value, name}, index) in getCredentials(branch.credentials)" :key="index"
                                 :class="[ value ? '' : 'rejected', 'view-helper__credentials-block']">
                                <img
                                    :src="value ? '/assets/images/profile/view-profile/checked.svg' : '/assets/images/profile/view-profile/rejected.svg'"
                                    alt="icon"/>
                                <p>{{ name }}</p>
                            </div>
                        </section>
                    </div>
                </section>
                <section class="view-helper__payment">
                    <div class="view-helper--container">
                        <h1 class="text-secondary mb-8">Payment method</h1>

                        <section class="view-helper__payment-section d-flex flex-wrap">
                            <div v-if="getPayment('Visa')">
                                <img src="/assets/images/profile/payment/visa.svg" alt="visa icon">
                                <img src="/assets/images/profile/view-profile/checked.svg" alt="checked">
                            </div>
                            <div v-if="getPayment('PayPal')">
                                <img src="/assets/images/profile/payment/paypal.svg" alt="paypal icon">
                                <img src="/assets/images/profile/view-profile/checked.svg" alt="checked">
                            </div>
                            <div v-if="getPayment('Mastercard')">
                                <img src="/assets/images/profile/payment/mastercard.svg" alt="mastercard icon">
                                <span>mastercard</span>
                                <img src="/assets/images/profile/view-profile/checked.svg" alt="checked">
                            </div>
                            <div v-if="getPayment('Venmo')">
                                <img src="/assets/images/profile/payment/venmo.svg" alt="venmo icon">
                                <img src="/assets/images/profile/view-profile/checked.svg" alt="checked">
                            </div>
                            <div v-if="getPayment('Zelle')">
                                <img src="/assets/images/profile/payment/zelle.svg" alt="zelle icon">
                                <img src="/assets/images/profile/view-profile/checked.svg" alt="checked">
                            </div>
                            <div v-if="getPayment('Google Pay')">
                                <img src="/assets/images/profile/payment/google-pay.svg" alt="google pay icon">
                                <img src="/assets/images/profile/view-profile/checked.svg" alt="checked">
                            </div>
                            <div v-if="getPayment('Apple Pay')">
                                <img src="/assets/images/profile/payment/apple-pay.svg" alt="apple pay icon">
                                <img src="/assets/images/profile/view-profile/checked.svg" alt="checked">
                            </div>
                            <div v-if="getPayment('MoneyGram')">
                                <img src="/assets/images/profile/payment/money-gram.svg" alt="money-gram icon">
                                <img src="/assets/images/profile/view-profile/checked.svg" alt="checked">
                            </div>
                            <div v-for="card in getSecondaryCardsName()">
                                <v-icon color="secondary">fa-credit-card</v-icon>
                                <span class="ml-2"> {{ card.name }}</span>
                                <img src="/assets/images/profile/view-profile/checked.svg" alt="checked">
                            </div>
                        </section>

                    </div>
                </section>
                <section class="view-helper__map">
                    <div class="view-helper--container">
                        <h1 class="text-secondary mb-8">Working Hours & Service Area</h1>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <table v-if="branch.working_days.length !== 0" class="table table-striped">
                                    <tbody>
                                    <tr v-for="({day, from_hour, to_hour}, index ) of branch.working_days" :key="index">
                                        <td>{{ day }}:</td>
                                        <td>
                                            <v-icon size="14" color="secondary">fa-clock</v-icon>
                                            {{ from_hour }} - {{ to_hour }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p v-else class="text-secondary font-weight-medium text-xl">Working days empty...</p>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <locations-item-map
                                    :latitude="branch.latitude"
                                    :longitude="branch.longitude"
                                    :radius="branch.service_area"
                                    style="z-index: 0; min-height: 350px"
                                />
                            </v-col>
                        </v-row>
                    </div>
                </section>
                <section class="view-helper__faq">
                    <div class="view-helper--container">
                        <h1 class="text-secondary mb-8">FAQ</h1>
                        <v-expansion-panels
                            v-if="branch.answers.length !== 0" accordion flat
                            class="custom--v-expansions-panel">
                            <v-expansion-panel
                                class="custom--v-expansion-panel"
                                active-class="expansion--active"
                                v-for="{id, question, answer } in branch.answers" :key="id"
                            >
                                <v-expansion-panel-header>
                                    {{ question }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content style="white-space: pre-line">
                                    {{ answer }}
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <p v-else class="text-secondary font-weight-medium text-xl text-center">FAQs empty...</p>
                    </div>
                </section>
            </main>
        </article>
    </v-main>
    <app-loading-indicator v-else/>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
// eslint-disable-next-line
import '@splidejs/splide/dist/css/splide.min.css';

import loading from "../../mixins/loading.js";
import { mapActions, mapGetters } from "vuex";
import { capitalize, includes } from "lodash";
import locationsItemMap from "../Locations/LocationsItemMap.vue";
import AppLoadingIndicator from "../../components/AppLoadingIndicator.vue";
import ViewImage from "../../components/ViewImage.vue";

export default {
    name: "ViewCompany",
    components: {
        AppLoadingIndicator,
        locationsItemMap,
        Splide,
        SplideSlide,
    },

    mixins: [loading],

    data () {
        return {
            toggledItemID: null,
            sliderOptions: {
                type: 'slider',
                perPage: 3,
                perMove: 1,
                gap: 0,
                speed: 800,
                arrows: true,
                pagination: true,
                breakpoints: {
                    1069: {
                        perPage: 2,
                    },
                },
            },
            main_cards: [
                'PayPal', 'Zelle', 'Apple Pay', 'Google Pay', "Visa", "Venmo", 'MoneyGram'
            ]
        }
    },

    computed: {
        ...mapGetters('auth', ['activeBranch', 'name']),
        ...mapGetters('viewHelper', ['branch'])
    },

    methods: {
        ...mapActions('viewHelper', ['load']),
        /**
         *
         * @param data
         * @returns {*[]}
         */
        getCredentials (data) {
            let newArr = [];

            const changeName = (name) => {
                let str = '';
                for (let i = 0; i < name.split('_').length; i++) {
                    str += capitalize(name.split('_')[i]) + ' ';
                }
                return str;
            }

            for (const property in data) {
                newArr.push({
                    value: data[property],
                    name: changeName(property)
                })
            }

            return newArr;
        },

        /**
         *
         * @param id
         */
        toggle (id) {
            if (this.toggledItemID !== id) {
                this.toggledItemID = id;
            } else {
                this.toggledItemID = null;
            }
        },

        /**
         *
         * @param name
         * @returns {boolean}
         */
        getPayment (name) {
            let isExist = false;

            for (const payment of this.branch.payment_methods) {
                if (payment.name === name && payment.is_active) {
                    isExist = true;
                }
            }

            return isExist;
        },

        //get secondary cards
        getSecondaryCardsName () {
            return this.branch.payment_methods.filter(card => {
                if (! includes(this.main_cards, card.name) && card.is_active) {
                    return card.name;
                }
            })
        },

        openImage (url) {
            this.$modal.show(ViewImage, {
                image: url
            }, {
                width: 900,
                height: 650
            })
        },

        branchLoad () {
            this.startLoading(async () => {
                await this.load();
            })
        }
    },

    watch: {
        activeBranch: {
            handler () {
                this.branchLoad();
            },

            deep: true
        }
    },

    created () {
        this.branchLoad();
    }
}
</script>

<style scoped lang="scss">
.splide__pagination__page {
    width: 14px;
    height: 14px;
    margin: 10px;
    opacity: 1;
    border: 4px solid transparent;
    background-color: #E9E9E9 !important;
}

.splide__pagination__page.is-active {
    border: 3.57247px solid #0D3659;
}

.splide__pagination {
    bottom: -28px;
}

.splide__arrow {

    @media (max-width: 1180px) {
        display: none;
    }

    &--prev,
    &--next {
        opacity: 0.8;
        line-height: 1;
        background: none;
        top: calc(50% - 35px);

        &:before {
            font-size: 30px;
            color: #0D3659;
            font-family: 'icomoon';
        }

        svg {
            display: none;
        }

        &:hover {
            opacity: 1;
        }
    }

    &--prev {
        left: -77px;

        &:before {
            content: "\f054"; // icon-arrow-left
        }
    }

    &--next {
        right: -77px;

        &:before {
            content: "\f054"; // icon-arrow-right
        }
    }
}

.review-slider-item {
    width: 311px;
    min-height: 311px;
    position: relative;
    padding: 20px 25px 15px;
    margin: 0 27px 70px;
    box-shadow: 0 19.9675px 19.1238px rgba(21, 31, 48, 0.06), 0 3px 20px rgba(160, 160, 160, 0.1);
    border-radius: 8px;

    @media (max-width: 780px) {
        padding: 15px 15px 35px 15px;
        margin-left: 15px;
        margin-right: 15px;
        box-shadow: 0 10px 10px rgba(#000, 0.1);
    }

    img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;

        @media (min-width: 780px) {
            position: absolute;
            top: 20px;
            right: 25px;
        }

        @media (max-width: 780px) {
            margin-right: 15px;
        }
    }

    strong {
        font-size: 18px;
        font-weight: 600;
        color: #0c3659;
        word-break: break-all;
    }

    span {
        margin-bottom: 10px;
        font-size: 14px;
    }

    .rating {
        margin-bottom: 15px;
    }

    p {
        margin: 0;
        line-height: 1.5;
        overflow: hidden;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        word-break: break-all;
    }
}

::v-deep {
    .custom--v-expansion-panel {
        margin-bottom: 25px;

        .v-expansion-panel-header {
            padding: 20px 50px 18px 33px;
            background: #FFFFFF;
            box-shadow: 0 8.41367px 8.05816px rgba(21, 31, 48, 0.04);
            border-radius: 8px;

            border-left: 7.3057px solid rgba(13, 54, 89, .5);
            font-size: 18px;
            font-weight: 600;
            line-height: 31px;
            color: #0c3659;

            i {
                color: #aaa;
                opacity: .7;
                width: 17px;
                font-size: 19px;
            }
        }

        .v-expansion-panel-content {
            padding: 0 100px 20px 10px;
            word-break: break-all;
        }
    }

    .expansion--active {
        border-left: 7.3057px solid #8F3985 !important;
        border-radius: 8px !important;
    }

    .expansion--active .v-expansion-panel-header {
        border-left: none !important;
        box-shadow: none !important;
        color: #8F3985;

        i {
            color: #8F3985 !important;
        }
    }
}

</style>
