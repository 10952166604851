export default {
    namespaced: true,

    state: {
        /** @type {Referencing[]}
         *
         * */

        referralData: [],
        coupon: ''
    },

    getters: {
      prices: state => {
          return {
              pending: state.referralData.pending,
              available: state.referralData.available
          }
        },
        emails: state => state.referralData.emails
    },

    actions: {
        /**
         * Get referral data
         *
         * @param {Object} state
         * @return {Promise<void>}
         */
        async index ({ state }) {
            state.referralData = await axios.get('referrals');
        },

        /**
         * Create a new referral
         *
         * @param {Function} dispatch
         * @param {String[]} emails
         * @return {Promise<void>}
         */
        async store ({ dispatch }, emails) {
            await axios.post('referrals', { emails });
            await dispatch('index');
        },

        /**
         * Destroy referral
         *
         * @param {Function} dispatch
         * @param {Number} referral_id
         * @return {Promise<any>}
         */
        async delete ({ dispatch }, referral_id) {
            await axios.delete('referrals/' + referral_id);
            await dispatch('index');
        }
    }
}