<template>
    <v-col class="invite-generate__column">
        <div class="invite-generate__gift">
            <div class="d-flex align-center justify-center">
                <h4>Gift coupon code</h4>
                <v-tooltip top content-class="charge-text__tooltip">
                    <template v-slot:activator="{ on, attrs }">
                        <img v-bind="attrs" v-on="on" src="assets/icons/info.svg" width="12" alt="info svg">
                    </template>
                    <span class="custom--v-tooltip">
                               You can generate different unique gift coupon codes for different amounts of money that you can send to your close people.
                            </span>
                </v-tooltip>
                <div class="gift ml-auto">
                    <img src="assets/icons/gift.svg" alt="gift icon">
                </div>
            </div>
            <input v-model="giftEmail" class="invite-generate__input" type="text" placeholder="Add email"/>
            <div class="d-flex align-center">
                <v-btn
                    color="primary"
                    height="29"
                    @click="sendGift(defaultSelected, giftEmail )"
                    :disabled="!validate(giftEmail) "
                >
                    Send an email
                </v-btn>
                <div class="ml-auto d-flex align-center gift-price">
                    <img src="assets/images/referral/dollor.svg" alt="dolor icon">
                    <v-select
                        v-model="defaultSelected"
                        :items="priceItems"
                        solo
                        min-height="29" min-width="66"
                        class="custom--v-select"
                    ></v-select>
                </div>
            </div>
        </div>
    </v-col>
</template>

<script>
import { validate } from "email-validator";
import loading from "../../../mixins/loading.js";
import { sweetAlertError, sweetAlertSuccess } from "../../../plugins/swal.js";
import { generateCouponCode } from "../../../mixins/generateCouponCode.js";

export default {
    name: "InviteFriendsGiftCode",
    mixins: [loading],

    data () {
        return {
            giftEmail: '',
            giftCouponCode: '',
            priceItems: [5, 10, 15, 20, 25],
            defaultSelected: 25,
        }
    },

    methods: {
        sendGift (amount, email) {
            if (email === this.$store.state.auth.user.email) {
                sweetAlertError("You can't send the gift coupon code to yourself");
                return false;
            }

            this.giftCouponCode = generateCouponCode(amount);

            this.startLoading(async () => {
                const { user_credit } = await axios.post('customer/promos/generate', {
                    amount,
                    email,
                    coupon: this.giftCouponCode
                });

                this.availableAmount = user_credit;
                sweetAlertSuccess('Gift coupon code send successfully to emails');
            })

            this.giftEmail = null;
        },

        validate (email) {
            return validate(email)
        },
    }
}
</script>

<style scoped lang="scss">
.custom--v-select::v-deep {
    border: 1px solid #DDDDDD !important;
    border-radius: 4px !important;

    .v-select__selection--comma {
        margin: 0;
        font-size: 18px !important;
        font-weight: 700 !important;
        color: #0D3659 !important;
    }

    input, .v-input__append-inner, .v-text-field__details {
        display: none !important;
    }

    .v-input__control {
        min-height: 29px !important;
        max-height: 29px !important;
        min-width: 54px !important;
    }

    .v-select__selections {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .v-input__slot {
        box-shadow: none !important;
        background: #F5F5F9 !important;
    }
}

.gift-price span {
    display: inline-block;
    margin-right: 7px;
    font-size: 18px;
    font-weight: 700;
    color: #0D3659;
}
</style>
