<template>
  <div>
      <InviteFriendsEmailsForm />
      <InviteFriendsEmailsTable />
  </div>
</template>

<script>
import InviteFriendsEmailsForm from "./InviteFriendsEmailsForm.vue";
import InviteFriendsEmailsTable from "./InviteFriendsEmailsTable.vue";
import { mapGetters } from "vuex";
export default {
    name: "InviteFriendsEmails",

    components: { InviteFriendsEmailsTable, InviteFriendsEmailsForm },

    computed: {
        ...mapGetters('referrals',['emails'])
    },
}
</script>