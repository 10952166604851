<template>
    <v-main class="custom--v-main">
        <app-header>
          <h3 class="tw-text-[25px] tw-text-[#0D3659] tw-font-semibold">
            Invite a friend
          </h3>
        </app-header>

        <app-customer-start-new-order-slider v-if="isCustomer"/>

        <div class="invite-friend">
            <InviteFriendsHeader />
            <InviteFriendsEmails :emails="referralData.emails" />
        </div>
        <InviteFriendsGenerateReferralCode />
    </v-main>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import InviteFriendsHeader from "./InviteFriendsHeader/InviteFriendsHeader.vue";
import InviteFriendsEmails from "./InviteFriendsEmail/InviteFriendsEmails.vue";
import InviteFriendsGenerateReferralCode
    from "./InviteFriendsGenerateReferralCode/InviteFriendsGenerateReferralCode.vue";

import loading from "../../mixins/loading.js";
import AppCustomerStartNewOrderSlider from '@/components/AppCustomerStartNewOrderSlider.vue';
export default {
    name: "InviteFriendsHome",
    mixins: [ loading ],

    components: {
        AppCustomerStartNewOrderSlider,
        InviteFriendsGenerateReferralCode,
        InviteFriendsEmails,
        InviteFriendsHeader,
    },

    computed: {
        ...mapState('referrals', [ 'referralData' ]),
        ...mapGetters('auth', ['isCustomer']),
    },

    methods: {
        ...mapActions('referrals', [ 'index', 'store' ]),
    },

    created() {
        this.startLoading(async () => await this.index());
    }
}
</script>
