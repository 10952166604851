export default {
    namespaced: true,

    state: {
        new_biddings: [],

        upcoming_deliveries: [],

        /** @type {Order[]} */
        upcoming: [],

        /** @type {Order[]} */
        new_requests: [],
        activeBranchId: null,
        nearestBranch: null,
        detailedDelivery: null
    },

    getters: {
        deliveryOrdersLength: (state) => [...state.new_biddings, ...state.upcoming_deliveries].length,
        movingOrdersLength: (state) => [...state.new_requests, ...state.upcoming].length,
    },

    mutations: {
        SET_ACTIVE_BRANCH_ID(state, payload) {
            state.activeBranchId = payload.id;
        },

        SET_NEAREST_BRANCH(state, payload) {
            state.nearestBranch = payload
        },

        SET_DELIVERY_DETAIL(state, payload) {
            state.detailedDelivery = payload
        }
    },

    actions: {
        /**
         * Load upcoming and new orders for a week
         *
         * @param {Object} state
         * @param {String} branch_id
         * @returns {Promise<void>}
         */
        async load ({ state }, branch_id = 'company') {
            const { upcoming, new_requests } = await axios.get('orders/dashboard', { params: { branch_id } });

            const new_biddings = await axios.get('deliveries/bidding', { params: {branch_id} });
            const upcoming_deliveries = await axios.get('deliveries/upcoming', { params: {branch_id} });

            state.upcoming = upcoming ?? [];
            state.new_requests = new_requests ?? [];

            state.upcoming_deliveries = upcoming_deliveries ?? [];
            state.new_biddings = new_biddings ?? [];
        },

        /**
         * Show details of the order
         *
         * @param {Object} _
         * @param {String} orderId
         * @return {Promise<AxiosResponse<Order>>}
         */
        async show (_, orderId) {
            return await axios.get(`orders/${ orderId }`);
        },

        /**
         * Accept an upcoming order
         *
         * @param {Function} dispatch
         * @param {Number} order_id
         * @returns {Promise<void>}
         */
        async accept ({ dispatch }, order_id) {
            await axios.get(`orders/${ order_id }?status=accept`);
            await dispatch('load');
        },

        /**
         * Reject an upcoming order
         *
         * @param {Function} dispatch
         * @param {Number} order_id
         * @returns {Promise<void>}
         */
        async reject ({ dispatch }, order_id) {
            await axios.get(`orders/${ order_id }?status=reject`);
            await dispatch('load');
        },

        /**
         * Complete an upcoming order
         *
         * @param {Function} dispatch
         * @param {Number} order_id
         * @returns {Promise<void>}
         */
        async complete ({ dispatch }, order_id) {
            await axios.post(`orders/${ order_id }/complete`);
            await dispatch('load');
        },

        /**
         * Reject an accepted order
         *
         * @param {Function} dispatch
         * @param {Number} order_id
         * @returns {Promise<void>}
         */
        async reject_after_accepting ({ dispatch }, payload) {
            const { order_id, reason } = payload;
            await axios.get(`orders/${ order_id }?status=reject_after_accepting`, {
                params: {
                    cancel_reason: reason
                }
            });
            await dispatch('load');
        },

        /**
         * archive (remove) an order
         *
         * @param {Function} dispatch
         * @param {Number} order_id
         * @returns {Promise<void>}
         */
        async archive ({ dispatch }, order_id) {
            await axios.get(`orders/${ order_id }?status=archive`);
            await dispatch('load');
        },

        // Accept delivery
        async acceptDelivery({ state, dispatch }, payload) {
            const { branch_id, delivery_id } = payload;
            await axios.post(`deliveries/accept/${ branch_id }/${ delivery_id }`);
            await dispatch('load', state.activeBranchId);
        },

        // Get nearest branch

        async getNearestBranch({ commit }, payload) {
            const { delivery } = payload;
            const data = await axios.get(`deliveries/nearest-branch/delivery/${ delivery }`);
            commit('SET_NEAREST_BRANCH', data)
        },

        async rejectDelivery ({ state, dispatch }, payload) {
            const { company_id, delivery_id } = payload;
            await axios.post(`deliveries/reject/${ company_id }/${ delivery_id }`);
            await dispatch('load', state.activeBranchId);
        },

        async cancelDelivery ({ state, dispatch }, payload) {
            const { branch_id, delivery_id, reason } = payload;
            await axios.post(`deliveries/cancel/${ branch_id }/${ delivery_id }`, {
                reason: reason
            });
            await dispatch('load', state.activeBranchId);
        },

        async getDetailedDelivery({ commit }, payload) {
            const data = await axios.get(`deliveries/${payload}`)
            if (!data) return;
            commit('SET_DELIVERY_DETAIL', data)
        },

        async completeDelivery({ dispatch }, delivery_id) {
            await axios.post(`deliveries/${ delivery_id }/complete`);
            await dispatch('load', state.activeBranchId);
        },

        async archiveDelivery ({ dispatch }, delivery_id) {
            await axios.post(`deliveries/${delivery_id}/archive`);
            await dispatch('load', state.activeBranchId);
        },
    },
}
