<template>
    <section class="profile-payment">
        <h1 class="profile-payment__heading">
            Set payment methods that you can receive payments from clients. You can add a transaction fee which will be
            paid by the client. The transaction fee can be set both to a flat rate or a percentage.
        </h1>
        <div class="pricing-payment-methods" v-if="isLoaded">
            <ul class="transaction-fee">
                <li>
                    <v-row dense>
                        <v-col lg="3">
                            <strong class="d-block mt-1 text-secondary">Transaction Fee</strong>
                        </v-col>
                        <v-col lg="4">
                            <v-row dense>
                                <v-col lg="6">
                                    <v-text-field
                                        v-model="paymentMethodsFee"
                                        single-line
                                        hide-details
                                        outlined
                                        type="number"
                                        class="custom--v-text-field"
                                    ></v-text-field>
                                </v-col>
                                <v-col lg="6">
                                    <v-select
                                        v-model="paymentMethodsIcon"
                                        :items="feeTypes"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                        outlined
                                        hide-details
                                        class="custom--v-select"
                                    >
                                        <template v-slot:append>
                                            <v-icon color="#aaa" size="10">fa-chevron-right</v-icon>
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>
                            <small style="color: red;" v-if="$v.paymentMethodsFee.$invalid"
                                   v-text="'Only positive numbers'"/>
                        </v-col>
                    </v-row>
                </li>
            </ul>
            <ul class="profile-payment__type">
                <li v-for="item in 2" :key="item">
                    <v-row align="center" dense>
                        <v-col cols="6"><strong class="text-secondary">Payment type</strong>
                        </v-col><v-col cols="3" class="d-flex justify-center"><strong class="text-secondary">No/Yes</strong></v-col>
                    </v-row>
                </li>
            </ul>
            <ul class="profile-payment__field">
                <li v-for="(payment, index) in paymentMethods" :key="payment.uuid">
                    <v-row align="center" dense>
                        <v-col cols="6" class="py-0">
                            <v-text-field
                                v-model.trim="payment.name"
                                single-line
                                hide-details
                                outlined
                                type="text"
                                class="custom--v-text-field"
                                :disabled="!payment.is_active"
                            ></v-text-field>
                            <span v-if="$v.paymentMethods.$invalid && paymentMethods.length === index+1" v-text="nameErrors(payment.name)" />
                        </v-col>
                        <v-col cols="3" class="d-flex justify-center py-0">
                            <v-switch
                                hide-details
                                v-model="payment.is_active"
                            ></v-switch>
                        </v-col>
                        <v-col cols="3" class="py-0">
                            <v-btn
                                fab
                                dark
                                text
                                x-small
                                depressed
                                class="pricing-payment-methods__remove-btn"
                                @click="remove(payment.uuid)"
                            >
                                <v-icon size="24" color="secondary">fa-times</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </li>
            </ul>
            <div class="d-flex justify-end mt-3 mb-10">
                <v-btn
                    class="mr-2"
                    dark
                    depressed
                    color="primary"
                    @click="add"
                >
                    Add payment type
                </v-btn>
                <v-btn
                    dark
                    depressed
                    color="primary"
                    @click="save"
                >
                    Save
                </v-btn>
            </div>
        </div>

        <app-loading-indicator v-else/>
    </section>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { v4 } from 'uuid';
import { reject } from "lodash/collection";
import { remove } from "lodash/array"
import loading from "@/mixins/loading.js";
import AppLoadingIndicator from "@/components/AppLoadingIndicator.vue";
import { maxLength, not, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
    components: {
        AppLoadingIndicator,
    },

    props: {
        activeBranch: Number,
        tabDescription: String,
    },

    data () {
        return {
            feeTypes: [
                { id: '$', name: '$' },
                { id: '%', name: '%' },
            ],

            paymentMethods: [],
            paymentMethodsFee: null,
            paymentMethodsIcon: null
        }
    },

    mixins: [loading, validationMixin],

    validations: {
        paymentMethods: {
            $each: {
                name: {
                    required,
                    maxLength: maxLength(30),
                }
            }
        },
        paymentMethodsFee: {
            not: not((fee) => {
                return fee <= 0;
            })
        }
    },

    methods: {
        ...mapActions('company', ['update', 'load']),
        ...mapMutations('company', ['SET_COMPANY']),

        add () {
            this.paymentMethods.push({
                uuid: v4(),
                name: '',
                is_active: true,
            });
        },

        remove (uuid) {
            this.paymentMethods = reject(this.paymentMethods, { uuid });
        },

        save () {
            remove(this.paymentMethods, method => {
                return ! method.name
            })

            if (! this.$v.$invalid) {
                this.startLoading(async () => {
                    this.SET_COMPANY({
                        'payment_methods': this.paymentMethods,
                        'payment_methods_fee': this.paymentMethodsFee,
                        'payment_methods_icon': this.paymentMethodsIcon
                    });

                    await this.update();
                    await this.loadCompanyData();
                });
            }
        },

        async loadCompanyData () {
            const { payment_methods, payment_methods_fee, payment_methods_icon } = await this.load();

            this.paymentMethods = payment_methods;
            this.paymentMethodsFee = payment_methods_fee;
            this.paymentMethodsIcon = payment_methods_icon ?? '$';
        },

        nameErrors (name) {
            if (name.length > 30) {
                return "Max length is 30 character";
            }

            return "Payment method field is required";
        }
    },

    created () {
        this.startLoading(async () => {
            await this.loadCompanyData();
        });
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/pages/profile/payment";
</style>