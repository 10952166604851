<template>
  <div class="tw-p-[30px_40px]">
    <div class="tw-flex tw-items-center tw-justify-between tw-gap-[15px] tw-pb-[24px] tw-mb-[24px] tw-border-b-[2px] tw-border-solid tw-border-[#E7EFF3]">
      <h3 class="tw-text-[#0D3659] tw-font-bold tw-text-[20px] md:tw-text-[25px]">How can we help?</h3>
      <button @click="$emit('close')" class="tw-flex tw-items-center tw-justify-center tw-w-[30px] md:tw-w-[40px] tw-aspect-square">
        <i class="icony icony-times tw-text-[#0D3659] !tw-w-[60%]"></i>
      </button>
    </div>
    <div class="tw-grid sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-[24px]">
      <div
        v-for="(item, index) in items"
        :key="index"
        @click="navigate(item)"
        class="tw-grid tw-gap-[12px] tw-p-[12px_16px] tw-border tw-border-solid tw-border-[#E7EFF3] tw-rounded-[12px] tw-shadow-[0_6px_15px] tw-shadow-[#C9C9C9]/20 hover:tw-shadow-[#C9C9C9]/50 tw-duration-300 tw-cursor-pointer"
      >
        <div class="tw-text-[#0D3659] tw-font-semibold tw-leading-none">{{ item.title }}</div>
        <div class="tw-flex tw-items-end tw-pt-[15px] tw-border tw-border-dashed tw-border-[#E5E5E5] tw-rounded-[12px]">
          <img :src="'/assets/images/start-new-order/' + item.image" :alt="item.title" class="tw-w-full tw-aspect-[7/3]">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APP_BOOKING_URL } from '@/plugins/consts';
import AppRequestQuote from '@/components/Modals/AppRequestQuote.vue';

export default {
  data() {
    return {
      items: [
        {
          title: 'Apartment Moves',
          image: '1.svg',
          isHelpersOnly: false,
        },
        {
          title: 'Store Delivery',
          image: '2.svg',
          url: APP_BOOKING_URL + '/delivery',
        },
        {
          title: 'Move a Few Items',
          image: '3.svg',
          url: APP_BOOKING_URL + '/delivery',
        },
        {
          title: 'Labor Only',
          image: '4.svg',
          isHelpersOnly: true,
        },
        {
          title: 'Declutter Items',
          image: '5.svg',
          isHelpersOnly: true,
        },
        {
          title: 'Used Item Delivery',
          image: '6.svg',
          url: APP_BOOKING_URL + '/delivery',
        },
        {
          title: 'Small Business Moves',
          image: '7.svg',
          isHelpersOnly: false,
        },
        {
          title: 'Storage Moves',
          image: '8.svg',
          isHelpersOnly: false,
        },
        {
          title: 'Junk Removal',
          image: '9.svg',
          isHelpersOnly: true,
        },
      ]
    }
  },

  methods: {
    navigate(item) {
      if (item.url) {
        // Redirect
        window.location.href = item.url;
      } else {
        // Close current modal
        this.$emit('close');
        // Open request quote modal
        this.$modal.show(AppRequestQuote, {
          isHelpersOnly: item.isHelpersOnly
        }, {
          width: 945,
          height: 640,
        });
      }
    }
  }
}
</script>
