<template>
    <section class="invite-generate">
        <v-row class="invite-generate__row">
            <InviteFriendsShowBalance :balance="balance" />
            <InviteFriendsGenerateCode :prices="getPrices"/>
            <InviteFriendsGiftCode />
        </v-row>
    </section>
</template>

<script>
import InviteFriendsShowBalance from "./InviteFriendsShowBalance.vue";
import { mapGetters } from "vuex";
import InviteFriendsGenerateCode from "./InviteFriendsGenerateCode.vue";
import InviteFriendsGiftCode from "./InviteFriendsGiftCode.vue";
export default {
    name: "InviteFriendsGenerateReferralCode",
    components: { InviteFriendsGiftCode, InviteFriendsGenerateCode, InviteFriendsShowBalance },

    data () {
        return {
            couponCode: null,
            couponPrice: null,
            availableAmount: null,
        }
    },

    created() {
        const { coupon, amount } = this.$store.state.auth.user;

        this.couponCode = coupon ?? null;
        this.couponPrice = amount ?? 0;
    },

    computed: {
        ...mapGetters('referrals', ['prices']),

        balance () {
            return {
                pricesPending: this.prices.pending,
                priceAvailable: this.prices.available,
                availableAmount: this.availableAmount
            }
        },

        getPrices () {
            return {
                couponPrice: this.couponPrice,
                availablePrice: this.prices.available
            }
        }
    },
}
</script>
