import VueRouter from 'vue-router';
import store from "../store";
import routes, { isGuestRoute, saveRedirectUrl } from "./routes";

const router = new VueRouter({
    routes,
    mode: 'history',
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters["auth/isAuthenticated"];
    const isCustomer = store.getters["auth/isCustomer"];
    const isLabor = store.getters["auth/isLabor"];

    let name;

    if (isAuthenticated && isGuestRoute(to.name)) {

        if(isCustomer) {
            name = 'user-dashboard';
        } else if (isLabor) {
            name = 'labor-dashboard';
        } else {
            name = 'dashboard';
        }

        next({ name });
    }

    if (! isAuthenticated && ! isGuestRoute(to.name)) {
        saveRedirectUrl();

        next({ name: 'login' });
    }

    if (!isCustomer && to.name === 'deliveries-tab' && isLabor) {
        next({ name: 'labor-dashboard' });
    }

    if (isCustomer && to.name === 'deliveries-tab') {
        next({ name: 'user-dashboard' });
    }

    next();
});

export default router;
