<template>
    <div class="operating-window">
        <p class="charge-text__info text-secondary mb-5">Operating window</p>
        <v-simple-table class="custom--v-simple-table">
            <tbody>
            <tr>
                <td>
                    You will receive orders from customers for the period of next
                </td>
                <td>
                    <div class="d-flex justify-end">
                        <v-text-field
                            v-model="accept_in_period_of"
                            single-line
                            hide-details
                            outlined
                            type="text"
                            class="custom--v-text-field"
                            v-maska="'####'"
                        ></v-text-field>
                        <span style="margin: 3px 0 3px 10px;">days</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Would you like to receive orders for the next day
                </td>
                <td>
                    <div class="d-flex justify-end">
                        <span style="margin: 3px 15px 3px 0;">No</span>
                        <v-switch
                            v-model="accept_next_day_move"
                            hide-details
                            class="operating-window__switch"
                            style="margin: 0 0 5px 0;"
                        ></v-switch>
                        <span style="margin: 3px 0;">Yes</span>
                    </div>
                </td>
            </tr>
            </tbody>
        </v-simple-table>
        <div class="d-flex justify-end mt-5">
            <v-btn
                color="primary"
                elevation="0"
                width="133"
                class="px-10 mt-2"
                @click="save"
                :loading="isLoading"
            >
                Save
            </v-btn>
        </div>
    </div>
</template>

<script>
import loading from "@/mixins/loading.js";
import { maska } from "maska";

export default {
    mixins: [loading],

    data () {
        const { accept_next_day_move, accept_in_period_of } = this.$store.state.auth.company;

        return {
            accept_next_day_move,
            accept_in_period_of
        };
    },

    directives: {
        maska
    },

    methods: {
        save () {
            this.startLoading(async () => {
                await this.$store.dispatch('company/patch', {
                    accept_next_day_move: this.accept_next_day_move,
                    accept_in_period_of: this.accept_in_period_of
                });

                await this.$store.dispatch('auth/check');
            });
        },
    },
}
</script>

<style scoped lang="scss">
.custom--v-text-field {
    background-color: #F5F5F9;;
    max-width: 47px;
    height: 27px;

    ::v-deep {

        .v-input__slot {
            height: 27px !important;
            min-height: 27px !important;

            .v-text-field__slot {

                input {
                    text-align: center;
                }
            }
        }
    }
}
.custom--v-switch {
    
}
</style>