<template>
    <div class="profile-company__info">
        <form v-if="isLoaded">
            <div class="tw-relative tw-w-[120px] tw-aspect-square tw-mb-[40px]">
                <v-avatar size="120" color="primary">
                    <img v-if="branchInfo.logo" :src="branchInfo.logo" alt="logo">

                    <v-icon v-else dark size="40">
                        fa-user
                    </v-icon>
                </v-avatar>

                <div @click="upload" class="tw-flex tw-absolute -tw-bottom-[5px] -tw-right-[12px] tw-z-10 tw-cursor-pointer">
                    <i class="icony icony-camera tw-min-w-[42px] tw-text-[#0D3659]"></i>
                </div>
            </div>

            <v-row class="profile-company__row">
                <v-col cols="12" lg="6">
                    <p>Company name</p>
                    <v-text-field
                        v-model="branchInfo.company_name"
                        class="custom--v-text-field"
                        outlined
                        dense
                        disabled
                        placeholder="Write your company name"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                    <p>Branch name</p>
                    <v-tooltip top max-width="300" class="pa-1" content-class="charge-text__tooltip">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-bind="attrs"
                                v-on="on"
                                v-model="branchInfo.name"
                                class="custom--v-text-field"
                                outlined
                                dense
                                placeholder="Write your branch name"
                                :error-messages="branchNameErrors"
                                @input="$v.branchInfo.name.$touch()"
                                @blur="$v.branchInfo.name.$touch()"
                            >
                            </v-text-field>
                        </template>
                        <span>{{ branchInfo.name.three_dots(300) }}</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="12" lg="6">
                    <p>Phone number</p>
                    <v-text-field
                        v-model="branchInfo.primary_phone"
                        class="custom--v-text-field"
                        outlined
                        dense
                        placeholder="Your number"
                        v-maska="phone_mask"
                        :error-messages="phoneNumberErrors"
                        @input="$v.branchInfo.primary_phone.$touch()"
                        @blur="$v.branchInfo.primary_phone.$touch()"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                    <p>Email</p>
                    <v-text-field
                        v-model="branchInfo.email"
                        class="custom--v-text-field"
                        outlined
                        dense
                        disabled
                        placeholder="Your email"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                    <p>Branch address</p>
                    <v-tooltip top content-class="charge-text__tooltip">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-bind="attrs"
                                v-on="on"
                                v-model="branchInfo.address"
                                class="custom--v-text-field"
                                outlined
                                dense
                                placeholder="Your branch address"
                                :error-messages="addressErrors"
                                @input="$v.branchInfo.address.$touch()"
                                @blur="$v.branchInfo.address.$touch()"
                            >
                            </v-text-field>
                        </template>
                        <span>{{ branchInfo.address.three_dots(300) }}</span>
                    </v-tooltip>
                </v-col>
                <v-col class="branch-info__website" cols="12" lg="6">
                    <p>Website</p>
                    <v-text-field
                        v-model="branchInfo.website"
                        class="custom--v-text-field"
                        outlined
                        dense
                        disabled
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                    <p>Your founded</p>
                    <v-text-field
                        :value="branchInfo.found_date"
                        class="custom--v-text-field"
                        outlined
                        dense
                        disabled
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                    <p>Number of employees</p>
                    <v-text-field
                        v-model="branchInfo.employees_count"
                        type="number"
                        class="custom--v-text-field"
                        outlined
                        dense
                        placeholder="Number of employees"
                        :error-messages="employeesErrors"
                        @input="$v.branchInfo.employees_count.$touch()"
                        @blur="$v.branchInfo.employees_count.$touch()"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-btn color="primary" class="custom--v-btn" min-width="133" @click="submit">Save</v-btn>
                </v-col>
            </v-row>
        </form>
        <app-loading-indicator v-else/>
    </div>
</template>

<script>
import AppProfilePicture from "@/components/AppProfilePicture.vue";
import { validationMixin } from "vuelidate";
import { required, minValue, numeric, minLength, maxLength } from "vuelidate/lib/validators";
import loading from "@/mixins/loading.js";
import { mapActions, mapGetters } from "vuex";
import { maska } from "maska"
import { phone_mask } from "@/plugins/custom_masks.js";
import AppImageCropper from "@/components/AppImageCropper.vue";
import AppLoadingIndicator from "@/components/AppLoadingIndicator.vue";
import { omit } from "lodash/object";

export default {
    name: "CompanyInfo",

    components: { AppLoadingIndicator, AppProfilePicture, AppImageCropper },

    mixins: [validationMixin, loading],

    directives: { maska },

    validations: {
        branchInfo: {
            name: { required, maxLength: maxLength(60) },
            address: { required, maxLength: maxLength(60) },
            primary_phone: { required, minLength: minLength(21) },
            employees_count: { required, minValue: minValue(1), numeric, maxLength: maxLength(7) }
        }
    },

    data () {
        return {
            phone_mask
        }
    },

    computed: {
        ...mapGetters('profile', ['branchInfo']),

        branchNameErrors () {
            const errors = [];
            if (! this.$v.branchInfo.name.$dirty) return errors;
            ! this.$v.branchInfo.name.required && errors.push('Branch name is required');
            ! this.$v.branchInfo.name.maxLength && errors.push('Max length should be 60 characters');

            return errors;
        },

        addressErrors () {
            const errors = [];
            if (! this.$v.branchInfo.address.$dirty) return errors;
            ! this.$v.branchInfo.address.required && errors.push('Branch address is required');
            ! this.$v.branchInfo.address.maxLength && errors.push('Max length should be 60 characters');

            return errors;
        },

        phoneNumberErrors () {
            const errors = [];
            if (! this.$v.branchInfo.primary_phone.$dirty) return errors;
            ! this.$v.branchInfo.primary_phone.required && errors.push('Phone number is required');
            ! this.$v.branchInfo.primary_phone.minLength && errors.push('Invalid phone number');

            return errors
        },

        employeesErrors () {
            const errors = [];
            if (! this.$v.branchInfo.employees_count.$dirty) return errors;
            ! this.$v.branchInfo.employees_count.required && errors.push('Number of employees field is required');
            ! this.$v.branchInfo.employees_count.minValue && errors.push('Number of employees is invalid');
            ! this.$v.branchInfo.employees_count.numeric && errors.push('Number of employees is invalid');
            ! this.$v.branchInfo.employees_count.maxLength && errors.push('Number of employees is invalid');

            return errors
        }
    },

    methods: {
        ...mapActions('profile', ['load', 'update']),

        submit () {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            const data = omit(this.branchInfo, ['website', 'logo']);

            this.update(data);
            return true;
        },

        upload () {
            this.$modal.show(AppImageCropper, {
                image: this.branchInfo.logo,
                name: 'profile'
            }, {
                width: 480,
                height: "auto"
            }, {
                closed: async () => {
                    await this.load();
                }
            })
        },
    },

    created () {
        this.startLoading(async () => {
            await this.load();
        })
    }
}
</script>

<style scoped lang="scss">
.branch-info__website {
    cursor: pointer;

    a {
        text-decoration: none;
    }
}

::v-deep {
    .custom--v-btn {
        background: none !important;
    }

    .custom--v-text-field {
        .v-input__slot {
            background: #F5F5F9;
            border-radius: 8px;
            min-height: 50px;
            overflow-x: auto;
        }

        input::placeholder, input {
            font-size: 14px;
            line-height: 21px;
            color: #0c3659;
            font-weight: 500;
        }

        fieldset {
            border: none !important;
        }
    }

    .col-xl-6, .col, .col-sm-12, .col-lg-6 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.charge-text__tooltip {
    word-break: break-all;
}

</style>
