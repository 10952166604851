<template>
    <div class="tw-flex max-md:tw-flex-col tw-gap-[15px] md:tw-items-center tw-justify-between">
        <div>
            <div class="tw-flex max-md:tw-flex-col tw-gap-[20px] lg:tw-gap-[65px]">
                <div class="tw-flex tw-items-start tw-gap-[10px]">
                    <i class="icony icony-calendar tw-w-[14px] tw-max-w-[14px] tw-min-w-[14px] tw-mt-[2px] tw-text-[#8F3985]"></i>
                    <div class="tw-flex tw-w-full tw-flex-col tw-text-[14px] tw-leading-snug">
                        <span v-text="'Date of delivery:'"/>
                        <strong v-text="delivery.arrival_date"/>
                    </div>
                </div>
                <div class="tw-flex tw-items-start tw-gap-[5px]">
                    <i class="icony icony-clock-circle tw-w-[14px] tw-max-w-[14px] tw-min-w-[14px] tw-mt-[2px] tw-text-[#8F3985]"></i>
                    <div class="tw-flex tw-w-full tw-flex-col tw-text-[14px] tw-leading-snug">
                        <span v-text="'Show up time:'"/>
                        <strong v-text="delivery.arrival_time"/>
                    </div>
                </div>
                <div class="tw-flex tw-items-start tw-gap-[10px]">
                    <i class="icony icony-dollar-circle tw-w-[14px] tw-max-w-[14px] tw-min-w-[14px] tw-mt-[2px] tw-text-[#8F3985]"></i>
                    <div class="tw-flex tw-w-full tw-flex-col tw-text-[14px] tw-leading-snug">
                        <span v-text="'Order price:'"/>
                        <strong v-if="isCustomer" v-text="'$' + delivery.price"/>
                        <strong v-else v-text="'$' + delivery.price"/>
                    </div>
                </div>
            </div>
        </div>

        <user-dashboard-delivery-card-actions :delivery="delivery"/>
    </div>
</template>

<script>
import UserDashboardDeliveryCardActions from '@/views/UserDashboard/UserDashboardDeliveryCardActions.vue';

export default {
    props: {
        delivery: {
            type: Object,
            required: true,
        }
    },

    components: {
        UserDashboardDeliveryCardActions
    },
};
</script>
