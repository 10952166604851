<template>
    <v-row>
        <v-col lg="5">
            <v-card
                outlined
            >
                <v-date-picker
                    v-model="picker"
                    full-width
                ></v-date-picker>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {

    data () {
        return {
            picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        }
    },
}
</script>


<style lang="scss" scoped>
::v-deep {

    // Date picker
    .v-picker {
        border: 0;

        .v-date-picker-header {

            .v-icon {
                font-size: 14px;
            }
        }

        .v-picker__title__btn {
            font-size: 20px;
        }

        .v-date-picker-table {
            height: 210px;
        }
    }
}
</style>