var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-bg-white tw-p-[10px]"},[_c('div',{staticClass:"tw-grid tw-grid-cols-3 tw-gap-[16px]"},[_c('div',{staticClass:"tw-col-span-2"},[_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-[16px]"},[_c('div',{staticClass:"tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[12px]"},[_c('strong',{staticClass:"tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]"},[_vm._v("Customer info")]),_c('ul',{staticClass:"tw-list-none !tw-p-0 tw-m-0"},_vm._l((_vm.moverInfo),function(ref,index){
var label = ref.label;
var value = ref.value;
return _c('li',{key:index,staticClass:"tw-flex tw-justify-between tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]"},[_c('span',{staticClass:"tw-text-[#aaaaaa]",domProps:{"textContent":_vm._s(label)}}),_c('span',{staticClass:"tw-text-[#6B6D81]",domProps:{"textContent":_vm._s(value)}})])}),0)]),_c('div',{staticClass:"tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[12px]"},[_c('strong',{staticClass:"tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]"},[_vm._v("Pickup address")]),_c('ul',{staticClass:"tw-list-none !tw-p-0 tw-m-0"},_vm._l((_vm.pickup),function(ref,index){
var label = ref.label;
var value = ref.value;
return _c('li',{key:index,staticClass:"tw-flex tw-justify-between tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]"},[_c('span',{staticClass:"tw-text-[#aaaaaa]",domProps:{"textContent":_vm._s(label)}}),_c('span',{staticClass:"tw-text-[#6B6D81]",domProps:{"textContent":_vm._s(value)}})])}),0)]),_c('div',{staticClass:"tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[12px]"},[_c('strong',{staticClass:"tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]"},[_vm._v("Note")]),_c('div',{staticClass:"tw-text-[#6B6D81] tw-font-medium tw-mb-[5px] tw-text-[14px]"},[_vm._v(_vm._s(_vm.deliveryDetails.notes || 'There is no notes!'))])]),_c('div',{staticClass:"tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[12px]"},[_c('strong',{staticClass:"tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]"},[_vm._v("Destination address")]),_c('ul',{staticClass:"tw-list-none !tw-p-0 tw-m-0"},_vm._l((_vm.destination),function(ref,index){
var label = ref.label;
var value = ref.value;
return _c('li',{key:index,staticClass:"tw-flex tw-justify-between tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]"},[_c('span',{staticClass:"tw-text-[#aaaaaa]",domProps:{"textContent":_vm._s(label)}}),_c('span',{staticClass:"tw-text-[#6B6D81]",domProps:{"textContent":_vm._s(value)}})])}),0)])])]),_c('div',[_c('div',{staticClass:"tw-h-full tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[12px]"},[_c('strong',{staticClass:"tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]"},[_vm._v("Item info")]),_c('ul',{staticClass:"tw-list-none !tw-p-[0_0_15px_0] last:!tw-pb-0 tw-mb-[15px] last:tw-mb-0 tw-border-b-[1px] tw-border-solid tw-border-[#e4e9ff] last:tw-border-b-0"},[_c('li',{staticClass:"tw-flex tw-justify-between tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]"},[_c('span',{staticClass:"tw-text-[#aaaaaa]",domProps:{"textContent":_vm._s('Items:')}}),_c('span',{staticClass:"tw-text-[#6B6D81]",domProps:{"textContent":_vm._s(_vm.deliveryDetails.items_name)}})])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }