<template>
    <section class="credentials__footer">
        <div class="d-flex align-center">
            <div class="__heading d-flex align-center">
                <h2>Uniformed Crew (optional)</h2>
                <v-tooltip top content-class="charge-text__tooltip">
                    <template v-slot:activator="{ on, attrs }">
                        <img v-bind="attrs" v-on="on" src="assets/icons/info.svg" alt="info icon" width="12"
                             class="ml-1">
                    </template>
                    <span class="custom--v-tooltip">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aut delectus, dignissimos ex id quod?
                            </span>
                </v-tooltip>
            </div>
            <v-radio-group v-model="uniformed_crew_data" @change="onChanged(uniformed_crew_data, 'uniformed_crew');"
                           class="custom--v-radio-group justify-end ml-auto">
                <v-radio :value="true" class="custom--v-radio">
                    <template v-slot:label>
                        <div class="invite-reviewer__radio">Yes</div>
                    </template>
                </v-radio>
                <v-radio :value="false" class="custom--v-radio">
                    <template v-slot:label>
                        <div class="invite-reviewer__radio">No</div>
                    </template>
                </v-radio>
            </v-radio-group>
        </div>
        <div class="d-flex align-center">
            <div class="__heading d-flex align-center">
                <h2>Drug Test (optional)</h2>
                <v-tooltip top content-class="charge-text__tooltip">
                    <template v-slot:activator="{ on, attrs }">
                        <img v-bind="attrs" v-on="on" src="assets/icons/info.svg" alt="info icon" width="12"
                             class="ml-1">
                    </template>
                    <span class="custom--v-tooltip">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aut delectus, dignissimos ex id quod?
                            </span>
                </v-tooltip>
            </div>
            <v-radio-group @change="onChanged(drug_test_data, 'drug_test');" v-model="drug_test_data"
                           class="custom--v-radio-group justify-end ml-auto">
                <v-radio :value="true" class="custom--v-radio">
                    <template v-slot:label>
                        <div class="invite-reviewer__radio">Yes</div>
                    </template>
                </v-radio>
                <v-radio :value="false" class="custom--v-radio">
                    <template v-slot:label>
                        <div class="invite-reviewer__radio">No</div>
                    </template>
                </v-radio>
            </v-radio-group>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "CredentialsDrugTest",

    props: {
        branchId: Number
    },

    data () {
        return {
            uniformed_crew_data: false,
            drug_test_data: false
        }
    },

    computed: {
        ...mapGetters('credentials', ['uniformed_crew', 'drug_test'])
    },

    methods: {
        ...mapActions('credentials', ['update']),

        onChanged (value, value_name) {
            let data = 0;
            value ? data = 1 : data = 0;

            this.update({
                data_name: value_name,
                data
            })
        }
    },

    created () {
        if (this.uniformed_crew) {
            this.uniformed_crew_data = this.uniformed_crew;
        }
        if (this.drug_test) {
            this.drug_test_data = this.drug_test;
        }
    }
}
</script>

<style scoped>

</style>