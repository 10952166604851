<template>
    <v-app-bar
        fixed height="62"
        color="#FFF" elevation="0"
        class="custom--v-app-bar"
    >
        <main class="main-header w-100">
            <div class="header">
<!--                mobile header-->
                <div class="header__item mobile--header d-flex align-center">
                    <v-btn
                        v-if="$route.path === '/' | $route.path === '/user-dashboard'"
                        @click.stop="$store.commit('switch')"
                        elevation="0"
                        min-width="40"
                        min-height="40"
                        max-width="40"
                        color="light"
                        class="mobile-profile-menu__btn">
                        <v-img src="/assets/images/mobile/burger-menu.svg" alt="menu logo"/>
                    </v-btn>

                    <v-btn
                        v-else
                        @click.stop="$router.go(-1)"
                        elevation="0"
                        min-width="40"
                        min-height="40"
                        max-width="40"
                        color="light"
                        class="mobile-profile-menu__btn m-0">
                        <v-icon size="14" color="primary">fa-chevron-left</v-icon>
                    </v-btn>
                    <v-img src="/assets/images/logos/login-logo.svg" alt="logo" class="mobile--header__logo mx-auto" height="30"
                           max-width="128"/>
                    <div class="mobile-notification">
                        <app-notification-bar/>
                    </div>
                </div>
                <div class="header__item" v-if="$slots.default">
                    <slot/>
                </div>
<!--                        notifications-->
                <div class="header__item">
                    <slot name="right"/>
                    <app-notification-bar/>
                </div>
            </div>
        </main>
    </v-app-bar>
</template>

<script>
import AppNotificationBar from "@/components/AppNotificationBar.vue";

export default {
    components: {
        AppNotificationBar
    },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/config/variables";

.main-header {
    width: 100% !important;
    padding: 5px 20px;

    @media (min-width: 980px) {
        padding: 5px 12px;
    }
}

.v-app-bar__nav-icon {
    @media (min-width: 1264px) {
        display: none;
    }
}


//mobile header
.mobile--header {
    i {
        color: $secondary;
    }
    i:active {
        color: $primary;
    }
}

@media (min-width: 767px) {
    .mobile--header__logo {
        display: none;
    }
}

.mobile-notification {
    @media (min-width: 767px) {
        display: none;
    }
    @media (max-width: 768px) {
        display: block;
    }
}

@media (max-width: 768px) {
    .mobile--header {
        width: 100%;
    }
    .header__item:nth-child(3) {
        display: none;
    }
    .header__item:nth-child(2) {
        margin-top: 20px !important;
    }
}

@media (max-width: 990px) {
    .header__item:nth-child(2) {
        max-width: 100%;
        margin-top: 20px;
    }
}

@media (max-width: 400px) {
    .header__item:nth-child(2) {
        max-width: 360px;
        margin-top: 20px;
    }
}

.custom--v-app-bar {
    @media (max-width: 990px) {
        width: 100%;
        margin-left: 0;
        box-shadow: none !important;
        border: none !important;
    }
    .v-toolbar__content {
        max-width: 100% !important;
    }
}
</style>