import Dashboard from "../views/Dashboard/Dashboard";
import Calendar from "../views/Calendar/Calendar";
import Login from "../views/Login/Login";
import ForgotPassword from "../views/ForgotPassword/ForgotPassword.vue";
import Locations from "../views/Locations/Locations.vue";
import Profile from "../views/Profile/Profile.vue";
import Orders from "../views/Orders/Orders.vue";
import Pricing from "../views/Pricing/Pricing.vue";
import Hours from "../views/Options/Options.vue";
import Teams from "../views/Team/Teams.vue";
import TeamMember from "../views/Team/TeamMember.vue";
import InviteFriends from "../views/InviteFriends/InviteFriends.vue";
import ReferralSignUp from "@/views/SignUp/SignUp.vue";
import ViewCompany from "../views/Profile/ViewCompany.vue";
import DeliveriesOrderTab from '../views/Dashboard/DeliveriesOrderTab.vue'
import MovingsOrdersTab from '../views/Dashboard/MovingsOrdersTab.vue'

// Customer Pages
import UserDashboard from "../views/UserDashboard/UserDashboard.vue";
import UserAccount from "../views/UserAccount/UserAccount.vue";
import UserLogout from "../views/UserLogout/UserLogout";
import Reviews from "@/views/Reviews/Reviews.vue";
import ChooseBranch from "../components/ChooseBranch.vue";


// Labor pages
import LaborDashboard from "../views/LaborDashboard/Dashboard.vue";
import Earnings from "../views/LaborDashboard/Earnings.vue";
import Settings from "../views/LaborDashboard/Settings.vue";

const helperRoutes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        redirect: () => ({ name: 'deliveries-tab'}),
        children: [
            {
                path: 'deliveries',
                name: 'deliveries-tab',
                component: DeliveriesOrderTab,
            },
            {
                path: 'movings',
                name: 'movings-tab',
                component: MovingsOrdersTab,
            },
        ]
    },
    { path: '/calendar', component: Calendar, name: 'calendar' },
    { path: '/login', component: Login, name: 'login' },
    { path: '/forgot-password', component: ForgotPassword, name: 'forgot-password' },
    { path: '/locations', component: Locations, name: 'locations' },
    { path: '/choose-branch', component: ChooseBranch, name: 'choose-branch'},
    { path: '/profile', component: Profile, name: 'profile' },
    { path: '/profile/:slug', component: ViewCompany, name: 'view-company'},
    { path: '/orders', component: Orders, name: 'orders' },
    { path: '/pricing', component: Pricing, name: 'pricing' },
    { path: '/options', component: Hours, name: 'options' },
    { path: '/teams/:branch_id?', component: Teams, name: 'teams' },
    { path: '/teams/:branch_id/:crew_id', component: TeamMember, name: 'team-member' },
    { path: '/sign-up', component: ReferralSignUp, name: 'sign-up'}
];

const customerRoutes = [
    { path: '/user-dashboard', component: UserDashboard, name: 'user-dashboard' },
    { path: '/user-account', component: UserAccount, name: 'user-account' },
    { path: '/user-logout', component: UserLogout, name: 'user-logout' },
    { path: '/reviews', component: Reviews, name: 'reviews' }
];

const laborRoutes = [
    { path: '/labor-dashboard', component: LaborDashboard, name: 'labor-dashboard' },
    { path: '/labor-earnings', component: Earnings, name: 'labor-earnings' },
    { path: '/labor-settings', component: Settings, name: 'labor-settings' }
];

export default [
    ...helperRoutes,
    ...customerRoutes,
    ...laborRoutes,
    { path: '/invite-friends', component: InviteFriends, name: 'user-invite-people' },
];

export const isGuestRoute = routeName => routeName && ['login', 'forgot-password', 'sign-up'].some(route => routeName.includes(route));

/**
 * Sometimes in the application, we will need to directly open some url which came into our email
 * It is ok, if user is already logged in. But if he is not logged in, we need to save url, so
 * we can visit it after authentication.
 */
export const saveRedirectUrl = () => {
    localStorage.setItem('redirect-after-login', window.location.href);
}

export const useRedirectUrl = () => {
    if (localStorage.getItem('redirect-after-login')) {
        window.location.href = localStorage.getItem('redirect-after-login');
        localStorage.removeItem('redirect-after-login');
        return true;
    }

    return false;
}
