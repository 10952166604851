<template>
    <section class="review-edit pa-10">
        <header class="d-flex justify-end mb-8">
            <h1 class="review-edit__header text-secondary font-semibold">Edit</h1>
            <v-btn icon class="review-edit__close ml-auto" @click="close">
                <img src="assets/icons/delete.svg" alt="x-icon"/>
            </v-btn>
        </header>
        <main>

            <div class="review-edit__action">
                <p>Select stars</p>
                <v-rating
                    half-icon="fa-star-half-alt"
                    size="18"
                    color="#FBAB1E"
                    background-color="#FBAB1E"
                    class="custom--v-rating mb-5"
                    :value="editDetails.stars"
                    v-model.trim="editDetails.stars"
                />

                <p>Edit comment</p>
                <v-textarea
                    rows="6"
                    autofocus
                    auto-grow
                    dense
                    outlined
                    class="custom--v-textarea"
                    v-model.trim="editDetails.comment"
                >
                </v-textarea>
            </div>
        </main>
        <footer class="review-edit__btn d-flex justify-end">
            <v-btn
                width="100" height="32"
                style="font-size: 14px; font-weight: 500; border-radius: 4px;"
                :style="$v.editDetails.$invalid ? '' : 'background-color: #8F3985'"
                color="primary"
                class="mr-3"
                :disabled="$v.editDetails.$invalid"
                @click="editReview"
            >
                Edit
            </v-btn>
            <v-btn
                width="100" height="32"
                color="secondary"
                style="font-size: 14px; font-weight: 500; border-radius: 4px"
                outlined
                @click="close"
            >Cancel
            </v-btn>
        </footer>
    </section>
</template>

<script>
import AppRating from "@/components/AppRating.vue";
import { mapActions } from "vuex/dist/vuex.esm.browser";
import loading from "@/mixins/loading.js";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
    name: "AppEditReview",

    mixins: [ loading, validationMixin ],

    components: { AppRating },

    props: {
        editDetails: {
            type: Object,
            required: true
        },
        isCustomer: {
            type: Boolean,
            required: true
        }
    },

    validations: {
        editDetails: {
            stars: {
                required
            },
            comment: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(1500)
            }
        }
    },

    methods: {
        ...mapActions('review', [ 'helper_edit', 'customer_edit' ]),

        close() {
            this.$emit('close')
        },

        editReview() {
            this.startLoading(async () => {
                const data = {
                    review_id: this.editDetails.id,
                    star: this.editDetails.stars,
                    comment: this.editDetails.comment
                };

                if (this.isCustomer) {
                    await this.customer_edit(data)
                } else {
                    await this.helper_edit(data)
                }

                this.close();
            })
        }
    },
}
</script>
