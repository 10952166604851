import Vue from 'vue';
import Vuetify from 'vuetify';
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify);

export default new Vuetify({
    vuetify: {
        treeShake: true
    },
    breakpoint: {
        thresholds: {
            xs: 340,
            sm: 540,
            md: 768,
            lg: 1280,
        },
        scrollBarWidth: 24,
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#8F3985',
                secondary: '#0D3659',
                light: '#E7EFF3',
                gray: '#F6F6F6',
                grayLight: '#aaaaaa',
                white: '#FFFFFF',
            },
        },
    },
    icons: {
        iconfont: 'fa',
    },
});
