<template>
    <div>
        <ShareNetwork
            :network="sharingLink.network"
            :url="sharingLink.url"
            :title="sharingLink.title"
            :class="[ sharingLink.className, 'invite-link__media d-flex align-center justify-center']"
            hashtags="Tophelpers,Mover,referral_link"
        >
            <svg v-if="sharingLink.network === 'facebook'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 10C20 14.9914 16.343 19.1285 11.5625 19.8785V12.8906H13.8926L14.3359 10H11.5625V8.12422C11.5625 7.3332 11.95 6.5625 13.1922 6.5625H14.4531V4.10156C14.4531 4.10156 13.3086 3.90625 12.2145 3.90625C9.93047 3.90625 8.4375 5.29062 8.4375 7.79687V10H5.89844V12.8906H8.4375V19.8785C3.65703 19.1285 0 14.9914 0 10C0 4.47734 4.47734 0 10 0C15.5227 0 20 4.47734 20 10Z" fill="#1877F2"/>
                <path d="M13.8926 12.8906L14.3359 10H11.5625V8.12418C11.5625 7.33336 11.9499 6.5625 13.1921 6.5625H14.4531V4.10156C14.4531 4.10156 13.3088 3.90625 12.2146 3.90625C9.93043 3.90625 8.4375 5.29063 8.4375 7.79688V10H5.89844V12.8906H8.4375V19.8785C8.94664 19.9584 9.46844 20 10 20C10.5316 20 11.0534 19.9584 11.5625 19.8785V12.8906H13.8926Z" fill="white"/>
            </svg>

            <svg v-if="sharingLink.network === 'twitter'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9762 7.96087C13.98 8.04899 13.9825 8.13712 13.9825 8.22649C13.9825 10.9371 11.9187 14.0634 8.145 14.0634C6.98625 14.0634 5.90812 13.724 5 13.1421C5.16062 13.1609 5.32375 13.1702 5.48938 13.1702C6.45062 13.1702 7.335 12.8427 8.0375 12.2921C7.13937 12.2759 6.38188 11.6827 6.12063 10.8677C6.24625 10.8915 6.37438 10.904 6.50688 10.904C6.69375 10.904 6.875 10.879 7.0475 10.8321C6.10875 10.644 5.40187 9.81462 5.40187 8.82087V8.79462C5.67812 8.94837 5.995 9.04087 6.33125 9.05149C5.78062 8.68337 5.41812 8.05524 5.41812 7.34399C5.41812 6.96774 5.51938 6.61524 5.69625 6.31212C6.70813 7.55337 8.22 8.37024 9.925 8.45587C9.89 8.30587 9.87187 8.14899 9.87187 7.98837C9.87187 6.85524 10.7906 5.93649 11.9231 5.93649C12.5138 5.93649 13.0469 6.18587 13.4206 6.58462C13.8881 6.49274 14.3275 6.32212 14.7238 6.08712C14.5706 6.56587 14.245 6.96774 13.8219 7.22212C14.2369 7.17212 14.6319 7.06212 15 6.89899C14.725 7.31024 14.3769 7.67149 13.9762 7.96087ZM10 0.000244141C4.47688 0.000244141 0 4.47712 0 10.0002C0 15.5227 4.47688 20.0002 10 20.0002C15.5231 20.0002 20 15.5227 20 10.0002C20 4.47712 15.5231 0.000244141 10 0.000244141" fill="#41A1F2"/>
            </svg>

            <svg v-if="sharingLink.network === 'linkedin'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#007AB9"/>
                <path d="M15.974 10.8047V14.9275H13.5837V11.081C13.5837 10.1152 13.2386 9.45567 12.3732 9.45567C11.7127 9.45567 11.3203 9.89971 11.1471 10.3297C11.0842 10.4833 11.0679 10.6967 11.0679 10.9122V14.9273H8.67747C8.67747 14.9273 8.70956 8.41267 8.67747 7.73831H11.0681V8.75707C11.0633 8.76509 11.0565 8.77293 11.0522 8.7806H11.0681V8.75707C11.3858 8.26828 11.9523 7.5695 13.2224 7.5695C14.795 7.5695 15.974 8.59699 15.974 10.8047ZM6.17685 4.27295C5.35917 4.27295 4.82422 4.80969 4.82422 5.51488C4.82422 6.2051 5.34367 6.75735 6.14548 6.75735H6.16099C6.99471 6.75735 7.51308 6.2051 7.51308 5.51488C7.49722 4.80969 6.99471 4.27295 6.17685 4.27295ZM4.96629 14.9275H7.35586V7.73831H4.96629V14.9275Z" fill="#F1F2F2"/>
            </svg>

            <span v-text="sharingLink.network"/>
        </ShareNetwork>
    </div>
</template>

<script>
export default {
    name: "InviteFriendsShareSocialCard",

    props: {
        sharingLink: {
            network: {
                type: String,
                required: true
            },
            url: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                required: true
            },
            className: {
                type: String,
                required: true
            }
        }
    }
}
</script>
<style lang="scss">
 .div span {
     text-transform: lowercase;
 }
</style>
