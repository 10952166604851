export default {
    namespaced: true,

    actions: {
        /**
         * Load service prices of a specific branch
         *
         * @param {Object} _
         * @param {Number} branch_id
         *
         * @returns {Promise}
         */
        async index (_, branch_id) {
            return await axios.get(`branches/${ branch_id }/services`);
        },

        /**
         * Create a new service price
         *
         * @param {Object} _
         * @param {Number} branch_id
         * @param {ServicePrice} data
         * @returns {Promise}
         */
        async store (_, [branch_id, data]) {
            return await axios.post(`branches/${ branch_id }/services`, data);
        },

        /**
         * Update service prices of a specific branch
         *
         * @param {Object} _
         * @param {Number} branch_id
         * @param {Number} service_price_id
         * @param {Object} changes
         *
         * @returns {Promise}
         */
        async update (_, [branch_id, service_price_id, changes]) {
            return await axios.put(`branches/${ branch_id }/services/${ service_price_id }`, changes);
        },

        /**
         * Destroy service of specific branch
         *
         * @param {Object} _
         * @param {Number} branch_id
         * @param {Number} service_id
         * @return {Promise<void>}
         */
        async destroy (_, [branch_id, service_id]) {
            return await axios.delete(`branches/${ branch_id }/services/${ service_id }`);
        }
    }

}