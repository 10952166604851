<template>
    <section class="pricing__double-driving">
        <div class="double-driving">
            <h4 class="double-driving__header font-semibold text-secondary mb-2">
                Double drive time
                <span>(between job locations)</span> 
            </h4>
            <p class="double-driving__info text-secondary">Change type</p>
            <div class="double-driving__action d-flex align-end">
                <v-select 
                    v-model="local"
                     :items="condition"
                     item-text="display"
                     item-value="value" dense outlined hide-details
                    append-icon="fa-chevron-down" class="double-driving__select custom--v-select" />
                <v-btn
                 depressed
                 color="primary"
                 class="double-driving__btn custom--v-btn"
                 min-width="133"
                 max-height="35"
                 @click="changeLocal"
                >
                    Save
                </v-btn>
            </div>
        </div>
    </section>
</template>

<script>
import ValueEmitter from "@/mixins/ValueEmitter.js";

export default {
    mixins: [ValueEmitter],

    props: {
        tabDescription: String,
    },

    data () {
        return {
            condition: [
                {
                    value: -1,
                    display: 'No double driving',
                },
                {
                    display: 'From the beginning',
                    value: 0,
                },
                {
                    display: 'After 5 miles',
                    value: 5,
                },
                {
                    display: 'After 10 miles',
                    value: 10,
                },
                {
                    display: 'After 15 miles',
                    value: 15,
                },
                {
                    display: 'After 20 miles',
                    value: 20,
                },
            ],
        };
    },

    methods: {
        changeLocal () {
            this.$emit('update');
        }
    },
};
</script>