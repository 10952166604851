<template>
    <div class="desktop-main__left tw-flex tw-flex-col">
        <app-desktop-profile-menu :items="profileMenuItems" @onClickItem="onClickProfileMenuItem"/>
        <app-desktop-main-menu :items="mainMenuItems"/>

        <app-mobile-profile-menu :items="profileMenuItems" @onClickItem="onClickProfileMenuItem"/>
        <app-mobile-main-menu :items="mobileMenuItems"/>
    </div>
</template>

<script>
import AppDesktopProfileMenu from "./Menus/AppDesktopProfileMenu";
import AppDesktopMainMenu from "./Menus/AppDesktopMainMenu";
import AppMobileProfileMenu from "./Menus/AppMobileProfileMenu";
import AppMobileMainMenu from "./Menus/AppMobileMainMenu";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        AppDesktopProfileMenu,
        AppDesktopMainMenu,
        AppMobileProfileMenu,
        AppMobileMainMenu,
    },

    methods: {
        ...mapActions('auth', ['logout']),

        onClickProfileMenuItem (item) {
            if (typeof item === "function") {
                return item();
            }

            this.$router.push({ name: item });
        }
    },

    computed: {
        ...mapGetters('auth', ['isCustomer', 'isLabor']),

        mainMenuItems () {
            const item = (title, name, icon) => ({ title, name, icon });

            if(this.isCustomer) {
                return [
                    // Customer menus
                    item('Orders', 'user-dashboard', 'icon-dashboard'),
                    item('Account settings', 'user-account', 'icon-people'),
                    item('Invite a friend', 'user-invite-people', 'icon-invite-friend'),
                    item('Reviews', 'reviews', 'icon-reviews'),
                ]
            } else if (this.isLabor) {
                return [
                    // Labor menus
                    item('Orders', 'labor-dashboard', 'icon-dashboard'),
                    // item('Earnings', 'labor-earnings', 'icon-people'),
                    // item('Invite a friend', 'user-invite-people', 'icon-invite-friend'),
                    item('Settings', 'labor-settings'),
                ]
            } else {
                return [
                    // Helper menus
                    item('Orders', 'dashboard', 'icon-dashboard'),
                    item('Calendar', 'calendar', 'icon-secondary-calendar'),
                    item('Order', 'orders', 'icon-order'),
                    item('Pricing', 'pricing', 'fa-money-bill-wave'),
                    item('Profile', 'profile', 'icon-profile'),
                    item('Teams', 'teams', 'icon-team'),
                    item('Invite a friend', 'user-invite-people', 'icon-invite-friend'),
                    item('Reviews', 'reviews', 'icon-reviews'),
                ]
            }
        },
        mobileMenuItems () {
            const item = (title, name, icon) => ({ title, name, icon });

            if(this.isCustomer) {
                return [
                    // Customer menus
                    item('Orders', 'user-dashboard', 'icon-dashboard'),
                    item('Account settings', 'user-account', 'icon-people'),
                    item('Invite a friend', 'user-invite-people', 'icon-invite-friend'),
                    item('Reviews', 'reviews', 'icon-reviews'),
                ]
            } else if (this.isLabor) {
                return [
                    // Labor menus
                    item('Orders', 'labor-dashboard', 'icon-dashboard'),
                    // item('Earnings', 'labor-earnings', 'icon-people'),
                    // item('Invite a friend', 'user-invite-people', 'icon-invite-friend'),
                    item('Settings', 'labor-settings'),
                ]
            } else {
                return [
                    // Helper menus
                    item('Orders', 'dashboard', 'icon-dashboard'),
                    item('Calendar', 'calendar', 'icon-secondary-calendar'),
                    item('Order', 'orders', 'icon-order'),
                    item('Pricing', 'pricing', 'fa-money-bill-wave'),
                    item('Profile', 'profile', 'icon-profile'),
                    item('Teams', 'teams', 'icon-team'),
                    item('Invite a friend', 'user-invite-people', 'icon-invite-friend'),
                    item('Reviews', 'reviews', 'icon-reviews'),
                ]
            }
        },

        profileMenuItems () {
            const item = (title, name, icon) => ({ title, name, icon });

            return this.isCustomer ?
                [
                item('Log out', () => this.logout(), 'fa-sign-out-alt')
                ] : [
                item('Teams', 'teams', 'icon-team'),
                item('Locations', 'locations', 'fa-map-marker-alt'),
                item('Pricing', 'pricing', 'fa-money-bill-wave'),
                item('Invite a friend', 'user-invite-people', 'icon-invite-friend'),
                item('Reviews', 'reviews', 'icon-reviews'),
                item('Log out', () => this.logout(), 'fa-sign-out-alt'),
            ];
        },
    },
}
</script>
