<template>
    <div class="tw-py-[40px] tw-px-[55px]">
        <div class="tw-flex tw-items-center tw-justify-between tw-pb-[25px]">
            <h1 class="tw-text-[30px] tw-text-[#0D3659] tw-font-semibold">Upload image</h1>
        </div>

        <div class="tw-mb-[35px]">
            <v-img v-if="!local && name !== 'photos'" :src="image" class="tw-w-full tw-aspect-[3/2]" alt="image"/>

            <cropper
                v-if="local"
                class="cropper"
                :src="local"
                :auto-zoom="true"
                :min-height="400"
	              :min-width="400"
                :stencil-props="stencilProps"
                :size-restrictions-algorithm="percentsRestriction"
                @change="onChange"
            />

            <div v-if="name === 'photos' && !local" class="__empty cropper d-flex align-center justify-center pa-3">
                <h1>Upload image</h1>
                <p>Photos are essential to present your business here on TopHelpers.com. Upload more photos to attract more customers to your business</p>
            </div>
        </div>


        <div class="tw-mb-[15px]">
            <button
                @click="onUploadClick"
                class="tw-flex tw-items-center tw-justify-center tw-gap-[10px] tw-w-full tw-p-[18px] tw-bg-[#F5FAFF] tw-border tw-border-dashed tw-border-[#8F3985] tw-rounded-[10px]"
            >
                <i class="icony icony-image-upload tw-w-[30px] tw-text-[#8F3985]"></i>
                <span class="tw-text-[#222222] tw-font-semibold">Upload image</span>
            </button>

            <input
                v-show="false"
                type="file"
                ref="fileAvatar"
                accept="image/png, image/jpg, image/jpeg"
                @change="onUploadImage"
            >
        </div>

        <div class="tw-flex tw-items-center tw-justify-between tw-mb-[35px]">
            <div class="tw-text-[#222222] tw-font-semibold">Less than 10mb</div>
            <div class="tw-text-[#222222] tw-font-semibold">File types: png, jpg, jpeg</div>
        </div>

        <div class="tw-flex tw-justify-end tw-gap-[10px] tw-pt-[35px] tw-border-t tw-border-solid tw-border-[#E2E2E2]">
            <button
                @click="save"
                :disabled="name === 'photos' && !local"
                class="tw-w-[135px] tw-bg-[#8F3985] tw-text-white tw-font-semibold tw-rounded-[8px] tw-p-[5px_5px]"
            >
                Save
            </button>

            <button
                @click="close"
                class="tw-w-[135px] tw-bg-[#E7EFF3] tw-text-[#6B6D81] tw-font-semibold tw-rounded-[8px] tw-p-[5px_5px]"
            >
                Cancel
            </button>
        </div>
    </div>
</template>

<script>
import ValueEmitter from "@/mixins/ValueEmitter.js";
import { Cropper } from 'vue-advanced-cropper';

import 'vue-advanced-cropper/dist/style.css';
import file_to_base64 from "@/plugins/file_to_base64.js";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { sweetAlertError } from '@/plugins/swal';

export default {
    name: "AppImageCropper",

    components: { Cropper },

    mixins: [ValueEmitter],

    props: {
        image: String,
        name: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            new_image: null,
            stencilProps: {
              aspectRatio: 1
            }
        }
    },

    computed: {
        ...mapGetters('profile', ['branchInfo']),
        ...mapState('auth', ['user']),
    },

    methods: {
        ...mapMutations('profile', ['SET_BRANCH_INFO']),
        ...mapActions('photos', ['create']),
        ...mapActions('profile', ['update']),
        ...mapActions('auth', ['updateCustomer']),


        /**
         * Triggers on user image upload
         *
         * @param {File} file
         */
        async onUploadImage({ currentTarget: { files: [file] } }) {
            if (file.size > 1024 * 1024 * 10) {
                sweetAlertError("File cannot be larger than 10M")
                return;
            }

            this.local = await file_to_base64(file);
        },

        /**
         * Triggers when user clicks upload icon
         *
         * @returns {Promise<void>}
         */
        async onUploadClick () {
            this.$refs.fileAvatar.click();
        },

        onChange ({ canvas }) {
            this.new_image = canvas.toDataURL();
        },

        close () {
            this.$emit('close')
        },

        save () {
            if (this.name === 'profile') {
                this.update({
                    logo: this.new_image
                });
            }

            if(this.name === 'customer') {
                this.updateCustomer({
                    image: this.new_image
                });
            }

            this.close();
            return true;
        },

        percentsRestriction({ minWidth, minHeight, maxWidth, maxHeight, imageWidth, imageHeight }) {
            return {
                minWidth: minWidth,
                minHeight: minHeight,
                maxWidth: maxWidth,
                maxHeight: maxHeight,
            }
        }
    },
}
</script>

<style scoped lang="scss">
.upload-image {
    padding: 0 15px 20px 15px;

    &__header {
        display: flex;

        h1 {
            font-size: 18px;
            font-weight: 500;
            line-height: normal;
            color: #0c3659;
        }
    }

    &__choose, &__actions {
        display: flex;
    }

    &__cropper {
        border: 1px dotted #0c3659;
        .__empty {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            h1 {
                font-size: 24px;
                font-weight: 500;
                color: #0c3659;
                margin-bottom: 10px;
            }
            p {
                font-size: 16px;
                font-weight: 500;
                color: #0c3659;
            }
        }
    }
}

.cropper {
    height: 300px;
    width: 450px;
    background: #DDD;
}

::v-deep {
    .custom--v-btn {
        background: #0c3659 !important;
    }
}
</style>
