<template>
    <section>
        <main v-if="isLoaded" class="photos">
            <h1 class="photos__header">Upload and manage photos</h1>
            <p class="photos__desc">Photos are essential to present your business here on TopHelpers.com. Upload more photos
                to attract more customers to your business</p>
            <section class="photos__add">
                <h2>Add your photos below</h2>
                <div class="__adding d-flex">
                    <div class="__action d-flex align-center justify-center flex-column" @click="onUploadClick">
                        <v-icon color="secondary" size="12">fa-plus</v-icon>
                        <span>Select Photos</span>

                        <input
                            v-show="false"
                            type="file"
                            ref="fileAvatar"
                            accept="image/*"
                            @change="onUploadImage"
                        >
                    </div>
                    <div v-for="{id, image } in images" :key="id" class="photos__add--data">
                        <v-img :src="image" alt="profile image"  @click="openImage(image, id)"/>
                        <div class="photos__add--actions">
                            <v-btn
                                depressed small
                                class="custom--v-btn mr-2.5"
                                max-width="32" min-width="35"
                                max-height="25"
                                @click="pinImage(id)"
                            >
                                <img src="/assets/images/profile/pin.svg"  alt="pin icon"/>
                            </v-btn>
                            <v-btn depressed
                                small class="custom--v-btn"
                                max-width="35" min-width="35"
                                max-height="25"
                                @click="deleteImage(id)"
                            >
                                <img src="/assets/images/profile/delete.svg"  alt="delete icon"/>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <app-loading-indicator v-else />
    </section>

</template>

<script>
import ValueEmitter from "@/mixins/ValueEmitter.js";
import loading from "@/mixins/loading.js";
import { mapActions, mapGetters } from "vuex";
import AppLoadingIndicator from "@/components/AppLoadingIndicator.vue";
import ViewImage from "../../components/ViewImage.vue";
import file_to_base64 from "../../plugins/file_to_base64.js";

export default {
    name: "Photos",
    components: { AppLoadingIndicator },
    mixins: [loading, ValueEmitter],

    computed: {
     ...mapGetters('photos', ['images']),
     ...mapGetters('auth',['activeBranch'])
    },

    methods: {
        ...mapActions('photos', ['load', 'create', 'pin', 'delete']),

        pinImage (id) {
            this.pin({image_id: id});
        },

        deleteImage (id) {
            this.delete({image_id: id});
        },

        /**
         * Triggers on user image upload
         *
         * @param {File} file
         */
        async onUploadImage ({ currentTarget: { files: [file] } }) {
            this.local = await file_to_base64(file);

            await this.create({
                photo: this.local
            });
        },

        /**
         * Triggers when user clicks upload icon
         *
         * @returns {Promise<void>}
         */
        async onUploadClick () {
            this.$refs.fileAvatar.click();
        },

        openImage (url, id) {
            this.$modal.show(ViewImage, {
                image: url,
                id,
                actions_exist: true
            }, {
                width: 900,
                height: 650
            })
        }

    },

    created () {
        this.startLoading( async ()=> {
            await this.load();
        });
    }
}
</script>

<style scoped>

</style>