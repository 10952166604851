export default {
    namespaced: true,

    actions: {
        /**
         * Get all invites
         *
         * @param commit
         * @return {Promise<void>}
         */
        async index ({ commit }) {
            return await axios.get('reviews/inviters')
        },

        /**
         * Create a new referral
         *
         * @param {Object} _
         * @param {Number} $branch_id
         * @param {String[]} emails
         * @return {Promise<void>}
         */
        async store (_, [branch_id, emails]) {
            await axios.post('reviews/invite', { branch_id, emails });
        },

         /**
          * Create new referral for tophelpers's clients
          *
          * @param {Object}
          * @param {Boolean} is_customers
          * @param {Number} branch_id
          * @param {Number[]} orders
          * @param {Boolean} all_orders
          * @return {Promise<void>>}
          * */

         async askReview (_, [is_customers, branch_id, orders, all_orders]) {
             await axios.post('/reviews/invite', { branch_id, is_customers, orders, all_orders })
         }
    },
}