import tokenize from "../plugins/tokenize";
export default {
    namespaced: true,

    state: {
        biddings: [],
        accepteds: [],
        detailedDelivery: null
    },

    getters: {
        biddingsCount: state => state.biddings.length || 0,
        acceptedsCount: state => state.accepteds.length || 0
    },

    mutations: {
        SET_BIDDINGS(state, biddings) {
            state.biddings = biddings;
        },

        SET_ACCEPTED(state, accepted) {
            state.accepteds = accepted;
        },

        SET_DELIVERY(state, delivery) {
            state.detailedDelivery = delivery;
        }
    },

    actions: {
        async load({ commit }) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + tokenize.get();

            const biddings = await axios.get('labor/deliveries/bidding');
            const accepteds = await axios.get('labor/deliveries/accepted');

            if (!biddings) return;
            commit('SET_BIDDINGS', biddings);

            if (!accepteds) return;
            commit('SET_ACCEPTED', accepteds);
        },

        // Get a single delivery
        async getOneDelivery({ commit }, id) {
            const data = await axios.get(`labor/deliveries/${id}`);
            if (!data) return;
            commit('SET_DELIVERY', data);
        },
        // Accept biddings
        async acceptDelivery({ dispatch }, id) {
            const data = await axios.post(`labor/deliveries/accept/${ id }`);
            if(!data) return;
            dispatch('load');
        },
        // Cancel biddings
        async cancelDelivery({ dispatch }, id) {
            const data = await axios.post(`labor/deliveries/cancel/${ id }`);
            if (!data) return;
            dispatch('load');
        },

        // Reject biddings
        async rejectDelivery({ dispatch }, id) {
            const data = await axios.post(`labor/deliveries/reject/${ id }`);
            if(!data) return;
            dispatch('load');
        },

        // Update labor info
        async updateLabor(_, payload) {
            const data = await axios.put(`labor/labors/${ payload.id }`, payload.personalInfo);
            if(!data) return;
        },
    },
}