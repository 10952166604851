<template>
    <v-main class="custom--v-main">
        <app-header class="px-10">
            <h3 class="text-2xl font-semibold text-secondary">
                Options
            </h3>
        </app-header>

        <v-container class="pa-lg-10" v-if="isLoaded">

            <app-location-selector
                v-model="activeLocation"
                :dont-show-company="true"
                class="mb-8"
            />

            <!-- Tabs -->
            <v-tabs v-model="tab">
                <v-tab>Operating window</v-tab>
                <v-tab>Operating calendar</v-tab>
                <v-tab>Operating hours</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <operating-window/>
                </v-tab-item>
                <v-tab-item>
                    <operating-calendar/>
                </v-tab-item>
                <v-tab-item>
                    <operating-hours :active-location="activeLocation"/>
                </v-tab-item>
            </v-tabs-items>
        </v-container>

        <app-loading-indicator v-else/>
    </v-main>
</template>

<script>
import AppLocationSelector from "../../components/AppLocationSelector";
import { mapGetters } from "vuex";
import loading from "../../mixins/loading";
import AppLoadingIndicator from "../../components/AppLoadingIndicator";

import OperatingCalendar from "./OperatingCalendar/OperatingCalendar.vue";
import OperatingWindow from "./OperatingWindow/OperatingWindow.vue";
import OperatingHours from "./OperatingHours/OperatingHours.vue";

export default {
    mixins: [loading],

    components: {
        AppLocationSelector,
        AppLoadingIndicator,
        OperatingCalendar,
        OperatingWindow,
        OperatingHours,
    },

    data () {
        return {
            activeLocation: null,
            tab: null,
        }
    },

    methods: {
        loadCompanyWorkingHours () {
            this.startLoading(async () => {
                await this.$store.dispatch('working_days/loadCompanyWorkingHours');
            });
        },
    },

    computed: {
        ...mapGetters('working_days', ['companyWorkingDays']),
    },

    created () {
        this.loadCompanyWorkingHours();
    },
};
</script>

<style lang="scss" scoped>
::v-deep {

    // Tabs
    .v-tabs {
        margin-bottom: 12px;

        .v-tabs-slider-wrapper {
            display: none;
        }
    }

    .v-tab {
        width: 200px;
        height: 36px;
        padding: 5px 10px;
        margin-right: 25px;
        color: #0D3659 !important;
        text-transform: unset;
        border: 1px solid #DDDDDD;

        &--active {
            color: #FFF !important;
            background: #8F3985;
            border-color: #8F3985;
        }
    }
}
</style>
