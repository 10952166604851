<template>
    <v-app class="order-review">
        <div class="location-modal__exit d-flex align-center justify-end mr-2 mt-2">
            <v-btn icon @click="close">
                <v-img src="/assets/icons/delete.svg" alt="close icon"/>
            </v-btn>
        </div>
        <main class="order-review__main">
            <h1 class="text-center text-white">All Reviews</h1>
            <div class="order-review__block" v-if="reviews.length > 0 && order.can_cancel">
                <div class="order-review__card"
                     v-for="{ id, comment, customer, helper, published_at, star, image } in reviews" :key="id">
                    <div class="d-flex align-center mb-2">
                        <img :src="image" alt="reviewer name" width="35" height="29">
                        <div class="order-review__heading">
                            <h5 class="text-secondary">{{ customer || helper }}</h5>
                            <div class="d-flex align-center">
                                <v-rating
                                    half-icon="fa-star-half-alt"
                                    size="10"
                                    readonly
                                    color="#FBAB1E"
                                    background-color="#FBAB1E"
                                    class="custom--v-rating"
                                    :value="Number(star)"
                                ></v-rating>
                                <span class="mt-1">{{ published_at }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="order-review__comment">
                        <p>{{ comment }}</p>
                    </div>
                </div>
            </div>
            <div v-else class="order-review__empty mx-auto m-5 font-secondary text-secondary">
                <v-img src="assets/images/logging-off.svg" alt="no talking and empty image" height="200"/>
                <p class="mt-4 text-center"> No reviews yet for this order </p>
            </div>
        </main>
    </v-app>
</template>

<script>
export default {
    name: "OrderReviews",

    props: {
        reviews: {
            type: Array,
            required: true
        },
        order: {
            type: Object,
            required: true
        }
    },

    methods: {
        close () {
            this.$emit('close');
        }
    }
}
</script>

<style scoped lang="scss">
.order-review {
    box-sizing: border-box;

    h1 {
        padding: 14px 0 10px;
        margin-bottom: 35px;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
        background: #0D3659;
    }

    &__block {
        min-height: 405px;
        overflow-y: auto;
    }

    &__card {
        padding: 0 15px;
        margin-bottom: 24px;
        max-height: 400px;
        overflow-y: auto;
    }

    &__main {
        margin: 34px;
        border: 1px solid #ddd;
        min-height: 475px;

        h5 {
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
        }

        img {
            border-radius: 50%;
            margin-right: 13px;
            max-height: 35px;
        }
    }

    &__heading span {
        margin-left: 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        color: #8F3985;
    }

    &__comment {
        padding: 15px 15px 18px 15px;
        background: #F5F5F9;
        border-radius: 0 8px 8px 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #0c3659;

        p:first-child {
            margin-bottom: 10px;
            word-break: break-all;
        }
    }

    &--note {
        border-top: 1px solid #D5D5E4;
        padding-top: 13px !important;
        margin-bottom: 0 !important;
        font-style: italic !important;
    }

    &__empty {
        width: 100%;
        height: 85%;
    }
}

::v-deep {
    .custom--v-rating.v-rating .v-icon {
        padding: 0 !important;
    }

    .custom--v-btn {
        border-radius: 8px;

        .v-btn__content {
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
        }
    }
}

</style>