<template>
  <div class="tw-relative tw-pb-[25px] tw-mb-[15px]">
    <div class="tw-flex tw-items-center tw-justify-between tw-gap-[30px] tw-mb-[12px]">
      <h3 class="tw-text-[22px] tw-text-[#0D3659] tw-font-semibold">Overview</h3>
      <orders-filter/>
    </div>

    <div class="tw-grid md:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-[15px] tw-bg-[#FFF] tw-p-[15px] tw-rounded-[8px]">
      <div
        v-if="changePosition(index)"
        v-for="({icon, header, count}, index) in orders"
        :key="index"
        class="tw-flex tw-items-center tw-gap-[20px] tw-p-[10px_20px] tw-bg-[#f5f5f9] tw-border tw-border-solid tw-border-[#e3e3e3] tw-rounded-[8px]"
      >
        <div class="">
          <v-icon class="orders-card__icon" size="32">{{ icon }}</v-icon>
        </div>
        <div class="tw-grid">
          <strong class="tw-text-[#0D3659] tw-text-[17px] tw-font-semibold tw-leading-tight">{{ header }}</strong>
          <span class="tw-text-[#aaaaaa] tw-font-semibold">{{ count }} orders</span>
        </div>
      </div>
    </div>
    <div @click="changeCard" class="tw-flex tw-items-start tw-justify-center tw-w-[40px] tw-h-[25px] tw-pt-[2px] tw-absolute tw-bottom-0 tw-right-[calc(50%_-_20px)] tw-bg-[#FFF] tw-rounded-b-[20px] tw-cursor-pointer tw-group">
      <i class="icony icony-chevron-down tw-text-[#0D3659] group-hover:tw-text-[#8F3985] tw-duration-300" :class="{'tw-rotate-[180deg]': isOpen}"></i>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import OrdersFilter from '@/views/Orders/OrdersFilter.vue';

export default {
  props: {
    branchId: Number | String
  },

  components: {
    OrdersFilter
  },

  data () {
    return {
      orders: [
        {
          header: 'All Orders',
          title: 'all_orders',
          icon: 'icon-orders',
          count: '...'
        },
        {
          header: 'Completed, Accepted Orders',
          title: 'completed_and_accepted_orders',
          icon: 'icon-accepted',
          count: '...'
        },
        {
          header: 'Cancelled Orders',
          title: 'cancelled_orders',
          icon: 'icon-cancelled',
          count: '...'
        },
        {
          header: 'New',
          title: 'new_orders',
          icon: 'icon-new_orders',
          count: '...'
        },
        {
          header: 'Waiting',
          title: 'waiting_for_payment_orders',
          icon: 'icon-waiting_orders',
          count: '...'
        },
        {
          header: 'Rejected',
          title: 'rejected_orders',
          icon: 'icon-rejected_orders',
          count: '...'
        },
      ],
      isOpen: false
    };
  },

  methods: {
    changeCard () {
      this.isOpen = !this.isOpen;
    },

    changePosition (index) {
      return this.isOpen ? index < 6 : index < 4;
    },

    ...mapActions('orders', ['getOrdersCounts']),

    async getOrders () {
      const data = await this.getOrdersCounts(this.branchId);

      this.orders.forEach((order, index) => {
        this.orders[index].count = data[order.title];
      });
    }
  },

  created () {
    this.getOrders();
  },

  watch: {
    branchId: 'getOrders',
  }
};
</script>
