<template>
    <div class="team-lists__row">
        <div class="team-lists__left team-lists__img mr-2.5">
            <v-avatar size="44">
                <img :src="team.profile_picture" :alt="team.first_name">
            </v-avatar>
        </div>
        <div class="team-lists__right w-full">
            <h5 class="team-lists__name text-secondary">{{ getFullName(team) }}</h5>
            <div class="team-lists__icon">
                <v-icon color="secondary" size="15" class="mr-2">fa-envelope</v-icon>
                <span class="text-secondary">{{ team.email }}</span>
            </div>

            <div class="team-lists__menu">
                <!-- three dots btn -->
                <v-menu offset-y bottom right class="custom-v-men">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn  dark v-bind="attrs" v-on="on" icon class="menu-btn">
                            <img src="/assets/icons/three-dots.svg" alt="three dots" />
                        </v-btn>
                    </template>

                    <v-list class="custom-v-list">
                        <v-list-item v-for="(item, index) in menu" :key="index">
                            <v-list-item-title @click="viewMember">{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <!-- button -->
            <div class="team-lists__btn hidden-md-and-down ms-auto">
                <v-btn outlined elevation="0" color="secondary" class="bg-white" style="border-color: #DDDDDD;font-weight: 500;"
                        @click="viewMember">
                        View member
                        <v-icon size="12" class="ms-5">fa-angle-right</v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        team: { type: Object, required: true }
    },

    data() {
        return {
            menu: [{ title: "View member" }]
        }
    },

    computed: {
        ...mapState('locations', ['branches']),

        activeBranch () {
            return this.$store.state.auth.activeBranch;
        },
    },

    methods: {
        getFullName(team) {
            return team.first_name + ' ' + team.last_name;
        },

        viewMember() {
            this.$router.push({ name: 'team-member', params: { branch_id: this.activeBranch.id, crew_id: this.team.id } })
        }
    },
}
</script>

<style scoped lang="scss">
::v-deep {
    .custom-v-list  {
        padding: 0 !important;

        .v-list-item {
            min-height: 36px !important;
        }

        .v-list-item__title {
            font-size: 14px;
            font-weight: 500;
            color: rgb(13, 54, 89);
            cursor: pointer;
        }
    }
}
</style>