<template>
    <v-main>
        <div class="login">
            <div class="login__left">
                <div class="login__left-inner">
                   <div class="logo mb-10">
                       <a href="https://tophelpers.com" class="login__left-logo mb-10">
                           <v-img src="/assets/images/logos/logo-purple.svg" alt="logo"> </v-img>
                       </a>
                   </div>
                    <div class="mb-4">
                        <strong class="text-3xl font-weight-bold text-secondary">
                            Reset Password
                        </strong>
                    </div>
                    <div class="mb-8">
                        <div class="mb-4">
                            <div class="mb-2.5 text-secondary font-medium">
                                Email
                            </div>
                            <v-text-field
                                label="Write your email"
                                single-line
                                hide-details
                                outlined
                                type="email"
                                class="custom--v-text-field"
                                v-model="email"
                            />
                        </div>
                    </div>
                    <v-btn
                        depressed
                        block
                        x-large
                        color="primary"
                        class="custom--v-btn"
                        @click="submit"
                        :loading="isLoading"
                    >
                        Reset password
                    </v-btn>
                </div>
            </div>
            <div class="login__right"/>
        </div>
    </v-main>
</template>

<script>
import { mapActions } from "vuex";
import loading from "@/mixins/loading.js";

export default {
    mixins: [loading],

    data () {
        return {
            email: '',
        };
    },

    methods: {
        ...mapActions('auth', ['resetPassword']),

        submit () {
            this.startLoading(async () => {
                await this.resetPassword({ email: this.email });
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.login {
    display: flex;
    width: 100%;
    background-color: #fafcff;

    &__left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58%;
        min-height: 100vh;
        padding: 50px 20px;

        @media (max-width: 767px) {
            width: 100%;
        }

        &-inner {
            width: 100%;
            max-width: 400px;
        }
    }

    &__right {
        width: 42%;
        min-height: 100vh;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top right;
        background-image: url('/assets/images/mover-login-bg.jpg');

        @media (max-width: 767px) {
            display: none;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(#8F3985, 0.8);
        }
    }
}

.custom--login-forgot-password {
    font-size: 14px;
    color: #0D3659 !important;
}
</style>
