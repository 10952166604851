export default {
  namespaced: true,

  state: () => ({
    /** @type {Order[]} */
    orders: [],
    last_page: 0,
    current_page: 1,
    deliveries: [],
    deliveries_last_page: 0,
    deliveries_current_page: 1,
    loadingSection: false,
  }),

  mutations: {
    SET_ORDERS (state, orders) {
      state.orders = orders;
    },

    SET_DELIVERIES (state, deliveries) {
      state.deliveries = deliveries;
    },

    SET_CURRENT_PAGE (state, page) {
      state.current_page = page;
    },

    SET_DELIVERIES_CURRENT_PAGE (state, page) {
      state.deliveries_current_page = page;
    },

    SET_LOADING (state, payload) {
      state.loadingSection = payload;
    },
  },

  getters: {
    orders: state => state.orders,
    deliveries: state => state.deliveries,
  },

  actions: {
    /**
     * Get all orders
     *
     * @param {Object} state
     * @param {Function} commit
     * @param branch_id
     * @returns {Promise<void>}
     */
    async load ({ state, commit }, { branch_id, filter, arrival_date_in }) {
      const { data: orders, meta } = await axios.get('orders', {
        params: {
          branch_id: branch_id,
          orderBy: 'id',
          desc: 1,
          paginate: 1,
          page: state.current_page,
          filter,
          arrival_date_in
        }
      });

      const { last_page } = meta;

      commit('SET_ORDERS', orders);
      state.last_page = last_page;
    },

    async loadDeliveries ({ state, commit }, { branch_id, filter, arrival_date_in }) {
      const { data: orders, meta } = await axios.get('deliveries/archives', {
        params: {
          branch_id: branch_id,
          orderBy: 'id',
          desc: 1,
          paginate: 1,
          page: state.deliveries_current_page,
          filter,
          arrival_date_in
        }
      });

      const { last_page } = meta;

      commit('SET_DELIVERIES', orders);
      state.deliveries_last_page = last_page;
    },

    /**
     * Get order by orderId
     *
     * @param {Object} _
     * @param {Number} orderId
     * @returns {Promise<Order>}
     */
    async show (_, orderId) {
      return await axios.get(`orders/${orderId}`);
    },

    /**
     * Go to page of pagination
     *
     * @param {Object} state
     * @param {Function} commit
     * @param {Function} dispatch
     * @param {Number} val
     * @param {String} branch_id
     * @returns {Promise<void>}
     */
    async goToPage ({ state, commit, dispatch }, { val, branch_id }) {
      commit('SET_CURRENT_PAGE', val);
      dispatch('load', { branch_id });
    },

    async deliveriesGoToPage ({ state, commit, dispatch }, { val, branch_id }) {
      commit('SET_DELIVERIES_CURRENT_PAGE', val);
      dispatch('loadDeliveries', { branch_id });
    },

    async getOrdersCounts (_, branch_id) {
      return await axios.get('orders/counts', {
        params: {
          branch_id: branch_id,
        }
      });
    },
  },
};
