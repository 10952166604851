<template>
    <v-col cols="12" class="invite-generate__column">
        <div class="invite-generate__credit d-flex align-center">
            <div class="generate-left d-flex align-center justify-center">
                <img src="assets/images/referral/debit-card.svg" alt="debit card">
            </div>
            <div class="generate-right">
                <p>Your credit</p>
                <div class="d-flex align-center generate-right__pending">
                    <h5>Pending: ${{ balance.pricesPending }}</h5>
                </div>
                <div class="d-flex align-center">
                    <h5>Available: ${{ balance.availableAmount ? balance.availableAmount : balance.priceAvailable }} </h5>
                </div>
            </div>
        </div>
    </v-col>
</template>

<script>
export default {
    name: "InviteFriendsShowBalance",

    props: {
        balance: {
            pricesPending: {
                type: String,
                required: true
            },
            priceAvailable: {
                type: String,
                required: true
            },
            availableAmount: {
                type: String | null,
                required: true
            }
        }
    }
}
</script>

<style scoped>

</style>
