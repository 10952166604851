<template>
    <v-app class="p-3">
        <div class="d-flex align-center justify-space-between w-full px-3 mb-3">
            <small>
                Reschedule
            </small>
            <v-btn text fab small class="mr-n3" @click="$emit('close')">
                <v-icon size="15">fa-times</v-icon>
            </v-btn>
        </div>
        <div class="align-center p-3 pt-0">
            <div class="bordered-wrapper">
                <div style="padding: 15px;border-bottom: 1px solid #E0E4E9;">
                    <v-row>
                        <v-col md="6">
                            <div class="mb-1.5 text-secondary font-medium">
                                Alternate date
                            </div>

                            <v-dialog
                                ref="dialog1"
                                v-model="modal1"
                                :return-value.sync="requested_date"
                                width="290"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="requested_date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        single-line
                                        hide-details
                                        outlined
                                        type="text"
                                        background-color="#F6F6F6"
                                        class="custom--v-text-field"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="requested_date"
                                    scrollable
                                    @change="$refs.dialog1.save(requested_date)"
                                >
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col md="6">
                            <div class="mb-1.5 text-secondary font-medium">
                                Show up time
                            </div>
                            <v-select
                                v-model="requested_time"
                                item-disabled="disable"
                                item-text="name"
                                :items="showUpTime"
                                dense
                                outlined
                                hide-details
                                background-color="#F6F6F6"
                                append-icon="fa-chevron-down"
                                class="custom--v-select"
                            ></v-select>
                        </v-col>
                        <v-col>
                            <div class="mb-1.5 text-secondary font-medium">
                                Add a note to the Request
                            </div>
                            <v-textarea
                                rows="4"
                                outlined
                                hide-details
                                class="custom--v-textarea"
                                background-color="#F6F6F6"
                                placeholder="Write your request"
                                v-model="$v.note.$model"
                            />
                            <span style="color: red;" v-if="$v.note.$error">Note is required!</span>
                        </v-col>
                    </v-row>
                </div>
                <div class="reschedule-footer">
                    <div v-if="!isCustomer" class="reschedule-footer__left">
                        <v-checkbox
                            v-model="moverCanReschedule"
                            label="Reschedule approved"
                            hide-details
                        ></v-checkbox>
                        <v-btn
                            elevation="0"
                            color="primary"
                            class="px-4 ml-3"
                            @click="instantlyReschedule"
                            :disabled="!moverCanReschedule"
                        >
                            Ok
                        </v-btn>
                    </div>
                    <div class="reschedule-footer__right">
                        <v-btn
                            elevation="0" color="light"
                            class="px-10 ml-3"
                            @click="$emit('close')"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            elevation="0"
                            color="primary"
                            class="px-4 ml-3"
                            @click="reschedule(false)"
                            :disabled="moverCanReschedule"
                        >
                            Send Request
                        </v-btn>
                    </div>
                </div>
                <hr>
                <v-skeleton-loader v-if="isLoading" type="table"/>

                <div v-else class="mt-6">
                    <v-simple-table class="custom--v-simple-table">
                        <thead>
                        <tr>
                            <th>Hourly:</th>
                            <th>Total for {{ price.hours_count }} hours minimum:</th>
                            <th>Service fee:</th>
                            <th>Grand Total Estimate:</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{ price.hourly }}</td>
                            <td>{{ price.total_for_n_hours }}</td>
                            <td>{{ price.service_fee }}</td>
                            <td>{{ price.total }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import dayjs from "dayjs";
import loading from "@/mixins/loading.js";
import { mapActions, mapGetters } from "vuex";
import { successConfirmAction } from "@/plugins/swal.js"

export default {
    props: {
        order: Object,
    },

    mixins: [loading, validationMixin],

    data () {
        const order_date = dayjs(this.order.arrival_date);
        const from = order_date.isBefore(dayjs()) ? dayjs() : order_date;

        return {
            order_id: this.order.id,
            modal1: false,
            moverCanReschedule: false,
            showUpTime: [
                {
                    name: 'Morning show up',
                    disable: true,
                },
                {
                    name: 'Arrival between 7 am - 8 am',
                    disable: false,
                },
                {
                    name: 'Arrival between 8 am - 9 am',
                    disable: false,
                },
                {
                    name: 'Arrival between 9 am - 10 am',
                    disable: false,
                },
                {
                    name: 'Arrival between 10 am - 11 am',
                    disable: false,
                },
                {
                    name: 'Arrival between 11 am - 12 pm',
                    disable: false,
                },
                {
                    name: 'Afternoon show up',
                    disable: true,
                },
                {
                    name: 'Arrival between 12 pm - 3 pm',
                    disable: false,
                },
                {
                    name: 'Arrival between 1 pm - 4 pm',
                    disable: false,
                },
                {
                    name: 'Arrival between 2 pm - 5 pm',
                    disable: false,
                },
                {
                    name: 'Arrival between 3 pm - 6 pm',
                    disable: false,
                },
            ],

            requested_date: from.add(1, 'day').format('YYYY-MM-DD'),
            requested_time: 'Arrival between 8 am - 9 am',
            note: '',
            price: {
                hourly: 0,
                total_for_n_hours: 0,
                service_fee: 0,
                total: 0,
                hours_count: 0,
            },
        }
    },

    validations: {
        note: { required },
    },

    methods: {
        ...mapActions('reschedule', {
            'helperStore': 'store',
            'calculate': 'calculate',
        }),
        ...mapActions('customer_reschedule', {
            'customerStore': 'store',
        }),

        calculatePrice () {
            this.startLoading(async () => {
                this.price = await this.calculate({
                    order_id: this.order_id,
                    requested_date: this.requested_date,
                });
            });
        },

        reschedule (instantly = false) {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                this.startLoading(async () => {
                    if (this.isCustomer) {
                        await this.customerStore({ ...this });
                        await this.$store.dispatch('user_orders/index');
                    } else {
                        await this.helperStore({ ...this, instantly });
                        await this.$store.dispatch('dashboard/load');
                    }
                    this.$modal.hideAll();

                    if (this.$attrs.afterClose) {
                        this.$attrs.afterClose();
                    }
                });
            }
        },

        instantlyReschedule () {
            successConfirmAction(
                'Do you really want to instantly reschedule?',
                'Reschedule',
                () => this.reschedule(true)
            );
        },
    },

    created () {
        this.calculatePrice();
    },

    watch: {
        'requested_date': function () {
            this.calculatePrice();
        }
    },

    computed: {
        ...mapGetters('auth', ['isCustomer']),
    },
}
</script>

<style scoped lang="scss">
.bordered-wrapper {
    width: 100%;
    border: 1px solid #E0E4E9;
}

::v-deep {

    .v-text-field {

        fieldset {
            border: 0 !important;
        }
    }

    .v-picker {
        border: 0;

        .v-date-picker-header {

            .v-icon {
                font-size: 14px;
            }
        }

        .v-picker__title__btn {
            font-size: 20px;
        }

        .v-date-picker-table {
            padding-bottom: 10px;
            height: auto;
        }
    }
}

.reschedule-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    &__left {
        display: flex;
        align-items: center;

        &::v-deep {

            .v-input--checkbox {
                margin: 0;
                padding: 0;
            }
        }
    }
}
</style>
