<template>
    <section class="review-card">
        <div class="review-card__left d-flex align-items-center pb-0 mr-lg-4">
            <div class="review-card__img">
                <img :src="review.sender.image" alt="logo" />
            </div>
            <div class="review-card__header mt-lg-1 ml-3 w-full">
                <v-tooltip top content-class="charge-text__tooltip" max-width="180">
                    <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex align-center justify-space-between">
                            <h5 v-bind="attrs" v-on="on" class="review-card__heading">{{ getReviewerName(review.sender.name) }}</h5>
                            <span class="hidden-md-and-up review-card__star--date text-secondary" v-text="getPublishDate"/>
                        </div>
                    </template>
                    <span class="custom--v-tooltip" style="word-break: break-word">
                        {{ getReviewerFullName(review.sender.name) }}
                    </span>
                </v-tooltip>
                <div class="review-card__reviewers d-flex align-center">
                    <span class="hidden-md-and-down review-card__star--date text-secondary" v-text="getPublishDate"/>
                    <ReviewCardHeader
                        :review-header-details="reviewHeaderPropsDetails"
                        is-mobile-header
                        class="hidden-md-and-up"
                    />
                </div>
            </div>
        </div>
        <div class="review-card__right d-flex">
            <div class="mr-2">
                <ReviewCardHeader
                    :review-header-details="reviewHeaderPropsDetails"
                    class="hidden-md-and-down mb-4"
                />

                <p class="review-card__text mb-2">
                    {{ cutText(review.comment) }}
                    <span
                        v-if="review.comment.length > 340 && !isOpenComment"
                        class="text-primary"
                        @click="() => {this.isOpenComment = true}"
                    >
                    ... more <i class="fa fa-chevron-right"></i>
                    </span>

                    <span v-if="isOpenComment" style="display: initial">
                        {{ review.comment.substring(341, review.comment.length) }}
                    <span
                        class="text-primary"
                        @click="() => {this.isOpenComment = false}"
                    >
                        less <i class="fa fa-chevron-right"></i>
                </span>
                </span>

                </p>

                <div v-if="review.replies.length > 0 && tab === 0" class="review-card__replies">
                    <a class="d-flex align-center" :class="{'mb-3': this.showReplies}" @click="() => {this.showReplies = !this.showReplies}">
                        <v-icon size="14" color="primary" class="mr-1">
                            {{ showReplies ? 'fa-caret-down' : 'fa-caret-right' }}
                        </v-icon>
                        {{ getName(tab) }}
                    </a>
                    <div v-show="showReplies" class="show-replies">
                        <div v-for="reply in review.replies" :key="reply.id">
                            <div class="d-flex align-center">
                                <img :src="reply.sender.image" width="29" height="29" alt="profile image">
                                <h5 class="ml-1 text-secondary font-semibold">{{ reply.sender.name }}</h5>
                            </div>
                            <p class="mt-1 ml-5 mb-0">{{ reply.comment }}</p>


                            <div v-for="item in reply.replies" :key="item.id" class="ml-10">
                                <div class="d-flex align-center">
                                    <img :src="item.sender.image" width="29" height="29" alt="profile image">
                                    <h5 class="ml-1 text-secondary font-semibold">{{ item.sender.name }}</h5>
                                </div>
                                <p class="mt-1 ml-5 mb-0">{{ item.comment }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="tab === 1" class="review-card__replies">
                    <a v-if="review.replies.length !== 0" class="d-flex align-center" :class="{'mb-3': this.showReplies}"
                       @click="() => {this.showReplies = !this.showReplies}">
                        <v-icon size="14" color="primary" class="mr-1">
                            {{ showReplies ? 'fa-caret-down' : 'fa-caret-right' }}
                        </v-icon>
                        {{ getName(tab) }}

                    </a>
                    <div v-show="showReplies">
                        <div v-for="reply in review.replies" :key="reply.id">
                            <div class="d-flex align-center">
                                <img :src="reply.sender.image" width="29" height="29" alt="profile image">
                                <h5 class="ml-1 text-secondary font-semibold">{{ reply.sender.name }}</h5>
                            </div>
                            <p class="mt-1 ml-5 mb-0">{{ reply.comment }}</p>

                            <div v-for="item in reply.replies" :key="item.id" class="ml-10">
                                <div class="d-flex align-center">
                                    <img :src="ite.sender.image" width="29" height="29" alt="profile image">
                                    <h5 class="ml-1 text-secondary font-semibold">{{ item.sender.name }}</h5>
                                </div>
                                <p class="mt-1 ml-5 mb-0">{{ item.comment }}</p>
                            </div>

                        </div>
                    </div>
                    <div v-if="review.replies.length === 0">
                        <a class="review-card__reply" @click="setReply">
                            Reply
                            <v-icon>icon-reply</v-icon>
                        </a>

                        <div v-if="isOpenReply">
                            <reply-review :review-id="review.id" @cancel="() => { this.isOpenReply = false }"/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="tab === 0 && review.replies.length === 0 && !review.publish_date"
                 class="__right d-flex align-self-end justify-end ml-auto">
                <v-btn
                    width="86" height="28"
                    style="font-size: 14px; font-weight: 500; border-radius: 4px"
                    color="primary"
                    class="mr-2"
                    @click="publishReview"
                >
                    Publish
                </v-btn>
                <v-btn
                    width="86" height="28"
                    color="secondary"
                    style="font-size: 14px; font-weight: 500; border-radius: 4px"
                    outlined
                    @click="edit"
                >Edit
                </v-btn>
            </div>
        </div>
    </section>
</template>


<script>
import AppRating from "@/components/AppRating.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import loading from "@/mixins/loading.js";
import ReplyReview from "@/views/Reviews/ReplyReview.vue";
import AppEditReview from "@/components/Modals/AppEditReview.vue";
import { includes } from "lodash";
import { successConfirmAction } from "@/plugins/swal.js";
import ReviewCardHeader from "./ReviewCardHeader.vue";


export default {
    name: "ReviewCard",

    components: { ReviewCardHeader, ReplyReview, AppRating },

    mixins: [loading],

    props: {
        review: Object,
        tab: Number,
        received: { type: Boolean, default: false }
    },

    data () {
        return {
            isOpenReply: false,
            isOpenComment: false,
            showReplies: false,
            isShowEdit: true,
            replyText: '',
            reviewHeaderPropsDetails : {
                star: this.review.star,
                order_link: this.review.order_link,
                publish_date: this.review.publish_date,
                activeTab: this.tab,
                customerName: this.review.receiver.name,
                branchName: this.review.branch_name
            }
        }
    },

    computed: {
        ...mapGetters('auth', ['user', 'isCustomer']),
        ...mapState('review', ['replies_count']),

        getPublishDate () {
            return !this.review.publish_date ? 'Not Published' : this.review.publish_date;
        },
    },

    methods: {
        ...mapActions('review', ['helper_publish', 'customer_publish']),

        setReply () {
            this.isOpenReply = !this.isOpenReply;
        },

        cutText (text) {
            if (text.length <= 340) {
                return text;
            }
            return text.substring(0, 340);
        },

        edit () {
            this.$modal.show(AppEditReview, {
                    editDetails: {
                        id: this.review.id,
                        comment: this.review.comment,
                        stars: Number(this.review.star),
                    },
                    isCustomer: this.user.isCustomer,
                }, {
                    width: 800,
                    height: 410
                },
            )
        },

        publishReview () {
            successConfirmAction('Do you want to publish this review', 'Confirm', () => {
                if (this.user.isCustomer) {
                    this.startLoading(async () => {
                        await this.customer_publish(this.review.id)
                    })
                } else {
                    this.startLoading(async () => {
                        await this.helper_publish(this.review.id);
                    })
                }
                this.isShowEdit = false;
            })
        },

        getName (tab) {
            if (tab === 1) {
                return this.review.replies.map(reply => {
                    if (reply.replies.length === 0) {
                        return this.replyText = '1 reply from you';
                    }

                    return this.replyText = `2 replies from you and ${ this.review.sender.name }`
                })[0]
            }

            return this.review.replies.map(reply => {
                if (reply.replies.length === 0) {
                    return this.replyText = `1 reply from ${ this.review.receiver.name }`;
                }

                return this.replyText = `2 replies from you and ${ this.review.receiver.name }`
            })[0]
        },

        getReviewerName (name) {
            if(this.isCustomer && this.tab === 1) return this.review.branch_name;

            if(!this.isCustomer && this.tab === 0) return this.review.branch_name;

            if (includes(name, ' ')) {
                return name.split(' ')[0] + ' ' + name.split(' ')[1][0] + '.'
            }
            return name.substring(0, 15) + '...'
        },

        getReviewerFullName(name) {
            if(this.isCustomer && this.tab === 1) return this.review.branch_name;
            if(!this.isCustomer && this.tab === 0) return this.review.branch_name;
            return name;

        }
    },
}
</script>

<style scoped lang="scss">
::v-deep {
    .custom--v-textarea {
        fieldset {
            border: none;
        }

        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #0D3659;
    }
}
</style>
