<script>
import DashboardCard from "@/views/Dashboard/DashboardCard";
import DeliveryCard from "@/views/LaborDashboard/DeliveryCard.vue";

import PersonalInfo from './SettingTabs/PersonalInfo.vue';
import Password from './SettingTabs/Password.vue';
import Notification from './SettingTabs/Notification.vue';
import Danger from './SettingTabs/Danger.vue';

import { mapActions } from "vuex";

export default {
    data() {
        return {
            tab: null
        }
    },

    components: {
        DashboardCard,
        DeliveryCard,
        PersonalInfo,
        Password,
        Notification,
        Danger
    },

    computed: {
        tabs () {
            
            return [
                'Personal info',
                'Reset password',
                'Notifications',
                'Danger zone',
            ];
        },
    },

    methods: {
        ...mapActions('labor', ['load']),
    },

    async mounted() {
        await this.load();
    }
}
</script>

<template>
  <v-main class="custom--v-main">
        <app-header class="dashboard-header">
            <v-tabs
                v-model="tab"
                bg-color="#0D3659"
            >
            <v-tabs-slider color="#8F3985" class="tw-rounded-[10px]"></v-tabs-slider>
                <v-tab
                    v-for="title in tabs"
                    :key="title"
                    :value="title"
                    class="!tw-m-[0_30px_0_0] !tw-p-0 [&.v-tab--active_span]:tw-text-[#0D3659]"
                >
                    <span class="tw-capitalize tw-text-base lg:tw-text-lg tw-font-medium">{{ title }}</span>
                </v-tab>
            </v-tabs>
        </app-header>

        <div>
            <v-tabs-items v-model="tab" class="custom--v-tabs-items">
                <v-tab-item>
                    <personal-info />
                </v-tab-item>

                <v-tab-item>
                    <password />
                </v-tab-item>

                <v-tab-item>
                    <notification />
                </v-tab-item>

                <v-tab-item>
                    <danger />
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-main>
</template>

<style scoped>

</style>