<template>
    <div class="mb-5">
        <div class="__heading d-flex">
            <h2>MTR License</h2>

            <v-tooltip top content-class="charge-text__tooltip">
                <template v-slot:activator="{ on, attrs }">
                    <img v-bind="attrs" v-on="on" src="assets/icons/info.svg" alt="info icon" width="12"
                         class="ml-1">
                </template>
                <span class="custom--v-tooltip">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aut delectus, dignissimos ex id quod?
                        </span>
            </v-tooltip>
        </div>
        <v-row>
            <v-col xl="3" lg="3" md="6" sm="12" cols="12">
                <h4>MTR license number</h4>

                <v-text-field
                    v-model="mtr_license_data.mtr_license_number"
                    dense
                    outlined
                    placeholder="Write a number"
                    class="custom--v-text-field"
                    :error-messages="mtrLicenseNumberErrors"
                    @input="$v.mtr_license_data.mtr_license_number.$touch()"
                    @blur="$v.mtr_license_data.mtr_license_number.$touch()"
                >
                </v-text-field>
            </v-col>
            <v-col xl="3" lg="3" md="6" sm="12" cols="12">
                <h4>Issuing city</h4>

                <v-text-field
                    v-model="mtr_license_data.issuing_city"
                    dense
                    outlined
                    placeholder="Write a city"
                    class="custom--v-text-field"
                    :error-messages="mtrLicenseIssuesErrors"
                    @input="$v.mtr_license_data.issuing_city.$touch()"
                    @blur="$v.mtr_license_data.issuing_city.$touch()"
                >
                </v-text-field>
            </v-col>
            <v-col xl="3" lg="3" md="6" sm="12" cols="12">
                <h4>Expiration date</h4>

                <date-picker
                    :expiration_date="mtr_license_data.expiration_date"
                    :is-clicked-submit="isSubmit"
                    @onChange="(value) => {this.mtr_license_data.expiration_date = value }"/>
            </v-col>
            <v-col xl="3" lg="3" md="6" sm="12" cols="12" class="d-flex align-center">
                <v-btn
                    depressed color="secondary"
                    class="custom--v-btn" min-width="80"
                    @click="submit(mtr_license_data)"
                >
                    Save
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import DatePicker from "../../components/DatePicker.vue";

export default {
    name: "CredentialsMtrLicense",

    components: { DatePicker },

    mixins: [validationMixin],

    props: {
        branchId: {
            type: Number,
            required: true
        }
    },

    validations: {
        mtr_license_data: {
            mtr_license_number: { required },
            issuing_city: { required },
            expiration_date: { required }
        },
    },

    data () {
        return {
            mtr_license_data: {
                mtr_license_number: '',
                issuing_city: '',
                expiration_date: '',
            },
            isSubmit: false
        }
    },


    computed: {
        ...mapGetters('credentials', ['mtr_license']),

        mtrLicenseNumberErrors () {
            const errors = [];
            if (! this.$v.mtr_license_data.mtr_license_number.$dirty) return errors
            ! this.$v.mtr_license_data.mtr_license_number.required && errors.push('Field is required')
            return errors
        },
        mtrLicenseIssuesErrors () {
            const errors = [];
            if (! this.$v.mtr_license_data.issuing_city.$dirty) return errors
            ! this.$v.mtr_license_data.issuing_city.required && errors.push('Field is required')
            return errors
        }
    },

    methods: {
        ...mapActions('credentials', ['update']),

        /**
         *
         * @param mtr_license_data
         * @returns {boolean}
         */
        submit (mtr_license_data) {
            this.$v.$touch();
            this.isSubmit = true;

            //if invalid return false
            if (this.$v.$invalid) {
                return false;
            }

            this.update({
                data_name: 'mtr_license',
                data: mtr_license_data
            })
        }
    },

    created () {
        if (this.mtr_license) {
            this.mtr_license_data = this.mtr_license;
        }
    },
}
</script>