<template>
    <v-app class="order-review tw-p-[25px] tw-pt-[15px]">
        <div class="tw-flex tw-items-center tw-justify-between">
            <div class="tw-font-bold tw-text-[25px]">
                <span>{{ order.items_name + ' - #' + order.id }}</span>
            </div>
            <v-btn icon @click="close">
                <v-img src="/assets/icons/delete.svg" alt="close icon"/>
            </v-btn>
        </div>

        <div class="tw-grid tw-gap-[15px] tw-p-[15px] tw-border tw-border-d-gray tw-rounded-lg tw-mt-4">
            <div class="tw-h-max-[500px] !tw-overflow-y-scroll tw-h-[390px] overflow-scroll" style="scrollbar-width: thin !important;">
                <div class="tw-grid xl:tw-grid-cols-3 tw-gap-[16px]">
                    <div class="xl:tw-col-span-2">
                        <div class="tw-grid md:tw-grid-cols-2 tw-gap-[16px]">
                            <div class="tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[8px]" :class="{'tw-order-1': isHomeMove}">
                                <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Mover info</strong>
                                <div class="tw-h-[125px] tw-overflow-scroll tw-pr-[15px]">
                                  <ul class="tw-list-none !tw-p-0 tw-m-0">
                                    <li v-for="({ label, value }, index) in detailOrderFormat.moverInfo" :key="index" class="tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]">
                                      <span class="tw-inline-block tw-min-w-[115px] tw-mr-[10px] tw-text-[#6B6D81]" v-text="label"/>
                                      <span class="tw-text-[#6B6D81]">{{ valueMapper(value)}}</span>
                                    </li>
                                  </ul>
                                </div>
                            </div>
                            <div class="tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[8px]" :class="{'tw-order-3': isHomeMove}">
                                <div class="tw-flex tw-items-center tw-justify-between tw-mb-[8px]">
                                    <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px]">
                                      {{ isHomeMove ? 'Address ( In home moving )' : 'Pickup address' }}
                                    </strong>

                                    <button @click="copyText(detailOrderFormat.pickup[0].value, 'Pickup address')" class="tw-flex tw-items-center tw-gap-[5px] tw-p-[1px_7px] tw-bg-white tw-text-[#0D3659] tw-border tw-border-solid tw-border-[#E4E8FF] tw-rounded-[8px] tw-duration-300 hover:tw-bg-[#0D3659] hover:tw-border-[#0D3659] hover:tw-text-[#FFF]">
                                        <i class="icony icony-copy tw-max-w-[12px]"></i>
                                        <span class="tw-text-[12px] tw-font-semibold">Copy</span>
                                    </button>
                                </div>
                                <div class="tw-h-[125px] tw-overflow-scroll tw-pr-[15px]">
                                  <ul class="tw-list-none !tw-p-0 tw-m-0">
                                      <li v-for="({ label, value }, index) in detailOrderFormat.pickup" :key="index" class="tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]">
                                          <span class="tw-inline-block tw-min-w-[115px] tw-mr-[10px] tw-text-[#6B6D81]" v-text="label"/>
                                          <span class="tw-text-[#6B6D81]">{{ valueMapper(value)}}</span>
                                      </li>
                                  </ul>
                                </div>
                            </div>
                            <div class="tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[8px]" :class="{'tw-order-2 tw-row-span-2 tw-relative tw-min-h-[200px]': isHomeMove}">
                                <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Note</strong>

                                <div v-if="isHomeMove && !detailOrderFormat.notes" class="tw-flex tw-items-center tw-justify-center tw-absolute tw-inset-0">
                                  <img src="/assets/images/empty-box.png" alt="Empty" class="tw-w-[145px] tw-h-[125px] tw-object-contain"/>
                                </div>

                                <div class="tw-text-[#6B6D81] tw-font-medium tw-text-[14px] tw-h-[125px] tw-overflow-scroll" :class="{'tw-h-[322px]': isHomeMove}" v-else>
                                  <div v-html="valueMapper(detailOrderFormat.notes, 'There is no notes!').replace(/\n/g, '<br>')"/>
                                </div>
                            </div>
                            <div class="tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[8px]" v-if="order.destination">
                                <div class="tw-flex tw-items-center tw-justify-between tw-mb-[8px]">
                                    <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px]">Destination address</strong>

                                    <button @click="copyText(detailOrderFormat.destination[0].value, 'Destination address')" class="tw-flex tw-items-center tw-gap-[5px] tw-p-[1px_7px] tw-bg-white tw-text-[#0D3659] tw-border tw-border-solid tw-border-[#E4E8FF] tw-rounded-[8px] tw-duration-300 hover:tw-bg-[#0D3659] hover:tw-border-[#0D3659] hover:tw-text-[#FFF]">
                                        <i class="icony icony-copy tw-max-w-[12px]"></i>
                                        <span class="tw-text-[12px] tw-font-semibold">Copy</span>
                                    </button>
                                </div>
                                <div class="tw-h-[125px] tw-overflow-scroll tw-pr-[15px]">
                                  <ul class="tw-list-none !tw-p-0 tw-m-0">
                                      <li v-for="({ label, value }, index) in detailOrderFormat.destination" :key="index" class="tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]">
                                          <span class="tw-inline-block tw-min-w-[115px] tw-mr-[10px] tw-text-[#6B6D81]" v-text="label"/>
                                          <span class="tw-text-[#6B6D81]">{{ valueMapper(value)}}</span>
                                      </li>
                                  </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-h-full tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[8px]">
                        <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Item info</strong>
                        <div class="tw-h-[322px] tw-overflow-scroll tw-pr-[15px]">
                          <div class="tw-mb-[15px]">
                              <ul
                                class="tw-list-none !tw-p-[0_0_15px_0] last:!tw-pb-0 tw-mb-[15px] last:tw-mb-0 tw-border-b-[1px] tw-border-solid tw-border-[#e4e9ff] last:tw-border-b-0"
                                v-for="item in detailOrderFormat.items"
                                :key="item"
                              >
                                  <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                      <span class="tw-text-[#6B6D81]" v-text="'Item name:'"/>
                                      <span class="tw-text-[#6B6D81]">
                                          {{ valueMapper(item.name) }}
                                      </span>
                                  </li>
                                  <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                      <span class="tw-text-[#6B6D81]" v-text="'Item quantity:'"/>
                                      <span class="tw-text-[#6B6D81]">
                                          {{ valueMapper(item.count) }}
                                      </span>
                                  </li>
                                  <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                      <span class="tw-text-[#6B6D81]" v-text="'Weight:'"/>
                                      <span class="tw-text-[#6B6D81]">
                                          {{ valueMapper(item.weight, null, 'lbs') }}
                                      </span>
                                  </li>
                                  <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                      <span class="tw-text-[#6B6D81]" v-text="'Height:'"/>
                                      <span class="tw-text-[#6B6D81]">
                                          {{ valueMapper(item.height, null, 'ft') }}
                                      </span>
                                  </li>
                                  <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                      <span class="tw-text-[#6B6D81]" v-text="'Width:'"/>
                                      <span class="tw-text-[#6B6D81]">
                                          {{ valueMapper(item.width, null, 'ft') }}
                                      </span>
                                  </li>
                                  <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                      <span class="tw-text-[#6B6D81]" v-text="'Length:'"/>
                                      <span class="tw-text-[#6B6D81]">
                                          {{ valueMapper(item.length, null, 'ft') }}
                                      </span>
                                  </li>
                              </ul>
                          </div>
                          <div v-if="detailOrderFormat.otherInfo.length !== 0">
                              <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Other info</strong>
                              <ul class="tw-list-none !tw-p-[0_0_15px_0] last:!tw-pb-0 tw-mb-[15px] last:tw-mb-0 tw-border-b-[1px] tw-border-solid tw-border-[#e4e9ff] last:tw-border-b-0">
                                  <li v-for="(item, index) in detailOrderFormat.otherInfo" :key="index" class="tw-flex tw-justify-between tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]">
                                    <span class="tw-text-[#6B6D81]" v-text="item.label"/>
                                    <span class="tw-text-[#6B6D81]">
                                        {{ valueMapper(item.value) }}
                                    </span>
                                  </li>
                              </ul>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tw-max-h-[163px] tw-overflow-x-scroll">
              <div
                  v-show="detailOrderFormat.images.length"
                  class="tw-grid tw-grid-cols-4 sm:tw-grid-cols-6 lg:tw-grid-cols-8 xl:tw-grid-cols-12 tw-flex-wrap tw-gap-[10px] tw-bg-[#F6F8FF] tw-p-[15px] tw-rounded-[8px] tw-max-w-[100vw]"
              >
                  <div v-for="{ id, path: image } of detailOrderFormat.images" :key="id"
                       class="tw-relative tw-rounded-[4px] tw-overflow-hidden tw-cursor-pointer tw-group"
                       @click="openImage(image, id)"
                  >
                      <img :src="image" alt="product image" class="tw-w-full tw-aspect-[4/3] tw-object-cover"/>
                      <div class="tw-flex tw-items-center tw-justify-center tw-bg-black/50 tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-duration-300 tw-opacity-0 group-hover:tw-opacity-100">
                          <i class="icony icony-target-square tw-w-[24px] tw-text-white"></i>
                      </div>
                  </div>
              </div>
            </div>

            <div class="tw-pt-[15px] tw-border-t-[1px] tw-border-solid tw-border-[#DDDDDD]">
                <user-dashboard-delivery-card-footer :order="order"/>
            </div>
        </div>

    </v-app>
</template>

<script>
import UserDashboardDeliveryCardFooter from '@/views/UserDashboard/UserDashboardDeliveryCardFooter.vue';
import { EventBus } from '@/main';
import ViewImage from '@/components/ViewImage.vue';
import { copyText, valueMapper } from "@/plugins/helpers.js";
import { mapGetters } from 'vuex';

export default {
    props: {
        order: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
      return {
        isHomeMove: this.$store.state.user_orders.orderDetails.destination.length === 0,
      }
    },

    components: {
        UserDashboardDeliveryCardFooter
    },

    computed: {
        ...mapGetters('user_orders', ['detailOrderFormat']),
    },

    methods: {
        valueMapper,
        copyText,

        close () {
            this.$emit('close');
        },

        accept() {
            EventBus.$emit('acceptDelivery')
            this.close()
        },

        cancel() {
            EventBus.$emit('cancelDelivery')
            this.close()
        },

        openImage (image, id) {
            this.$modal.show(ViewImage, {
                image,
                id,
                actions_exist: false
            }, {
                width: 900,
                height: 650,
            })
        }
    }
}
</script>
