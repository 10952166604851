<template>
  <div>
    <v-autocomplete
      single-line
      hide-details
      outlined
      class="custom--v-text-field custom-v-autocomplete"
      :items="counties"
      item-text="name"
      item-value="id"
      v-model="modifiedLocal"
      :multiple="multiple"
      id="county"
      :search-input.sync="search"
      placeholder="Type to search..."
    />
  </div>
</template>

<script>
import ValueEmitter from "../mixins/ValueEmitter";
import { mapActions, mapState } from "vuex";

export default {

  mixins: [ValueEmitter],

  props: {
    multiple: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      search: '',
    }
  },

  methods: {
    ...mapActions('locations', ['loadCounties']),
  },

  computed: {
    ...mapState('locations', ['counties']),

    // This code is needed when autocomplete is not multiple but value is array
    modifiedLocal: {
      get() {
        let modifiedValue = this.local;
        if (!this.multiple && Array.isArray(modifiedValue)) {
          modifiedValue = modifiedValue[0];
        }
        return modifiedValue;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    search(query) {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        this.loadCounties(query);
      }, 500);
    },
  },

  created() {
    this.loadCounties(this.modifiedLocal.name);
  }
};
</script>

<style scoped lang="scss">
.custom-app-state > .custom-v-autocomplete::v-deep {
  background: #F5F5F9 !important;
  border: none !important;
  border-radius: 8px !important;

  input {
    color: #0D3659;
  }

  fieldset {
    border: none !important;
  }

  &:focus, &:focus-within, &:focus-visible {
    border: 1px solid #8F3985 !important;
  }
}
</style>
