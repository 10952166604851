<template>
    <v-app class="font-family-gilroy">
        <div class="desktop-main">
            <app-menu v-if="showNavbar"/>
            <router-view/>
        </div>
    </v-app>
</template>

<script>
import AppMenu from './components/AppMenu.vue';
import { isGuestRoute } from "@/router/routes.js";

export default {
    components: {
        AppMenu,
    },

    computed: {
        showNavbar () {
            return ! isGuestRoute(this.$route.name);
        }
    }
}
</script>
