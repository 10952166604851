import { BACKEND_URL } from "@/plugins/consts.js";

window.cache = {};

/**
 * make an api request to backend to validate asynchronous
 * if we checked this value before, show from cache
 *
 * @param {String} instance - name of field to be validated
 * @param {String} value    - value of field to be validated
 * @return {Promise<boolean>}
 */
const doValidate = async (instance, value) => {
    /**
     * if we do not have such instance on caching system, create a new one
     * note that caching is done only in runtime, so cache will be deleted
     * on page refresh
     */
    if (! cache.hasOwnProperty(instance)) {
        cache[instance] = {};
    }

    /**
     * if we have already validation response of this value in the cache,
     * show from there. it is saved as boolean in cache as in the response
     */
    if (cache[instance].hasOwnProperty(value)) {
        return cache[instance][value];
    }

    const { valid } = await axios.get(`validations/${ instance }/${ value }`, {
        baseURL: BACKEND_URL,
    });

    /**
     * save incoming validation response into the cache, and return value
     */
    return cache[instance][value] = valid;
};

/**
 * asynchronous zip_code validation
 *
 * @return {Boolean}
 */
export const validZipCode = async value => {
    const zip_code = parseInt(value).toString();

    if (zip_code.length !== 5) return false;

    return await doValidate('zip_code', zip_code);
};

export const validEmail = async value => {
    return await doValidate('email', value);
};