<template>
    <v-main class="custom--v-main">
        <app-header>
            <h3 class="tw-text-[25px] tw-font-bold tw-text-[#0D3659]">
                Locations
            </h3>
            <template #right>
                <v-btn depressed color="primary" class="custom--v-btn" @click="addLocation">
                    Add Location
                </v-btn>
            </template>
        </app-header>

        <main v-if="isLoaded"
              class="tw-p-[20px] md:tw-p-[30px] tw-w-[calc(100%_-_40px)] md:tw-w-full tw-max-w-[1230px]">
            <div class="tw-flex tw-flex-col tw-gap-[15px]">
                <div
                    v-for="branch of branches"
                    :key="branch.id"
                    :class="{'tw-order-first': branch.is_main}"
                >
                    <locations-item v-bind="{branch, load}"/>
                </div>
            </div>
        </main>

        <app-loading-indicator v-else/>
    </v-main>
</template>

<script>
import LocationsFilter from "./LocationsFilter";
import LocationsItem from "./LocationsItem";
import { mapState } from "vuex";
import loading from "../../mixins/loading";
import LocationsModal from "./LocationsModal";
import AppLoadingIndicator from "../../components/AppLoadingIndicator";

export default {
    components: {
        LocationsFilter,
        LocationsItem,
        AppLoadingIndicator,
    },

    mixins: [loading],

    computed: {
        ...mapState('locations', ['branches']),
    },

    methods: {
        /**
         *
         * @returns {Promise<void>}
         */
        async load () {
            this.loadingStart();
            await this.$store.dispatch('locations/load');
            this.loadingFinish();
        },

        addLocation () {
            const modalProps = {
                width: 1000,
                height: 565,
            };

            this.$modal.show(LocationsModal, {
                load: this.load,
                isCreating: true,
                branch: {
                    name: '',
                    address: '',
                    state_id: null,
                    city_id: null,
                    zip_code: '90001',
                    latitude: 33.974027,
                    longitude: -118.249509,
                    service_area: 25,
                    primary_phone: null
                }
            }, modalProps);
        }
    },

    created () {
        this.load();
    }
};
</script>
<style lang="scss" scoped>
.location {
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #DDDDDD;
    box-shadow: 0 20px 40px rgba(#7090b0, 0.1);

    &-main {
        margin: 20px 30px;
    }
}
</style>
