<template>
    <main class="credentials">
        <section v-if="isLoaded" class="__loading--section">
            <section class="credentials__top d-flex align-center mb-5">
                <div class="d-flex align-center mr-7">
                    <span>Get your verified profile badge</span>
                    <img src="/assets/images/profile/badge.svg" class="justify-end d-inline-block ml-auto"
                         alt="badge icon"/>
                </div>
                <div class="d-flex align-center">
                    <span>You get more orders if you are verified</span>
                    <img src="/assets/images/profile/win.svg" class="justify-end d-inline-block ml-auto" alt="win icon"/>
                </div>
            </section>
            <section class="credentials__body mb-5">
                <credentials-mtr-license :branch-id="activeBranch.id"/>
                <credentials-general-liability :branch-id="activeBranch.id"y/>
                <credential-cargo-insurance :branch-id="activeBranch.id"/>
                <credentials-transportation-service :branch-id="activeBranch.id"/>
                <credentials-workers-compensation :branch-id="activeBranch.id"/>
            </section>
            <credentials-drug-test :branch-id="activeBranch.id"/>
        </section>
        <app-loading-indicator v-else />
    </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import loading from "@/mixins/loading.js";
import CredentialsMtrLicense from "@/views/Profile/CredentialsMtrLicense.vue";
import CredentialsGeneralLiability from "@/views/Profile/CredentialsGeneralLiability.vue";
import CredentialCargoInsurance from "@/views/Profile/CredentialCargoInsurance.vue";
import CredentialsTransportationService from "@/views/Profile/CredentialsTransportationService.vue";
import CredentialsWorkersCompensation from "@/views/Profile/CredentialsWorkersCompensation.vue";
import CredentialsDrugTest from "@/views/Profile/CredentialsDrugTest.vue";
import AppLoadingIndicator from "@/components/AppLoadingIndicator.vue";

export default {
    name: "Credentials",
    components: {
        AppLoadingIndicator,
        CredentialsDrugTest,
        CredentialsWorkersCompensation,
        CredentialsTransportationService,
        CredentialCargoInsurance,
        CredentialsGeneralLiability,
        CredentialsMtrLicense
    },
    mixins: [loading],


    data () {
        return {
            credentials: {}
        }
    },

    computed: {
        ...mapGetters('auth', ['activeBranch']),
    },

    methods: {
        ...mapActions('credentials', ['load'])
    },

    created () {
        this.startLoading( async () => {
            await this.load();
        })
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/pages/profile/credentials";
</style>