<template>
    <v-col class="invite-generate__column">
        <div class="invite-generate__coupon d-flex align-center">
            <div class="coupon-left d-flex align-center justify-center flex-column">
                <v-btn @click="increase" :disabled="couponPrice >= prices.availablePrice">+</v-btn>
                <div class="coupon-left__input d-flex align-center justify-center">
                    <img src="assets/images/referral/dollor.svg" alt="dolor icon">
                    <input class="invite-generate__code" type="number" v-model="couponPrice">
                </div>
                <v-btn @click="decrease" :disabled="couponPrice <= 0 || prices.availablePrice === 0">-</v-btn>
            </div>
            <div class="coupon-right">
                <div class="d-flex align-center">
                    <h4>Your coupon code</h4>
                    <v-tooltip top content-class="charge-text__tooltip">
                        <template v-slot:activator="{ on, attrs }">
                            <img v-bind="attrs" v-on="on" src="assets/icons/info.svg" alt="info icon" width="12">
                        </template>
                        <span class="custom--v-tooltip">
                                You can generate various unique coupon codes for different amounts of money you want to use on checkout.
                            </span>
                    </v-tooltip>
                </div>
                <input
                    readonly
                    :placeholder="getCouponCode()"
                >
                <v-btn
                    color="primary"
                    class="custom--v-btn"
                    depressed min-width="71"
                    style="height: 29px !important;"
                    @click="activate"
                    :disabled="isCopyDisable"
                >
                    Activate
                </v-btn>
            </div>
        </div>
    </v-col>
</template>

<script>
import { copyClipboard } from "../../../plugins/helpers.js";
import { sweetAlertError } from "../../../plugins/swal.js";
import loading from "../../../mixins/loading.js";
import { generateCouponCode } from "../../../mixins/generateCouponCode.js";

export default {
    name: "InviteFriendsGenerateCode",

    mixins:[loading],

    props: {
        prices: {
            couponPrice: {
                type: String,
                required: true
            },
            availablePrice: {
                type: String,
                required: true
            }
        }
    },

    data () {
        return {
            isCopyDisable: true,
            couponCode: null,
            couponPrice: this.prices.couponPrice
        }
    },

    methods: {
        increase () {
            this.couponPrice++;
        },

        decrease () {
            this.couponPrice--;
        },

        activate () {
            if (this.couponPrice > 0 && this.prices.availablePrice >= this.couponPrice) {
                this.startLoading(async () => {
                    const { amount, coupon, user_credit } = await axios.post('customer/promos/generate', {
                        amount: this.couponPrice,
                        coupon: this.couponCode
                    });

                    this.couponPrice = amount
                    this.couponCode = coupon;
                    this.availableAmount = user_credit;
                });
                copyClipboard(this.couponCode, 'Coupon code is copied and activated successfully');
            } else {
                sweetAlertError('Please enter a valid amount')
            }
        },

        getCouponCode () {
            if (this.couponCode) {
                return this.couponCode.split('').join(' ');
            }
            return 'Coupon code'
        }
    },

    watch: {
        couponPrice (value) {
            if (typeof (value) === 'string') {
                value = Number(value);
            }

            if (this.prices.availablePrice >= this.couponPrice && this.couponPrice > 0 && Number.isInteger(value)) {
                this.couponCode = generateCouponCode(value);
                this.isCopyDisable = false;
            } else {
                this.isCopyDisable = true;
            }

            if (value <= 0 || value > this.prices.available) {
                this.couponCode = null;
            }
        }
    }
}
</script>

<style scoped>

</style>
