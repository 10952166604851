import Vue from "vue"
import Vuex from "vuex"
import auth from "./auth";
import dashboard from "./dashboard";
import locations from "./locations";
import orders from "./orders";
import calendar from "./calendar";
import working_days from "./working_days";
import service_pricing from "./service_pricing";
import discounts from "./discounts.js";
import company from "./company";
import drawer from "./drawer";
import team from "./team";
import user_orders from "./user_orders";
import referrals from "./referrals.js";
import invites from "./invites";
import reschedule from "./reschedule.js";
import notifications from "./notifications.js";
import customer_reschedule from "./customer_reschedule";
import review from "./review.js";
import profile from "./profile";
import labor from "./labor";
import profile_introduction from "./profile_introduction.js";
import faq from "./faq.js";
import photos from "./photos.js";
import credentials from "./credentials.js";
import viewHelper from "./view-helper.js";
import customer_new_order from "./customer_new_order";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        dashboard,
        locations,
        orders,
        calendar,
        working_days,
        service_pricing,
        discounts,
        company,
        drawer,
        team,
        user_orders,
        referrals,
        invites,
        reschedule,
        notifications,
        customer_reschedule,
        review,
        profile,
        profile_introduction,
        faq,
        photos,
        credentials,
        viewHelper,
        labor,
        customer_new_order
    },
});
