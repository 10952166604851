var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"order-review"},[_c('div',{staticClass:"location-modal__exit d-flex align-center justify-end mr-2 mt-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-img',{attrs:{"src":"/assets/icons/delete.svg","alt":"close icon"}})],1)],1),_c('main',{staticClass:"order-review__main"},[_c('h1',{staticClass:"text-center text-white"},[_vm._v("All Reviews")]),(_vm.reviews.length > 0 && _vm.order.can_cancel)?_c('div',{staticClass:"order-review__block"},_vm._l((_vm.reviews),function(ref){
var id = ref.id;
var comment = ref.comment;
var customer = ref.customer;
var helper = ref.helper;
var published_at = ref.published_at;
var star = ref.star;
var image = ref.image;
return _c('div',{key:id,staticClass:"order-review__card"},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('img',{attrs:{"src":image,"alt":"reviewer name","width":"35","height":"29"}}),_c('div',{staticClass:"order-review__heading"},[_c('h5',{staticClass:"text-secondary"},[_vm._v(_vm._s(customer || helper))]),_c('div',{staticClass:"d-flex align-center"},[_c('v-rating',{staticClass:"custom--v-rating",attrs:{"half-icon":"fa-star-half-alt","size":"10","readonly":"","color":"#FBAB1E","background-color":"#FBAB1E","value":Number(star)}}),_c('span',{staticClass:"mt-1"},[_vm._v(_vm._s(published_at))])],1)])]),_c('div',{staticClass:"order-review__comment"},[_c('p',[_vm._v(_vm._s(comment))])])])}),0):_c('div',{staticClass:"order-review__empty mx-auto m-5 font-secondary text-secondary"},[_c('v-img',{attrs:{"src":"assets/images/logging-off.svg","alt":"no talking and empty image","height":"200"}}),_c('p',{staticClass:"mt-4 text-center"},[_vm._v(" No reviews yet for this order ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }