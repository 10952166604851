<script>
import { maska } from "maska";
import { mapActions, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      unitNumber: '',
      streetAddress: '',
      city: '',
      state: '',
      zipCode: '',
      serviceArea: '',
      socialSecurityNumber: '',
      vehicleType: '',
      vehicleYear: '',
      vehicleMake: '',
      vehicleModel: '',
      file: null,
      stateName: '',
    }
  },
  directives: {
    maska
  },

  validations: {
      email: {
          required,
          email
      },
    },

  computed: {
    ...mapState('locations', ['states', 'cities']),
    ...mapState('auth', ['user']),
  },

  mixins: [validationMixin],

  methods: {
    ...mapActions('locations', ['loadStates', 'loadCities']),
    ...mapActions('labor', ['updateLabor']),

    onFileChange(file) {
      this.file = file.target.files[0];
    },

    async getState(state_id) {
      this.stateName = this.states.find(state => state.id === state_id).name;
      await this.loadCities(state_id) || [];
    },
    savePersonalInfo() {
      const formData = new FormData();
      formData.append('file', this.file);

      const personalInfo = {
            personal: {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            phone: this.phoneNumber
        },
        options: {
            address: this.stateName,
            state_id: this.state,
            city_id: this.city,
            zip_code: this.zipCode,
            service_area: this.serviceArea,
            has_vehicle: true
        },
        vehicle: {
            type: this.vehicleType,
            model: this.vehicleModel,
            year: this.vehicleYear,
            make: this.vehicleMake
        }
      }

      this.updateLabor({ id: this.user.id, personalInfo })
    }
  },
  async mounted() {
    await this.loadStates();
  }
}
</script>

<template>
  <v-card class="tw-mx-[30px] tw-my-[18px] tw-p-8">
    <div class="tw-flex tw-items-start tw-justify-between tw-px-3">
      <div class="tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-mb-8">
        <img 
          src="../../../../public/assets/images/profile/labor.png" 
          class="tw-rounded-full tw-w-[80px] tw-h-[80px]"
          alt=""
        />
        <div class="tw-text-center tw-ml-[24px]">
          <h2 class="tw-font-bold tw-text-lg tw-mb-3 tw-text-secondary">Roberd Polansky</h2>
          <input id="file" class="tw-hidden" accept="image/*" type="file" @change="onFileChange($event)"/>
          <label for="file" class="tw-text-sm tw-font-semibold tw-py-[5px] tw-px-[15px] tw-rounded-lg tw-text-secondary" style="border: 1px solid #ddd;">Upload image</label>
      </div>
    </div>
    <input type="checkbox" id="switch" checked />
    <label for="switch" id="label"></label>
  </div>
  <span class="tw-w-full tw-h-[2px] tw-bg-[#ddd] tw-block tw-mb-8 tw-mx-auto"></span>
  <h2 class="tw-text-base tw-font-bold tw-text-secondary tw-mb-6">Tell us about yourself</h2>
  <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap tw-mb-8">
    <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
      <div class="tw-mx-2 tw-mb-4">
        <div class="text-fiel-label">
          First name
        </div>
        <input 
          class="custom-input tw-border-2 tw-w-full tw-border-[#ddd] tw-rounded-lg tw-bg-[#f5F5F9] tw-outline-none tw-px-3 tw-py-2"
          v-model="firstName"
          type="text"
          placeholder="Your first name"
        />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
        <div class="tw-mx-2 tw-mb-4">
          <div class="text-fiel-label">
            Last name
          </div>
          <input 
            class="custom-input tw-border-2 tw-w-full tw-border-[#ddd] tw-rounded-lg tw-bg-[#f5F5F9] tw-outline-none tw-px-3 tw-py-2"
            v-model="lastName"
            type="text"
            placeholder="Your last name"
          />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
        <div class="tw-mx-2 tw-mb-4">
          <div class="text-fiel-label">
            Email
          </div>
          <input 
            class="custom-input tw-border-2 tw-w-full tw-border-[#ddd] tw-rounded-lg tw-bg-[#f5F5F9] tw-outline-none tw-px-3 tw-py-2"
            v-model.trim="email"
            type="text"
            placeholder="Write your email"
            :error="$v.email.$error"
          />
          <small v-if="$v.email.$error">
            Invalid email
          </small>
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
        <div class="tw-mx-2 tw-mb-4">
          <div class="text-fiel-label">
            Phone number
          </div>
          <input 
            class="custom-input tw-border-2 tw-w-full tw-border-[#ddd] tw-rounded-lg tw-bg-[#f5F5F9] tw-outline-none tw-px-3 tw-py-2"
            v-model="phoneNumber"
            type="text"
            v-maska="'+1 (###) - ### - ####'"
            placeholder="+1 (___) - ___ - ____"
          />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
        <div class="tw-mx-2 tw-mb-4">
          <div class="text-fiel-label">
            Street address
          </div>
          <input 
            class="custom-input tw-border-2 tw-w-full tw-border-[#ddd] tw-rounded-lg tw-bg-[#f5F5F9] tw-outline-none tw-px-3 tw-py-2"
            v-model="streetAddress"
            type="text"
            placeholder="Street address"
          />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
        <div class="tw-mx-2 tw-mb-4">
          <div class="text-fiel-label">
            Apt /unit number
          </div>
          <input 
            class="custom-input tw-border-2 tw-w-full tw-border-[#ddd] tw-rounded-lg tw-bg-[#f5F5F9] tw-outline-none tw-px-3 tw-py-2"
            v-model="unitNumber"
            type="text"
            placeholder="Enter your security number"
          />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4 tw-mb-4 md:tw-mt-0 lg:tw-mb-4">
        <div class="tw-mx-2 tw-mb-0">
          <div class="text-fiel-label">
            State
          </div>
          <v-autocomplete
            v-model="state"
            single-line 
            hide-details
            outlined 
            filled
            dense
            label="Choose state"
            class="custom--v-text-field custom-v-autocomplete"
            :items="states"
            item-text="name" 
            item-value="id"
            background-color="#f5F5F9"
            id="state"
            @change="getState(state)"
          />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4 tw-mb-4 md:tw-mt-0 lg:tw-mb-4">
        <div class="tw-mx-2 tw-mb-0">
          <div class="text-fiel-label">
            City
          </div>
          <v-autocomplete
            v-model="city"
            single-line 
            hide-details
            outlined 
            filled
            dense
            label="Choose city"
            class="custom--v-text-field custom-v-autocomplete"
            :items="cities" 
            item-text="display" 
            item-value="value"
            background-color="#f5F5F9"
            id="city"
          />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4 tw-mb-4 md:tw-mt-0 lg:tw-mb-4">
        <div class="tw-mx-2 tw-mb-0">
          <div class="text-fiel-label">
            Zip code
          </div>
          <input 
            class="custom-input tw-border-2 tw-w-full tw-border-[#ddd] tw-rounded-lg tw-bg-[#f5F5F9] tw-outline-none tw-px-3 tw-py-2"
            v-model="zipCode"
            type="text"
            placeholder="Your zip code"
          />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4 tw-mb-4 md:tw-mt-0 lg:tw-mb-4">
        <div class="tw-mx-2 tw-mb-0 tw-mt-0 ">
          <div class="text-fiel-label">
            Service area
          </div>
          <v-autocomplete
            v-model="serviceArea"
            single-line 
            hide-details
            outlined 
            filled
            dense
            label="Choose your area"
            class="custom--v-text-field custom-v-autocomplete"
            :items="['Item 1', 'Item 2', 'Item 3', 'Item 4']" 
            item-text="name" 
            item-value="id"
            background-color="#f5F5F9"
            id="servArea"
          />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4 tw-mb-4 md:tw-mt-0 lg:tw-mb-4">
        <div class="tw-mx-2 tw-mb-0">
          <div class="text-fiel-label">
            Social security number
          </div>
          <input 
            class="custom-input tw-border-2 tw-w-full tw-border-[#ddd] tw-rounded-lg tw-bg-[#f5F5F9] tw-outline-none tw-px-3 tw-py-2"
            v-model="socialSecurityNumber"
            type="text"
            placeholder="Your social security number"
          />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
        <div class="tw-mx-2 tw-mb-0 tw-hidden"/>
      </div>
    </div>
    <h2 class="tw-text-[16px] tw-font-bold tw-text-secondary tw-mb-[24px]">
      Tell us about the vehicle you'll use for Tophelpers
    </h2>
    <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap tw-mb-8 sm:tw-mb-[108px]">
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4 tw-mb-4 md:tw-mt-0 lg:tw-mb-4">
        <div class="tw-mx-2 tw-mb-0">
          <div class="text-fiel-label">
            Vehicle type
          </div>
          <v-autocomplete
            v-model="vehicleType"
            single-line 
            hide-details
            outlined 
            filled
            dense
            label="Select a type"
            class="custom--v-text-field custom-v-autocomplete"
            :items="[ {id: 1, name: 'Sedan'}, {id: 2, name: 'Hatchback'}, { id: 3, name: 'Pickup'}, { id: 4, name: 'Truck'}]" 
            item-text="name"
            item-value="id"
            background-color="#f5F5F9"
            id="state"
          />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4 tw-mb-4 md:tw-mt-0 lg:tw-mb-4">
        <div class="tw-mx-2 tw-mb-0">
          <div class="text-fiel-label">
            Vehicle year
          </div>
          <v-text-field
            v-model="vehicleYear"
            single-line 
            hide-details
            outlined 
            filled
            dense
            v-maska="'####'"
            label="2012"
            class="custom--v-text-field custom-v-autocomplete"
            item-text="name" 
            item-value="id"
            background-color="#f5F5F9"
            id="state"
          />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
        <div class="tw-mx-2 tw-mb-4">
          <div class="text-fiel-label">
            Vehicle make
          </div>
          <input 
            class="custom-input tw-border-2 tw-w-full tw-border-[#ddd] tw-rounded-lg tw-bg-[#f5F5F9] tw-outline-none tw-px-3 tw-py-2"
            v-model="vehicleMake"
            type="text"
            placeholder="Vehicle make"
          />
        </div>
      </div>
      <div class="tw-w-full md:tw-w-1/2 lg:tw-w-1/4">
        <div class="tw-mx-2 tw-mb-4">
          <div class="text-fiel-label">
            Vehicle model
          </div>
          <input 
            class="custom-input tw-border-2 tw-w-full tw-border-[#ddd] tw-rounded-lg tw-bg-[#f5F5F9] tw-outline-none tw-px-3 tw-py-2"
            v-model="vehicleModel"
            type="text"
            placeholder="Vehicle model"
          />
        </div>
      </div>
    </div>
    <div class="tw-flex tw-justify-end">
      <v-btn 
        color="#8F3985"
        class="!tw-text-[#fff] tw-w-[177px]"
        type="submit"
        @click="savePersonalInfo"
      >
        Save
      </v-btn>
    </div>
  </v-card>
</template>

<style lang="scss" scoped>
.v-text-field__details > .v-messages theme--light {
  display: none !important;
}
.text-fiel-label {
  color: #6B6D81;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;
}
.custom-input::placeholder {
  font-size: 14px;
}
#label {
  width: 106px;
  height: 30px;
  background: #F5F5F9;
  border-radius: 25px;
  position: relative;
  border: 1px solid #ddd;
}
#label::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 28px;
  border-radius: 25px;
  background: #fff;
  top: 0;
  left: 1px;
  transform: translateX(62px);
  transition: 0.3s;
}
#label::after {
  content: "Inactive";
  position: absolute;
  width: 80px;
  top: 2px;
  left: 5px;
  transform: translateX(0px);
  transition: 0.3s ease-in-out;
}
input[type="checkbox"] {
  display: none;
}
input[type="checkbox"]:checked + #label::before {
  transform: translateX(-1px);
}
input[type="checkbox"]:checked + #label::after {
  content: "Active";
  color: #F5F5F9;
  transform: translateX(40px);
}
input[type="checkbox"]:checked + #label {
  background-color: #12924C;
}
</style>