import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import '@babel/polyfill';

import "./plugins/axios";
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import VModal from "vue-js-modal";
import pluralize from "pluralize";
import VueSocialSharing from "vue-social-sharing";
import Vuelidate from 'vuelidate';
import VueCookies from 'vue-cookies';
import VueSplide from '@splidejs/vue-splide';
import VueMeta from 'vue-meta';
import ScrollLock from '@/plugins/scroll_lock';

// Styles
import "vue-js-modal/dist/styles.css"
import "./assets/tailwind.css";
import "./assets/scss/style.scss";
import { getParam } from "@/plugins/helpers";
import "./plugins/str.prototype.js";
export const EventBus = new Vue();

// Components
import AppHeader from "./components/AppHeader.vue";

Vue
    .use(VModal, { dynamicDefault: { draggable: true, resizable: true, adaptive:true } })
    .use(VueRouter)
    .use(VueSocialSharing)
    .use(Vuelidate)
    .use(VueCookies)
    .use(VueSplide)
    .use(VueMeta)
    .use(ScrollLock)

Vue.component('app-header', AppHeader);

// Global methods
Vue.prototype.$pluralize = function (amount, label) {
    return amount + " " + pluralize(label, amount);
}

store.dispatch('auth/check', getParam('token')).then(() => {
    new Vue({
        vuetify,
        router,
        store,
        render: h => h(App),
    }).$mount('#app');
});
