<template>
    <div>
        <v-autocomplete
            single-line hide-details
            outlined class="custom--v-text-field custom-v-autocomplete"
            :items="cities" item-text="display" item-value="value"
            v-model="local"
            id="city"
        />
    </div>
</template>

<script>
import ValueEmitter from "../mixins/ValueEmitter";
import { mapActions, mapState } from "vuex";
import { find } from "lodash";

export default {
    props: {
        stateId: {
            type: Number | null,
            required: true,
        },
    },

    data () {
        return {
            cities: []
        }
    },

    mixins: [ValueEmitter],

    methods: {
        ...mapActions('locations', ['loadCities']),
    },

    computed: {
        ...mapState('locations', ['states']),
    },

    watch: {
        stateId: {
            immediate: true,

            async handler (state_id) {
                if (state_id) {
                    this.cities = await this.loadCities(state_id);

                    if (! find(this.cities, ['value', this.local])) {
                        this.local = this.cities.at(0).value;
                    }
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.custom-app-city > .custom-v-autocomplete::v-deep {
    background: #F5F5F9 !important;
    border:none !important;
    border-radius: 8px !important;

    input {
      color: #0D3659;
    }

    fieldset {
      border:none !important;
    }

    &:focus, &:focus-within, &:focus-visible {
      border: 1px solid #8F3985 !important;
    }
}
</style>
