import auth from "@/store/auth.js";

export default {
    namespaced: true,

    state: {
        reviews: {
            sent: {}, received: {}
        }, replies_count: 1
    },

    getters: {
        reviews: state => state.reviews
    },

    actions: {
        /**
         @returns {Promise<Object>}
         */

        async index ({ state }) {
            if (auth.state.user.isCustomer) {
                const { sent, received } = await axios.get('/customer/reviews');
                state.reviews.sent = sent;
                state.reviews.received = received;
            } else {
                const { sent, received } = await axios.get('/reviews');
                state.reviews.sent = sent;
                state.reviews.received = received;
            }
        },

        async reply ({ dispatch }, { review_id, comment }) {
            await axios.post(`/reviews/${ review_id }/reply`, { comment });

            await dispatch('index');
        },

        async helper_edit ({ dispatch }, { review_id, star, comment }) {
            await axios.put(`/reviews/${ review_id }`, {
                star, comment
            });

            await dispatch('index');
        },

        async customer_edit ({ dispatch }, { review_id, star, comment }) {
            await axios.put(`/customer/reviews/${ review_id }`, {
                star, comment
            });

            await dispatch('index');
        },

        async helper_publish ({ dispatch }, review_id) {
            await axios.post(`/reviews/${ review_id }/publish`);

            await dispatch('index');
        },

        async customer_publish ({ dispatch }, review_id) {
            await axios.post(`/customer/reviews/${ review_id }/publish`);

            await dispatch('index');
        },

        async get_helper_order_reviews (_, order_id) {
            return await axios.get(`/orders/${ order_id }/reviews`);
        },

        async get_customer_order_reviews (_, order_id) {
            return await axios.get(`/customer/orders/${ order_id }/reviews`)
        }
    }
}
