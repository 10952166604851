<template>
    <div class="tw-p-[15px] tw-rounded-[8px] tw-bg-white">
        <div class="tw-rounded-[8px] tw-overflow-hidden tw-border tw-border-solid tw-border-[#dddddd]">
            <div :class="[timeDiff === 0 ? 'bg--change': '', 'tw-bg-[#E4E8FF] tw-px-[15px] tw-py-[10px] tw-mb-[10px]']">
                <div class='tw-flex tw-justify-between'>
                    <div class="tw-font-semibold tw-text-[#0D3659]">
                        <span>{{ delivery.items_name + ' - #' + delivery.id }}</span>
                    </div>
                    <div
                        class="tw-block tw-px-[15px] tw-py-[3px] tw-bg-white tw-text-[#0D3659] tw-font-semibold tw-text-[14px] tw-rounded-[8px]">
                        <span v-text="'See reviews'"/>
                    </div>
                    <div v-if="showUpOrderTime" class="ml-auto ml-sm-0 card-time d-flex align-center">
                        <v-icon size="14" color="secondary" class="mr-2 ml-3">fa-clock</v-icon>
                        <span>{{ timeDiff }}</span>
                    </div>
                </div>
            </div>

            <v-card outlined tile class="rounded-b dashboard-card__main">
                <div class="tw-bg-[#F4F6FF]">
                    <div
                        class="tw-w-full tw-flex tw-items-center tw-justify-between tw-flex-wrap tw-px-[15px] tw-py-[8px]">
                        <div class="tw-font-bold tw-text-[#0D3659]">
                            <span v-text="delivery.status"/>
                        </div>
                        <button type="button"
                                @click="openDeliveryDetailsModal(delivery.id)"
                                class="tw-bg-[white] tw-border tw-border-solid tw-border-[#E3E8FF] tw-text-[14px] tw-p-[2px_12px] tw-rounded-[8px] tw-text-[#0D3659] tw-font-semibold hover:tw-bg-[#0D3659] hover:tw-border-[#0D3659] tw-duration-300 hover:tw-text-[#FFF]">
                            {{ isCardOpened ? "Close details" : "View details" }}
                        </button>
                    </div>
                </div>

                <div class="tw-p-[15px_15px_0_15px]" v-if="getVehicle || delivery.acceptor.phone">
                    <div
                        class="tw-flex tw-items-center tw-justify-between tw-gap-[15px] tw-pb-[15px] tw-border-b tw-border-solid tw-border-[#DDDDDD]">
                        <div class="tw-flex tw-items-center tw-justify-between tw-gap-[5px]" v-if="getVehicle">
                            <img :src="getVehicle.image" alt="" class="tw-h-[18px] tw-w-auto">
                            <span class="tw-text-[16px] tw-text-[#0D3659] tw-font-semibold tw-whitespace-nowrap"> -
                                {{ getVehicle.name }}
                            </span>
                        </div>
                        <div class="tw-text-[16px] tw-text-[#0D3659] tw-font-semibold tw-ml-auto"
                             v-if="delivery.acceptor.phone">Helper phone: {{ delivery.acceptor.phone }}
                        </div>
                    </div>
                </div>

                <div class="tw-p-[15px]">
                    <user-dashboard-delivery-card-footer :delivery="delivery"/>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserDashboardDeliveryCardDetailsModal from "@/views/UserDashboard/UserDashboardDeliveryCardDetailsModal";
import UserDashboardDeliveryCardFooter from "@/views/UserDashboard/UserDashboardDeliveryCardFooter.vue";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { convertSecondToTime } from "@/plugins/helpers.js";
import { EventBus } from '@/main';
import { DASHBOARD_URL } from '@/plugins/consts';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
    components: {
        UserDashboardDeliveryCardDetailsModal,
        UserDashboardDeliveryCardFooter
    },

    props: {
        delivery: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            isCardOpened: false,
            timeDiff: 0,
            interval: null,
        }
    },

    methods: {
        ...mapActions('orders', { showHelperOrder: 'show' }),
        ...mapActions('user_orders', { showCustomerOrder: 'show' },),
        ...mapActions('user_orders', ['getOrderDetails']),
        ...mapActions('review', ['get_helper_order_reviews', 'get_customer_order_reviews']),

        getDate(date) {
            return date ? date.split(' ')[0] : ''
        },

        async openDeliveryDetailsModal(id) {
            await this.getOrderDetails(id);

            this.$modal.show(UserDashboardDeliveryCardDetailsModal, {
                order: this.delivery
            }, {
                width: 1200,
                height: 'auto',
            }, {
                'before-open': this.$scrollLock.enable,
                'before-close': this.$scrollLock.disable,
            });
            EventBus.$on('acceptDelivery', () => {
                this.shouldAccept = true;
            });
            EventBus.$on('cancelDelivery', () => {
                this.shouldCancel = true;
            });
        },
    },

    computed: {
        ...mapGetters('auth', ['isCustomer']),

        ...mapGetters('user_orders', ['detailOrderFormat']),

        showUpOrderTime() {
            return !this.isCustomer && this.timeDiff && this.delivery.status !== 'Rejected' && this.delivery.status !== 'Canceled' && this.delivery.status !== 'Accepted'
        },

        getVehicle() {
            const vehicle = this.delivery.vehicle;

            if (vehicle) {
                const slug = vehicle.toLowerCase();
                return {
                    name: vehicle,
                    image: `${ DASHBOARD_URL }/assets/images/vehicles/${ slug }.svg`,
                }
            }
        },
    },

    mounted() {
        this.interval = setInterval(() => {
            const a = new Date(this.delivery.till_auto_reject);
            const b = new Date(dayjs().tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss'));

            const difference = a - b;

            this.timeDiff = difference > 0
                ? convertSecondToTime(difference)
                : null;
        }, 1000);
    },

    destroyed() {
        clearInterval(this.interval);
    }
}
</script>
