<template>
    <v-row class="w-100 order-card__info py-3">
        <v-col sm="12" md="4">
            <h3>Reservation</h3>
            <ul>
                <li>Type of move: <strong>{{ orderDetails.type_moving }}</strong></li>
                <li>Service method: <strong>{{ orderDetails.service }}</strong></li>
                <li v-if="orderDetails.bedrooms_count > 0">Number of bedrooms: <strong>{{ orderDetails.bedrooms_count }}</strong></li>
                <li>Number of floors: <strong>{{ orderDetails.stories_count }}</strong></li>
                <li></li>
            </ul>
        </v-col>
        <v-col sm="12" md="4">
            <h3>Order details</h3>
            <ul>
                <li>Type of help: <strong>{{ orderDetails.type_help }}</strong></li>
                <li>Transportation type: <strong>{{ orderDetails.type_transportation.join(', ') }}</strong>
                </li>
                <li v-if="orderDetails.truck_size">Amount & Size of truck: <strong>{{ orderDetails.truck_size }}</strong></li>
                <li>Payment type: <strong>{{ orderDetails.type_payment }}</strong></li>
                <li v-if="orderDetails.unique_items">Extra heavy items: <strong>{{ heavyItems(orderDetails.unique_items) }}</strong></li>
            </ul>
        </v-col>
        <v-col sm="12" md="4">
                <h3>Contact info</h3>
                <ul>
                    <li>Full name: <strong>{{ orderDetails.name }}</strong></li>
                    <li>Email: <strong>{{ orderDetails.email }}</strong></li>
                    <li class="d-flex">
                        <span>{{ orderDetails.secondary_phone ? 'Phone numbers:' : 'Phone number:'}}</span>
                        <div class="d-flex ml-2 flex-column">
                            <strong class="mr-2"> {{ orderDetails.phone }}</strong>
                            <strong> {{ orderDetails.secondary_phone }}</strong>
                        </div></li>
                </ul>
        </v-col>
        <v-col sm="12" md="4" v-if="orderDetails.pickups.length > 0">
            <h3>Pickups</h3>
            <ul v-for="{address, city_or_town, flights_of_stairs, state, zip_code, type_property} of orderDetails.pickups">
                <li>Address: <strong>{{ address }}</strong></li>
                <li>City or town & State & Zip-code: <strong>{{ city_or_town }}, {{ state }} {{ zip_code }} </strong></li>
                <li>Flights of stairs: <strong>{{ flights_of_stairs }}</strong></li>
                <li>Property Type: <strong> {{ properties[type_property] }} </strong></li>
            </ul>
        </v-col>
        <v-col sm="12" md="4" v-if="orderDetails.destinations.length > 0">
            <h3>Destinations</h3>
            <ul v-for="{address, city_or_town, flights_of_stairs, state, zip_code, type_property} of orderDetails.destinations">
                <li>Address: <strong>{{ address }}</strong></li>
                <li>City or town & State & Zip-code: <strong>{{ city_or_town }}, {{ state }} {{ zip_code }} </strong></li>
                <li>Flights of stairs: <strong>{{ flights_of_stairs }}</strong></li>
                <li>Property Type: <strong> {{ properties[type_property] }} </strong></li>
            </ul>
        </v-col>
    </v-row>
</template>

<script>
import { PROPERTY_TYPES } from "@/plugins/data.js";
import Order from "@/plugins/types/order";

export default {
    props: {
        orderDetails: {
            type: Order,
            default: () => ({})
        }
    },

    data() {
        return {
            properties: PROPERTY_TYPES,
        }
    },

    methods: {
        heavyItems(items) {
            return items.length > 150 ? items.substring(0,150) + '...' : items
        }
    }
}
</script>
