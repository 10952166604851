<template>
    <v-app class="p-3">
        <div class="d-flex align-center justify-space-between w-full px-3 mb-3">
            <small>New Order</small>
            <v-btn text fab small class="mr-n3" @click="$emit('close')">
                <v-icon size="15">fa-times</v-icon>
            </v-btn>
        </div>
        <div class="order-modal">
            <div class="new-order-modal">
                <div class="new-order-modal__row">
                    <v-row>
                        <v-col md="6">
                            <div class="text-secondary font-medium mb-3">
                                Pickup address
                            </div>
                            <v-text-field
                                v-model="form.from_zip" single-line hide-details
                                outlined type="text" background-color="#F6F6F6"
                                class="custom--v-text-field" v-maska="zip_mask"
                                placeholder="Zip code"
                            />

                            <small style="color: red;" v-if="$v.form.from_zip.$error" v-show="isSearchClicked">
                                Invalid zip code
                            </small>
                        </v-col>

                        <v-col md="6">
                            <div class="text-secondary font-medium mb-3">
                                Loading Date
                            </div>

                            <v-dialog
                                ref="dialog1"
                                v-model="modal1"
                                :return-value.sync="form.from_date"
                                width="290"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.from_date" readonly
                                        v-bind="attrs" v-on="on"
                                        single-line hide-details
                                        outlined type="text"
                                        background-color="#F6F6F6"
                                        class="custom--v-text-field"
                                        placeholder="Choose a date"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="form.from_date"
                                    :min="minPickupDate"
                                    scrollable
                                    @change="$refs.dialog1.save(form.from_date)"
                                />
                            </v-dialog>

                            <small style="color: red;" v-if="$v.form.from_date.$error" v-show="isSearchClicked">
                                Date is required
                            </small>
                        </v-col>
                    </v-row>
                </div>

                <div class="new-order-modal__row">
                    <v-row>
                        <v-col md="6">
                            <div class="text-secondary font-medium mb-3">
                                Destination
                            </div>
                            <v-text-field
                                v-model="form.to_zip" single-line hide-details
                                outlined type="text" background-color="#F6F6F6"
                                class="custom--v-text-field" v-maska="zip_mask"
                                placeholder="Zip code"
                            />

                            <small style="color: red;" v-if="$v.form.to_zip.$error" v-show="isSearchClicked">
                                Invalid zip code
                            </small>
                        </v-col>
                        <v-col md="6">
                            <div class="text-secondary font-medium mb-3">
                                Unloading Date
                            </div>

                            <v-dialog
                                ref="dialog2"
                                v-model="modal2"
                                :return-value.sync="form.to_date"
                                width="290"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.to_date" readonly
                                        v-bind="attrs" v-on="on"
                                        single-line hide-details
                                        outlined type="text"
                                        background-color="#F6F6F6"
                                        class="custom--v-text-field"
                                        placeholder="Choose a date"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="form.to_date"
                                    :min="minDestinationDate"
                                    scrollable
                                    @change="$refs.dialog2.save(form.to_date)"
                                >
                                </v-date-picker>
                            </v-dialog>

                            <small style="color: red;" v-if="$v.form.to_date.$error" v-show="isSearchClicked">
                                Date is required
                            </small>
                        </v-col>
                    </v-row>
                </div>
                <div class="new-order-modal__row">
                    <v-row>
                        <v-col md="6">
                            <div class="text-secondary font-medium mb-3">Type of moving</div>
                            <v-select
                                v-model="form.type_moving" :items="moving_type_options"
                                dense outlined hide-details background-color="#F6F6F6"
                                append-icon="fa-chevron-down" class="custom--v-select"
                                item-value="value" item-text="display"
                            />
                            <small style="color: red;" v-if="$v.form.type_moving.$error" v-show="isSearchClicked">
                                Required
                            </small>
                        </v-col>
                        <v-col md="6">
                            <div class="text-secondary font-medium mb-3">Service needed</div>
                            <v-select
                                v-model="form.type_service" :items="service_type_options"
                                dense outlined hide-details background-color="#F6F6F6"
                                append-icon="fa-chevron-down" class="custom--v-select"
                                item-value="value" item-text="display"
                            />
                            <small style="color: red;" v-if="$v.form.type_service.$error" v-show="isSearchClicked">
                                Required
                            </small>
                        </v-col>
                    </v-row>
                </div>
                <div class="new-order-modal__row">
                    <div class="new-order-modal__footer">
                        <v-btn
                            class="px-10 mr-3"
                            elevation="0"
                            color="primary"
                            @click="search"
                            :loading="isLoading"
                        >
                            Search
                        </v-btn>
                        <v-btn
                            class="px-10"
                            elevation="0" color="light"
                            @click="$emit('close')"
                        >
                            Cancel
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import dayjs from "dayjs";
import { minLength, required } from "vuelidate/lib/validators";
import { maska } from "maska";
import { zip_mask } from "@/plugins/custom_masks";
import { validationMixin } from "vuelidate";
import { validZipCode } from "@/plugins/custom_validations.js";
import tokenize from "@/plugins/tokenize.js";
import loading from "@/mixins/loading.js";
import {APP_BOOKING_URL} from "@/plugins/consts.js";

export default {
    directives: {
        maska
    },

    mixins: [validationMixin, loading],

    data () {
        return {
            modal1: false,
            modal2: false,
            isSearchClicked: false,
            zip_mask,

            form: {
                from_zip: '',
                from_date: null,
                to_zip: '',
                to_date: null,
                type_service: null,
                type_moving: null,
            },

            moving_type_options: [
                { value: null, display: 'Choose one' },
                { value: 1, display: 'Residential' },
                { value: 2, display: 'Commercial' },
            ],

            service_type_options: [
                { value: null, display: 'Choose one' },
                { value: 1, display: 'Helpers only' },
                { value: 2, display: 'Helpers and trucks' },
            ],
        };
    },

    validations: {
        form: {
            from_zip: {
                required,
                minLength: minLength(5),
                validZipCode,
            },

            from_date: {
                required,
            },

            to_zip: {
                required,
                minLength: minLength(5),
                validZipCode,
            },

            to_date: {
                required,
            },

            type_service: {
                required,
            },

            type_moving: {
                required,
            },
        }
    },

    methods: {
        async search () {
            // Show loading indicator
            this.loadingStart();

            // Validate form
            this.isSearchClicked = true;
            this.$v.$touch();

            // If form is invalid, return
            if (this.$v.$invalid) {
                this.loadingFinish();
                return false;
            }

            // get form data and save as query params
            const params = new URLSearchParams(this.form);

            // add token to params
            params.set('auth_token', tokenize.get());

            // redirect to frontend with query params
            // https://mover.atlassian.net/browse/TH-13
             window.location.href = APP_BOOKING_URL + "/selection?" + params.toString();
        },
    },

    computed: {
        minPickupDate () {
            return dayjs().toISOString();
        },

        minDestinationDate () {
            // e.x: 2022-08-03T05:14:40.610Z => 05:14:40.610Z
            const time = this.minPickupDate.split('T').at(1);

            return this.form.from_date + 'T' + time;
        },
    },

    watch: {
        'form.from_date' () {
            if (dayjs(this.form.from_date).isAfter(dayjs(this.form.to_date)) || ! this.form.to_date) {
                this.form.to_date = this.form.from_date;
            }
        }
    },

    mounted () {
        this.$v.$touch();
    },
}
</script>

<style lang="scss" scoped>
.new-order-modal {
    margin: 0 12px 12px 12px;
    border: 1px solid #E0E4E9;

    &__row {
        padding: 15px 15px 15px 15px;

        &:not(:last-child) {
            border-bottom: 1px solid #E0E4E9;
        }
    }

    &__footer {
        display: flex;
        justify-content: center;

        button {
            width: 140px;
        }
    }
}

::v-deep {

    .v-text-field {

        fieldset {
            border: 0 !important;
        }
    }

    .v-picker {
        border: 0;

        .v-date-picker-header {

            .v-icon {
                font-size: 14px;
            }
        }

        .v-picker__title__btn {
            font-size: 20px;
        }

        .v-date-picker-table {
            height: 210px;
        }
    }
}

</style>