<template>
 <aside class="review-card__comment">
         <div class="d-flex mb-2">
             <div class="d-flex align-center">
                 <div class="review-card__img">
                     <img :src="user.image" width="29" height="29" alt="logo">
                 </div>
                 <div class="review-card__header">
                     <h5 class="review-card__heading">{{ user.first_name}}</h5>
                     <p class="mb-0 font-semibold text-primary"> Your reply </p>
                 </div>
             </div>
         </div>
         <div class="review-card__post px-0">
             <v-textarea
                 v-model="comment"
                 ref="comment"
                 rows="3"
                 autofocus
                 auto-grow
                 dense
                 outlined
                 class="custom--v-textarea pa-0">
             </v-textarea>
             <v-divider></v-divider>
             <div class="review-card__action row d-flex align-center px-2">
                 <p class="col-auto">Note in reply: Once you post a reply, it will be public and cannot be edited.</p>
                 <div class="col-auto ml-auto">
                     <v-btn color="primary" min-width="78px" height="23px" class="mr-1 py-1 px-3"
                            style="font-size: 14px; font-weight: 400; border-radius: 4px"
                            :disabled="isPostDisable"
                            @click.once="replyReview()"
                     >
                         Post reply
                     </v-btn>
                     <v-btn text width="78px" height="23px"
                            style="font-size: 14px; font-weight: 500; border-radius: 4px"
                            @click="cancel"
                     >Cancel
                     </v-btn>
                 </div>
             </div>
         </div>
 </aside>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loading from "@/mixins/loading.js";

export default {
    name: "ReplyReview",

    mixins: [loading],

    props: {
      reviewId: Number
    },

    data () {
        return {
            comment: '',
            isPostDisable: true
        }
    },

    computed: {
        ...mapGetters('auth', ['user'])
    },

    methods: {
        ...mapActions('review', ['reply']),

        cancel () {
            this.comment = '';
            this.$emit('cancel');
        },

        replyReview () {
            this.startLoading(async () => {
                await this.reply({
                    review_id: this.reviewId,
                    comment: this.comment
                });

                this.isPostDisable = true;
                return true;
            })
        }
    },

    watch: {
        comment (text) {
            this.isPostDisable = ! text;
        },
    }
}
</script>
