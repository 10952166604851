<template>
    <v-app class="p-3">
        <div class="d-flex align-center justify-space-between w-full px-3 mb-3">
            <small>
                {{ isCustomer ? 'Customers' : 'Helpers' }} you’ve invited
            </small>
            <v-btn text fab small class="mr-n3" @click="$emit('close')">
                <v-icon size="15">fa-times</v-icon>
            </v-btn>
        </div>
        <v-row class="align-center p-6 pt-2">
            <div class="bordered-wrapper">
                <v-tabs v-model="tab">
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab>Pending</v-tab>
                    <v-tab>Available</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-list dense>
                            <v-list-item v-for="({id, referred_email}) in referralData.emails.pending" :key="id">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ referred_email }}
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-btn icon x-small color="primary" @click="onTrashIconClick(id)">
                                        <v-icon x-small>
                                            fa-trash
                                        </v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-tab-item>
                    <v-tab-item>
                        <v-list dense>
                            <v-list-item v-for="({id, referred_email}) in referralData.emails.approved" :key="id">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ referred_email }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-tab-item>
                </v-tabs-items>
                <div class="d-flex justify-center w-full mt-auto">
                    <v-btn
                        depressed color="primary"
                        class="custom--v-btn px-10"
                        @click="$emit('close')"
                    >
                        Close
                    </v-btn>
                </div>
            </div>
        </v-row>
    </v-app>
</template>

<script>
import { warningConfirmAction } from "@/plugins/swal.js";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    data () {
        return {
            tab: null,
        }
    },

    methods: {
        ...mapActions('referrals', ['destroy']),

        onTrashIconClick (referral_id) {
            const confirmationText = 'Do you want to delete this referral?';
            warningConfirmAction(confirmationText, 'Delete', () => this.destroy(referral_id));
        }
    },

    computed: {
        ...mapState('referrals', ['referralData']),
        ...mapGetters('auth', ['isCustomer']),
    }
}
</script>

<style scoped lang="scss">
.bordered-wrapper {
    width: 100%;
    padding-bottom: 30px;
    border: 1px solid #E0E4E9;
}

.v-tab {
    width: 50%;
}

.v-tabs-items {
    width: calc(100% - 50px);
    height: 150px;
    margin: 40px 25px;
    overflow: scroll;

    ul {
        padding: 0;
        margin: 0;

        li {
            color: #0D3659;
            font-size: 14px;
            font-weight: bold;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
}
</style>