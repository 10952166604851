<template>
    <div class="dashboard-card-action-buttons d-flex flex-wrap">
        <div class="customer-buttons__approve" v-if="order.isCustomerBeingRescheduled">
            <v-btn
                class="card-complete card-complete__active"
                elevation="0"
                color="primary"
                @click="approveRescheduling"
            >
                Approve
            </v-btn>
            <v-btn
                elevation="0" color="light"
                @click="rejectRescheduling"
            >
                Reject
            </v-btn>

            <v-btn
                elevation="0" color="light"
                @click="cancel(order.id)"
            >
                Cancel
            </v-btn>
        </div>

        <div class="customer-buttons__reschedule" v-if="order.isCustomerReadyToComplete">
            <v-btn
                v-if="!order.customerCanComplete"
                elevation="0" color="primary"
                @click="openRescheduleModal"
            >
                Reschedule
            </v-btn>

            <v-btn
                :disabled="!order.customerCanComplete"
                class="card-complete card-complete__active"
                elevation="0"
                color="primary"
                @click="complete(order.id)"
            >
                Complete
            </v-btn>
            <v-btn
                v-if="!order.customerCanComplete"
                elevation="0" color="light"
                @click="cancel(order.id)"
            >
                Cancel
            </v-btn>
        </div>

        <div class="customer-buttons__archive">
            <v-btn
                v-if="order.customerCanArchive"
                elevation="0" color="primary"
                @click="archive(order.id)"
            >
                Archive
            </v-btn>

            <v-btn
                v-if="order.customerCanReview"
                class="card-complete card-complete__active"
                elevation="0"
                color="primary"
                @click="openReviewModal"
            >
                Review
            </v-btn>
            <v-btn
                v-if="order.customerCanArchive && !order.isCustomerCompleted"
                elevation="0" color="light"
                @click="openNewOrderModal"
            >
                Hire a new helper
            </v-btn>
        </div>
    </div>
</template>

<script>
import { successConfirmAction, warningConfirmAction } from "@/plugins/swal";
import AppReview from "@/components/Modals/AppReview";
import AppNewCustomerOrder from "@/components/Modals/AppNewCustomerOrder";
import { mapActions } from "vuex";
import AppReschedule from "@/components/Modals/AppReschedule";

export default {
    props: {
        order: {
            type: Object,
            required: true,
        },
    },

    methods: {
        ...mapActions('customer_reschedule', ['approve', 'reject']),
        ...mapActions('user_orders', {
            indexAction: 'index',
            completeAction: 'complete',
            cancelAction: 'cancel',
            archiveAction: 'archive',
        }),

        openRescheduleModal () {
            this.$modal.show(AppReschedule, {
                order: this.order
            }, {
                width: 1000,
                height: 700,
            });
        },

        complete (order_id) {
            successConfirmAction("Do you really want to complete this order?", 'Complete it', async () => {
                await this.completeAction(order_id);
            });
        },

        approveRescheduling () {
            successConfirmAction("Do you really want to approve this rescheduling?", 'Approve it', async () => {
                await this.approve({
                    order_id: this.order.id,
                    reschedule_request_id: this.order.reschedule_request.id,
                });

                await this.indexAction();
            });
        },

        rejectRescheduling () {
            warningConfirmAction("Do you really want to reject this rescheduling?", 'Reject it', async () => {
                await this.reject({
                    order_id: this.order.id,
                    reschedule_request_id: this.order.reschedule_request.id,
                })

                await this.indexAction();
            });
        },

        cancel (order_id) {
            warningConfirmAction(
              "Do you really want to cancel the order?",
              "Confirm",
              async (result) => {
                const inputValue = result.value;

                await this.cancelAction({
                  order_id: order_id,
                  reason: inputValue
                });
              },
              'text',
              'Enter reason for cancellation...'
            )
        },

        archive (order_id) {
            warningConfirmAction("Do you really want to archive this order?", "Archive it", async () => {
                await this.archiveAction(order_id);
            })
        },


        openReviewModal () {
            this.$modal.show(AppReview, {
                order: this.order
            }, {
                width: 764,
                height: 700,
            }, {
                closed: this.indexAction()
            });
        },

        openNewOrderModal () {
            this.$modal.show(AppNewCustomerOrder, {}, {
                width: 450,
                height: 495,
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.dashboard-card-action-buttons div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
}

::v-deep {
    .v-btn {
        font-size: 16px;
        font-weight: 600;

        //extra small devices
        @media (max-width: 375px) {
            width: 100%;
            &:nth-child(1), &:nth-child(2) {
                margin-bottom: 10px;
            }
        }

        //small devices
        @media (max-width: 420px) and (min-width: 376px) {
            &:nth-child(1), &:nth-child(2) {
                min-width: 140px;
                max-width: 170px;
                width: 48%;
            }

            &:nth-child(2) {
                margin-left: auto;
            }

            &:nth-child(3) {
                width: 100%;
                margin-top: 10px;
            }
        }
        //other devices
        @media (min-width: 421px) {
            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
}
</style>
