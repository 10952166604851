<template>
    <div>
        <v-tabs
            v-model="tab"
            align-with-title
            color="#0D3659"
            height="61"
            class="custom--dashboard-header--v-tabs helper--dashboard !tw-mb-[18px]"
        >
            <v-tab v-for="item in tabs" :key="item.title" class="custom--v-tab">
                <span
                    inline
                    class="tw-capitalize tw-font-semibold tw-text-[18px]"
                >
                    {{ item.title }} - {{ item.count }}
                </span>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="custom--v-tabs-items">
            <v-tab-item>
              <div v-if="isNewBiddingsExist">
                <div class="tw-grid tw-gap-[18px]" v-if="activeBranchId === 'company'">
                    <dashboard-delivery-card
                        v-for="delivery in new_biddings"
                        :key="delivery.id"
                        :delivery="delivery"
                    />
                </div>
                <div v-else class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-[25px] tw-mt-[80px] w-[379px] tw-mb-[15px]">
                    <img src="../../../public/assets/images/branch-biddings.png" alt="">
                    <div class="tw-font-bold tw-text-[22px] text-center">
                      Branch cannot accept New <br> Biddings. To accept biddings, visit
                    </div>
                    <div class="text-center">
                        <v-btn color="primary" @click="onSelectAllBranches">All branches</v-btn>
                    </div>
                </div>
              </div>

              <v-alert v-if="!isNewBiddingsExist" color="primary" text class="tw-text-center">
                No new bids. Check back soon for updates.
              </v-alert>
            </v-tab-item>
            <v-tab-item>
                <div class="tw-grid tw-gap-[18px]" v-if="isUpcomingDeliveriesExist">
                    <dashboard-delivery-card
                        v-for="delivery in upcoming_deliveries"
                        :key="delivery.id"
                        :delivery="delivery"
                    />
                </div>

              <v-alert v-if="!isUpcomingDeliveriesExist" color="primary" text class="tw-text-center">
                No accepted bids.
              </v-alert>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import DashboardDeliveryCard from './DashboardDeliveryCard.vue';

export default {
  name: 'DeliveryTab',
  components: {
    DashboardDeliveryCard
  },

  data() {
    return {
      tab: null
    }
  },

  computed: {
    ...mapState('dashboard', ['new_biddings', 'upcoming_deliveries', 'activeBranchId']),

    tabs() {
      const item = (title, count) => ({ title, count });

      return [
        item('New Biddings', this.new_biddings.length),
        item('Accepted', this.upcoming_deliveries.length),
      ];
    },

    isNewBiddingsExist() { return this.new_biddings.length; },
    isUpcomingDeliveriesExist() { return this.upcoming_deliveries.length },
  },

  methods: {
    ...mapMutations('dashboard', ['SET_ACTIVE_BRANCH_ID']),
    ...mapActions('dashboard', ['load']),

    async onSelectAllBranches() {
      this.SET_ACTIVE_BRANCH_ID({ id: 'company' });
      this.$emit('setAllLocations')
    }
  }
}
</script>
