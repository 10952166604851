/**
 * Mixin to automatically
 * emit on data change
 */
export default {
    props: {
        value: {},
    },

    data () {
        return {
            local: this.value
        }
    },

    watch: {
        value (newValue) {
            this.local = newValue;
        },

        local: {
            deep: true,

            handler (newValue) {
                return this.$emit('input', newValue);
            }
        },
    },
}