var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-mb-[15px]"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-gap-[30px] tw-mb-[12px]"},[_c('h3',{staticClass:"tw-text-[22px] tw-text-[#0D3659] tw-font-semibold"},[_vm._v("Overview")]),_c('orders-filter',{attrs:{"orders-deliveries":""}})],1),_c('div',{staticClass:"tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-[12px]"},_vm._l((_vm.overview),function(ref,index){
var title = ref.title;
var icon = ref.icon;
var current = ref.current;
var percent = ref.percent;
var isIncreased = ref.isIncreased;
var difference = ref.difference;
return _c('div',{key:index,staticClass:"tw-grid tw-p-[16px] tw-rounded-[16px] tw-bg-[#FFF]"},[_c('div',{staticClass:"tw-flex tw-items-center tw-gap-[10px] tw-text-[#AAAAAA] tw-mb-[18px]"},[_c('i',{staticClass:"!tw-w-[18px] !tw-min-w-[18px]",class:['icony', icon]}),_c('span',{staticClass:"tw-text-[16px] tw-font-semibold",domProps:{"textContent":_vm._s(title)}})]),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-[12px] tw-mb-[8px]"},[_c('span',{staticClass:"tw-text-[#0D3659] tw-text-[22px] tw-font-semibold",domProps:{"textContent":_vm._s(current)}}),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-[5px] tw-p-[5.5px_6.5px] tw-rounded-[8px]",class:isIncreased ? 'tw-bg-[#E6F5EB] tw-text-[#1CA647]' : 'tw-bg-[#EE3939] tw-text-[#FFF]'},[_c('i',{staticClass:"!tw-w-[10px]",class:['icony', isIncreased ? 'icony-arrow-up' : 'icony-arrow-down']}),_c('span',{staticClass:"tw-text-[14px] tw-font-semibold tw-leading-none",domProps:{"textContent":_vm._s(percent)}})])]),_c('div',{staticClass:"tw-text-[#AAAAAA] tw-text-[14px] tw-font-semibold"},[_c('span',{class:isIncreased ? 'tw-text-[#1CA647]' : 'tw-text-[#EE3939]'},[_vm._v(_vm._s(difference))]),_vm._v(" than last month")])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }