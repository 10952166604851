<template>
    <v-app class="p-3">
        <div class="d-flex align-center justify-space-between w-full px-3 mb-3">
            <small>Edit blocked time</small>
            <v-btn text fab small class="mr-n3" @click="$emit('close')">
                <v-icon size="15">fa-times</v-icon>
            </v-btn>
        </div>
        <div class="block-day-modal">
            <div class="block-day-modal__body">
                <div class="block-day-modal__row">
                    <h3 class="block-day-modal__title">For {{ formattedDate }}</h3>
                    <p>If you are unavailable to work, you can block a time period by selecting Morning, Afternoon block or you can block a full day. Then customers will not be able to give you new orders.</p>
                    <p>You can constantly adjust or remove blocked time periods at any time.</p>

                    <div class="mb-1.5 text-secondary font-medium">Location</div>
                    <v-row>
                        <v-col lg="10">

                            <v-row>
                                <v-col lg="6">
                                    <v-btn
                                        color="primary"
                                        width="100%"
                                        elevation="none"
                                        :outlined="interval === 'is_on_afternoon'"
                                        :disabled="interval === 'is_on_full_day'"
                                        @click="interval = 'is_on_morning'"
                                    >
                                        Morning (blocks 7.00AM - 12.00AM)
                                    </v-btn>
                                </v-col>

                                <v-col lg="6">
                                    <v-btn
                                        color="primary"
                                        width="100%"
                                        elevation="none"
                                        :outlined="interval === 'is_on_morning'"
                                        :disabled="interval === 'is_on_full_day'"
                                        @click="interval = 'is_on_afternoon'"
                                    >
                                        Afternoon (blocks 12.00AM - 6.00PM)
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-checkbox
                        v-model="is_on_all_day"
                        label="All day - check to block the full day. You should only use this option if you cannot accept any new requests this entire day"
                        hide-details
                        @change="newValue => interval = newValue ? 'is_on_full_day' : 'is_on_morning'"
                    />
                </div>
                <div class="block-day-modal__row d-flex">
                    <v-btn
                        class="px-10 mr-3"
                        elevation="0"
                        color="primary"
                        @click="isCreating ? create() : update()"
                    >
                        {{ isCreating ? 'Create' : 'Update' }}
                    </v-btn>
                    <v-btn
                        class="px-10 mr-auto"
                        elevation="0"
                        color="light"
                        @click="destroy"
                        v-if="!isCreating"
                    >
                        Delete
                    </v-btn>
                    <v-btn
                        class="px-10 mr-3"
                        elevation="0"
                        color="light"
                        @click="$emit('close')"
                    >
                        Cancel
                    </v-btn>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import AppLocationSelector from "@/components/AppLocationSelector.vue";
import { mapActions } from "vuex/dist/vuex.esm.browser";
import { getBranchId, getInterval } from "@/views/Calendar/helpers.js";
import { warningConfirmAction } from "@/plugins/swal.js";
import { mapState } from "vuex";

export default {
    props: {
        date: String,
        isCreating: Boolean,
        blockDay: Object,
        activeBranchId: Number | String
    },

    components: {
        AppLocationSelector,
    },

    data () {
        const branch_id = getBranchId(this.blockDay);
        const interval = getInterval(this.blockDay);

        return {
            branch_id, interval,
            is_on_all_day: interval === 'is_on_full_day',

            intervalOptions: [{
                value: 'is_on_morning',
                display: 'Morning (it is blocked from 7.00 AM - 12.00 AM *)',
            }, {
                value: 'is_on_afternoon',
                display: 'Afternoon (it is blocked from 12.00 PM - 6.00 PM)',
            }, {
                value: 'is_on_full_day',
                display: 'Full Day',
            }],
        };
    },

    methods: {
        ...mapActions('calendar', ['storeBlockedDay', 'updateBlockedDay', 'destroyBlockedDay']),

        create () {
            this.storeBlockedDay({
                branch_id: this.activeBranchId,
                date: this.date,
                is_on_morning: this.isOnMorning,
                is_on_afternoon: this.isOnAfternoon,
            });

            this.$emit('close');
        },

        update () {
            this.updateBlockedDay({
                id: this.blockDay.id,
                branch_id: this.activeBranchId,
                is_on_morning: this.isOnMorning,
                is_on_afternoon: this.isOnAfternoon,
            });

            this.$emit('close');
        },

        destroy () {
            warningConfirmAction(
                'Do you want to delete this blocked time?',
                'Delete',
                () => {
                    this.destroyBlockedDay(this.blockDay);
                    this.$emit('close');
                }
            );
        },
    },

    computed: {
        ...mapState('auth', ['activeBranch']),
        isOnMorning () {
            return ['is_on_morning', 'is_on_full_day'].includes(this.interval);
        },

        isOnAfternoon () {
            return ['is_on_afternoon', 'is_on_full_day'].includes(this.interval);
        },

        formattedDate () {
            let fullDate = new Date(this.date);
            return fullDate.toLocaleDateString('en-us', {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric"
            }).replace(',', '');
        },
    }
};
</script>

<style lang="scss" scoped>
.block-day-modal {
    margin: 0 12px 12px 12px;

    &__body {
        border: 1px solid #E0E4E9;
    }

    &__row {
        padding: 15px;

        &:not(:last-child) {
            border-bottom: 1px solid #E0E4E9;
        }
    }

    &__title {
        color: #0D3659;
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 10px;
    }
}
</style>