<template>
    <v-main class="custom--v-main">
        <app-header class="dashboard-header">
            <div class="[&_.v-tabs-slider-wrapper]:tw-hidden">
                <v-tabs
                    v-model="tab"
                    align-with-title
                    color="#0D3659"
                    class="[&_.v-tabs-bar]:!tw-bg-[transparent] custom--dashboard-header--v-tabs"
                >
                    <v-tab
                        v-for="{title, count} in tabs"
                        :key="title"
                        class="before:tw-hidden !tw-m-[0_30px_0_0] !tw-p-0 [&.v-tab--active_span]:tw-text-[#0D3659]"
                    >
                        <span 
                            class="tw-capitalize tw-text-[16px] tw-text-[#6B6D81] tw-font-semibold tw-mr-1"
                        >
                            {{ title + ' -' }}
                        </span>
                        <span
                            class="tw-text-[16px] tw-text-[#6B6D81] tw-font-semibold tw-mr-1"
                        >
                            {{ ' ' + count }}
                        </span>
                    </v-tab>
                </v-tabs>
            </div>

            <!-- <template v-slot:right>
                Custom Right side of app header here
            </template> -->
        </app-header>

        <div class="tw-mx-[30px] tw-my-[18px]">
            <v-skeleton-loader v-if="isLoading" type="card@10" class="tw-grid tw-gap-[18px]"/>

            <v-tabs-items v-else v-model="tab" class="custom--v-tabs-items">
                <v-tab-item>
                    <div class="tw-grid tw-gap-[18px]" :class="{'tw-mb-[18px]': Object.keys(biddings).length > 0 }">
                        <delivery-card
                            v-for="order in biddings"
                            :key="order.id"
                            :order="order"
                        />
                    </div>
                </v-tab-item>

                <v-tab-item>
                    <div class="tw-grid tw-gap-[18px]">
                        <dashboard-card
                            v-for="order in accepteds"
                            :key="order.id"
                            :order="order"
                        />
                    </div>
                </v-tab-item>
            </v-tabs-items>

            <v-alert v-if="showAlert" color="primary" text class="tw-m-[20px_30px_0_15px] tw-text-center">
                Get a professional help for your move
            </v-alert>
        </div>
    </v-main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import loading from "@/mixins/loading";
import DashboardCard from "@/views/LaborDashboard/DashboardCard";
import DeliveryCard from "@/views/LaborDashboard/DeliveryCard.vue";

export default {
    mixins: [loading],

    components: {
        DashboardCard,
        DeliveryCard
    },

    data () {
        return {
            tab: null,
        }
    },

    methods: {
        ...mapActions('labor', ['load']),
    },

    computed: {
        ...mapState('labor', ['biddings', 'accepteds']),
        ...mapGetters('labor', ['biddingsCount' ,'acceptedsCount']),

        /**
         * Active, Archive tabs
         *
         * @return {{count, title}[]}
         */
        tabs () {
            const item = (title, count) => ({ title, count });

            return [
                item('New bidding', this.biddingsCount || 0),
                item('Accepted', this.acceptedsCount || 0),
            ];
        },

        /**
         * Can we show no orders alert
         *
         * @return {boolean}
         */
        showAlert () {
            return this.isLoaded
                && this.biddings.length + this.accepteds.length === 0;
        },
    },

    async mounted() {
        await this.load();
    },
}
</script>

<style lang="scss" scoped>

.custom--v-text-field::v-deep {
    width: 263px;
    height: 43px;
    padding-left: 15px;
    background: #F5F5F9;
    border-radius: 35px;

    .v-text-field .v-input__control .v-input__slot {
        padding-right: 0 !important;
    }

    fieldset {
        border: none;
    }

    .v-input__slot {
        padding-right: 0 !important;

        .v-input__append-inner {
            margin-top: 12px !important;
        }

    }

    .v-input__prepend-outer {
        margin-top: 13px !important;
        margin-right: 0 !important;
    }

    i {
        font-size: 18px !important;
        color: #aaa !important;
    }
}

.custom--v-tab::v-deep {
    .v-badge__badge {
        display: flex !important;
        align-items: center !important;
        background: #E7EFF3 !important;
        border-radius: 8px !important;
        color: #6B6D81 !important;
    }
}

.custom--dashboard-header--v-badge::v-deep {
    .v-badge__badge {
        display: flex !important;
        align-items: center !important;
        background: #fff !important;
        border-radius: 8px !important;
        color: #6B6D81 !important;
    }
}
</style>
