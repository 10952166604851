<template>
    <v-main class="custom--v-main">
        <app-header class="dashboard-header">
            <template>
                <div class="d-flex flex-wrap flex-column w-100" style="max-width: 100%">
                    <div class="tw-flex tw-items-center">
                        <router-link
                            class="tw-font-bold tw-capitalize tw-flex tw-items-center tw-mr-[32px] tw-pb-2 tw-text-[18px]"
                            to="/deliveries"
                        >
                            <span class="tw-mr-2 tab-text">Delivery</span>
                            <span class="tab-number tw-px-2 tw-rounded-md">{{ deliveryOrdersLength || 0 }}</span>
                        </router-link>
                        <router-link
                            class="tw-font-bold tw-capitalize tw-flex tw-items-center tw-pb-2 tw-ml-5 tw-text-[18px]"
                            to="/movings"
                        >
                            <span class="tw-mr-2 tab-text">Moving</span>
                            <span class="tab-number tw-px-2 tw-rounded-md">{{ movingOrdersLength || 0 }}</span>
                        </router-link>
                    </div>
                    <div class="dashboard-header--mobile mt-8"
                         :class="$vuetify.breakpoint.mdAndDown ? 'd-flex': 'd-none'">
                        <v-btn
                            depressed color="primary"
                            min-width="150"
                            max-width="170"
                            class="app-selector custom--v-btn v-size--medium mr-5"
                            @click="$router.push({name: 'choose-branch'})"
                        >
                            <span class="mr-auto">{{ branchName.three_dots(12) }}</span>
                            <v-icon size="8" class="ml-4">
                                {{ !isClickedBranch ? 'fa-chevron-right' : 'fa-chevron-down' }}
                            </v-icon>
                        </v-btn>

                        <app-location :isOpen="isOpenLocationMenu"/>
                    </div>
                </div>
            </template>
            <template #right>

                <v-btn
                    depressed
                    color="primary"
                    class="app-selector custom--v-btn v-btn v-size--medium mr-5"
                    @click="openBranchModal"
                >
                    <span class="mr-auto">{{ branchName.three_dots(12) }}</span>
                    <v-icon size="8" class="ml-8">
                        {{ !isClickedBranch ? 'fa-chevron-right' : 'fa-chevron-down' }}
                    </v-icon>
                </v-btn>

                <app-location-selector
                    v-if="isClickedBranch"
                    @closeBranch="(isClose) => {isClickedBranch = isClose}"
                    @getBranchId="(id, name) => {branch = id; branchName = name}"
                    class="v-size--medium mr-5"
                />
            </template>
        </app-header>

        <section class="tw-mx-[30px] tw-my-[18px]">
            <v-skeleton-loader
                v-if="isLoading"
                type="card@10"
            />

            <!-- Tabs page content shows by taping link -->
            <router-view v-else @setAllLocations="chooseAllLocations"/>
        </section>
    </v-main>
</template>

<script>
import DashboardCard from './DashboardCard.vue';
import loading from "@/mixins/loading.js";
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import AppLocationSelector from "@/components/AppLocationSelector";
import AppLocation from "../../components/Modals/AppLocation.vue";

export default {
    components: {
        DashboardCard,
        AppLocationSelector,
        AppLocation
    },

    data() {
        return {
            branch: 'company',
            branchName: 'All Branches',
            isClickedBranch: false,
        }
    },

    mixins: [loading],

    methods: {
        ...mapActions('dashboard', ['load']),
        ...mapMutations('locations', ['SET_LOCATION_MENU']),
        ...mapMutations('dashboard', ['SET_ACTIVE_BRANCH_ID']),

        async loadDashboard() {
            await this.startLoading(async () => await this.load(this.branch));
        },

        openBranchModal() {
            this.isClickedBranch = !this.isClickedBranch
        },

        chooseAllLocations() {
            this.branch = 'company';
            this.branchName = 'All Branches';
        }
    },

    computed: {
        ...mapState('locations', ['isOpenLocationMenu']),
        ...mapGetters('dashboard', ['deliveryOrdersLength', 'movingOrdersLength']),
    },

    created() {
        this.SET_ACTIVE_BRANCH_ID({ id: 'company' });
        this.loadDashboard();
    },

    watch: {
        branch: 'loadDashboard',

    }
}
</script>
<style lang="scss" scoped>
@import "src/assets/scss/vuetify-components/v-btn";

.dashboard-header {
    padding-left: 0 !important;

    ::v-deep {
        .v-tabs-slider {
            margin-left: 13px;
        }
    }

    &--mobile {
        max-width: 100%;
    }
}

.header {
    &__item:nth-child(3) {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 991px) {
    .custom--v-main {
        padding-bottom: 0 !important;
        background-color: #F5F6F7;
    }
    .v-slide-group__wrapper {
        display: flex;
        justify-content: flex-start;
    }
    .helper--dashboard {
        display: block;
        max-width: 380px;
    }
}

.custom--v-tab::v-deep {
    .v-badge__badge {
        background: #E7EFF3 !important;
        border-radius: 8px !important;
        color: #6B6D81 !important;
    }
}

.custom--dashboard-header--v-badge::v-deep {
    .v-badge__badge {
        background: #E7EFF3 !important;
        border-radius: 8px !important;
        color: #6B6D81 !important;
    }
}

.dashboard-container {
    margin-left: 15px !important;
    margin-right: 15px !important;

    @media (min-width: 1920px) {
        max-width: 1400px;
    }
}

.dashboard-alert {
    margin: 20px 30px 0 15px;
}

.router-link {
    transition: all 0.3s ease-in-out;
    color: #81849a;
    border-bottom: 3px solid transparent;
}

.router-link-exact-active {
    border-bottom: 3px solid #0d3659b0;
}

.router-link-exact-active > .tab-text {
    color: #0D3659;
}

.router-link-exact-active > .tab-number {
    color: #fff;
    background-color: #8F3985;
}
</style>
