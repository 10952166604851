import axios from "axios";
import { sweetAlertError, sweetAlertSuccess } from "@/plugins/swal.js";
import { BACKEND_URL } from "@/plugins/consts.js";

const instance = axios.create({
    baseURL: BACKEND_URL + '/dashboard',
    timeout: 15000,
});

const handleSuccessResponse = response => {
    if (response.data.message) {
        sweetAlertSuccess(response.data.message);
    }

    return response.data.data;
};

const handleErrorResponse = error => {
    if (error.response.data.message) {
        sweetAlertError(error.response.data.message)
    }
    return Promise.reject(error);
};

instance.interceptors.response.use(handleSuccessResponse, handleErrorResponse);

window.axios = instance;
