var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"custom--v-main"},[_c('app-header',[_c('h3',{staticClass:"text-xl font-semibold text-secondary mb-4"},[_vm._v(" Locations ("+_vm._s(_vm.branches.length)+") ")])]),_c('section',{staticClass:"location-modal--mobile"},[_c('v-divider',{staticClass:"mb-7"}),_c('div',{staticClass:"d-flex align-center justify-center flex-column flex-wrap"},[_c('div',{staticClass:"location-modal--mobile__search w-100 d-flex align-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchingInput),expression:"searchingInput"}],attrs:{"type":"text","name":"locationSearch","placeholder":"Search locations"},domProps:{"value":(_vm.searchingInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchingInput=$event.target.value}}}),_c('v-icon',{staticClass:"d-inline-block ms-auto",attrs:{"size":"20","color":"#aaa"}},[_vm._v(" fa-search ")])],1),_c('div',{staticClass:"location-modal--mobile__locations d-flex align-center flex-column mr-sm-auto mx-auto"},[_vm._l((_vm.getBranch(_vm.searchingInput)),function(ref){
var id = ref.id;
var state_name = ref.state_name;
var zip_code = ref.zip_code;
var address = ref.address;
var city_name = ref.city_name;
var name = ref.name;
var slug = ref.slug;
return _c('div',{key:id,staticClass:"w-100 d-flex align-center mr-auto"},[_c('div',{staticClass:"location-modal--mobile__block d-flex align-center",class:{'location--active': _vm.isActive(id)}},[_c('div',{staticClass:"modal-locations__column",on:{"click":function($event){return _vm.changeBranch(id)}}},[_c('h5',{class:{'d-none': _vm.isActive(id)}},[_vm._v(_vm._s(name))]),(_vm.isActive(id))?_c('span',[_vm._v(_vm._s(address))]):_vm._e(),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(state_name + ' ' + city_name + ', ' + zip_code))])]),(!_vm.isActive(id))?_c('a',{staticClass:"modal-locations__column ml-auto",attrs:{"href":((_vm.frontend_url()) + "/companies/" + slug),"target":"_blank"}},[_c('v-icon',{attrs:{"size":"15","color":"#6B6D81"}},[_vm._v(" icon-logout ")])],1):_vm._e()])])}),(_vm.searchingInput.length > 0 && _vm.getBranch(_vm.searchingInput).length === 0)?_c('div',{staticClass:"branch-not__found"},[_c('p',[_vm._v("Branch not found")])]):_vm._e()],2)]),_c('v-divider',{staticClass:"mb-5"}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"v-btn v-size--medium mx-auto",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.passLocationAndCloseMenu.apply(null, arguments)}}},[_c('span',[_vm._v("Add a location")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }