<template>
    <v-main>
        <div class="login">
            <div class="login__left d-flex align-center">
                <div class="login__left-inner">
                  <div class="login__left-form">
                    <a :href="frontedUrl" class="login__left-logo mb-7">
                        <img src="/assets/images/logos/logo-purple.svg" alt="logo" />
                    </a>
                    <div class="mb-8 pb-6  border-b border-light border-solid d-block text-secondary">
                      <strong class="font-weight-bold">
                        Sign in
                      </strong>
                    </div>
                    <div class="mb-8 login--form">
                      <div class="mb-6">
                        <div class="login__left__header mb-2.5 text-secondary font-medium">
                          Email
                        </div>
                        <v-text-field
                            label="Write your email"
                            single-line
                            hide-details
                            outlined
                            type="email"
                            class="custom--v-text-field"
                            v-model.trim="email"
                            :error="$v.email.$error"
                        />
                        <small v-if="$v.email.$error">
                            Invalid email
                        </small>
                      </div>
                      <div class="mb-6">
                        <div class="login__left__header mb-2.5 text-secondary font-medium">
                          Password
                        </div>
                        <v-text-field
                            label="Write your password"
                            single-line
                            hide-details
                            outlined
                            class="custom--v-text-field"
                            v-model="password"
                            :append-icon="showCurrent ? 'fa-eye' : 'fa-eye-slash'"
                            :type="showCurrent ? 'text' : 'password'"
                            :error="$v.password.$error"
                            @click:append="showCurrent = !showCurrent"
                        />
                         <small v-if="$v.password.$error">
                             Invalid password
                         </small>
                      </div>
                    </div>
                    <div class="flex w-full align-center justify-between mb-4">
                      <v-checkbox
                          label="Remember me"
                          hide-details
                          class="mt-0 pt-0 checkbox--sm custom--v-checkbox"
                      />
                      <router-link :to="{name: 'forgot-password'}" class="custom--login-forgot-password text-sm">
                        Forgot password?
                      </router-link>
                    </div>
                    <v-btn
                        depressed
                        block
                        x-large
                        color="primary"
                        class="custom--v-btn mb-5"
                        @click="submit"
                        :loading="isLoading"
                    >
                      Sign in
                    </v-btn>
                    <span class="login__left-txt text-primary">Don’t you have an account?</span>
                    <div class="text-center">
                      <v-btn
                          depressed
                          block
                          x-large
                          color="light"
                          class="custom--v-btn mt-1"
                          @click.prevent="signup"
                      >
                        Sign Up
                      </v-btn>
                    </div>
                  </div>
                </div>
            </div>
            <div class="login__right"/>
        </div>
    </v-main>
</template>

<script>
import loading from "@/mixins/loading.js";
import { validationMixin } from "vuelidate";
import { minLength, required, email } from "vuelidate/lib/validators";
import AppSignUp from "@/components/Modals/AppSignUp.vue";
import { FRONTEND_URL } from "@/plugins/consts.js";

export default {
    mixins: [loading, validationMixin],

    data () {
        return {
            email: '',
            password: '',
            frontedUrl: FRONTEND_URL,
            showCurrent: false,
            isSubmit: false
        };
    },

    validations: {
      email: {
          required,
          email
      },
      password: {
          required,
          minLength: minLength(2)
      }
    },

    methods: {
        submit () {

            //checking form
            this.isSubmit = true;
            this.$v.$touch();

            //if invalid return false

            if(this.$v.$invalid) {
                return false;
            }


            this.startLoading(async () => {
                await this.$store.dispatch('auth/login', this.$data);
            });
        },

        signup () {

            this.$modal.show(AppSignUp, {}, {
                width: 912,
                height: window.innerWidth < 1264 ? 366 : 273,
                'border-radius': '8'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.login--form > div {
    position: relative;
}
small {
    color: #ff5252;
    position: absolute;
    bottom: -20px;
}
.custom--v-text-field::v-deep {
    .v-input__slot {
        height:  51px !important;
    }
}
.custom--v-checkbox::v-deep {
 label {
     font-weight: 400;
 }
}

.vm--modal {
  height: 600px !important;
}
</style>
