export default {
    namespaced: true,

    state: {
        /**
         * @type  {Object}
         */
        credentials: {}
    },

    getters: {
        mtr_license: state => state.credentials.mtr_license,
        general_liability_license: state => state.credentials.general_liability_license,
        cargo_insurance_license: state => state.credentials.cargo_insurance_license,
        transportation_service_license: state => state.credentials.transportation_service_license,
        workers_compensation_license: state => state.credentials.workers_compensation_license,
        uniformed_crew: state => state.credentials.uniformed_crew,
        drug_test: state => state.credentials.drug_test
    },

    mutations: {
        SET_CREDENTIALS(state, payload) {
            state.credentials = payload
        },

        SET_MTR_LICENSE (state, payload) {

        }
    },

    actions: {
        /**
         *
         * @param rootState
         * @param commit
         * @returns {Promise<void>}
         */
        async load({ rootState, commit }) {
            const { id } = rootState.auth.activeBranch;
            const data = await axios.get(`/branches/${ id }?section=credentials`);

            commit('SET_CREDENTIALS', data);
        },

        /**
         *
         * @param rootState
         * @param dispatch
         * @param data_name
         * @param data
         * @returns {Promise<void>}
         */
        async update({rootState, dispatch}, {data_name, data}) {
            const { id } = rootState.auth.activeBranch;

            await axios.put(`/branches/${ id }`, {
                [data_name]: data
            })
        }
    }
}