export default {
    namespaced: true,

    state: {
        branch: {}
    },

    getters: {
        branch: state => state.branch
    },

    mutations: {
        SET_BRANCH (state, payload) {
            state.branch = payload
        }
    },

    actions: {
        async load ({ rootState, commit }) {
            const { id } = rootState.auth.activeBranch;
            const data = await axios.get(`/branches/${ id }`);

            commit("SET_BRANCH", data);
            return data;
        }
    }
}