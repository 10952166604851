let notification_fetcher;

export default {
  namespaced: true,

  state: {
    /** @type {Notification[]} */
    notifications: [],
  },

  getters: {
    unreadNotificationsCount: (state) => {
      return state.notifications.filter((notification) => notification.unread)
        .length;
    },

    hasUnreadNotifications: (state) => {
      return state.notifications.some((notification) => notification.unread);
    },
  },

  actions: {
    async index({ state }) {
      /** @type {Notification[]} */
      const notifications = await axios.get("notifications");

      state.notifications = notifications;

      return notifications;
    },

    async markAsRead({ dispatch }, notification) {
      await axios.get("notifications/" + notification.id);

      dispatch("index");
    },

    async createNotificationFetcher({ dispatch }) {
      dispatch("index");

      notification_fetcher = setInterval(() => {
        dispatch("index");
      }, 10000);
    },

    async destroyNotificationFetcher() {
      clearInterval(notification_fetcher);
    },
  },
};
