<template>
    <v-app class="order-review tw-p-[25px] tw-pt-[15px]">
        <div class="tw-flex tw-items-center tw-justify-end">
            <v-btn icon @click="close">
                <v-img src="/assets/icons/delete.svg" alt="close icon"/>
            </v-btn>
        </div>
        <div class="tw-text-center">
            <div class="tw-font-bold tw-text-[25px] tw-mb-[15px]">Delete Account</div>
            <p class="tw-font-bold tw-text-[16px] tw-text-[#6B6D81] tw-w-[80%] tw-mx-auto">
                Once you delete your account, there’s no getting it back. Make sure you want to do this
            </p>
            <div class="tw-center tw-mb-10">
                <div class="tw-mx-auto tw-w-[80%]">
                    <v-text-field
                        v-model="delValue"
                        label="Comfirm by typing 'delete'"
                        single-line
                        background-color="#f5F5F9"
                        hide-details
                        outlined
                        type="text"
                        class="custom--v-text-field tw-text-center"
                    />
                </div>
            </div>
            <div>
                <v-btn
                    color="#E7EFF3"
                    class="!tw-text-[#6B6D81] !tw-px-8 tw-mr-3"
                    depressed
                    @click="cancel"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="#8F3985"
                    class="!tw-text-[#fff] !tw-px-8"
                    depressed
                    :disabled="delValue !== 'delete'"
                    @click="deleteAccount"
                >
                    Delete
                </v-btn>
            </div>
        </div>
    </v-app>
</template>

<script>
import { EventBus } from "../../main.js";
export default {
    name: 'DeleteAccount',

    data() {
        return {
            delValue: '',
            disabled: true
        }
    },

    methods: {
        close () {
            this.$emit('close');
        },

        deleteAccount() {
            if (this.delValue === 'delete') {
                EventBus.$emit('deleteAccount')
                this.close()
            }
        },
        cancel() {
            this.close()
        },
    },
}
</script>

<style lang="scss">
.order-review__main {
    margin-bottom: 20px;
}
.vm--modal {
    border-radius: 10px !important;
}
</style>
