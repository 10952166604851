<template>
    <section class="location-branch">
            <div class="location-branch__header">
                <div class="location-modal__exit d-flex align-center justify-end" :class="$vuetify.breakpoint.mdAndUp ? 'd-flex': 'd-none'">
                    <v-btn icon @click="closeBranchModal">
                        <v-img src="/assets/icons/delete.svg" />
                    </v-btn>
                </div>
                <h1 class="font-family-gilroy">Branches</h1>
                <p class="font-family-gilroy">Choose branches you want see</p>
            </div>
            <main>
                <div @click="passBranchIdAndCloseMenu(item.id, item.name)" v-for="(item, index) in locations" :key="index">
                    <h3>{{ item.name }}</h3>
                    <p class="mb-0">{{ item.zip_code }}</p>
                </div>
            </main>
    </section>

</template>

<script>
import { mapActions, mapMutations } from "vuex";
import ValueEmitter from "../mixins/ValueEmitter";

export default {
    props: {
        dontShowCompany: {
            type: Boolean,
            default: false,
        },
    },

    mixins: [
        ValueEmitter
    ],

    methods: {
        ...mapActions('locations', ['load']),
        ...mapMutations('dashboard', ['SET_ACTIVE_BRANCH_ID']),

        closeBranchModal () {
            this.$emit('closeBranch', false);
        },

        passBranchIdAndCloseMenu (id, name) {
            this.$emit('getBranchId', id, name);
            this.SET_ACTIVE_BRANCH_ID({ id: id });
            this.closeBranchModal();
        }
    },

    computed: {
        locations () {
            const company = this.$store.state.auth.company;
            const branches = this.$store.state.auth.branches;

            if (this.dontShowCompany) {
                return branches;
            }

            return [
                { id: 'company', name: `All locations (${ company.name })` },
                ...branches,
            ];
        },
    },

    async created () {
        if (! this.local) {
            this.local = this.locations[0].id;
        }
    }
};
</script>
<style  lang="scss">

@import "src/assets/scss/style";

 .location-branch {
     width: 262px;
     position: absolute;
     top: 58px;
     margin-right: -30px;
     padding: 0 4px 30px 30px;
     background: $white;
     border: 0.5px solid $secondary-white;
     box-shadow: 0 9.18458px 20.4102px rgba(112, 144, 176, 0.12);
     border-radius: $border-radius;
     h1, h3, p {
         color: $secondary;
     }
     h3, p {
         font-size: 14px;
     }

     &__header {


         h1 {
             margin-top: -8px;
             margin-bottom: 9px;
             font-size: 25px;
             font-weight: 600;
             line-height: 18px;
         }
         p {
             max-width: 165px;
             font-weight: 400;
             line-height: 17px;
         }
         i {
             width: 11px;
             height: 11px;
         }
     }

     main {
         max-height: 400px;
         overflow-y: auto;
         padding-right: 21px;
         & > div {
             max-width: 250px;
             padding: 10px 25px 4.5px 25px;
             margin-bottom: 10px;
             background: #F5F5F9;
             border-radius: $border-radius / 2;
             cursor: pointer;
         }


         h3, p {
             word-break: break-all;
         }

         h3 {
             font-weight: 600;
             line-height: 17px;
         }
         p {
             font-weight: 500;
             line-height: 25px;
             color: #6B6D81;
         }

     }

     a {
         font-weight: 500;
         i {
             margin-left: 10px;
             width: 15px;
             height: 10px;
         }
     }
 }
</style>
