<template>
    <div class="surge-rate mb-4">
        <v-btn
            class="surge-rate__btn mb-2 mr-3 pricing-header--extra text-secondary"
            text elevation="0"
            color="secondary"
            @click="surgeRateCardOpened = !surgeRateCardOpened">
            Surge rate

            <v-icon size="12" color="secondary" class="ms-3 mt-0.5">
                {{ surgeRateIcon }}
            </v-icon>
        </v-btn>

        <!-- Surge rate form card -->
        <v-card class="custom--v-card pa-5" v-if="surgeRateCardOpened">
            <div class="text-secondary text-sm font-semibold mb-4">
                Service area for Surge Rate
            </div>

            <v-row>
                <v-col lg="10">
                    <v-row>
                        <v-col class="col-10">
                            <div class="slider-box mb-4">
                                <v-range-slider
                                    hide-details
                                    :max="100"
                                    :min="0"
                                    :value="[local.service_area, local.surge_distance]"
                                    @change="updateSurgeDistance"
                                    class="custom--v-slider"
                                />

                                <div class="slider-box__div" style="width: 120px;">
                                    {{ local.service_area }} - {{ local.surge_distance }} miles
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <!-- Form columns -->
                    <v-row class="align-end">
                        <v-col class="col">
                            <div class="mb-1.5 text-secondary text-sm font-semibold">
                                Additional hourly rate
                            </div>

                            <v-text-field
                                single-line
                                hide-details
                                outlined
                                type="text"
                                class="custom--v-text-field --dark"
                                v-model.number="local.surge_additional_hourly_rate"
                            />
                        </v-col>
                        <v-col class="col">
                            <div class="mb-1.5 text-secondary text-sm font-semibold">
                                Additional Minimum hours
                            </div>

                            <v-text-field
                                single-line
                                hide-details
                                outlined
                                type="text"
                                class="custom--v-text-field --dark"
                                v-model.number="local.surge_additional_min_hours"
                            />
                        </v-col>
                        <v-col class="col">
                            <div class="mb-1.5 text-secondary text-sm font-semibold">
                                Additional service fee
                            </div>

                            <v-text-field
                                single-line
                                hide-details
                                outlined
                                type="text"
                                class="custom--v-text-field --dark"
                                v-model.number="local.surge_additional_service_fee"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import ValueEmitter from "../../mixins/ValueEmitter";

export default {
    mixins: [ValueEmitter],

    data () {
        return {
            surgeRateCardOpened: false,
        };
    },

    methods: {
        updateSurgeDistance ([min, max]) {
            this.local.service_area = min;
            this.local.surge_distance = max;
        },
    },

    computed: {
        surgeRateIcon () {
            return this.surgeRateCardOpened ? 'fa-chevron-down' : 'fa-chevron-right';
        },
    }
}
</script>