<template>
    <v-switch
        :color="color"
        hide-details
        :style="cssVars"
        class="custom--v-switch"
        v-model="local"
    >
        <template v-slot:label>
            {{ label }}
        </template>
    </v-switch>
</template>
<script>
import ValueEmitter from "../mixins/ValueEmitter";

export default {
    props: {
        color: String,
        label: String,
    },

    mixins: [ValueEmitter],

    computed: {
        cssVars () {
            return {
                '--color': this.color,
            }
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep {

    .v-label {
        color: #0c3659 !important;
    }
}
</style>