export default {
    namespaced: true,

    state: () => ({
        /**
         * @type {{legal_name: String, primary_phone: String, contact_phone: String}}
         */
        company: {},
    }),

    mutations: {
        SET_COMPANY (state, payload) {
            state.company = payload;
        },
    },

    actions: {
        async load ({ rootState, commit }) {
            const { id } = rootState.auth.company;

            const company = await axios.get(`companies/${ id }`);

            commit('SET_COMPANY', company);

            return company;
        },

        async update ({ rootState, state }) {
            const { id } = rootState.auth.company;

            await axios.put(`companies/${ id }`, { ...state.company });
        },

        patch ({ rootState }, changes) {
            const { id } = rootState.auth.company;

            return axios.put(`companies/${ id }`, changes);
        }
    }
}