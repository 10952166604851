export default {
    namespaced: true,

    state: () => ({
        /** @type {Order[]} */
        orders: [],

        /** @type {BranchBlockDay[]} */
        blocked_days: [],
    }),

    getters: {
        orders: state => filters => {
            return filters.length > 0
                ? state.orders.filter(order => filters.includes(order.status_id))
                : [];
        },
    },

    mutations: {
        SET_ORDERS (state, payload) {
            state.orders = payload;
        },

        SET_BLOCKED_DAYS (state, payload) {
            state.blocked_days = payload;
        },

    },

    actions: {
        async load ({ commit }, branch_id) {
            const orders = await axios.get('orders/calendar', { params: { branch_id } });

            commit('SET_ORDERS', orders);
        },

        async loadBlockedDays ({ commit }) {
            const blocked_days = await axios.get('blocked-days');

            commit('SET_BLOCKED_DAYS', blocked_days);
        },

        async storeBlockedDay ({ dispatch }, data) {
            await axios.post('blocked-days', data);

            await dispatch('loadBlockedDays');
        },

        async updateBlockedDay ({ dispatch }, data) {
            await axios.put(`blocked-days/${ data.id }`, data);

            await dispatch('loadBlockedDays');
        },

        async destroyBlockedDay ({ dispatch }, data) {
            await axios.delete(`blocked-days/${ data.id }`);

            await dispatch('loadBlockedDays');
        }
    }
}