<template>
    <div class="horizontal-calendar-grid">
        <div class="horizontal-calendar-grid__header">
            <v-btn icon @click="handlePrev">
                <v-icon size="16" color="secondary">fa-solid fa-chevron-left</v-icon>
            </v-btn>
            <v-btn icon @click="handleNext">
                <v-icon size="16" color="secondary">fa-solid fa-chevron-right</v-icon>
            </v-btn>
        </div>
        <v-carousel hide-delimiters touchless :show-arrows="false" class="horizontal-calendar-grid__carousel" ref="carousel"
            v-model="currentWeekIndex" :reverse="reverseTransition">
            <v-carousel-item v-for="(weekDays, i) in weeks" :key="i">
                <div class="calendar-title">{{ calendarTitle }}</div>
                <v-tabs>
                    <v-tab v-for="(weekDay, j) in weekDays" :key="j * j" :class="[{ 'active-tab': isToday(weekDay.date) }]" @click="selectDay(weekDay)">
                        <div class="weekday-number">{{ weekDay.day }}</div>
                        <div class="weekday-name">{{ weekDayNames[j] }}</div>
                        <div class="ordered-status" :style="`background-color: ${orderedWeekdayColors[weekDay.day]}`" :key="orders.length"></div>
                    </v-tab>
                </v-tabs>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
import Calendar from 'json-calendar'
import { ORDER_COLORS, WEEK_DAY_SHORT_NAMES } from "@/plugins/data.js";
export default {
    props: {
        pickerDate: {
            type: String,
            default: ""
        },
        orders: {
            type: Array
        }
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            currentMonthIndex: 0,
            currentWeekIndex: null,
            weeksMaxLength: 6,
            weeksRealLength: 0,
            weeks: [],
            reverseTransition: false,
        }
    },
    computed: {
        calendar() {
            return new Calendar({ today: new Date() });
        },
        weekDayNames() {
            const result = [];
            this.calendar.dayNames.forEach(dn => result.push(dn.name.slice(0, 3)));
            return result;
        },
        calendarTitle() {
            const result =
                this.firstWeekDay.day + " " + this.calendar.monthNames[this.firstWeekDay.monthIndex] + " - " +
                this.lastWeekDay.day + " " + this.calendar.monthNames[this.lastWeekDay.monthIndex] + ", " + this.currentYear;
            return result;
        },
        firstWeekDay() {
            return this.weeks[this.currentWeekIndex][0]
        },
        lastWeekDay() {
            return this.weeks[this.currentWeekIndex][6]
        },
        willChangeCurrentYear() {
            return this.firstWeekDay.monthIndex === 11 && this.lastWeekDay.monthIndex === 0 || this.firstWeekDay.monthIndex === 0 && this.firstWeekDay.day === 1
        },
        orderedWeekdayColors() {
            const result = {};
            this.orders.forEach(({dayName, dayNumber}) => {
                const weekdayIndex = WEEK_DAY_SHORT_NAMES.indexOf(dayName);
                const color = ORDER_COLORS[weekdayIndex];
                result[Number(dayNumber)] = color;
            })
            return result;
        }
    },
    methods: {
        handlePrev() {
            this.currentMonthIndex = this.firstWeekDay.monthIndex;

            this.reverseTransition = false;

            // Indicating previus month index:
            if (this.firstWeekDay.day === 1) {
                if (this.currentMonthIndex === 0) {
                    this.currentMonthIndex = 11;
                }
                else {
                    this.currentMonthIndex--;
                }
            }

            // Indicating previus year:
            if (this.willChangeCurrentYear) {
                this.currentYear--;
            }

            if (this.currentWeekIndex === this.weeksMaxLength - this.weeksRealLength) {
                this.reverseTransition = true;

                this.setWeeks();
                this.currentWeekIndex = this.weeks.length - 1;
            }
            else {
                this.currentWeekIndex--;
                this.$refs.carousel.prev();
            }

            this.$emit('setCurrentWeek', this.weeks[this.currentWeekIndex]);
        },

        handleNext() {
            this.reverseTransition = false;

            if (this.currentWeekIndex === this.weeks.length - 1) {
                this.reverseTransition = true;

                this.currentMonthIndex = this.firstWeekDay.monthIndex + 1;

                this.setWeeks();
                this.currentWeekIndex = this.weeksMaxLength - this.weeksRealLength;
            }
            else {
                this.currentWeekIndex++;
                this.$refs.carousel.next();
            }

            // Indicating next year:
            if (this.willChangeCurrentYear) {
                this.currentYear++;
            }

            this.$emit('setCurrentWeek', this.weeks[this.currentWeekIndex]);
        },

        setCurrentWeekIndex(todayDate) {
            let currentWeekIndex = 0;
            this.weeks.forEach((weekDays, wdi) => {
                weekDays.forEach((weekDay) => {
                    if (weekDay.day === todayDate && weekDay.monthIndex === this.currentMonthIndex) {
                        currentWeekIndex = wdi;
                    }
                })
            });
            this.currentWeekIndex = currentWeekIndex;
        },

        setWeeks() {
            let sliceEndIndex = 5;
            let weeks = new Calendar({ today: new Date(this.currentYear, this.currentMonthIndex) }).weeks;
            if (weeks[4][0].monthIndex !== weeks[4][6].monthIndex) {
                sliceEndIndex = 4
            }
            this.weeks = weeks.slice(0, sliceEndIndex);

            this.weeksRealLength = this.weeks.length;

            for (let i = 0; i < this.weeksMaxLength - this.weeksRealLength; i++) {
                this.weeks.unshift([]);
            };
        },

        isToday(dateToCheck) {
            var today = new Date();

            return (
                dateToCheck.getFullYear() === today.getFullYear() &&
                dateToCheck.getMonth() === today.getMonth() &&
                dateToCheck.getDate() === today.getDate()
            );
        },

        selectDay(weekDay) {
            this.$emit('selectWeekDay', weekDay);
        }
    },
    created() {
        let currentDay = 1;
        const pickerDateList = this.pickerDate.split("-");
        this.currentYear = Number(pickerDateList[0]);
        this.currentMonthIndex = pickerDateList[1] - 1;

        if (pickerDateList[2]) {
            currentDay = Number(pickerDateList[2])
        }

        this.setWeeks();
        this.setCurrentWeekIndex(currentDay);

        this.$emit('setCurrentWeek', this.weeks[this.currentWeekIndex]);
    },
}
</script>