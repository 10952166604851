import { ORDER_STATUSES } from "@/plugins/data";

export const isNewOrder = order => order.status_id === ORDER_STATUSES.STATUS_NEW;

export const isAcceptedOrder = order => order.status_id === ORDER_STATUSES.STATUS_ACCEPTED;

export const isRejectedOrder = order => order.status_id === ORDER_STATUSES.STATUS_REJECTED;

export const isCompletedOrder = order => order.status_id === ORDER_STATUSES.STATUS_COMPLETED;

export const isRejectedAfterAcceptingOrder = order => order.status_id === ORDER_STATUSES.STATUS_REJECTED_AFTER_ACCEPTING;

export const isBeingRescheduledOrder = order => order.status_id === ORDER_STATUSES.STATUS_BEING_RESCHEDULED;

export const isBeingCompletedOrder = order => order.status_id === ORDER_STATUSES.STATUS_BEING_COMPLETED;

export const isCustomerCompletedOrder = order => order.isCustomerCompleted;

export const isHelperCompletedOrder = order => order.isHelperCompleted;