var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-navigation',{staticClass:"mobile-main-menu custom--v-bottom-navigation",attrs:{"color":"primary","grow":"","hide-overlay":"","fixed":""}},_vm._l((_vm.items),function(ref){
var title = ref.title;
var name = ref.name;
var icon = ref.icon;
return _c('router-link',{key:name,attrs:{"link":"","custom":"","to":{name: name},"exact-active-class":"v-list-item--active"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('v-btn',{staticClass:"tw-relative",attrs:{"role":"link"},on:{"click":navigate}},[(!icon)?_c('span',{staticClass:"tw-absolute tw-top-[37px]"},[_vm._v(_vm._s(title))]):_c('span',[_vm._v(_vm._s(title))]),(icon)?_c('v-icon',{staticClass:"mb-2",attrs:{"size":"20"}},[_vm._v(_vm._s(icon))]):_c('span',{staticClass:"tw-absolute tw-top-[8px] tw-right-[43%] !tw-mb-3"},[_c('svg',{style:({fill: isActive ? '#6B6D81' : '#aaa'}),attrs:{"width":"26","height":"26","viewBox":"0 0 14 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M3.30926 12.9781L3.44074 13.0497C4.64786 13.707 5.25142 14.0357 5.89138 14.1645C6.45774 14.2785 7.04226 14.2785 7.60862 14.1645C8.24858 14.0357 8.85214 13.707 10.0592 13.0497L10.1907 12.9781C11.3152 12.3657 11.8774 12.0595 12.3004 11.6399C12.7822 11.162 13.1356 10.577 13.3296 9.93619C13.5 9.37353 13.5 8.74903 13.5 7.50002C13.5 6.251 13.5 5.62647 13.3296 5.06381C13.1356 4.42298 12.7822 3.83798 12.3004 3.36007C11.8774 2.94046 11.3152 2.63428 10.1907 2.02194L10.0593 1.95034C8.85215 1.29297 8.24858 0.96428 7.60862 0.835485C7.04226 0.721505 6.45774 0.721505 5.89138 0.835485C5.25142 0.96428 4.64786 1.29297 3.44074 1.95034L3.30926 2.02194C2.18483 2.63428 1.6226 2.94046 1.19959 3.36007C0.717813 3.83798 0.364423 4.42298 0.170377 5.06381C0 5.62647 0 6.25098 0 7.5C0 8.74902 0 9.37353 0.170377 9.93619C0.364424 10.577 0.717814 11.162 1.19959 11.6399C1.6226 12.0595 2.18484 12.3657 3.30926 12.9781ZM6.75 4.96769C5.30543 4.96769 4.13438 6.10144 4.13438 7.49999C4.13438 8.89853 5.30543 10.0323 6.75 10.0323C8.19457 10.0323 9.36563 8.89853 9.36563 7.49999C9.36563 6.10144 8.19457 4.96769 6.75 4.96769ZM5.14688 7.49999C5.14688 6.64281 5.86462 5.94793 6.75 5.94793C7.63538 5.94793 8.35313 6.64281 8.35313 7.49999C8.35313 8.35716 7.63538 9.05204 6.75 9.05204C5.86462 9.05204 5.14688 8.35716 5.14688 7.49999Z"}})])])],1)]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }