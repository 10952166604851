<template>
  <div>
    <v-skeleton-loader v-if="isLoading || loadingSection" type="table"/>

    <div v-if="!loadingSection">
      <v-data-table
        :headers="headers"
        :items="orders"
        hide-default-footer
        :items-per-page="15"
        :class="tableClasses"
        v-show="orders.length > 0"
      >
        <template #item.id="{ item }">
          <button @click.prevent="openOrderModal(item)" class="tw-p-[3px_8px] -tw-mx-[8px] tw-text-[14px] tw-font-semibold tw-text-[#8F3985] tw-rounded-[5px] hover:tw-bg-[#8F3985]/20 tw-duration-300">
            <span v-text="item.id"/>
          </button>
        </template>

        <template #item.reviews="{ item }">
          <v-rating
            :value="item.reviews"
            half-increments
            half-icon="fa-star-half-alt"
            readonly
            size="14"
            background-color="purple lighten-3"
            class="custom--v-rating"
          />
        </template>

        <template #item.status="{ item }">
                <span
                  class="tw-p-[4.5px_8px] tw-border tw-rounded-[8px] tw-text-[#0D3659] tw-text-[14px] tw-font-medium"
                  :class="[statusClass(item.status_id)]"
                  v-text="statusLabel(item.status_id)"
                />
        </template>
      </v-data-table>

      <v-pagination
        v-model="page"
        :length="last_page"
        class="custom--v-pagination"
      />
    </div>

    <v-alert v-else color="primary" text class="text-center">Invite your client to the platform</v-alert>
  </div>
</template>

<script>
import loading from '@/mixins/loading.js';
import { mapActions, mapGetters, mapState } from 'vuex';
import AppOrder from '@/components/Modals/AppOrder.vue';
import { getParam } from '@/plugins/helpers.js';

export default {
  props: {
    branchId: Number | String
  },

  mixins: [loading],

  data () {
    return {
      headers: [
        {
          text: 'Order #',
          value: 'id',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Order Date',
          value: 'arrival_date',
          sortable: false,
        },
        {
          text: 'Customer Name',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Helpers & Trucks',
          value: 'service',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          align: 'end',
        },
      ],
      tableClasses: `tw-mb-[15px] tw-border-[4px] tw-border-solid tw-border-[#FFF] !tw-rounded-[5px] [&_thead_th]:!tw-h-[40px] [&_thead_th]:!tw-text-[14px] [&_thead_th]:!tw-text-[#0D3659] [&_thead_th]:!tw-font-semibold [&_thead_th]:!tw-border-0 [&_tbody_td]:!tw-h-[40px] [&_tbody_td]:tw-text-[14px] [&_tbody_td]:tw-text-[#0D3659] [&_tbody_td]:tw-font-medium [&_tbody_td]:!tw-border-0 [&_tbody_td]:!tw-rounded-none [&_tbody_tr:nth-child(odd)]:tw-bg-[#F5F5F9]`,
    };
  },

  methods: {
    ...mapActions('orders', ['load', 'goToPage']),

    openOrderModal ({ id }) {
      this.$modal.show(AppOrder, {
        orderId: id,
        afterClose: this.loadOrders,
      }, {
        width: '800px',
        height: '660px',
      });
    },

    statusClass (status) {
      const statusClasses = [
        'tw-bg-[#FFF7D9] tw-border-[#FFE37E]', // Waiting
        'tw-bg-[] tw-border-[]', // New
        'tw-bg-[#D9FFE3] tw-border-[#94EBAB]', // Accepted
        'tw-bg-[#FBE2FF] tw-border-[#F4ADFF]', // Rejected
        'tw-bg-[#FEE4E2] tw-border-[#FFADAD]', // Cancelled
        'tw-bg-[#E2EAFF] tw-border-[#8EACFC]', // Completed
        'tw-bg-[#FFF7D9] tw-border-[#FFE37E]', // Waiting
        'tw-bg-[#FFF7D9] tw-border-[#FFE37E]', // Waiting for Complete
      ][status];

      return statusClasses;
    },

    statusLabel (status) {
      return [
        'Waiting',
        'New',
        'Accepted',
        'Rejected',
        'Cancelled',
        'Completed',
        'Waiting',
        'Waiting for Complete',
      ][status];
    },

    async loadOrders () {
      await this.startLoading(async () => await this.load({ branch_id: this.branchId }));
    },
  },

  computed: {
    ...mapGetters('orders', ['orders']),
    ...mapState('orders', ['last_page', 'loadingSection']),

    page: {
      get () {
        return this.$store.state.orders.current_page;
      },

      /**
       * @param {number} val
       * @return {Promise<void>}
       */
      set (val) {
        return this.goToPage({ val, branch_id: this.branchId });
      }
    },
  },

  created () {
    if (getParam('order_id')) {
      this.openOrderModal({ id: getParam('order_id') });
    }

    this.loadOrders();
  },

  watch: {
    branchId: 'loadOrders',
  }
};
</script>
