<template>
    <v-app class="review-modal">
        <div class="order-modal__row">
            <span class="text-secondary">Review Modal</span>
            <div class="review-modal__close">
                <v-btn icon @click="close">
                    <img src="assets/icons/delete.svg" alt="x-icon"/>
                </v-btn>
            </div>
            <section class="review-modal__card">
                <v-img src="/assets/images/logos/logo-purple.svg" alt="logo" aspect-ratio="1/2" max-width="236"
                       height="55" class="mx-auto mb-4"/>
                <h1 class="review-modal__header text-secondary font-bold text-center">Set review for
                    {{ reviewUser.receiverName }}</h1>
                <v-divider style="color: #ddd"></v-divider>
                <v-form class="review-modal__form">
                    <div class="review-modal__name">
                        <h5>{{ reviewUser.reviewText }}</h5>
                        <p class="mb-0">{{ reviewUser.name }}</p>
                    </div>
                    <div class="review-modal__branch">
                        <h5>Branch name</h5>
                        <p class="mb-0">{{ reviewUser.branchName }}</p>
                    </div>

                    <div>
                        <h5>{{ reviewUser.info }}</h5>
                        <v-rating
                            half-icon="fa-star-half-alt"
                            size="18"
                            color="#FBAB1E"
                            background-color="#FBAB1E"
                            class="custom--v-rating"
                            v-model="review.star"
                        />
                    </div>
                    <div>
                        <h5>Write some comments</h5>
                        <v-textarea
                            rows="4"
                            aria-valuemin="5"
                            outlined
                            autofocus
                            auto-grow
                            placeholder="Write some comment"
                            class="custom--v-textarea"
                            :rules="commentRules"
                            v-model.trim="review.comment"
                        >
                        </v-textarea>
                    </div>
                    <div class="d-flex align-center justify-center">
                        <v-btn
                            class="px-10"
                            elevation="0"
                            color="primary"
                            height="50"
                            max-width="400"
                            :width="$vuetify.breakpoint.smAndDown ? '100%' : '400' "
                            :disabled="$v.review.$invalid"
                            @click.prevent="submitReview"
                        >
                            Submit
                        </v-btn>
                    </div>
                </v-form>
            </section>
        </div>
    </v-app>
</template>

<script>

import loading from "../../mixins/loading";
import { mapActions, mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required } from "vuelidate/lib/validators";

export default {
    props: {
        order: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            review: {},
            reviewUser: {
                name: '',
                receiverName: '',
                reviewText: '',
                branchName: ''
            },
            commentRules: [
                v => !!v || 'Comment is required',
                v => (v && v.length >= 10) || 'Comment must be more than 10 characters',
            ],
        }
    },

    mixins: [ loading, validationMixin ],

    validations: {
        review: {
            star: {
                required: required
            },
            comment: {
                required: required,
                minLength: minLength(10),
                maxLength: maxLength(1500)
            }
        }
    },

    computed: {
        ...mapGetters('auth', [ 'user', 'activeBranch', 'isCustomer' ]),
        ...mapState('auth', [ 'company' ])
    },

    methods: {
        ...mapActions('user_orders', [ 'set_review_customer', 'set_review_mover' ]),

        submitReview() {
            if (this.user.isCustomer) {
                this.startLoading(async () => {
                    await this.set_review_customer({
                        order_id: this.order.id,
                        review: this.review
                    });
                    this.$emit('close');
                });
            } else {
                this.startLoading(async () => {
                    await this.set_review_mover({
                        order_id: this.order.id,
                        review: this.review
                    });

                    await this.$store.dispatch('dashboard/load');
                    this.$emit('close');
                });
            }
        },

        close() {
            this.$emit('close');
        }
    },

    created() {
        if (this.user.isCustomer) {
            this.reviewUser = {
                name: this.user.last_name ? this.user.first_name + ' ' + this.user.last_name : this.user.first_name,
                reviewText: 'Your name',
                receiverName: this.order.company_name.split(' - ')[0],
                branchName: this.order.branch_name,
                info: 'Rate your mover'
            }
        } else {
            this.reviewUser = {
                name: this.company.name,
                reviewText: 'Company name',
                receiverName: this.order.name,
                branchName: this.order.branch_name,
                info: 'Rate your customer'
            }
        }
    },
}
</script>
