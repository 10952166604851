<template>
    <div class="tw-flex tw-justify-end tw-items-center tw-gap-[10px]">
        <v-btn
            elevation="0"
            color="primary"
            v-if="isEnabledReschedule"
        >
            Reschedule
        </v-btn>

        <v-btn
            elevation="0"
            color="light"
            @click="cancel(delivery.id)"
            v-if="isEnabledCancel"
        >
            Cancel
        </v-btn>

        <v-btn
            elevation="0"
            color="primary"
            @click="archive(delivery.id)"
            v-if="isEnabledArchive"
        >
            Archive
        </v-btn>

        <v-btn
            elevation="0" color="light"
            @click="openNewOrderModal"
            v-if="isEnabledHireHelper"
        >
            Hire a new helper
        </v-btn>

        <v-btn
            elevation="0"
            color="primary"
            @click="complete(delivery.id)"
            v-if="isEnabledComplete"
        >
            Complete
        </v-btn>
    </div>
</template>

<script>
import { warningConfirmAction } from "@/plugins/swal.js";
import { mapActions } from "vuex";
import AppNewCustomerOrder from '@/components/Modals/AppNewCustomerOrder.vue';

export default {
    props: {
        delivery: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            status: this.delivery.status,
            currentDate: new Date(),
            arrivalDate: new Date(this.delivery.arrival_date),
        }
    },

    methods: {
        ...mapActions('user_orders', {
            cancelAction: 'cancelDelivery',
            completeAction: 'completeDelivery',
            archiveAction: 'archiveDelivery',
        }),

        cancel(order_id) {
            warningConfirmAction(
              "Do you really want to cancel the order?",
              "Confirm",
              async (result) => {
                const inputValue = result.value;

                await this.cancelAction({
                  order_id: order_id,
                  reason: inputValue
                });
              },
              'text',
              'Enter reason for cancellation...'
            )
        },

        complete(order_id) {
            warningConfirmAction("Do you really want to complete the order?", "Confirm", async () => {
                await this.completeAction(order_id);
            })
        },

        archive(order_id) {
            warningConfirmAction("Do you really want to archive the order?", "Confirm", async () => {
                await this.archiveAction(order_id);
            })
        },

        openNewOrderModal() {
            this.$modal.show(AppNewCustomerOrder, {}, {
                width: 450,
                height: 495,
            });
        }
    },

    computed: {
        isEnabledReschedule() {
            return this.delivery.canCustomerReschedule;
        },

        isEnabledCancel() {
            return this.delivery.canCustomerCancel;
        },

        isEnabledArchive() {
            return this.delivery.canCustomerArchive;
        },

        isEnabledHireHelper() {
            return this.delivery.isArchived;
        },

        isEnabledComplete() {
            return this.delivery.canCustomerComplete;
        },
    }
}
</script>
