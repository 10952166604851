<template>
    <v-app>
        <div class="p-8">
            <v-data-table
                :headers="headers"
                :items="[local]"
                hide-default-footer
                class="custom--v-table mb-5"
            >
                <template #item.name>
                    {{ servicePriceName }}
                </template>
            </v-data-table>

            <v-row>
                <v-col lg="10">
                    <v-row class="mb-3">
                        <v-col cols="6">
                            <div class="mb-1.5 text-secondary">
                                Movers size
                            </div>
                            <v-select
                                v-model="local.employees_count"
                                :items="employees_count_selection"
                                item-text="display"
                                item-value="value"
                                dense
                                outlined
                                hide-details
                                append-icon="fa-chevron-down"
                                class="custom--v-select"
                                small
                            />
                        </v-col>
                        <v-col cols="6">
                            <div class="mb-1.5 text-secondary">
                                Minimum hours
                            </div>

                            <v-select
                                v-model="local.minimum_hours"
                                :items="minimum_hours_selection"
                                item-text="display"
                                item-value="value"
                                dense
                                outlined
                                hide-details
                                append-icon="fa-chevron-down"
                                class="custom--v-select"
                            />
                        </v-col>
                        <v-col cols="6">
                            <div class="mb-1.5 text-secondary">
                                Base hourly rate
                            </div>
                            <v-text-field
                                v-model="local.base_hourly_rate"
                                single-line
                                hide-details
                                outlined
                                type="text"
                                class="custom--v-text-field"
                                v-maska="only_integers_allowed"
                            />
                        </v-col>
                        <v-col cols="6" class="d-flex align-center" style="padding-top: 30px">
                            <v-checkbox
                                single-line hide-details
                                outlined type="text"
                                class="custom--v-text-field"
                                v-model="local.charge_overtime"
                                label="Charge for overtime"
                            />
                            <v-tooltip bottom color="gray"  class="custom--v-tooltip tooltip" max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                        <v-icon  v-bind="attrs"  v-on="on" size="20" class="mt-4 ml-4">
                                           fa-info-circle
                                        </v-icon>
                                </template>
                                <span class="tooltip-text"> California Labor Code 510 states that:
                                    Eight hours of labor constitutes a day’s work. Any work in excess of eight hours in one workday and any work in excess of 40 hours in any one workweek and the first eight hours worked on the seventh day of work in any one workweek shall be compensated at the rate of no less than one and one-half times the regular rate of pay for an employee. Any work in excess of 12 hours in one day shall be compensated at the rate of no less than twice the regular rate of pay for an employee. In addition, any work in excess of eight hours on any seventh day of a workweek shall be compensated at the rate of no less than twice the regular rate of pay of an employee. Nothing in this section requires an employer to combine more than one rate of overtime compensation in order to calculate the amount to be paid to an employee for any hour of overtime work.</span>
                            </v-tooltip>

                        </v-col>

                        <v-col cols="6" v-if="!movers_only">
                            <div class="mb-1.5 text-secondary">
                                Truck size
                            </div>

                            <v-select
                                v-model="local.truck_size"
                                :items="truck_sizes"
                                dense outlined
                                hide-details append-icon="fa-chevron-down"
                                class="custom--v-select"
                            />
                        </v-col>

                        <v-col cols="6" v-if="!movers_only">
                            <div class="mb-1.5 text-secondary">
                                Truck numbers
                            </div>

                            <v-select
                                v-model="local.truck_number"
                                :items="trucks_number_selection"
                                item-text="display"
                                item-value="value"
                                dense outlined
                                hide-details append-icon="fa-chevron-down"
                                class="custom--v-select"
                            />
                        </v-col>
                    </v-row>

                    <div class="d-flex justify-end">
                        <v-btn
                            class="px-10 mt-2"
                            elevation="0"
                            color="primary"
                            @click="save"
                            large
                        >
                            Save
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-app>
</template>

<script>
import { generateSelection } from "@/plugins/data.js";
import { mapActions } from "vuex";
import { MOVERS_AND_TRUCKS, MOVERS_ONLY, TRUCK_SIZES } from "@/plugins/consts.js";
import { maska } from "maska";
import { only_integers_allowed } from "@/plugins/custom_masks";

export default {
    props: {
        branch_id: {
            type: Number,
            required: true
        },

        service_price: {
            type: Object,
            required: true,
        },

        is_creating: {
            type: Boolean,
            default: false
        },

        movers_only: {
            type: Boolean,
            required: true,
        }
    },

    data () {
        return {
            /** @type {ServicePrice} */
            local: this.service_price || {
                name: 'Loading...',
                employees_count: 1,
                minimum_hours: 1,
                base_hourly_rate: 1,
                charge_overtime: false,
                truck_size: TRUCK_SIZES[0],
                truck_number: 1,
            },

            headers: [
                {
                    text: 'Service',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'Base Hourly Rate',
                    align: 'end',
                    sortable: false,
                    value: 'base_hourly_rate',
                },
                {
                    text: '',
                    align: 'end',
                    sortable: false,
                    value: 'edit',
                },
            ],

            employees_count_selection: generateSelection(25, 'Mover'),
            minimum_hours_selection: generateSelection(15, 'Hour'),
            trucks_number_selection: generateSelection(10, 'Truck'),

            truck_sizes: TRUCK_SIZES,

            only_integers_allowed,
        };
    },

    computed: {
        servicePriceName () {
            let movers = this.local.employees_count > 1 ? 'Movers' : 'Mover';
            let hours = this.local.minimum_hours > 1 ? 'Hours' : 'Hour'
            let string = `${ this.local.employees_count } ${movers}, ${ this.local.minimum_hours } ${hours}`;

            if (! this.movers_only) {
                string += `, ${ this.local.truck_size } Truck`;
            }

            return string;
        },
    },

    directives: {
        maska
    },

    methods: {
        ...mapActions('service_pricing', ['store', 'update']),

        async save () {
            this.local.service_type = (this.movers_only ? MOVERS_ONLY : MOVERS_AND_TRUCKS).toString();

            if (this.is_creating) {
                await this.store([this.branch_id, this.local]);
            } else {
                await this.update([this.branch_id, this.local.id, this.local]);
            }

            this.$emit('close');
        },
    },
};
</script>
<style lang="scss">
@import "./src/assets/scss/style";
.tooltip-text {
    font-size: 15px;
    padding: 5px;
    font-weight: 500;
    color: $secondary;
}
</style>
