<template>
  <div class="tw-mb-[15px]">
    <div class="tw-flex tw-items-center tw-justify-between tw-gap-[30px] tw-mb-[12px]">
      <h3 class="tw-text-[22px] tw-text-[#0D3659] tw-font-semibold">Overview</h3>
      <orders-filter orders-deliveries/>
    </div>

    <div class="tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-[12px]">
      <div
        v-for="({ title, icon, current, percent, isIncreased, difference }, index) in overview"
        :key="index"
        class="tw-grid tw-p-[16px] tw-rounded-[16px] tw-bg-[#FFF]"
      >
        <div class="tw-flex tw-items-center tw-gap-[10px] tw-text-[#AAAAAA] tw-mb-[18px]">
          <i class="!tw-w-[18px] !tw-min-w-[18px]" :class="['icony', icon]"></i>
          <span class="tw-text-[16px] tw-font-semibold" v-text="title"/>
        </div>
        <div class="tw-flex tw-items-center tw-gap-[12px] tw-mb-[8px]">
          <span class="tw-text-[#0D3659] tw-text-[22px] tw-font-semibold" v-text="current"/>
          <div class="tw-flex tw-items-center tw-gap-[5px] tw-p-[5.5px_6.5px] tw-rounded-[8px]" :class="isIncreased ? 'tw-bg-[#E6F5EB] tw-text-[#1CA647]' : 'tw-bg-[#EE3939] tw-text-[#FFF]'">
            <i class="!tw-w-[10px]" :class="['icony', isIncreased ? 'icony-arrow-up' : 'icony-arrow-down']"></i>
            <span class="tw-text-[14px] tw-font-semibold tw-leading-none" v-text="percent"/>
          </div>
        </div>
        <div class="tw-text-[#AAAAAA] tw-text-[14px] tw-font-semibold"><span :class="isIncreased ? 'tw-text-[#1CA647]' : 'tw-text-[#EE3939]'">{{ difference }}</span> than last month</div>
      </div>
    </div>
  </div>
</template>

<script>
import OrdersFilter from '@/views/Orders/OrdersFilter.vue';

export default {
  components: {
    OrdersFilter
  },

  data () {
    return {
      overview: [
        {
          title: 'Earnings',
          current: '$1500.60',
          icon: 'icony-dollar-circle-o',
          percent: '15.6%',
          isIncreased: true,
          difference: '+$250.8'
        },
        {
          title: 'Completed Orders',
          icon: 'icony-check-circle-o',
          current: '60',
          percent: '7.6%',
          isIncreased: true,
          difference: '+$40.8'
        },
        {
          title: 'Cancelled Orders',
          icon: 'icony-times-circle-o',
          current: '35',
          percent: '3.6%',
          isIncreased: false,
          difference: '-$6.8'
        },
        {
          title: 'Waiting Orders',
          icon: 'icony-info-circle-o',
          current: '140',
          percent: '9.8%',
          isIncreased: true,
          difference: '+$6.8'
        },
      ]
    };
  },
};
</script>
