const LOADING = 0;
const LOADED = 1;
const FAILED = -1;

export default {
    data: () => ({
        loading: null,
    }),

    methods: {
        loadingStart () {
            this.loading = LOADING;
        },

        loadingFail () {
            this.loading = FAILED;
        },

        loadingFinish () {
            this.loading = LOADED;
        },

        async startLoading (callback) {
            try {
                this.loadingStart();
                await callback();
                this.loadingFinish();
            } catch (error) {
                this.loadingFail();
                throw error;
            }
        }
    },

    computed: {
        isLoading () {
            return this.loading === LOADING;
        },

        isFailed () {
            return this.loading === FAILED;
        },

        isLoaded () {
            return this.loading === LOADED;
        },
    },
}