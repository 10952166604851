<template>
    <div
          class="tw-grid sm:tw-grid-cols-2 tw-gap-[25px] tw-p-[25px] tw-bg-white tw-border tw-border-solid tw-border-[#DDDDDD] tw-rounded-[8px]"
          :class="{ '!tw-border-[#8f3985]': branch.is_main }
    ">
        <div>
            <div class="tw-flex tw-items-start tw-gap-[10px] tw-mb-[25px]">
                <div
                    v-if="branch.is_main"
                    class="tw-flex tw-items-center tw-justify-center tw-bg-[#824FE7] tw-rounded-[8px] tw-w-[22px] tw-min-w-[22px] tw-mt-[2px] tw-aspect-square"
                >
                    <v-icon size="10" color="#FFC909">fa-star</v-icon>
                </div>

                <div>
                    <span class="tw-text-[18px] tw-text-[#0D3659] tw-font-semibold" v-text="branch.name"/>

                    <span
                      class="tw-inline-block tw-h-[17px] tw-leading-none tw-ml-[10px] tw-pl-[10px] tw-border-0 tw-border-l tw-border-[#8F3985]">
                        <span class="tw-text-[#8F3985] tw-text-[14px] tw-font-semibold" v-text="branch.zip_code" />
                    </span>
                </div>
            </div>

            <div
                class="tw-grid tw-gap-[15px]"
                :class="{'sm:tw-grid-cols-2': !branch.is_main}"
            >
                <div>
                    <ul class="location-card__lists pa-0 ma-0">
                        <li class="location-card__item location-card--address mt-2">
                            {{ branch.address }}
                        </li>
                        <li class="location-card__item mt-2">
                            {{ branch.state_name }}
                        </li>
                        <li class="location-card__item mt-2">
                            {{ branch.city_name }}
                        </li>
                        <li class="location-card__item mt-2">
                          {{ getCountyName }}
                        </li>
                    </ul>
                </div>

                <div class="tw-flex tw-items-end">
                    <div class="tw-grid tw-grid-cols-2 tw-gap-[10px] tw-w-full">
                        <div>
                            <v-btn depressed color="primary" small
                                   @click="edit"
                                   class="tw-w-full custom--v-btn"
                                   :class="{'sm:tw-max-w-[50%]': branch.is_main}"
                            >
                                Edit
                            </v-btn>
                        </div>
                        <div>
                            <v-btn depressed color="light" small
                                   @click="destroy(branch)"
                                   class="tw-w-full custom--v-btn"
                                   v-if="!branch.is_main"
                            >
                                Delete
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="max-sm:tw-order-first">
            <locations-item-map
                :latitude="branch.latitude"
                :longitude="branch.longitude"
                :radius="branch.service_area"
                class="tw-z-0 tw-rounded-[8px] tw-min-h-[148px]"
            />
        </div>
    </div>
</template>

<script>
import LocationsItemMap from "./LocationsItemMap";
import LocationsModal from "./LocationsModal";
import { mapActions, mapMutations } from "vuex";
import { warningConfirmAction } from "@/plugins/swal.js";

export default {
    props: {
        load: Function
    },

    components: {
        LocationsItemMap,
        LocationsModal,
    },

    data () {
        return {
            /**
             * @type {Branch|String}
             */
            branch: this.$attrs.branch,
        }
    },

    computed: {
        getPhoneNumber () {
            return this.branch.phone_number ? this.branch.phone_number : this.branch.primary_phone;
        },

        getCountyName () {
          return this.branch.counties?.[0]?.name || '';
        }
    },

    methods: {
        ...mapActions('locations', ['destroyBranch']),
        ...mapMutations('auth', ['SET_BRANCHES']),

        edit () {
            const modalProps = {
                width: 1000,
                height: 565,
            };

            this.$modal.show(LocationsModal, { branch: this.branch, load: this.load }, modalProps);
        },

        destroy (branch) {
            warningConfirmAction(
                'Are you sure you want to delete this branch?',
                'Delete',
                async () => {
                    await this.destroyBranch(branch);
                    await this.load();
                    const branches = await axios.get("branches")
                    this.SET_BRANCHES(branches);
                });
        }
    },
};
</script>

<style lang="scss" scoped>
.location-items {
    h5, p {
        word-break: break-all;
    }
}

.location-card {
    &--address {
        max-width: 90%;
        word-break: break-all;
    }
}
</style>
