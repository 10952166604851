export default {
    namespaced: true,

    state: {
        introduction: {}
    },

    getters: {
        introduction: state => state.introduction,
        services: state => state.introduction.services,
    },

    mutations: {
        SET_INTRODUCTION (state, payload) {
            state.introduction = payload;
        }
    },

    actions: {
        async load ({ rootState, commit }) {
            const { id }= rootState.auth.activeBranch;
            const introductionData = await axios.get(`/branches/${ id }?section=introduction`);

            commit('SET_INTRODUCTION', introductionData);
        },

        async update ({ rootState, dispatch }, { new_data }) {
            const { id }= rootState.auth.activeBranch;

            await axios.put(`/branches/${ id }`, {
                ...new_data
            });

            dispatch('load');
        },
    }
}