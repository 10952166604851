export default {
    namespaced: true,

    actions: {
        index (_, { branch_id }) {
            return axios.get(`branches/${ branch_id }/discounts`);
        },

        store (_, { branch_id, data }) {
            return axios.post(`branches/${ branch_id }/discounts`, data);
        },

        update (_, { branch_id, discount_id, data }) {
            return axios.put(`branches/${ branch_id }/discounts/${ discount_id }`, data);
        },

        destroy (_, { branch_id, discount_id }) {
            return axios.delete(`branches/${ branch_id }/discounts/${ discount_id }`);
        }
    }
}