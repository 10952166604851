/**
 * Converts file to base64
 *
 * @param {File} file
 * @return {Promise<String>}
 */
export default file => {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
    })
}