<template>
    <div
        class="tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-center tw-justify-center tw-items-end sm:tw-justify-end tw-gap-[10px]">
        <div v-if="delivery.canAcceptorAccept">
            <v-btn
                elevation="0"
                color="primary"
                @click="acceptDeliveryAction()"
                v-if="activeBranchId !== 'company'"
            >
                Accept
            </v-btn>

            <v-btn
                color="primary"
                @click="acceptWith"
            >
                Accept
            </v-btn>
        </div>

        <div v-if="delivery.canAcceptorReject">
            <v-btn
                v-if="activeBranchId !== 'company'"
                elevation="0"
                color="light"
                @click="rejectDeliveryAction()"
            >
                Reject
            </v-btn>
            <v-btn
                color="light"
                @click="rejectWith"
            >
                Reject
            </v-btn>
        </div>

        <v-btn
          elevation="0"
          color="primary"
          v-if="isEnabledReschedule"
        >
          Reschedule
        </v-btn>

        <v-btn
            elevation="0"
            color="light"
            @click="cancelDeliveryAction()"
            v-if="isEnabledCancel"
        >
            Cancel
        </v-btn>

        <v-btn
            elevation="0"
            color="primary"
            @click="complete(delivery.id)"
            v-if="isEnabledComplete"
        >
            Complete
        </v-btn>

        <v-btn
            elevation="0"
            color="primary"
            @click="remove(delivery.id)"
            v-if="isEnabledRemove"
        >
            Remove
        </v-btn>
    </div>
</template>

<script>
import { successConfirmAction, warningConfirmAction } from "@/plugins/swal";
import { mapActions, mapGetters, mapState } from 'vuex';
import Swal from 'sweetalert2';
import confirmImage from '../../../public/assets/images/confirm-modal.png'

export default {
    props: {
        delivery: {
            type: Object,
            required: true,
        },
        shouldAccept: {
            type: Boolean,
            default: false
        },
        shouldCancel: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            status: this.delivery.status,
            currentDate: new Date(),
            arrivalDate: new Date(this.delivery.arrival_date),
        }
    },

    watch: {
        async 'shouldAccept'(newVal, oldVal) {
            if (newVal !== oldVal) {
                await this.acceptWith()
            }
        },
        async 'shouldCancel'(newVal, oldVal) {
            if (newVal !== oldVal) {
                await this.cancelDeliveryAction()
            }
        },
    },

    computed: {
        ...mapGetters('auth', ['branches']),
        ...mapState('dashboard', ['activeBranchId', 'nearestBranch']),

        isEnabledComplete() {
            return this.delivery.canAcceptorComplete;
        },

        isEnabledRemove() {
            return this.delivery.canAcceptorArchive;
        },

        isEnabledCancel() {
          return this.delivery.canAcceptorCancel;
        },

        isEnabledReschedule() {
          return this.delivery.canAcceptorReschedule;
        },
    },

    methods: {
        ...mapActions('dashboard', {
            completeAction: 'completeDelivery',
            archiveAction: 'archiveDelivery',
        }),

        acceptDeliveryAction() {
            successConfirmAction('Do you really want to accept this bidding?', 'Accept', async () => {
                await this.$store.dispatch('dashboard/acceptDelivery', {
                    branch_id: this.activeBranchId,
                    delivery_id: this.delivery.id
                });
            });
        },

        rejectDeliveryAction() {
            warningConfirmAction('Do you really want to reject this delivery?', 'Reject', async () => {
                await this.$store.dispatch('dashboard/rejectDelivery', {
                    branch_id: this.activeBranchId,
                    delivery_id: this.delivery.id
                });
            });
        },

        async cancelDeliveryAction() {
            warningConfirmAction(
                'Do you really want to cancel this delivery?',
                'Confirm',
                async (result) => {
                    const inputValue = result.value;
                    await this.$store.dispatch('dashboard/cancelDelivery', {
                        branch_id: this.delivery.branch_id,
                        delivery_id: this.delivery.id,
                        reason: inputValue
                    });
                },
                'text',
                'Enter reason for cancellation...'
            );

            await this.$store.dispatch('dashboard/load');
        },

        async acceptWith() {
            await this.$store.dispatch('dashboard/getNearestBranch', { delivery: this.delivery.id });

            Swal.fire({
                title: `<strong class="tw-text-[28px] tw-font-semibold tw-pt-0">You are accepting this <br/> delivery for ${ this.nearestBranch.name }</strong>`,
                imageUrl: confirmImage,
                imageHeight: 200,
                imageWidth: 230,
                showCloseButton: true,
                focusConfirm: false,
                confirmButtonText: "Confirm",
                confirmButtonColor: '#8F3985',
                confirmButtonAriaLabel: "Thumbs up, great!",
                customClass: 'rounded-xl'
            }).then(({ isConfirmed }) => {
                if (!isConfirmed) return;
                this.$store.dispatch('dashboard/acceptDelivery', {
                    branch_id: this.$store.state.dashboard.nearestBranch.id,
                    delivery_id: this.delivery.id
                });
            });
            await this.$store.dispatch('dashboard/load')
        },

        async rejectWith() {
            warningConfirmAction(
                'Do you really want to reject this delivery?',
                'Reject',
                async () => {
                    await this.$store.dispatch('dashboard/rejectDelivery', {
                        company_id: this.$store.state.auth.company.id,
                        delivery_id: this.delivery.id
                    });
                }
            );

            await this.$store.dispatch('dashboard/load')
        },

        complete(delivery_id) {
            warningConfirmAction("Do you really want to complete the order?", "Confirm", async () => {
                await this.completeAction(delivery_id);
            })
        },

        remove(delivery_id) {
            warningConfirmAction("Do you really want to remove the order?", "Confirm", async () => {
                await this.archiveAction(delivery_id);
            })
        }
    }
}
</script>
