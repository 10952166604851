<template>
    <v-app class="order-review tw-p-[25px] tw-pt-[15px]">
        <div class="tw-flex tw-items-center tw-justify-between">
            <div class="tw-font-bold tw-text-[25px]">
                <span>{{ deliveryDetails.status }}</span>
            </div>
            <v-btn icon @click="close">
                <v-img src="/assets/icons/delete.svg" alt="close icon"/>
            </v-btn>
        </div>

        <div class="tw-grid tw-gap-[15px] tw-p-[15px] tw-border tw-border-d-gray tw-rounded-lg tw-mt-4">
            <div class="tw-h-max-[500px] !tw-overflow-y-scroll tw-h-[390px] overflow-scroll" style="scrollbar-width: thin !important;">
                <div class="tw-grid xl:tw-grid-cols-3 tw-gap-[16px]">
                    <div class="xl:tw-col-span-2">
                        <div class="tw-grid md:tw-grid-cols-2 tw-gap-[16px]">
                            <div class="tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[8px]" :class="{'order-1': isHomeMove}">
                                <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Customer info</strong>
                                <div class="tw-h-[125px] tw-overflow-scroll tw-pr-[15px]">
                                  <ul class="tw-list-none !tw-p-0 tw-m-0">
                                      <li v-for="({ label, value }, index) in moverInfo" :key="index" class="tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]">
                                          <span class="tw-inline-block tw-min-w-[115px] tw-mr-[10px] tw-text-[#6B6D81]" v-text="label"/>
                                          <span class="tw-text-[#6B6D81]" :class="{ 'tw-blur-sm': deliveryDetails.can_accept }">
                                              {{ valueMapper(value) }}
                                          </span>
                                      </li>
                                  </ul>
                                </div>
                            </div>
                            <div class="tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[8px]" :class="{'order-3': isHomeMove}">
                                <div class="tw-flex tw-items-center tw-justify-between tw-mb-[8px]">
                                    <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px]">
                                      {{ isHomeMove ? 'Address ( In home moving )' : 'Pickup address' }}
                                    </strong>

                                    <button @click="copyText(pickup[0].value, 'Pickup address')" class="tw-flex tw-items-center tw-gap-[5px] tw-p-[1px_7px] tw-bg-white tw-text-[#0D3659] tw-border tw-border-solid tw-border-[#E4E8FF] tw-rounded-[8px] tw-duration-300 hover:tw-bg-[#0D3659] hover:tw-border-[#0D3659] hover:tw-text-[#FFF]">
                                        <i class="icony icony-copy tw-max-w-[12px]"></i>
                                        <span class="tw-text-[12px] tw-font-semibold">Copy</span>
                                    </button>
                                </div>
                                <div class="tw-h-[125px] tw-overflow-scroll tw-pr-[15px]">
                                  <ul class="tw-list-none !tw-p-0 tw-m-0">
                                      <li v-for="({ label, value }, index) in pickup" :key="index" class="tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]">
                                          <span class="tw-inline-block tw-min-w-[115px] tw-mr-[10px] tw-text-[#6B6D81]" v-text="label"/>
                                          <span class="tw-text-[#6B6D81]">
                                              {{ valueMapper(value) }}
                                          </span>
                                      </li>
                                  </ul>
                                </div>
                            </div>
                            <div class="tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[8px]" :class="{'order-2 tw-row-span-2 tw-relative tw-min-h-[200px]': isHomeMove}">
                                <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Note</strong>

                                <div v-if="isHomeMove && !deliveryDetails.notes"
                                     class="tw-flex tw-items-center tw-justify-center tw-absolute tw-inset-0">
                                  <img src="/assets/images/empty-box.png" alt="Empty"
                                       class="tw-w-[145px] tw-h-[125px] tw-object-contain"/>
                                </div>

                                <div class="tw-text-[#6B6D81] tw-font-medium tw-mb-[5px] tw-text-[14px] tw-h-[125px] tw-overflow-scroll" :class="{'tw-h-[322px]': isHomeMove}" v-else>
                                    <div v-html="valueMapper(deliveryDetails.notes, 'There is no notes!').replace(/\n/g, '<br>')"/>
                                </div>
                            </div>
                            <div class="tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[8px]"
                                 v-if="deliveryDetails.destination.length !== 0">
                                <div class="tw-flex tw-items-center tw-justify-between tw-mb-[8px]">
                                    <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px]">Destination address</strong>

                                    <button @click="copyText(destination[0].value, 'Destination address')" class="tw-flex tw-items-center tw-gap-[5px] tw-p-[1px_7px] tw-bg-white tw-text-[#0D3659] tw-border tw-border-solid tw-border-[#E4E8FF] tw-rounded-[8px] tw-duration-300 hover:tw-bg-[#0D3659] hover:tw-border-[#0D3659] hover:tw-text-[#FFF]">
                                        <i class="icony icony-copy tw-max-w-[12px]"></i>
                                        <span class="tw-text-[12px] tw-font-semibold">Copy</span>
                                    </button>
                                </div>
                                <div class="tw-h-[125px] tw-overflow-scroll tw-pr-[15px]">
                                  <ul class="tw-list-none !tw-p-0 tw-m-0">
                                      <li v-for="({ label, value }, index) in destination" :key="index" class="tw-font-medium tw-mb-[5px] last:tw-mb-0 tw-text-[14px]">
                                          <span class="tw-inline-block tw-min-w-[115px] tw-mr-[10px] tw-text-[#6B6D81]" v-text="label"/>
                                          <span class="tw-text-[#6B6D81]">
                                              {{ valueMapper(value) }}
                                          </span>
                                      </li>
                                  </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw-h-full tw-bg-[#F4F6FF] tw-p-[12px] tw-rounded-[8px]">
                        <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Item info</strong>
                        <div class="tw-h-[322px] tw-overflow-scroll tw-pr-[15px]">
                          <ul
                              v-for="item of deliveryDetails.items"
                              :key="item.name"
                              class="tw-list-none !tw-p-[0_0_15px_0] last:!tw-pb-0 tw-mb-[15px] last:tw-mb-0 tw-border-b-[1px] tw-border-solid tw-border-[#e4e9ff] last:tw-border-b-0"
                          >
                            <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                <span class="tw-text-[#6B6D81]" v-text="'Item name:'"/>
                                <span class="tw-text-[#6B6D81]">
                                    {{ valueMapper(item.name) }}
                                </span>
                            </li>
                            <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                <span class="tw-text-[#6B6D81]" v-text="'Item quantity:'"/>
                                <span class="tw-text-[#6B6D81]">
                                    {{ valueMapper(item.count) }}
                                </span>
                            </li>
                            <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                <span class="tw-text-[#6B6D81]" v-text="'Weight:'"/>
                                <span class="tw-text-[#6B6D81]">
                                    {{ valueMapper(item.weight, null, 'lbs') }}
                                </span>
                            </li>
                            <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                <span class="tw-text-[#6B6D81]" v-text="'Height:'"/>
                                <span class="tw-text-[#6B6D81]">
                                    {{ valueMapper(item.height, null, 'ft') }}
                                </span>
                            </li>
                            <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                <span class="tw-text-[#6B6D81]" v-text="'Width:'"/>
                                <span class="tw-text-[#6B6D81]">
                                    {{ valueMapper(item.width, null, 'ft') }}
                                </span>
                            </li>
                            <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                <span class="tw-text-[#6B6D81]" v-text="'Length:'"/>
                                <span class="tw-text-[#6B6D81]">
                                    {{ valueMapper(item.length, null, 'ft') }}
                                </span>
                            </li>
                          </ul>
                          <div v-if="deliveryDetails.vehicle">
                            <strong class="tw-block tw-text-[#0D3659] tw-font-semibold tw-text-[16px] tw-mb-[8px]">Other info</strong>
                            <ul class="tw-list-none !tw-p-[0_0_15px_0] last:!tw-pb-0 tw-mb-[15px] last:tw-mb-0 tw-border-b-[1px] tw-border-solid tw-border-[#e4e9ff] last:tw-border-b-0">
                              <li class="tw-flex tw-items-center tw-justify-between tw-mb-[5px] last:tw-mb-0 tw-font-medium tw-text-[14px]">
                                <span class="tw-text-[#6B6D81]" v-text="'Vehicle:'"/>
                                <span class="tw-text-[#6B6D81]">
                                    {{ deliveryDetails.vehicle }}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tw-max-h-[163px] tw-overflow-x-scroll">
              <div
                  v-show="deliveryDetails.images.length"
                  class="tw-grid tw-grid-cols-4 sm:tw-grid-cols-6 lg:tw-grid-cols-8 xl:tw-grid-cols-12 tw-flex-wrap tw-gap-[10px] tw-bg-[#F6F8FF] tw-p-[15px] tw-rounded-[8px] tw-max-w-[100vw]"
              >
                  <div v-for="{ id, path: image } of deliveryDetails.images" :key="id"
                       class="tw-relative tw-rounded-[4px] tw-overflow-hidden tw-cursor-pointer tw-group"
                       @click="openImage(image, id)"
                  >
                      <img :src="image" alt="product image" class="tw-w-full tw-aspect-[4/3] tw-object-cover"/>
                      <div class="tw-flex tw-items-center tw-justify-center tw-bg-black/50 tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-duration-300 tw-opacity-0 group-hover:tw-opacity-100">
                          <i class="icony icony-target-square tw-w-[24px] tw-text-white"></i>
                      </div>
                  </div>
              </div>
            </div>

            <div
              class="tw-flex max-md:tw-flex-col tw-gap-[15px] md:tw-items-center tw-justify-between tw-pt-[15px] tw-border-t-[1px] tw-border-solid tw-border-[#DDDDDD]">
                <div>
                    <div class="tw-flex max-md:tw-flex-col tw-gap-[20px] lg:tw-gap-[65px]">
                        <div class="tw-flex tw-items-start tw-gap-[10px]">
                            <i class="icony icony-calendar tw-w-[14px] tw-max-w-[14px] tw-min-w-[14px] tw-mt-[2px] tw-text-[#8F3985]"></i>
                            <div class="tw-flex tw-w-full tw-flex-col tw-text-[14px] tw-leading-snug">
                                <span v-text="'Date of delivery:'"/>
                                <strong v-text="deliveryDetails.arrival_date"/>
                            </div>
                        </div>
                        <div class="tw-flex tw-items-start tw-gap-[5px]">
                            <i class="icony icony-clock-circle tw-w-[14px] tw-max-w-[14px] tw-min-w-[14px] tw-mt-[2px] tw-text-[#8F3985]"></i>
                            <div class="tw-flex tw-w-full tw-flex-col tw-text-[14px] tw-leading-snug">
                                <span v-text="'Show up time:'"/>
                                <strong v-text="deliveryDetails.arrival_time"/>
                            </div>
                        </div>
                        <div class="tw-flex tw-items-start tw-gap-[10px]">
                            <i class="icony icony-dollar-circle tw-w-[14px] tw-max-w-[14px] tw-min-w-[14px] tw-mt-[2px] tw-text-[#8F3985]"></i>
                            <div class="tw-flex tw-w-full tw-flex-col tw-text-[14px] tw-leading-snug">
                                <span v-text="'Order price:'"/>
                                <strong v-text="'$' + deliveryDetails.price"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tw-flex max-md:tw-justify-end tw-gap-[10px]">
                    <v-btn
                        v-if="deliveryDetails.can_cancel"
                        color="light"
                        elevation="0"
                        @click="cancel"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        v-if="deliveryDetails.can_reject && isLabor"
                        color="light"
                        elevation="0"
                        @click="reject"
                    >
                        Reject
                    </v-btn>
                    <v-btn
                        v-if="deliveryDetails.can_reject && !isLabor"
                        color="light"
                        elevation="0"
                        @click="cancel"
                    >
                        Reject
                    </v-btn>
                    <v-btn
                        v-if="deliveryDetails.can_accept"
                        color="primary"
                        elevation="0"
                        @click="accept"
                    >
                        Accept
                    </v-btn>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import { EventBus } from "../../main";
import ViewImage from '@/components/ViewImage.vue';
import { warningConfirmAction } from "@/plugins/swal";
import { copyText, valueMapper } from '@/plugins/helpers';
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'DeliveryCardDetails',
    props: {
        deliveryDetails: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            moverInfo: [
                { 'label': 'Full name:', 'value': this.deliveryDetails.customer.full_name },
                { 'label': 'Phone number:', 'value': this.deliveryDetails.customer.phone },
                { 'label': 'Email:', 'value': this.deliveryDetails.customer.email },
                { 'label': 'Secondary phone:', 'value': this.deliveryDetails.customer.secondary_phone },
            ],
            pickup: [
                { 'label': 'St address:', 'value': this.deliveryDetails.pickup.address },
                { 'label': 'City or town:', 'value': this.deliveryDetails.pickup.city },
                { 'label': 'State:', 'value': this.deliveryDetails.pickup.state },
                { 'label': 'Zip code:', 'value': this.deliveryDetails.pickup.zip },
                { 'label': 'Flight stairs:', 'value': this.deliveryDetails.pickup.stairs?.display || '' },
            ],
            destination: this.deliveryDetails.destination ? [
                { 'label': 'St address:', 'value': this.deliveryDetails.destination.address },
                { 'label': 'City or town:', 'value': this.deliveryDetails.destination.city },
                { 'label': 'State:', 'value': this.deliveryDetails.destination.state },
                { 'label': 'Zip code:', 'value': this.deliveryDetails.destination.zip },
                { 'label': 'Flight stairs:', 'value': this.deliveryDetails.destination.stairs?.display || '' },
            ] : [],
            isHomeMove: this.deliveryDetails.destination.length === 0,
        }
    },
    computed: {
        ...mapGetters('auth', ['isLabor']),
    },

    methods: {
        valueMapper,
        copyText,
        ...mapActions('labor', ['cancelDelivery']),

        close () {
            this.$emit('close');
        },

        accept() {
            EventBus.$emit('acceptDelivery')
            this.close()
        },

        cancel() {
            EventBus.$emit('cancelDelivery')
            this.close()
        },

        reject() {
            EventBus.$emit('rejectDelivery')
            this.close()
        },

        async cancelDeliveryAction() {
            warningConfirmAction(
                'Do you really want to cancel this delivery?',
                'Confirm',
                async () => {
                    await this.$store.dispatch('labor/cancelDelivery', this.deliveryDetails.id);
                }
            );
            
            await this.$store.dispatch('labor/load');
        },

        openImage (image, id) {
            this.$modal.show(ViewImage, {
                image,
                id,
                actions_exist: false
            }, {
                width: 900,
                height: 650,
            })
        }
    }
}
</script>

<style lang="scss">
.order-review {
    max-height: 95vh !important;
    overflow-y: scroll !important;
}
.order-review__main {
    margin-bottom: 20px;
}
.vm--modal {
    border-radius: 16px !important;
}
</style>
