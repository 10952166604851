import { WORKING_DAY_HOURS, WORKING_DAY_NAMES } from "../plugins/data";

export default {
    namespaced: true,

    state: {
        /** @type {WorkingHours[]} */
        companyWorkingDays: []
    },

    getters: {
        companyWorkingDays: state => {
            return state.companyWorkingDays.map(companyWorkingDay => {
                companyWorkingDay.day = WORKING_DAY_NAMES[companyWorkingDay.day];
                companyWorkingDay.from_hour = WORKING_DAY_HOURS[companyWorkingDay.from_hour];
                companyWorkingDay.to_hour = WORKING_DAY_HOURS[companyWorkingDay.to_hour];

                return companyWorkingDay;
            });
        }
    },

    mutations: {
        SET_COMPANY_WORKING_DAYS (state, payload) {
            state.companyWorkingDays = payload;
        }
    },

    actions: {
        async load (_, branch_id) {
            return axios.get('working-days', {
                params: {
                    branch_id
                }
            });
        },

        async loadCompanyWorkingHours ({ dispatch, commit }) {
            const working_days = await dispatch('load', 'company');

            commit('SET_COMPANY_WORKING_DAYS', working_days);

            return working_days;
        },

        async loadBranchWorkingHours ({ dispatch }, branch_id) {
            return await dispatch('load', branch_id);
        },

        async updateWorkingHours (_, [branch_id, days]) {
            return await axios.post('working-days', { branch_id, days });
        }
    }
}