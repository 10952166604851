export default {
    namespaced: true,

    state: {
        branch_info: {}
    },

    getters: {
        branchInfo: state => state.branch_info
    },

    mutations: {
        SET_BRANCH_INFO(state, payload) {
            state.branch_info = payload;
        },
    },

    actions: {
        async load({ rootState, commit }) {
            const { id } = rootState.auth.activeBranch;
            const branchInfo = await axios.get(`/branches/${ id }?section=branch_info`);

            commit('SET_BRANCH_INFO', branchInfo);
            return branchInfo;
        },

        async update({ rootState, dispatch }, data) {
            const { id } = rootState.auth.activeBranch;

            await axios.put(`/branches/${ id }`, data);
            dispatch('load');
        }
    }

}