<template>
  <v-main class="custom--v-main">
    <app-header>
      <h1 class="team-header text-lg-2xl font-semibold text-secondary">
        Team Member
      </h1>
    </app-header>

    <v-container class="team-member--container" v-if="isLoaded">
      <v-row class="team-member__row" no-gutters>
        <div>
          <h2
            class="team-member__heading text-lg-xl font-semibold text-secondary mb-lg-10"
          >
            Personal Information
          </h2>

          <v-row align="end" class="mb-10">
            <v-col lg="8">
              <app-profile-picture v-model="crew.profile_picture" />
            </v-col>
            <v-col lg="4">
              <div class="d-flex justify-end">
                <v-checkbox
                  label="Activate"
                  hide-details
                  v-model="crew.is_active"
                  class="custom-v-checkbox"
                />
              </div>
            </v-col>
          </v-row>

          <!-- form -->
          <form @submit.prevent class="team-member__form">
            <v-row class="mb-4">
              <v-col cols="12" sm="6" class="py-0">
                <div class="mb-4">
                  <label
                    for="employee_type"
                    class="team-member__label mb-1.5 d-inline-block text-secondary font-medium"
                  >
                    Employment Type
                  </label>

                  <v-select
                    single-line
                    hide-details
                    outlined
                    class="team-member__field custom--v-text-field"
                    :items="employeeTypes"
                    item-text="display"
                    item-value="value"
                    v-model="crew.employee_type"
                    id="employee_type"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <div class="mb-4">
                  <label for="first_name" class="team-member__label mb-1.5 d-inline-block text-secondary font-medium">
                    First name
                  </label>

                  <v-text-field
                    v-model="$v.crew.first_name.$model"
                    single-line
                    hide-details
                    outlined
                    type="text"
                    class="team-member__field custom--v-text-field"
                    id="first_name"
                  />
                  <span
                    style="color: red"
                    v-if="$v.crew.first_name.$error"
                    v-text="firstNameValidationMessage"
                  />
                </div>
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <div class="mb-4">
                  <label for="last_name" class="team-member__label mb-1.5 text-secondary font-medium">Last name</label>

                  <v-text-field
                    v-model="$v.crew.last_name.$model"
                    single-line
                    hide-details
                    outlined
                    type="text"
                    class="team-member__field custom--v-text-field"
                    id="last_name"
                  />
                  <span
                    style="color: red"
                    v-if="$v.crew.last_name.$error"
                    v-text="lastNameValidationMessage"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <div class="mb-4">
                  <label for="email" class="team-member__label mb-1.5 d-inline-block text-secondary font-medium">
                    Email address
                  </label>

                  <v-text-field
                    v-model="$v.crew.email.$model"
                    single-line
                    hide-details
                    outlined
                    type="email"
                    class="team-member__field custom--v-text-field"
                    id="email"
                  />
                  <span
                    style="color: red"
                    v-if="$v.crew.email.$error"
                    v-text="emailValidationMessage"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <div class="mb-4">
                  <label for="phone_number" class="team-member__label d-inline-block mb-1.5 text-secondary font-medium">
                    Cell Phone
                  </label>

                  <v-text-field
                    v-model="$v.crew.phone_number.$model"
                    single-line
                    hide-details
                    outlined
                    type="text"
                    class="team-member__field custom--v-text-field"
                    v-maska="phone_mask"
                    id="phone_number"
                  />
                  <span
                    style="color: red"
                    v-if="$v.crew.phone_number.$error"
                    v-text="phoneValidationMessage"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <div class="mb-4">
                    <label for="phone_number" class="team-member__label d-inline-block mb-1.5 text-secondary font-medium">
                        State
                    </label>
                  <app-state-select v-model="crew.state_id" class="custom-app-state" />
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <div class="mb-4">
                    <label for="phone_number" class="team-member__label d-inline-block mb-1.5 text-secondary font-medium">
                        City or Town
                    </label>
                  <app-city-select
                    v-model="crew.city_id"
                    :state-id="crew.state_id"
                    class="custom-app-city" 
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <div class="mb-4">
                  <label for="zip_code" class="team-member__label d-inline-block mb-1.5 text-secondary font-medium">Zip code</label>
                  
                  <v-text-field
                    v-model="$v.crew.zip_code.$model"
                    single-line
                    hide-details
                    outlined
                    type="number"
                    class="team-member__field custom--v-text-field"
                    v-maska="zip_mask"
                    id="zip_code"
                  />
                  <span
                    style="color: red"
                    v-if="$v.crew.zip_code.$error"
                    v-text="zipCodeValidationMessage"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <div class="mb-4">
                  <label for="skills" class="mb-1.5 text-secondary font-medium">Skills</label>
                  <v-checkbox
                    label="Driver"
                    hide-details
                    v-model="crew.is_driver"
                    class="custom-v-checkbox"
                    id="skills"
                    />
                </div>
              </v-col>
            </v-row>

            <div class="team-member__buttons d-flex justify-between justify-sm-end mt-10 flwe-wrap">
              <v-btn
                depressed
                color="light"
                class="custom--v-btn w-36 mr-3"
                @click="$router.go(-1)"
              >
                Cancel
              </v-btn>

              <v-btn
                depressed
                color="secondary"
                class="custom--v-btn w-36 mr-3"
                @click="deleteCrew"
                v-if="!isCreating"
              >
                Delete
              </v-btn>

              <v-btn
                depressed
                color="primary"
                class="custom--v-btn w-36"
                :class="{'button-save': !isCreating}"
                @click="submit"
              >
                Save
              </v-btn>
            </div>
          </form>
        </div>
      </v-row>
    </v-container>

    <app-loading-indicator v-else />
  </v-main>
</template>

<script>
import { validationMixin } from "vuelidate";
import { email, required, minLength, maxLength } from 'vuelidate/lib/validators';

import { mapActions } from "vuex/dist/vuex.esm.browser";
import loading from "../../mixins/loading";
import AppLoadingIndicator from "../../components/AppLoadingIndicator";
import AppProfilePicture from "../../components/AppProfilePicture";
import AppStateSelect from "../../components/AppStateSelect";
import AppCitySelect from "../../components/AppCitySelect";
import { maska } from "maska";
import { phone_mask, zip_mask } from "@/plugins/custom_masks";
import { sweetAlertError, warningConfirmAction } from "@/plugins/swal.js";

export default {
  mixins: [loading, validationMixin],

  components: {
    AppLoadingIndicator,
    AppProfilePicture,
    AppStateSelect,
    AppCitySelect,
  },

  data() {
    return {
      /** @type {Crew} */
      crew: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        zip_code: "",
        profile_picture: null,
        branch_id: this.$route.params.branch_id,
      },

      zip_mask,
      phone_mask,

      employeeTypes: [
        { value: "1", display: "Full time" },
        { value: "2", display: "Part time" },
        { value: "3", display: "Contractor" },
      ],
    };
  },

  validations: {
    crew: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      phone_number: { required, minLength: minLength(19) },
      zip_code: { required, minLength: minLength(5) },
    },
  },

  directives: {
    maska,
  },

  methods: {
    ...mapActions("team", ["show", "update", "store", "destroy"]),

    // Create or update crew
    async submit() {
      if (!this.$v.$invalid) {
        (this.isCreating
          ? this.store({
              branch_id: this.$route.params.branch_id,
              data: this.crew,
            })
          : this.update({
              ...this.$route.params,
              data: this.crew,
            })
        )
          .then((response) => {
            this.$router.push({
              name: "teams",
              params: {
                branch_id: this.$route.params.branch_id,
              },
            });
          })
          .catch((error) => {
            sweetAlertError(error);
          });
      }
    },

    // Delete crew
    async deleteCrew() {
      warningConfirmAction(
        "Do you want to delete this crew?",
        "Delete",
        async () => {
          await this.destroy(this.$route.params);
          await this.$router.push({
            name: "teams",
            params: {
              branch_id: this.$route.params.branch_id,
            },
          });
        }
      );
    },
  },

  computed: {
    isCreating() {
      return this.$route.params.crew_id === "new";
    },

    firstNameValidationMessage() {
      if (!this.$v.crew.first_name.required) {
        return "First Name is required!";
      } else {
        return "";
      }
    },

    lastNameValidationMessage() {
      if (!this.$v.crew.last_name.required) {
        return "Last Name is required!";
      } else {
        return "";
      }
    },

    emailValidationMessage() {
      if (!this.$v.crew.email.required) {
        return "Email is required!";
      } else if (!this.$v.crew.email.email) {
        return "Email is not valid!";
      } else {
        return "";
      }
    },

    phoneValidationMessage() {
      if (!this.$v.crew.phone_number.required) {
        return "Phone number is required!";
      } else if (!this.$v.crew.phone_number.minLength) {
        return "Phone number is not valid!";
      } else {
        return "";
      }
    },

    zipCodeValidationMessage() {
      if (!this.$v.crew.zip_code.required) {
        return "Zip Code is required!";
      } else if (!this.$v.crew.zip_code.minLength) {
        return "Zip Code is not valid!";
      } else {
        return "";
      }
    },
  },

  async created() {
    if (this.isCreating) {
      this.loadingFinish();
      return true;
    }

    await this.startLoading(async () => {
      this.crew = await this.show(this.$route.params);
    });
  },
};
</script>

<style scoped lang="scss">
::v-deep {
    .custom-v-checkbox {
        i {
            font-size: 1.2rem !important;
        }
        label {
            color: #0D3659;
            font-weight: 500;
        }
    }
    .custom--v-btn.button-save {

        @media (max-width: 480px) {
            width: 100%;
            max-width: 320px;
            margin: 10px auto 0;
        } 
    }

    @media (max-width: 375px) {
        .custom--v-btn {
            width: 100% !important;
            
            &:first-child {
                margin-bottom: 10px;
            }
        }        
    }
}
</style>
