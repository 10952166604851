<template>
    <app-loading-indicator
        v-show="!isLoaded"
    />
</template>

<script>
import loading from "@/mixins/loading";
import { mapActions } from "vuex";
import AppLoadingIndicator from "@/components/AppLoadingIndicator";

export default {
    mixins: [loading],

    components: {
        AppLoadingIndicator,
    },

    methods: {
        ...mapActions('auth', ['logout']),
    },

    created () {
        this.startLoading(async () => {
            return await this.logout();
        })
    }
}
</script>