export default {
    namespaced: true,

    state: {
        /**
         * @type photos
         */
        photos: {}
    },

    getters: {
        images: state => state.photos
    },

    mutations: {
        SET_PHOTOS (state, payload) {
            state.photos = payload
        }
    },

    actions: {
        /**
         *
         * @param rootState
         * @param commit
         * @returns {Promise<void>}
         */

        async load ({rootState, commit}) {
            const { id } = rootState.auth.activeBranch;
            const photos = await axios.get(`branches/${id}/photos`);

            commit('SET_PHOTOS', photos);
        },

        /**
         *
         * @param rootState
         * @param dispatch
         * @param photo
         * @returns {Promise<void>}
         */
        async create ({rootState, dispatch}, {photo}) {
            const { id } = rootState.auth.activeBranch;
            await axios.post(`branches/${id}/photos`, {
                photo
            });

            dispatch('load');
        },

        async pin ({ rootState, dispatch }, { image_id }) {
            const { id } = rootState.auth.activeBranch;
            await axios.put(`branches/${id}/photos/${image_id}`, {
                is_pinned: 1
            });

            dispatch('load');
        },

        async delete ({rootState, dispatch}, { image_id }) {
            const { id } = rootState.auth.activeBranch;
            await axios.delete(`branches/${id}/photos/${image_id}`);

            dispatch('load');
        },
    }
}