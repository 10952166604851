<template>
    <div class="tw-relative tw-w-[120px] tw-aspect-square">
        <v-avatar size="120" color="primary">
            <v-img v-if="local" :src="local" />

            <v-icon v-else dark size="40">
                fa-user
            </v-icon>
        </v-avatar>

        <div @click="onUploadClick" class="tw-flex tw-absolute -tw-bottom-[5px] -tw-right-[12px] tw-z-10 tw-cursor-pointer">
            <input
                v-show="false"
                type="file"
                ref="fileAvatar"
                accept="image/*"
                @change="onUploadImage"
            >

            <i class="icony icony-camera tw-min-w-[42px] tw-text-[#0D3659]"></i>
        </div>
    </div>
</template>

<script>
import ValueEmitter from "../mixins/ValueEmitter";
import file_to_base64 from "../plugins/file_to_base64";

export default {
    mixins: [ValueEmitter],

    methods: {
        /**
         * Triggers on user image upload
         *
         * @param {File} file
         */
        async onUploadImage ({ currentTarget: { files: [file] } }) {
            this.local = await file_to_base64(file);
        },

        /**
         * Triggers when user clicks upload icon
         *
         * @returns {Promise<void>}
         */
        async onUploadClick () {
            this.$refs.fileAvatar.click();
        }
    }
}
</script>

<style scoped lang="scss">
.custom--v-btn::v-deep {
        .v-btn.v-btn--has-bg  {
            background-color: none !important;
        }
    }
</style>
