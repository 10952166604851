export const only_integers_allowed = {
    mask: 'X#*',
    tokens: {
        'X': {
            pattern: /[1-9]/
        },
        '#': {
            pattern: /[0-9]/
        }
    }
}

export const zip_mask = {
    mask: '#####',
}

export const phone_mask = {
    mask: '+1 (XXX) - XXX - XXXX',
    tokens: {
        'X' : {
            pattern: /[0-9]/
        }
    }
}

export const date_mask = {
    mask: '##/##/####'
}