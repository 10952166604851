<template>
    <component :is="component" :class="classes">
        <div class="loading-box">
            <v-progress-circular
                :size="50"
                color="secondary"
                indeterminate
            />
        </div>
    </component>
</template>

<script>
export default {
    props: {
        small: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        component () {
            return this.small ? 'div' : 'v-container'
        },

        classes () {
            return !this.small ? 'pa-0' : '';
        }
    }
};
</script>

<style lang="scss" scoped>
.loading-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 76px);
}
</style>
