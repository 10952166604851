<template>
    <main v-if="isLoaded" class="introduction">
        <section class="introduction-about">
            <h1 class="introduction__header text-secondary">Why {{ activeBranch.name }}?</h1>

            <v-textarea
                v-model.trim="introduction.about"
                ref="about"
                auto-grow
                rows="6"
                aria-valuemin="20"
                placeholder="About"
                dense
                outlined
                background-color="white"
                class="custom--v-textarea"
                :disabled="is_disable"
                :error-messages="aboutErrors"
                @input="$v.introduction.$touch()"
                @blur="$v.introduction.$touch()"
            >
            </v-textarea>
            <div class="introduction-about__actions d-flex justify-end">
                <v-btn depressed color="primary" min-width="69"
                       min-height="36" class="mr-2.5"
                       @click="editAbout"
                >
                    Edit
                </v-btn>
                <v-btn depressed color="secondary"
                       max-width="69" min-height="36"
                       :disabled="$v.$invalid || is_disable"
                       @click="updateAbout"
                >
                    Save
                </v-btn>
            </div>
        </section>
        <v-divider class="my-4"></v-divider>
        <section class="introduction-services">
            <h1 class="introduction__header text-secondary">Services</h1>
            <div class="introduction-services__lists d-flex flex-wrap">
                <div class="introduction-services__list"
                     v-for="{ id, service, is_active } in services" :key="id"
                     :class="is_active ? 'introduction-services__active' : ''"
                     @click="activeService(id)"
                >
                    {{ service }}
                </div>
            </div>
        </section>
        <v-divider class="my-4"></v-divider>
        <dashboard-faq/>
    </main>
    <app-loading-indicator v-else/>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import loading from "../../mixins/loading.js";
import AppLoadingIndicator from "@/components/AppLoadingIndicator.vue";
import DashboardFaq from "@/views/FAQ/DashboardFaq.vue";

export default {
    name: "Introduction",

    components: { DashboardFaq, AppLoadingIndicator },

    mixins: [loading, validationMixin],

    validations: {
        introduction: {
            about: {
                required,
                minLength: minLength(20),
                maxLength: maxLength(1000)
            }
        }
    },

    data () {
        return {
            is_loading: false,
            is_disable: true
        }
    },

    computed: {
        ...mapGetters('profile_introduction', ['introduction', 'services']),
        ...mapGetters('auth', ['activeBranch']),

        aboutErrors () {
            const errors = [];
            if (! this.$v.introduction.about.$dirty) return errors
            ! this.$v.introduction.about.required && errors.push('About field is required');
            ! this.$v.introduction.about.minLength && errors.push('About text should contain at least 20 characters');
            ! this.$v.introduction.about.maxLength && errors.push('Sorry you are exceeding the limit');
            return errors;
        },

        introductionLength () {
            return this.introduction.about.length;
        }
    },

    methods: {
        ...mapActions('profile_introduction', ['load', 'update']),
        ...mapMutations('profile_introduction', ['SET_INTRODUCTION']),

        editAbout () {
            this.is_disable = false;
            this.$nextTick(() => {
                this.$refs.about.focus();
            });
        },

        updateAbout () {
            this.$v.$touch();

            //if invalid return false
            if (this.$v.$invalid) {
                return false;
            }

            this.SET_INTRODUCTION(this.introduction);
            this.update({ new_data: { about_us: this.introduction.about } });
            this.is_disable = true;
        },

        /**
         *
         * @param serviceId
         */
        activeService (serviceId) {
            this.services.forEach(service => {
                if (service.id === serviceId) {
                    service.is_active = ! service.is_active;
                }
            });

            this.SET_INTRODUCTION(this.introduction);
            this.update({ new_data: { selected_services: this.introduction.services } });
        }
    },

    created () {
        this.startLoading(async () => {
            await this.load();
        });
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/pages/profile/introduction";

::v-deep {
    .introduction-about .custom--v-textarea .v-input__control .v-input__slot {
        padding: 15px !important;
    }
}
</style>